import { Form, Button, Row, Col, Modal, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WebService from "../../../Services/WebService";
import TextEditor from "../../../components/Common/TextEditor/Editor";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import editIcon from "../../../assets/images/icon-edit.svg";
import lockIcon from "../../../assets/images/icon-lock.svg";
import { Link } from "react-router-dom";
import HelperService from "../../../Services/HelperService";
import { essayInterface } from "../../../OpenInterfaces";
import CardLoader from "../../../components/Common/CardLoader";
import { Label } from "../../../components/Common/Label/Label";

interface propData {
    isUpdate: Function;
    tab?: string;
}

const PersonalEssay = (props: propData) => {
    const { handleSubmit, formState: { errors }, register, watch, control, reset, setValue } = useForm<any>({});
    const watchAllFields = watch();
    const [essayData, setEssayData] = useState<essayInterface[]>([])
    const [isUnderstand, setIsUnderstand] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedEasy, setSelectedEasy] = useState<string>("");
    const [editQuestion, setEditQuestion] = useState<string>("");
    const [editorValue, setEditorValue] = useState<string>("");
    const [editorCount, setEditorCount] = useState<number>(0);
    const [essayCount, setEssayCount] = useState<number>(0);
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        if (props.tab == "personalEssay") {
            getPersonalEssay();
        }
    }, [props.tab]);

    const getPersonalEssay = () => {
        setIsLoading(true);
        WebService.getAPI({
            action: `get/user-writing-sample/detail`,
        })
            .then((res: any) => {
                if (res.is_prompt_resonates && res.is_prompt_resonates.length) {
                    let count: number = 0;
                    let temp: essayInterface[] = [];
                    res.is_prompt_resonates.map((item: essayInterface) => {
                        if (item.description) { count = ++count }
                        temp.push({ ...item, ...{ value: item.question, count: item.description ? HelperService.countWords(HelperService.removeHtml(item.description)) : 0 } })
                    });
                    setEssayCount(count)
                    setEssayData(temp)
                }
                setIsUnderstand(res.is_submit_your_essay);
                setIsLoading(false);
            })
            .catch((e) => { setIsLoading(false); });
    };

    const onSave = (is_understand: boolean) => {
        const obj = {
            is_submit_your_essay: is_understand,
            is_prompt_resonates: essayData
        }
        WebService.postAPI<any>({
            action: `save/user-writing-sample`,
            body: obj,
            id: "writing_sample_btn",
        })
            .then((res: any) => {
                props.isUpdate();
                toast.success(res.message);
            })
            .catch((e: any) => { });
    };

    const onEditEssay = (item: essayInterface) => {
        updateValues(item);
        setShowModal(true);
    };

    const updateValues = (item: essayInterface) => {
        setSelectedEasy(item.id);
        setEditQuestion(item.question);
        setEditorValue(item.description);
        setEditorCount(item.count);
    };

    const onClose = () => {
        setSelectedEasy("");
        setEditQuestion("");
        setEditorValue("");
        setEditorCount(0);
        setShowModal(false);
    };

    const onSaveEssay = () => {
        let obj: any = {
            id: selectedEasy,
            description: editorValue
        };

        WebService.postAPI({
            action: `save/writing-sample`,
            body: obj,
            id: "save_sample"
        })
            .then((res: any) => {
                toast.success(res.message);
                getPersonalEssay();
                onClose();
            })
            .catch((e) => { });
    };


    return (
        <>
            {/* <form onSubmit={handleSubmit(onSave)}> */}
            <Row>
                <Col  style={{maxWidth: "624px"}}>
                    <h4 className="font-medium font-20 mb-3 pb-">Personal Essay</h4>
                    <Form className="form-style-2">
                        <p className="font-16">
                            Many colleges request a personal essay with your college application. You have the choice to submit a personal essay to any college, regardless of whether it's required. For colleges that don't require it, you'll have the choice to either submit your essay or opt out during the application process.<span className="text-danger">*</span>
                        </p>
                        {
                            isLoading ? <CardLoader /> :
                                <>
                                    <Form.Group className="mb-4">
                                        <Form.Check
                                            type="checkbox"
                                            id="Yes"
                                            label="I understand"
                                            checked={isUnderstand}
                                            disabled={isLoading}
                                            onChange={() => { setIsUnderstand(!isUnderstand); onSave(isUnderstand ? false : true); }}
                                        />
                                    </Form.Group>

                                    {
                                        essayData && essayData.length > 0 && essayData.map((item: essayInterface, index: number) => {
                                            return (
                                                <>
                                                    {
                                                        item.description &&
                                                        <Card className={`border border-light card-padding mb-4 ${item.isCompleted ? 'bg-light' : ""}`}>
                                                            <div className="d-flex gap--3 justify-content-between">
                                                                <h4 className="font-18 font-medium mb-4">{item.question}</h4>
                                                                <div>
                                                                    {item.isCompleted ?
                                                                        <img src={lockIcon} alt="icon" width={15} />
                                                                        :
                                                                        <img onClick={() => onEditEssay(item)} className="cursor-pointer" src={editIcon} alt="icon" />}
                                                                </div>
                                                            </div>
                                                            {/* <div dangerouslySetInnerHTML={{ __html: item.description || "" }} /> */}
                                                            <div className="mb-3 font-16">{HelperService.removeHtml(item.description).slice(0, 240)}{HelperService.removeHtml(item.description).length > 240 && "..."}</div>
                                                            <Form.Check
                                                                type="switch"
                                                                label="This essay is completed"
                                                                id="completed"
                                                                disabled={item.count < 250 ? true : false}
                                                                checked={item.isCompleted}
                                                                onChange={() => { essayData[index].isCompleted = !essayData[index].isCompleted; setEssayData([...essayData]); onSave(isUnderstand) }}
                                                            />
                                                        </Card>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }

                        {!isLoading && essayData && essayData.length != essayCount && <Link onClick={() => setShowModal(true)} className="text-link mb-2" to={""}>
                            + Add an essay
                        </Link>}
                    </Form>
                    {/* {!isLoading && <div className="text-center">
                            <Button
                                type="submit"
                                id="writing_sample_btn"
                                className="btn btn-brand-1 w-100 mt-2"
                            >
                                Save
                            </Button>
                        </div>} */}
                </Col>
            </Row>
            {/* </form> */}

            <Modal show={showModal} onHide={() => { onClose() }} centered size="lg">
                <Modal.Header closeButton><Modal.Title>Personal Essay</Modal.Title></Modal.Header>
                <Modal.Body className="form-style-2">
                    <p className="font-16">
                        Your essay presents an opportunity to showcase your ability to write well and share something unique about yourself, beyond academics and scores. What do you want the admissions team to know about you that isn't evident from your grades and test scores?
                        <br />
                        Choose a prompt that resonates with you and write an essay no longer than 650 words. Use the prompt as a foundation for your story, but remember, 650 words is the cap, not the requirement. Feel free to use less space if it suffices to express your thoughts. Essays shorter than 250 words will not be considered.
                    </p>
                    <div>
                        <div className="react-select mb-3">
                            <OpenSelect options={essayData as any[]} selected={selectedEasy} isSearchable={true} onChange={(e: any) => { updateValues(e); }} />
                        </div>

                        {selectedEasy && <div>
                            <p className="font-16 mb-3">Write your essay on the topic you've selected:<br />
                                <i className="text-brand">{editQuestion} </i> <br />You can type directly into the box, or you can paste text from another source.</p>
                            <TextEditor
                                skey={selectedEasy}
                                key={selectedEasy}
                                wordLimit={650} minWords={250}
                                data={editorValue || ""}
                                onChange={(data: any, count: number) => { setEditorValue(data); setEditorCount(count); }}
                                updateWordCount={(wordCount: number) => { wordCount > 650 ? setShowError(true) : setShowError(false) }} />
                            {
                                editorValue && showError &&
                                <div className="login-error mb-3">
                                    <Label
                                        title={("Please enter less than 650 words.")}
                                        modeError={true}
                                    />
                                </div>
                            }
                        </div>}
                    </div>
                    <div className="d-flex">
                        <Button type="button" className="btn-brand-1 w-100 m-1 mb-3" id="save_sample" disabled={!editorValue || !selectedEasy || showError} onClick={() => onSaveEssay()}>Save</Button>
                        <Button className="btn-outline-brand w-100 m-1 mb-3" onClick={() => { onClose() }}>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default PersonalEssay;
