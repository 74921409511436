import { useEffect, useState } from "react";
import Logo from "../../assets/images/open-logo.svg";
import { Form, Button, Modal, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./signup.scss";
import { Controller, useForm } from "react-hook-form";
import {
  API_OTPInterface,
  meCallInterface,
  socialSignupFormInterface,
} from "../../OpenInterfaces";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import { useAuth0 } from "@auth0/auth0-react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import {
  SET_SIGNUP_DATA,
  USER_INFO,
  USER_LOGIN_SUCCESS,
  setDataInRedux,
} from "../../action/CommonAction";
import PhoneNumberInput from "../Common/PhoneNumberInput/PhoneNumberInput";
// import CometChatWebService from '../../Services/CometChatWebService';
// import { CometChatUIKit } from "@cometchat/chat-uikit-react";

const SocialSignup = () => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    setValue,
    register,
  } = useForm<socialSignupFormInterface>({});
  const watchAllFields = watch();
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
  const mobileRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  const nameRegex = /^[A-Za-z][A-Za-z_ ]{1,30}$/;
  const { user, isAuthenticated } = useAuth0();
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const roleOptions = [
    { id: "STUDENT", value: "Student", isDisable: false },
    { id: "SCHOOL", value: "High School Representative", isDisable: true },
    { id: "PARENT", value: "Parent or guardian", isDisable: true },
  ];

  useEffect(() => {
    setValue("phone_code", "239");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user && !isAuthenticated) {
      navigate("/login");
    } else {
      setValue("firstName", user?.given_name || "");
      user?.family_name != "undefined" &&
        setValue("lastName", user?.family_name || "");
      setValue("email", user?.email || "");
    }
  }, [user, isAuthenticated]);

  const handleClose = () => setShow(false);

  const handleShow = (type: string) => {
    if (type) {
      setType(type);
    }
    setShow(true);
  };

  const onSignup = (data: socialSignupFormInterface) => {
    if (!user?.sub) {
      navigate("/login");
    }
    data.phone = data.phone.replaceAll("-", "");
    if (user?.sub?.includes("google")) {
      data.social_type = "GOOGLE";
    } else if (user?.sub?.includes("facebook")) {
      data.social_type = "FACEBOOK";
    } else if (user?.sub?.includes("linkedin")) {
      data.social_type = "LINKEDIN";
    }
    data.social_id = user?.sub || "";
    WebService.postAPI<API_OTPInterface>({
      action: "social-login",
      body: data,
      id: "save_btn",
    })
      .then((res: API_OTPInterface) => {
        localStorage.removeItem("is_social");
        if (data.social_type == "GOOGLE") {
          localStorage.setItem("token", res?.access_token);
          userMeCall(res.access_token);
        } else {
          dispatch(setDataInRedux({ type: SET_SIGNUP_DATA, value: data }));
          navigate("/verify-email");
        }
      })
      .catch((e: any) => {});
  };

  const userMeCall = async (token: string) => {
    await WebService.getAPI<meCallInterface>({ action: "me" })
      .then(async (res: meCallInterface) => {
        toast.success("You are now logged in.");
        // await createCometChatUser(res.result);
        res?.result?.is_pending_question &&
          localStorage.setItem("pending_ques", "true");
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: { access_token: token },
        });
        dispatch(setDataInRedux({ type: USER_INFO, value: res.result }));
      })
      .catch(() => {
        typeof window !== "undefined" && localStorage.clear();
        document.cookie.split(";").forEach(function (c) {
          document.cookie =
            c.trim().split("=")[0] +
            "=;" +
            "expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        });
      });
  };

  const createCometChatUser = async (data: any) => {
    const abc: any = {
      uid: data.uuid,
      name: data.firstName + " " + data.lastName,
      role: data.role,
    };

    if (data.avtar) {
      abc.avatar = data.avtar;
    }

    // CometChatWebService.postAPI({ action: "users", body: abc, id: "login_btn", })
    //     .then(async (res: any) => {
    //         await commetLogin(data.uuid);
    //     })
    //     .catch(async (e: any) => {
    //         if (e.code == "ERR_UID_ALREADY_EXISTS") {
    //             await commetLogin(data.uuid)
    //         }
    //     });
  };

  async function commetLogin(uid: string) {
    // try { CometChatUIKit.login(uid)?.then(loggedInUser => { }) }
    // catch (error) {  }
  }

  return (
    <>
      <div className="login-page signup-page">
        <Container>
          <div className="row g-0 justify-content-center align-items-center min-vh-100">
            <div className="col-lg-5 d-flex align-items-center justify-content-center">
              <div className="mb-3 w-100">
                <div className="px-lg-5 mb-3">
                  <div className="mb-4 text-center">
                    <img src={Logo} width="110" alt="Logo" />
                  </div>
                  <form onSubmit={handleSubmit(onSignup)}>
                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow">
                      <div className="text-center">
                        <h3 className="mb-2 font-bold mb-4">
                          Complete your profile
                        </h3>
                      </div>

                      {/* First Name */}
                      <Controller
                        control={control}
                        name="firstName"
                        rules={{
                          required: "Please Enter First Name",
                          pattern: {
                            value: nameRegex,
                            message:
                              "Between 2 to 30 characters, letters only.",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched, isDirty },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="firstName"
                            >
                              <Form.Label>
                                First name<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${
                                  watchAllFields.firstName ? "border-brand" : ""
                                }`}
                                value={watchAllFields?.firstName}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            </Form.Group>
                            {(errors["firstName"]?.message ||
                              Boolean(errors["firstName"]?.message) ||
                              (isTouched && !watchAllFields.firstName) ||
                              (watchAllFields.firstName &&
                                !nameRegex.test(watchAllFields.firstName))) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.firstName?.message ||
                                    watchAllFields.firstName
                                      ? "Between 2 to 30 characters, letters only"
                                      : "Please Enter First Name."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Last Name */}
                      <Controller
                        control={control}
                        name="lastName"
                        rules={{
                          required: "Please Enter Last Name",
                          pattern: {
                            value: nameRegex,
                            message:
                              "Between 3 to 30 characters, letters only.",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched, isDirty },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="lastName"
                            >
                              <Form.Label>
                                Last name<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${
                                  watchAllFields.lastName ? "border-brand" : ""
                                }`}
                                value={watchAllFields?.lastName}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            </Form.Group>
                            {(errors["lastName"]?.message ||
                              Boolean(errors["lastName"]?.message) ||
                              (isTouched && !watchAllFields.lastName) ||
                              (watchAllFields.lastName &&
                                !nameRegex.test(watchAllFields.lastName))) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.lastName?.message ||
                                    watchAllFields.lastName
                                      ? "Between 2 to 30 characters, letters only"
                                      : "Please Enter Last Name."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Phone Number */}
                      <Controller
                        control={control}
                        name="phone"
                        rules={{
                          required: "Please Enter Phone Number",
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="phone"
                            >
                              <Form.Label>
                                Phone Number
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <PhoneNumberInput
                                selected={watchAllFields.phone_code}
                                value={watchAllFields.phone}
                                onChange={(e: any) => onChange(e)}
                                onBlur={onBlur}
                                onChangeCode={(e: any) =>
                                  setValue("phone_code", e)
                                }
                              />
                            </Form.Group>
                            {(errors["phone"]?.message ||
                              Boolean(errors["phone"]?.message) ||
                              (isTouched && !watchAllFields.phone)) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.phone?.message ||
                                    watchAllFields.phone
                                      ? "Please Enter valid Phone Number."
                                      : "Please Enter Phone Number."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Email */}
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: "Please Enter Email",
                          pattern: {
                            value: emailRegex,
                            message: "Enter valid email address",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched, isDirty },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="email"
                            >
                              <Form.Label>
                                Email<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${
                                  watchAllFields.email ? "border-brand" : ""
                                }`}
                                value={watchAllFields?.email}
                                onChange={onChange}
                                onBlur={onBlur}
                                disabled={user?.email ? true : false}
                              />
                            </Form.Group>
                            {(errors["email"]?.message ||
                              Boolean(errors["email"]?.message) ||
                              (isTouched && !watchAllFields.email) ||
                              (watchAllFields.email &&
                                !emailRegex.test(watchAllFields.email))) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.email?.message ||
                                    watchAllFields.email
                                      ? "Enter valid email address"
                                      : "Please Enter Email."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Role */}
                      <Controller
                        control={control}
                        name="role"
                        rules={{
                          required: "Please Select User Type",
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched, isDirty },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>
                                Account Used For
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select onChange={onChange} onBlur={onBlur}>
                                <option disabled selected hidden value="">
                                  Choose an Option
                                </option>
                                {roleOptions.map((item: any) => {
                                  return (
                                    <option
                                      value={item.id}
                                      disabled={item.isDisable}
                                    >
                                      {item.value}
                                    </option>
                                  );
                                })}
                                ;
                              </Form.Select>
                            </Form.Group>
                            {(errors["role"]?.message ||
                              Boolean(errors["role"]?.message) ||
                              (isTouched && !watchAllFields.role)) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.role?.message || watchAllFields.role
                                      ? "Please Select User Type"
                                      : "Please Select User Type"
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      <div className="font-14 d-flex flex-wrap">
                        <Form.Check
                          type="checkbox"
                          id="remember"
                          label="I agree to the"
                          className="font-14 manage-alignment"
                          checked={isAccepted}
                          onChange={() => setIsAccepted(!isAccepted)}
                        />
                        &nbsp;
                        <a
                          onClick={() => handleShow("term")}
                          className="text-brand font-14 font-medium cursor-pointer mt-1"
                        >
                          Terms and Conditions{" "}
                        </a>
                        &nbsp;<span className="mt-1">and</span>&nbsp;
                        <a
                          className="text-brand font-14 font-medium cursor-pointer mt-1"
                          onClick={() => handleShow("policy")}
                        >
                          Privacy Policy
                        </a>
                      </div>
                      {isValid && !isAccepted && (
                        <div className="login-error">
                          <Label
                            title={"Please Accept Terms and Conditions"}
                            modeError={true}
                          />
                        </div>
                      )}
                      <div className="text-center mt-3">
                        <Button
                          className="btn btn-brand-1 w-100 mb-3"
                          type="submit"
                          id="save_btn"
                          disabled={!isValid || !isAccepted}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* Terms Codions modal  */}
      <Modal show={show} onHide={handleClose} size="lg" centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {type == "term" && "Terms and Conditions"}{" "}
            {type == "policy" && "Privacy Policy"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type == "term" && (
            <>
              <p>
                Locks4phone is an application which contains ads and in-app
                purchases. This Service is provided by Sayee Shishir Karna . If
                you choose to use these Service, then you agree to the policies
                defined here. I will not use or share your information with
                anyone except as described in this Privacy Policy.
              </p>
              <p>About the Accessibility API Usages</p>
              <p>
                Locks4phone app uses accessibility services even when app is
                closed and not in use . Locks4phone app uses accessibility
                services to detect phones locked and unlocked conditions
                .Accessibility service is used to lock phone and provide lock
                functionality for immediate locks feature and scheduled locks
                feature . Accessibility services is also used to prevent opening
                settings and prevent uninstallation when strict mode feature is
                enabled by user . Accessibility services are used for these
                functions and data is not collected or shared in any way . Once
                accessibility permissions are granted , accessibility
                permissions persists until explicitly revoked .
              </p>
              <p>
                Please note this app does use third party services that may
                collect information used to identify you.
              </p>
              <p>
                Third party Service Providers used in this application - To
                serve advertisement : Google ads
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app
              </p>
              <p>
                {" "}
                <a
                  href="https://support.google.com/admob"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/admob
                </a>{" "}
              </p>
            </>
          )}
          {type == "policy" && (
            <>
              <p>
                I may update these Privacy Policies from time to time. Thus, you
                are advised to review this page periodically for any changes. I
                will notify you of any changes by posting the new or updated
                Privacy Policies on this page. These changes are effective
                immediately, after they are posted on this page.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SocialSignup;
