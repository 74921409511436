import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './config/Store';
import './i18n/config';
import { Auth0Provider } from '@auth0/auth0-react';

// import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
// import { CometChatUIKit } from "@cometchat/chat-uikit-react";
// import { CometChat } from '@cometchat/chat-sdk-javascript';
import { APP_ID, AUTH_KEY, REGION } from './Services/CometChatWebService';

const AppConstants = {
  APP_ID: APP_ID,
  REGION: REGION,
  AUTH_KEY: AUTH_KEY,
};

const metaInfo = {
  name: "cometchat-chat-sample-app-react",
  version: "4.3.4",
  type: "sample",
  platform: "React"
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain="dev-ly1a640v0e7eikin.us.auth0.com"
    clientId="fI7uJvVQkbLVZsMZY3fKnWwUHbMN4ERm"
    authorizationParams={{
      redirect_uri: `${window.location.origin}/login`,
    }}
  // useRefreshTokens
  // cacheLocation="localstorage"
  >
    <Provider store={store}>
      {/* <Router> */}
        <App />
      {/* </Router> */}
    </Provider>
  </Auth0Provider>
);



// (async () => {
//   const uiKitSettings = new UIKitSettingsBuilder()
//     .setAppId(AppConstants.APP_ID)
//     .setRegion(AppConstants.REGION)
//     .setAuthKey(AppConstants.AUTH_KEY)
//     .subscribePresenceForAllUsers()
//     .build();
//   try {
//     await CometChatUIKit.init(uiKitSettings);
//     try { CometChat.setDemoMetaInfo(metaInfo) } catch (err) { }
//     console.log("Initialization completed successfully");
//     const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
//     root.render(
//       <Auth0Provider
//         domain="dev-ly1a640v0e7eikin.us.auth0.com"
//         clientId="fI7uJvVQkbLVZsMZY3fKnWwUHbMN4ERm"
//         authorizationParams={{
//           redirect_uri: `${window.location.origin}/login`,
//         }}
//       // useRefreshTokens
//       // cacheLocation="localstorage"
//       >
//         <Provider store={store}>
//           <BrowserRouter>
//             <App />
//           </BrowserRouter>
//         </Provider>
//       </Auth0Provider>
//     );
//   }
//   catch (error) {
//     console.log("Initialization failed with error:", error);
//   }
// })();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//  console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
