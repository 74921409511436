import { Form, Button, Col, Row } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import HelperService from "../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";

interface HouseholdForm {
  parent_marital_status: any;
  divorce_year: any;
  whom_permanent_home: any;
  specify_other_living_situation: any;
  is_children: any;
  how_many_children: any;
}

const MaritalOptions = [
  { id: "MARRIED", value: "Married" },
  { id: "SEPARATED", value: "Separated" },
  { id: "DIVORCED", value: "Divorced" },
  { id: "NEVER_MARRIED", value: "Never Married" },
  { id: "WIDOWED", value: "Widowed" },
  {
    id: "CIVIL_UNION_DOMESTIC_PARTNERS",
    value: "Civil Union/Domestic Partners  ",
  },
];

const permanentOptions = [
  { id: "Parent_1", value: "Parent 1" },
  { id: "Parent_2", value: "Parent 2  " },
  { id: "Both_Parents", value: "Both Parents" },
  { id: "Legal_Guardian", value: "Legal Guardian" },
  { id: "OTHER", value: "Other" },
  { id: "Ward_court", value: "Ward of Court/State" },
];

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const Household = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset } = useForm<HouseholdForm>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const yearOption: any = HelperService.getYearList(72);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "living" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "living" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'living');
    } else {
      props.isFormDirty(false, 'living');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "living" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "living") {
      reset({});
      getHouseData();
    }
  }, [props.tab]);

  const getHouseData = () => {
    WebService.getAPI({
      action: `house-hold/detail`,
    })
      .then((res: any) => {
        reset(res.household);
        setValue("whom_permanent_home", res.household?.whom_permanent_home)

      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI({
      action: "save/house-hold",
      body: data,
      id: "hold_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <Row>
        <Col style={{ maxWidth: '624px' }}>
          <form onSubmit={handleSubmit(onSave)}>
            <Form className="form-style-2">
              <h4 className="font-medium font-20 mb-3 pb-1">Living situation</h4>
              <Form.Group className="mb-4">
                <Form.Label>
                  My parents are...<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="parent_marital_status"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="parent_marital_status"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={MaritalOptions}
                          selected={watchAllFields.parent_marital_status}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields.parent_marital_status === "DIVORCED" && (
                <Form.Group className="mb-4">
                  <Form.Label> Year of Divorce<span className="text-danger">*</span></Form.Label>
                  <Controller
                    control={control}
                    name="divorce_year"
                    rules={{ required: false }}
                    render={({ field }: any) => (
                      <>
                        <div className="react-select1">
                          <OpenSelect
                            sakey="divorce_year"
                            isSearchable={true}
                            placeholder="Choose Year"
                            options={yearOption}
                            selected={watchAllFields.divorce_year ? Number(watchAllFields.divorce_year) : ""}
                            onChange={(e: any) => {
                              field.onChange(e.id);
                            }}
                          />
                        </div>
                      </>
                    )}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-4">
                <Form.Label>
                  {" "}
                  Which parent do you live with full time?<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="whom_permanent_home"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="whom_permanent_home"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={permanentOptions}
                          selected={watchAllFields.whom_permanent_home}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields.whom_permanent_home == "OTHER" && (
                <Form.Group className="mb-4">
                  <Form.Label> Specify other living situation<span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    className={`${watchAllFields.specify_other_living_situation ? "border-brand" : ""}`}
                    placeholder="Enter Other Situations"
                    {...register("specify_other_living_situation", {
                      required: false,
                    })}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-4">
                <Form.Label>Do you have any children?</Form.Label>
                <Form.Check
                  type="radio"
                  id="YES"
                  label="Yes"
                  value={"YES"}
                  {...register("is_children", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="NO"
                  label="No"
                  value={"NO"}
                  onClick={() => { setValue("how_many_children", "") }}
                  {...register("is_children", { required: false })}
                />
              </Form.Group>

              {watchAllFields.is_children === "YES" && (
                <Form.Group className="mb-4">
                  <Form.Label>How Many?</Form.Label>
                  <Form.Control
                    type="text"
                    className={`${watchAllFields.how_many_children ? "border-brand" : ""}`}
                    onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
                    placeholder="Enter number of children"
                    {...register("how_many_children")}
                  />
                </Form.Group>
              )}
            </Form>
            <div className="text-center">
              <Button type="submit" id="hold_btn" className="btn btn-brand-1 w-100">
                Save
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </>
  );
};
export default Household;
