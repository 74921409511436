import { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";

interface PropData {
  show: boolean;
  isClose: Function;
}


const ExitConfirmModal = (props: PropData) => {

  const closeClick = (type?: boolean) => {
    props.isClose(type);
  };



  return (
    <>
      <Modal className="modal-size-660" show={props.show} centered aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body>
          <Row className="p-2">
            <div className="mb-3 text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <rect width="48" height="48" rx="24" fill="#FFEAE9" />
                <path d="M22.5039 11.4708C24.3169 10.4654 26.6232 11.126 27.6363 12.9228L37.328 29.743C37.5413 30.245 37.6346 30.6533 37.6613 31.0774C37.7146 32.0683 37.368 33.0314 36.6881 33.7726C36.0083 34.5112 35.0884 34.9472 34.0886 35H14.5719C14.1586 34.9749 13.7453 34.8811 13.3587 34.7358C11.4257 33.9563 10.4926 31.7631 11.2791 29.8619L21.0374 12.9109C21.3707 12.315 21.8773 11.8011 22.5039 11.4708ZM24.3302 27.3635C23.6903 27.3635 23.1571 27.892 23.1571 28.5275C23.1571 29.1603 23.6903 29.6901 24.3302 29.6901C24.9701 29.6901 25.49 29.1603 25.49 28.5129C25.49 27.8801 24.9701 27.3635 24.3302 27.3635ZM24.3302 19.1205C23.6903 19.1205 23.1571 19.6345 23.1571 20.27V24.0076C23.1571 24.6418 23.6903 25.1716 24.3302 25.1716C24.9701 25.1716 25.49 24.6418 25.49 24.0076V20.27C25.49 19.6345 24.9701 19.1205 24.3302 19.1205Z" fill="#FF3A29" />
              </svg>
            </div>
            <p className="font-16 font-medium text-center">Are you sure you want to exit?</p>
            <p className="font-14 text-secondary text-center">You forgot to save your information!</p>


            <div className="text-center mt-3">

              <Button className="btn-outline-light text-secondary w-100 mb-3" onClick={() => closeClick()}>Exit without saving</Button>
              <Button
                className="btn btn-brand-1 w-100"
                type="button"
                id="Save_and_exit_btn"
                onClick={() => closeClick(true)}
              >
                Save
              </Button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExitConfirmModal;
