import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import graduationHat from "../../assets/images/GraduationHat.svg"
import AddGradeModal from "./AddGradeModal";
import DeleteModal from "../DeleteModal";
import CardLoader from "../Common/CardLoader";

interface propData {
  isUpdate: Function;
  tab: string;
}

const scheduleOption = [
  { id: "SEMESTER", value: "Semester" },
  { id: "TRIMESTER", value: "Trimester" },
  { id: "QUARTER", value: "Quarter" },
  { id: "YEARLY", value: "Yearly" },
  { id: "OTHER", value: "Other" }
];

const GradesAndCourse = (props: propData) => {
  const { handleSubmit, register, watch, reset, setValue } = useForm<any>({});
  const watchAllFields = watch();
  const [isOpenGrade, setOpenGrade] = useState<boolean>(false);
  const [currentGrade, setCurrentGrade] = useState<number>(0);
  const [deleteGrade, setDeleteGrade] = useState<number>(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showData, setShowData] = useState<boolean>(false);

  useEffect(() => {
    props.tab == "grades" && getGradesData();
  }, [props.tab]);

  const getGradesData = () => {
    WebService.getAPI({
      action: `get/user-course-grade/details`,
    })
      .then((res: any) => {
        reset(res.result);
        setShowData(true);
      })
      .catch(() => { });
  };

  const onSave = (data: any) => {
    WebService.postAPI({
      action: "save/education/course-grade",
      body: data,
      id: "course_grade_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate()
      })
      .catch((e: any) => { });
  };

  const onClose = (type?: boolean) => {
    type && getGradesData();
    setIsEdit(false);
    setOpenGrade(false);
  };

  const onAddCourse = (grade: number, type?: string) => {
    if (type) {
      setIsEdit(true);
    }
    setCurrentGrade(grade);
    setOpenGrade(true);
  };

  const confirmDeleteGrade = (gre: number) => {
    setDeleteGrade(gre);
    setShowDeleteModal(true)
  };

  const onDeleteGrade = () => {
    setShowDeleteModal(false);
    WebService.postAPI({
      action: `delete/class-grade?type=${deleteGrade}th`,
    })
      .then((res: any) => {
        toast.success(res.message);
        getGradesData();
        props.isUpdate()
      })
      .catch((e: any) => { });
  };

  const getSchedule = (type: string) => {
    let val: string = "";
    scheduleOption.map((item: any) => {
      if (item.id == type) {
        val = item.value;
      }
    });
    return val;
  };


  return (
    <>
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col className="form-style-2" style={{maxWidth: "624px"}}>
            <h4 className="font-medium font-20 mb-3 pb-1">Courses & grades</h4>
            {!showData && <CardLoader />}
            {showData &&
              <Form.Group className="mb-3">
                <Form.Label>I have my transcript(s) or official grades I can report<span className="text-danger">*</span></Form.Label>
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="Yes"
                  name="is_transcript_official_grades"
                  value={"YES"}
                  checked={watchAllFields.is_transcript_official_grades == "YES" ? true : false}
                  onMouseDown={(e: any) => { setValue("is_transcript_official_grades", "YES") }}
                  onClick={(e: any) => { onSave(watchAllFields) }}
                />
                <Form.Check
                  type="radio"
                  id="Decile"
                  label="No"
                  name="is_transcript_official_grades"
                  value={"NO"}
                  checked={watchAllFields.is_transcript_official_grades == "NO" ? true : false}
                  onMouseDown={(e: any) => { setValue("is_transcript_official_grades", e.target.value) }}
                  onClick={(e: any) => { onSave(watchAllFields) }}
                />
              </Form.Group>
            }
            {
              watchAllFields && watchAllFields.is_transcript_official_grades == "YES" &&
              <>
                <p className="font-15 font-medium">You should enter your courses for each year of secondary/high school. Make sure to include all the schools you've attended in the "other secondary/high schools" section above.</p>
                {/* ///////////////////////////////////// || 9th || ////////////////////////////////////////////////////// */}
                {
                  watchAllFields && watchAllFields.nine && watchAllFields.nine.length > 0 ?
                    <Card className="border p-4 rounded-4 mb-4 border-opacity-50">
                      <div className="d-flex gap-3 mb-4 align-items-center">
                        <div className=" bg-brand-light rounded-circle p-2 d-flex">
                          <img src={graduationHat} width={42} height={42} alt="" />
                        </div>
                        <div>
                          <p className="font-16 m-0 font-medium ms-1 w-100">9th grade</p>
                        </div>
                      </div>

                      {watchAllFields.nine.map((item: any, index: number) => {
                        return (
                          <Row className="mb-3" key={`${new Date().getTime()}_${index}`}>
                            <Col lg={8} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School :</span>
                              <span className="font-16 text-secondary font-light ms-2">{item.school_name}</span>
                            </Col>
                            <Col lg={4} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Grading scale :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.grading_scale ? item.grading_scale : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School year :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.school_year ? item.school_year : "N/A"}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Schedule :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.schedule ? getSchedule(item.schedule) : "N/A"}</span>
                            </Col>

                            <Col lg={7} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Completed courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_completed_grades_entered}</span>
                            </Col>
                            <Col lg={5} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Credits :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.credits ? item.credits : "N/A"}</span>
                            </Col>
                            <Col lg={12} xs={12} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Incomplete courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_incompleted_grades_entered}</span>
                            </Col>
                          </Row>
                        )
                      })}

                      <div className="d-flex justify-content-between">
                        <a className="text-link font-16" style={{ cursor: "pointer" }} href="javascript:void(0)" onClick={() => onAddCourse(9, "edit")}> Edit </a>
                        <span className="font-16 cursor-pointer text-decoration-underline" style={{ color: "#8A9FD4" }} onClick={() => confirmDeleteGrade(9)}> Remove</span>
                      </div>
                    </Card>

                    :
                    <div className="card card-style p-4 mb-4 border border-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <div className=" bg-brand-light rounded-circle d-flex" style={{ padding: "12px" }}><img src={graduationHat} alt="" width={22} /></div>

                          <p className="font-16 m-0 font-medium ms-1 w-100">9th grade</p>
                        </div>
                        <div>
                          <a href="javascript:void(0)" className="font-16 font-medium text-decoration-underline" onClick={() => onAddCourse(9)}>Add courses for 9th grade</a>
                        </div>
                      </div>
                    </div>
                }

                {/* ///////////////////////////////////// || 10th || ////////////////////////////////////////////////////// */}
                {
                  watchAllFields && watchAllFields.ten && watchAllFields.ten.length > 0 ?
                    <Card className="border p-4 rounded-4 mb-4 border-opacity-50">
                      <div className="d-flex gap-3 mb-4 align-items-center">
                        <div className=" bg-brand-light rounded-circle p-2 d-flex">
                          <img src={graduationHat} width={42} height={42} alt="" />
                        </div>
                        <div>
                          <p className="font-16 m-0 font-medium ms-1 w-100">10th grade</p>
                        </div>
                      </div>

                      {watchAllFields.ten.map((item: any, index: number) => {
                        return (
                          <Row className="mb-3" key={`${new Date().getTime()}_${index}`}>
                            <Col lg={8} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School :</span>
                              <span className="font-16 text-secondary font-light ms-2">{item.school_name}</span>
                            </Col>
                            <Col lg={4} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Grading scale :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.grading_scale ? item.grading_scale : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School year :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.school_year ? item.school_year : "N/A"}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Schedule :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.schedule ? getSchedule(item.schedule) : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Completed courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_completed_grades_entered}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Credits :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.credits ? item.credits : "N/A"}</span>
                            </Col>
                            <Col lg={12} xs={12} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Incomplete courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_incompleted_grades_entered}</span>
                            </Col>
                          </Row>
                        )
                      })}

                      <div className="d-flex justify-content-between">
                        <a className="text-link font-16" style={{ cursor: "pointer" }} href="javascript:void(0)" onClick={() => onAddCourse(10, "edit")}> Edit </a>
                        <span className="font-16 cursor-pointer text-decoration-underline" style={{ color: "#8A9FD4" }} onClick={() => confirmDeleteGrade(10)}> Remove</span>
                      </div>
                    </Card>
                    :
                    <div className="card card-style p-4 mb-4 border border-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <div className=" bg-brand-light rounded-circle d-flex" style={{ padding: "12px" }}><img src={graduationHat} alt="" width={22} /></div>

                          <p className="font-16 m-0 font-medium ms-1 w-100">10th grade</p>
                        </div>
                        <div>
                          <a href="javascript:void(0)" className="font-16 font-medium text-decoration-underline" onClick={() => onAddCourse(10)}>Add courses for 10th grade</a>
                        </div>
                      </div>
                    </div>
                }

                {/* ///////////////////////////////////// || 11th || ////////////////////////////////////////////////////// */}
                {
                  watchAllFields && watchAllFields.eleven && watchAllFields.eleven.length > 0 ?
                    <Card className="border p-4 rounded-4 mb-4 border-opacity-50">
                      <div className="d-flex gap-3 mb-4 align-items-center">
                        <div className=" bg-brand-light rounded-circle p-2 d-flex">
                          <img src={graduationHat} width={42} height={42} alt="" />
                        </div>
                        <div>
                          <p className="font-16 m-0 font-medium ms-1 w-100">11th grade</p>
                        </div>
                      </div>

                      {watchAllFields.eleven.map((item: any, index: number) => {
                        return (
                          <Row className="mb-3" key={`${new Date().getTime()}_${index}`}>
                            <Col lg={8} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School :</span>
                              <span className="font-16 text-secondary font-light ms-2">{item.school_name}</span>
                            </Col>
                            <Col lg={4} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Grading scale :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.grading_scale ? item.grading_scale : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School year :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.school_year ? item.school_year : "N/A"}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Schedule :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.schedule ? getSchedule(item.schedule) : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Completed courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_completed_grades_entered}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Credits :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.credits ? item.credits : "N/A"}</span>
                            </Col>
                            <Col lg={12} xs={12} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Incomplete courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_incompleted_grades_entered}</span>
                            </Col>
                          </Row>
                        )
                      })}

                      <div className="d-flex justify-content-between">
                        <a className="text-link font-16" style={{ cursor: "pointer" }} href="javascript:void(0)" onClick={() => onAddCourse(11, "edit")}> Edit </a>
                        <span className="font-16 cursor-pointer text-decoration-underline" style={{ color: "#8A9FD4" }} onClick={() => confirmDeleteGrade(11)}> Remove</span>
                      </div>
                    </Card>
                    :
                    <div className="card card-style p-4 mb-4 border border-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <div className=" bg-brand-light rounded-circle d-flex" style={{ padding: "12px" }}><img src={graduationHat} alt="" width={22} /></div>

                          <p className="font-16 m-0 font-medium ms-1 w-100">11th grade</p>
                        </div>
                        <div>
                          <a href="javascript:void(0)" className="font-16 font-medium text-decoration-underline" onClick={() => onAddCourse(11)}>Add courses for 11th grade</a>
                        </div>
                      </div>
                    </div>
                }

                {/* ///////////////////////////////////// || 12th || ////////////////////////////////////////////////////// */}
                {
                  watchAllFields && watchAllFields.tweleve && watchAllFields.tweleve.length > 0 ?
                    <Card className="border p-4 rounded-4 mb-4 border-opacity-50">
                      <div className="d-flex gap-3 mb-4 align-items-center">
                        <div className=" bg-brand-light rounded-circle p-2 d-flex">
                          <img src={graduationHat} width={42} height={42} alt="" />
                        </div>
                        <div>
                          <p className="font-16 m-0 font-medium ms-1 w-100">12th grade</p>
                        </div>
                      </div>

                      {watchAllFields.tweleve.map((item: any, index: number) => {
                        return (
                          <Row className="mb-3" key={`${new Date().getTime()}_${index}`}>
                            <Col lg={8} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School :</span>
                              <span className="font-16 text-secondary font-light ms-2">{item.school_name}</span>
                            </Col>
                            <Col lg={4} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Grading scale :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.grading_scale ? item.grading_scale : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">School year :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.school_year ? item.school_year : "N/A"}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Schedule :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.schedule ? getSchedule(item.schedule) : "N/A"}</span>
                            </Col>

                            <Col lg={6} xs={6} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Completed courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_completed_grades_entered}</span>
                            </Col>
                            <Col lg={6} xs={6} className="font-16 font-light text-end font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Credits :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.credits ? item.credits : "N/A"}</span>
                            </Col>
                            <Col lg={12} xs={12} className="font-16 font-light font-xs-12 mb-3">
                              <span className="font-16 font-light font-xs-12">Incomplete courses & grades entered :</span>
                              <span className="font-16 text-secondary text-end font-light ms-2">{item.courses_entered?.courses_incompleted_grades_entered}</span>
                            </Col>
                          </Row>
                        )
                      })}

                      <div className="d-flex justify-content-between">
                        <a className="text-link font-16" style={{ cursor: "pointer" }} href="javascript:void(0)" onClick={() => onAddCourse(12, "edit")}> Edit </a>
                        <span className="font-16 cursor-pointer text-decoration-underline" style={{ color: "#8A9FD4" }} onClick={() => confirmDeleteGrade(12)}> Remove</span>
                      </div>
                    </Card>
                    :
                    <div className="card card-style p-4 mb-4 border border-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <div className=" bg-brand-light rounded-circle d-flex" style={{ padding: "12px" }}><img src={graduationHat} alt="" width={22} /></div>

                          <p className="font-16 m-0 font-medium ms-1 w-100">12th grade</p>
                        </div>
                        <div>
                          <a href="javascript:void(0)" className="font-16 font-medium text-decoration-underline" onClick={() => onAddCourse(12)}>Add courses for 12th grade</a>
                        </div>
                      </div>
                    </div>
                }
              </>
            }
          </Col>
        </Row>
      </form>

      {isOpenGrade && <AddGradeModal show={isOpenGrade} isClose={onClose} grade={currentGrade} isEdit={isEdit} />}
      <DeleteModal show={showDeleteModal} isClose={() => { setDeleteGrade(0); setShowDeleteModal(false); }} title={`Are you sure you want to remove all your ${deleteGrade}th grade courses?`} isDelete={() => { onDeleteGrade() }} deleteButtonText={"Yes, Remove"} subTitle="hide" modalTitle={"Remove"} />
    </>
  );
};
export default GradesAndCourse;
