import { Row, Col, Button, Form } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import HelperService from "../../../Services/HelperService";
import { Label } from "../../../components/Common/Label/Label";
import { Link, useBlocker, useNavigate } from "react-router-dom";
import { TbTrash } from "react-icons/tb";
import deleteIcon from "../../../assets/images/delete-icon-red.svg";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
  activeTab: any;
  isUpdate: Function;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const APSubjectTest = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors, isValid } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [subjectList, setSubjectList] = useState<any[]>([]);
  const [examList, setExamList] = useState<any[]>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.activeTab == "APSubjectTests" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.activeTab == "APSubjectTests" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'APSubjectTests');
    } else {
      props.isFormDirty(false, 'APSubjectTests');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.activeTab == "APSubjectTests" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.activeTab == "APSubjectTests") {
      reset({});
      subjectList.length == 0 && getSubjectList();
      getDatail();
    }
  }, [props.activeTab]);

  const getDatail = () => {
    WebService.getAPI({
      action: `get/standarized/test/detail?type=AP_SUBJECT_TEST`,
    })
      .then((res: any) => {
        if (res.result.length) {
          res.result.map((item: any) => {
            setValue(`subject_id${item.id}`, item.subject_id);
            setValue(`score${item.id}`, item.score);
          });
          setExamList(res.result);
        } else {
          setExamList([
            {
              id: HelperService.generateOTP(),
              subject_id: "",
              score: "",
              isNew: true,
            },
          ]);
        }
      })
      .catch((e) => { });
  };

  const getSubjectList = () => {
    WebService.getAPI({
      action: "subject/list?type=AP_SUBJECT",
    }).then((res: any) => {
      if (res.list && res.list.length > 0) {
        setSubjectList(
          res.list.map((item: any) => {
            return {
              ...item,
              ...{ ...{ value: item.name } },
            };
          })
        );
      }
    });
  };

  const onSave = async (data: any) => {
    let temp: any[] = [];
    examList.map((item: any) => {
      // if (item.isNew) {
      temp.push({
        subject_id: data[`subject_id${item.id}`],
        score: data[`score${item.id}`],
      });
      // } else {
      //     temp.push(item)
      // }
    });
    await WebService.postAPI({
      action: "save/tests?type=AP_SUBJECT_TEST",
      body: { ap_exam: temp },
      id: "ap_test_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const addNewScore = () => {
    examList.push({
      id: HelperService.generateOTP(),
      subject_id: "",
      score: "",
      isNew: true,
    });
    setExamList([...examList]);
  };

  const onDeleteApExam = (data: any) => {
    if (data.isNew) {
      setExamList(
        examList.filter((item: any) => {
          return item.id != data.id;
        })
      );
      toast.success("AP exam removed successfully.");
    } else {
      WebService.deleteAPI({
        action: `apexam/delete/${data.id}`,
      })
        .then((res: any) => {
          setExamList(
            examList.filter((item: any) => {
              return item.id != data.id;
            })
          );
          toast.success(res.message);
        })
        .catch((e) => { });
    }
  };

  const handleDisable = () => {
    let isDisable: boolean = false;
    if (examList && examList.length) {
      examList.map((item: any, index: number) => {
        if (
          !watchAllFields[`subject_id${item.id}`] ||
          !watchAllFields[`score${item.id}`]
        ) {
          isDisable = true;
        }
      });
    }
    return isDisable;
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">AP Exams</h4>
            {examList &&
              examList.length > 0 &&
              examList.map((item: any, index: number) => {
                return (
                  <Form className="form-style-2" key={index}>
                    {/* {examList.length > 1 && index > 0 && <hr />} */}
                    {examList.length > 1 && (
                      <div className="text-end">
                        <img
                          src={deleteIcon}
                          height={20}
                          onClick={() => onDeleteApExam(item)}
                          className="cursor-pointer text-danger font-16"
                        />
                      </div>
                    )}
                    <Form.Group className="mb-4">
                      <p className="font-14 mb-2">
                        Subject<span className="text-danger">*</span>
                      </p>
                      <Controller
                        control={control}
                        name={`subject_id${item.id}`}
                        rules={{ required: true }}
                        render={({ field }: any) => (
                          <>
                            <div className="react-select">
                              <OpenSelect
                                isSearchable={true}
                                options={subjectList}
                                onChange={(e: any) => {
                                  field.onChange(e.id);
                                }}
                                selected={
                                  watchAllFields[`subject_id${item.id}`]
                                }
                              />
                            </div>
                            {errors[`subject_id${item.id}`] && (
                              <div className="login-error">
                                <Label
                                  title={"Please Select Subject."}
                                  modeError={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <p className="font-14 mb-2">
                        Score<span className="text-danger">*</span>
                      </p>
                      <Form.Control
                        type="text"
                        className={`${watchAllFields[`score${item.id}`]
                          ? "border-brand"
                          : ""
                          }`}
                        min={0}
                        {...register(`score${item.id}`, {
                          required: true,
                          pattern: /^[0-5]{1}$/,
                        })}
                        onKeyPress={HelperService.allowOnlyNumericValueUpto1}
                      />
                      {(errors[`score${item.id}`] ||
                        watchAllFields[`score${item.id}`] > 5) && (
                          <div className="login-error">
                            <Label
                              title={
                                watchAllFields[`score${item.id}`]
                                  ? "Please Enter Valid Score."
                                  : "Please Enter Score."
                              }
                              modeError={true}
                            />
                          </div>
                        )}
                    </Form.Group>
                  </Form>
                );
              })}
            <Link
              onClick={() => addNewScore()}
              className="text-link"
              to={"javascript:void(0)"}
            >
              + Add another AP exam
            </Link>

            <div className="text-center mt-4">
              <Button
                type="submit"
                disabled={handleDisable() || !isValid}
                id="ap_test_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default APSubjectTest;
