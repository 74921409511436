import { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Modal, Tab, Nav } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";
import OpenSelect from "../../components/Common/OpenTagSelect/OpenSelect";
import { Label } from "../../components/Common/Label/Label";
import { addEventFormInterface } from "../../OpenInterfaces";
import HelperService from "../../Services/HelperService";
import OpenDatePicker from "../../components/Common/OpenDatePicker/OpenDatePicker";
import moment from "moment";

interface propData {
    show: boolean;
    isClose: any;
    data?: addEventFormInterface;
};

const remainderOptios = [
    { id: "Day of", value: "Day of" },
    { id: "1 day before", value: "1 day before" },
    { id: "2 days before", value: "2 days before" },
    { id: "3 days before", value: "3 days before" },
    { id: "1 week before", value: "1 week before" },
];

const notifyOptios = [
    { id: "EMAIL", value: "via email" },
    { id: "APP", value: "in app" },
    { id: "EMAIL_APP", value: "email & in app" }
];

const AddEventModal = (props: propData) => {
    const { control, handleSubmit, watch, register, reset, formState: { errors, isValid }, setValue, } = useForm<addEventFormInterface>();
    const watchAllFields = watch();
    const [modalTitle, setModalTitle] = useState<string>("Add Event");
    const [formKey, setFormKey] = useState<any>();
    const [file, setFile] = useState<any>();
    const [eventType, setEventType] = useState<string>("CALENDAR");

    useEffect(() => {
        if (props.show) {
            if (props.data != null && props.data && !HelperService.isEmptyObject(props.data)) {
                reset(props.data);
                setEventType(props.data.type);
                setModalTitle("Edit Event");
            } else {
                setFile("")
                setModalTitle("Add Event");
                setFormKey(new Date().getTime());
                reset({} as addEventFormInterface);
                setValue("start", new Date());
                const date: any = HelperService.addHours(new Date(), 1);
                setValue("end", date);
                setEventType("CALENDAR");
            }
        } else {
            setFormKey(new Date().getTime());
            reset({});
        }
    }, [props.show, props.data]);

    const onSubmit = (data: addEventFormInterface) => {
        data.type = eventType;
        data.start = data.start ? HelperService.getFormatedDateForTimePicker(data.start) as any : new Date();
        data.end = data.end ? HelperService.getFormatedDateForTimePicker(data.end) as any : new Date();
        if (props.data != null && props.data && !HelperService.isEmptyObject(props.data)) {
            WebService.putAPI({
                action: `update/event/${data.uuid}`,
                body: data,
                id: "save_btn",
            })
                .then((res: any) => {
                    toast.success(res.message);
                    onClose(true);
                })
                .catch(() => { });
        } else {
            WebService.postAPI({
                action: `add/event`,
                body: data,
                id: "save_btn",
            })
                .then((res: any) => {
                    toast.success(res.message);
                    onClose(true);
                })
                .catch(() => { });
        }
    };

    const onClose = (type?: boolean) => {
        reset();
        setValue("title", "")
        props.isClose(type);
    };


    return (
        <>

            <Modal
                show={props.show}
                onHide={() => { onClose(); }}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="form-style-2">
                    <div className="mb-4">
                        <Tab.Container id="left-tabs-example" activeKey={eventType} >
                            <div className="tab-style-3">
                                <Nav variant="pills" className=" " fill={true}>
                                    <Nav.Item onClick={() => setEventType("CALENDAR")}>
                                        <Nav.Link eventKey="CALENDAR">Event</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setEventType("REMINDER")}>
                                        <Nav.Link eventKey="REMINDER">Reminder</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Tab.Container>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} key={formKey}>
                        <Form.Group className="mb-4 overlap-label" controlId="email">
                            <Form.Label>Title<span className="text-danger">*</span></Form.Label>
                            <Form.Control type="text" value={watchAllFields.title} {...register("title", { required: true })} />
                            {errors.title && (
                                <div className="login-error">
                                    <Label title={"Please enter title."} modeError={true} />
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label" controlId="email">
                            <Form.Label>Location</Form.Label>
                            <Form.Control type="text" value={watchAllFields.location} {...register("location", { required: false })} />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label date-time-picker">
                            <Form.Label>Start Date</Form.Label>
                            <Controller
                                control={control}
                                name="start"
                                rules={{ required: true }}
                                render={({ field }: any) => (
                                    <OpenDatePicker
                                        type="dateTime"
                                        selected={watchAllFields.start || ""}
                                        minData={new Date()}
                                        onChange={(e: Date) => {
                                            field.onChange(e);
                                            if (e) {
                                                const date: any = HelperService.addHours(new Date(e), 1);
                                                setValue("end", date)
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label date-time-picker">
                            <Form.Label>End Date</Form.Label>
                            <Controller
                                control={control}
                                name="end"
                                rules={{ required: true }}
                                render={({ field }: any) => (
                                    <OpenDatePicker
                                        type="dateTime"
                                        selected={watchAllFields.end || ""}
                                        minTime={watchAllFields.start ? new Date(watchAllFields.start) : ""}
                                        maxTime={watchAllFields.start ? new Date(moment(watchAllFields.start).hour(22).minute(45).second(0).format("YYYY-MM-DD HH:mm:ss")) : ""}
                                        minData={watchAllFields.start ? new Date(watchAllFields.start) : new Date()}
                                        onChange={(e: any) => field.onChange(e)}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Check
                                type="checkbox"
                                id="0"
                                label="All day"
                                {...register("is_all_day", { required: false })}
                                checked={watchAllFields.is_all_day}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label">
                            <Form.Label>Reminder</Form.Label>
                            <Controller
                                control={control}
                                name="remind_me"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                    <OpenSelect
                                        sakey="remind_me"
                                        placeholder=""
                                        isSearchable={true}
                                        options={remainderOptios}
                                        selected={watchAllFields.remind_me}
                                        onChange={(e: any) => { field.onChange(e.id); }}
                                        column={3}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label">
                            <Form.Label>Notification</Form.Label>
                            <Controller
                                control={control}
                                name="push_notification"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                    <OpenSelect
                                        placeholder=""
                                        sakey="push_notification"
                                        isSearchable={true}
                                        options={notifyOptios}
                                        selected={watchAllFields.push_notification}
                                        onChange={(e: any) => { field.onChange(e.id); }}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label" controlId="email">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" className="h-auto" rows={4} value={watchAllFields.description} {...register("description", { required: false })} />
                        </Form.Group>

                        <div className="mt-3">
                            <Button type="submit" disabled={!isValid} className="btn-brand-1 w-100 mb-3" id="save_btn">
                                Save
                            </Button>
                            <Button type="button" className="btn-outline-brand w-100 mb-3" onClick={() => { onClose(); }}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddEventModal;
