import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";
import { useBlocker, useNavigate } from "react-router-dom";

interface SiblingsForm {
  Siblings_number: any;
  first_name: any;
  sibling_surname: any;
  age: any;
}

const NumbersCount = [
  { id: "0", value: "0" },
  { id: "1", value: "1" },
  { id: "2", value: "2" },
  { id: "3", value: "3" },
  { id: "4", value: "4" },
  { id: "5", value: "5" },
];

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const Siblings = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const array = [1, 2, 3, 4, 5];
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "siblings" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "siblings" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'siblings');
    } else {
      props.isFormDirty(false, 'siblings');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "siblings" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "siblings") {
      reset({});
      getHouseData();
    }
  }, [props.tab]);

  const getHouseData = () => {
    WebService.getAPI({
      action: `get/sibling/details`,
    })
      .then((res: any) => {
        setValue("Siblings_number", res.no_of_sibling);
        if (res.sibling && res.sibling.length > 0) {
          res.sibling.map((item: any, i: number) => {
            setValue(`first_name${i + 1}`, item.first_name);
            setValue(`sibling_surname${i + 1}`, item.sibling_surname);
            setValue(`age${i + 1}`, item.age);
          });
        }
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    const userSiblings = [];
    for (let i in array) {
      if (array[i] <= watchAllFields.Siblings_number) {
        const first_name = data[`first_name${array[i]}`];
        const sibling_surname = data[`sibling_surname${array[i]}`];
        const age = data[`age${array[i]}`];
        userSiblings.push({
          first_name: first_name,
          sibling_surname: sibling_surname,
          age: age ? age : null,
        });
      }
    }

    const requestBody = {
      siblings: userSiblings,
      no_of_sibling: userSiblings.length,
    };

    await WebService.postAPI({
      action: "save/sibling/details",
      body: requestBody,
      id: "sibling_save-btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <h4 className="font-medium font-20 mb-3 pb-1">Siblings</h4>
            <Form className="form-style-2">
              <Form.Group className="mb-4">
                <Form.Label>
                  How many siblings do you have?
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="Siblings_number"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="Siblings_number"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={NumbersCount}
                          selected={watchAllFields.Siblings_number}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            array.map((item: any) => {
                              if (item > e.id) {
                                setValue(`first_name${item}`, "");
                                setValue(`sibling_surname${item}`, "");
                                setValue(`age${item}`, "");
                              }
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              {array.map((_, index) => {
                if (_ <= watchAllFields.Siblings_number) {
                  return (
                    <div key={index}>
                      <Form.Label>Sibling {index + 1} details:</Form.Label>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          First/Given name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields[`first_name${_}`]
                            ? "border-brand"
                            : ""
                            }`}
                          value={watchAllFields[`first_name${_}`]}
                          {...register(`first_name${_}`, { required: false })}
                        />
                        {errors[`first_name${_}`] && (
                          <div className="login-error">
                            <Label
                              title={"Please Enter Name."}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>
                          Last/Family/Surname
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields[`sibling_surname${_}`]
                            ? "border-brand"
                            : ""
                            }`}
                          value={watchAllFields[`sibling_surname${_}`]}
                          {...register(`sibling_surname${_}`, {
                            required: false,
                          })}
                        />
                        {errors[`sibling_surname${_}`] && (
                          <div className="login-error">
                            <Label
                              title={"Please Enter Last Name."}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>Age </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields[`age${_}`] ? "border-brand" : ""
                            }`}
                          value={watchAllFields.age}
                          onKeyPress={(e) =>
                            HelperService.allowOnlyNumericValue(e)
                          }
                          {...register(`age${_}`, { required: false })}
                        />
                      </Form.Group>
                    </div>
                  );
                }
              })}
            </Form>
            <div className="text-center">
              <Button
                type="submit"
                id="sibling_save-btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Siblings;
