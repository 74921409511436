
const Terms = () => {
    return (
        <>

            <div className="page-container">
                <p className="font-20 font-medium"> Terms and Conditions</p>
                <p>
                    Locks4phone is an application which contains ads and in-app
                    purchases. This Service is provided by Sayee Shishir Karna . If
                    you choose to use these Service, then you agree to the policies
                    defined here. I will not use or share your information with
                    anyone except as described in this Privacy Policy.
                </p>
                <p>About the Accessibility API Usages</p>
                <p>
                    Locks4phone app uses accessibility services even when app is
                    closed and not in use . Locks4phone app uses accessibility
                    services to detect phones locked and unlocked conditions
                    .Accessibility service is used to lock phone and provide lock
                    functionality for immediate locks feature and scheduled locks
                    feature . Accessibility services is also used to prevent opening
                    settings and prevent uninstallation when strict mode feature is
                    enabled by user . Accessibility services are used for these
                    functions and data is not collected or shared in any way . Once
                    accessibility permissions are granted , accessibility
                    permissions persists until explicitly revoked .
                </p>
                <p>
                    Please note this app does use third party services that may
                    collect information used to identify you.
                </p>
                <p>
                    Third party Service Providers used in this application - To
                    serve advertisement : Google ads
                </p>
                <p>
                    Link to privacy policy of third party service providers used by
                    the app
                </p>
                <p>
                    Link to privacy policy of third party service providers used by
                    the app
                </p>
                <p>
                    {" "}
                    <a
                        href="https://support.google.com/admob"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://support.google.com/admob
                    </a>{" "}
                </p>
            </div>
        </>
    );
};
export default Terms;





