import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import PhoneNumberInput from "../Common/PhoneNumberInput/PhoneNumberInput";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

const PrefixList = [
  { id: "Dr", value: "Dr." },
  { id: "Mr", value: "Mr." },
  { id: "Ms", value: "Ms." },
  { id: "Mrs", value: "Mrs." },
  { id: "Mx", value: "Mx." },
  { id: "Other", value: "Other" },
];

interface propData {
  isUpdate: Function;
  tab: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const ComunityBasedOrg = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
  const nameRegex = /^[A-Za-z][A-Za-z_ ]{1,30}$/;
  const array = [1, 2, 3];
  const [organizationOption, setOrganizationOption] = useState<any[]>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "community" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "community" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'community');
    } else {
      props.isFormDirty(false, 'community');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "community" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "community") {
      reset({});
      getUserDetail();
      getOrganizationList();
    }
  }, [props.tab]);

  const getUserDetail = () => {
    WebService.getAPI({
      action: `get/user-community-organization/details`,
    })
      .then((res: any) => {
        setValue(
          "no_of_high_schools",
          res.community_programs_free_assistance_process
        );
        if (
          res.user_community_organizations &&
          res.user_community_organizations.length > 0
        ) {
          res.user_community_organizations.map((item: any, i: number) => {
            setValue(
              `community_organization_received_id${i + 1}`,
              item.community_organization_received_id
            );
            setValue(
              `specify_other_community_organization${i + 1}`,
              item.specify_other_community_organization
            );
            setValue(`counselor_prefix${i + 1}`, item.counselor_prefix);
            setValue(`counselor_first_name${i + 1}`, item.counselor_first_name);
            setValue(`counselor_last_name${i + 1}`, item.counselor_last_name);
            setValue(`counselor_email${i + 1}`, item.counselor_email);
            setValue(
              `counselor_phone_number${i + 1}`,
              item.counselor_phone_number
            );
            setValue(`counselor_phone_code${i + 1}`, item.counselor_phone_code);
          });
        }
      })
      .catch((e) => { });
  };

  const getOrganizationList = () => {
    WebService.getAPI({ action: `get/community-organization/list` })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          let temp: any[] = [];
          res.list.map((item: any) => {
            temp.push({ ...item, ...{ value: item.name } });
          });
          setOrganizationOption(temp);
        }
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    const userOrg = [];
    for (let i in array) {
      if (array[i] <= watchAllFields.no_of_high_schools) {
        const userObj = {
          community_organization_received_id:
            data[`community_organization_received_id${array[i]}`],
          specify_other_community_organization:
            data[`specify_other_community_organization${array[i]}`],
          counselor_prefix: data[`counselor_prefix${array[i]}`],
          counselor_first_name: data[`counselor_first_name${array[i]}`],
          counselor_last_name: data[`counselor_last_name${array[i]}`],
          counselor_email: data[`counselor_email${array[i]}`],
          counselor_phone_number: data[`counselor_phone_number${array[i]}`],
          counselor_phone_code: data[`counselor_phone_code${array[i]}`]
            ? data[`counselor_phone_code${array[i]}`]
            : "",
        };
        userOrg.push(userObj);
      }
    }

    const requestBody = {
      user_community_organizations: userOrg,
      community_programs_free_assistance_process: data.no_of_high_schools,
    };

    await WebService.postAPI({
      action: "save/user-community-organization/details",
      body: requestBody,
      id: "comunity_save-btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const resetOtherFields = (id: number) => {
    array.map((item: any) => {
      if (item > id) {
        setValue(`community_organization_received_id${item}`, "");
        setValue(`specify_other_community_organization${item}`, "");
        setValue(`counselor_prefix${item}`, "");
        setValue(`counselor_first_name${item}`, "");
        setValue(`counselor_last_name${item}`, "");
        setValue(`counselor_email${item}`, "");
        setValue(`counselor_phone_number${item}`, "");
        setValue(`counselor_phone_code${item}`, "");
      } else if (!watchAllFields[`counselor_phone_code${item}`]) {
        setValue(`counselor_phone_code${item}`, 239);
      }
    });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: "624px" }} >
            <h4 className="font-medium font-20 mb-3 pb-1">
              Community-based organisations
            </h4>

            <Form.Group className="mb-4">
              <p className="font-16">
                Have any community programs or organisations helped you in your
                college application process? If so, how many?
                <span className="text-danger">*</span>
              </p>
              <Form.Check
                type="radio"
                id="1"
                label="0"
                value={"0"}
                checked={
                  watchAllFields.no_of_high_schools == "0" ? true : false
                }
                {...register("no_of_high_schools", { required: false })}
                onClick={() => resetOtherFields(0)}
              />
              <Form.Check
                type="radio"
                id="1"
                label="1"
                value={"1"}
                checked={
                  watchAllFields.no_of_high_schools == "1" ? true : false
                }
                {...register("no_of_high_schools", { required: false })}
                onClick={() => resetOtherFields(1)}
              />
              <Form.Check
                type="radio"
                id="2"
                label="2"
                value={"2"}
                checked={
                  watchAllFields.no_of_high_schools == "2" ? true : false
                }
                {...register("no_of_high_schools", { required: false })}
                onClick={() => resetOtherFields(2)}
              />
              <Form.Check
                type="radio"
                id="3"
                label="3"
                value={"3"}
                checked={
                  watchAllFields.no_of_high_schools == "3" ? true : false
                }
                {...register("no_of_high_schools", { required: false })}
                onClick={() => resetOtherFields(3)}
              />
            </Form.Group>

            {array.map((_, index) => {
              if (_ <= watchAllFields.no_of_high_schools) {
                return (
                  <div key={index}>
                    {index > 0 && <hr />}
                    <Form.Group className="mb-4">
                      <Form.Label>
                        Which organisation helped you the most?
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        control={control}
                        name={`community_organization_received_id${_}`}
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <OpenSelect
                            sakey={`community_organization_received_id${_}`}
                            isSearchable={true}
                            placeholder="Choose an option"
                            options={organizationOption}
                            selected={
                              watchAllFields[
                              `community_organization_received_id${_}`
                              ]
                            }
                            onChange={(e: any) => {
                              field.onChange(e.id);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    {watchAllFields[`community_organization_received_id${_}`] ==
                      "528" && (
                        <Form.Group className="mb-4">
                          <Form.Label>
                            Specify other organization
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.specify_other_community_organization ? "border-brand" : ""}`}
                            {...register(
                              `specify_other_community_organization${_}`
                            )}
                          />
                        </Form.Group>
                      )}

                    <Form.Group className="mb-4">
                      <Form.Label className="mb-3 d-block">
                        Counselor/Advisor/Mentor
                      </Form.Label>
                      <Form.Label>Prefix</Form.Label>
                      <Controller
                        control={control}
                        name={`counselor_prefix${_}`}
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <OpenSelect
                            sakey={`counselor_prefix${_}`}
                            isSearchable={true}
                            placeholder="Choose an option"
                            options={PrefixList}
                            selected={watchAllFields[`counselor_prefix${_}`]}
                            onChange={(e: any) => {
                              field.onChange(e.id);
                            }}
                          />
                        )}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${watchAllFields[`counselor_first_name${_}`] ? "border-brand" : ""}`}
                        {...register(`counselor_first_name${_}`, {
                          required: false,
                          pattern: nameRegex,
                        })}
                      />
                      {errors[`counselor_first_name${_}`] && (
                        <div className="login-error">
                          <Label
                            title={"Between 3 to 30 characters, letters only."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${watchAllFields[`counselor_last_name${_}`] ? "border-brand" : ""}`}
                        {...register(`counselor_last_name${_}`, {
                          required: false,
                          pattern: nameRegex,
                        })}
                      />
                      {errors[`counselor_last_name${_}`] && (
                        <div className="login-error">
                          <Label
                            title={"Between 3 to 30 characters, letters only."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        className={`${watchAllFields[`counselor_email${_}`] ? "border-brand" : ""}`}
                        {...register(`counselor_email${_}`, {
                          required: false,
                          pattern: emailRegex,
                        })}
                      />
                      {errors[`counselor_email${_}`] && (
                        <div className="login-error">
                          <Label
                            title={"Please Enter Valid Email."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label> Phone number</Form.Label>
                      <Controller
                        control={control}
                        name={`counselor_phone_number${_}`}
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <div>
                            <PhoneNumberInput
                              selected={
                                watchAllFields[`counselor_phone_code${_}`]
                              }
                              value={
                                watchAllFields[`counselor_phone_number${_}`]
                              }
                              onChange={(e: any) => {
                                field.onChange(e);
                              }}
                              onChangeCode={(e: any) =>
                                setValue(`counselor_phone_code${_}`, e)
                              }
                            />
                          </div>
                        )}
                      />
                    </Form.Group>
                  </div>
                );
              }
            })}

            <div className="text-center">
              <Button
                type="submit"
                id="comunity_save-btn"
                disabled={!watchAllFields.no_of_high_schools}
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default ComunityBasedOrg;
