import { Button, Card, Form, InputGroup, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import logo from "../../assets/images/login-logo.svg";
import { CiSearch } from "react-icons/ci";
import DocIcon from '../../assets/images/doc-icon.svg';
import { IoClose } from "react-icons/io5";
import UploadFile from "../../components/Common/UploadFile/UploadFile";


interface PropData {
    show: boolean;
    isClose: Function;
    id: string;
    type: string;
};

const UploadDocumentModal = (props: PropData) => {
    const [docList, setDocList] = useState<any[]>([]);

    useEffect(() => {
        if (props.show) {
            getDocuments();
        }
    }, [props.show])

    const closeClick = (type?: boolean) => {
        setDocList([]);
        props.isClose(type);
    };

    const onSave = async () => {
        if (docList.length) {
            await onSaveFile(0, "");
            closeClick(true);
        } else {
            toast.error("Please upload file");
        }
    };

    const getDocuments = () => {
        WebService.getAPI({ action: `get/document/detail?university_document_id=${props.type}` })
            .then((res: any) => {
                setDocList(res.result?.documents ? res.result?.documents : [])
            })
            .catch((e: any) => { });
    };

    const onUploadFile = (data: any, name: string, size: string) => {
        if (docList.length) { toast.error("Maximum 1 file can be uploaded"); return; }
        var isImageExte = /(\.(jpg|jpeg|png))$/i;
        let file_type: string = "PDF";
        if (isImageExte.test(name)) {
            file_type = 'IMAGE'
        }
        docList.push({ document_name: name, file_size: `${size} KB`, file: data, file_type: file_type });
        setDocList([...docList]);
    };

    const onSaveFile = async (index: number, uuid: string) => {
        if (index == docList.length) { toast.success("File uploaded successfully"); setDocList([]); return; };
        if (docList[index].file) {
            await WebService.fileUploadAPI({
                action: `upload/application-documents`,
                key: "file",
                file: docList[index].file,
                body: { file_type: docList[index].file_type, university_document_id: props.type, university_id: props.id },
                id: "save_file_btn"
            })
                .then(async (res: any) => {
                    await onSaveFile(index + 1, uuid);
                })
                .catch(async (error: any) => { await onSaveFile(index + 1, uuid); });
        } else {
            onSaveFile(index + 1, uuid);
        }
    };

    const removeFile = (i: number, id: string) => {
        if (id) {
            WebService.deleteAPI({ action: `application-document/delete?uuid=${id}` })
                .then((res: any) => {
                    toast.success(res.message);
                    setDocList(docList.filter((p: any, q: number) => i != q))
                })
                .catch((e: any) => { });
        } else {
            setDocList(docList.filter((p: any, q: number) => i != q))
        }
    };


    return (
        <Modal show={props.show} centered onHide={() => closeClick()} className="size-md">
            <Modal.Header closeButton >
                <div className="text-center w-100">
                    <img src={logo} width={55} alt="" className="mx-auto" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="search-bar bg-white mb-4">
                    <InputGroup.Text id="inputGroup-sizing-sm " className="border-light"><CiSearch size={16} /></InputGroup.Text>
                    <Form.Control
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        className="border-light ps-0"
                        placeholder="Search files..."
                    />
                </InputGroup>
                <p className="mb-4 text-secondary ">Suggested files:</p>
                <div className="d-flex gap-2 mb-3">
                    <div>
                        <img src={DocIcon} width={40} height={40} alt="" />
                    </div>
                    <div>
                        <p className="font-medium font-16 mb-0">Essay.pdf</p>
                        <span className="text-secondary">245KB</span>
                    </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                    <div>
                        <img src={DocIcon} width={40} height={40} alt="" />
                    </div>
                    <div>
                        <p className="font-medium font-16 mb-0">Harvard Essay.pdf</p>
                        <span className="text-secondary">245KB</span>
                    </div>
                </div>
                <div className="d-flex gap-2 mb-3">
                    <div>
                        <img src={DocIcon} width={40} height={40} alt="" />
                    </div>
                    <div>
                        <p className="font-medium font-16 mb-0">MIT.pdf</p>
                        <span className="text-secondary">245KB</span>
                    </div>
                </div>
                <p className="font-medium font-16 mb-4">Or upload a new one:</p>
                <div className="mb-3">
                    <UploadFile onChange={(data: any, name: string, size: string) => { onUploadFile(data, name, size) }} acceptFileType={["jpg", "jpeg", "pdf", "png"]} message="Upload or drag and drop" acceptedSize={10} />
                    <p className="font-12 text-secondary">Maximum uploaded files: 1</p>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                    {docList && docList.length > 0 && docList.map((item: any, i: number) => {
                        return <Card className={`border p-2 d-inline-block px-3 ${i > 3 ? 'card-danger' : ""}`}>
                            <div className="d-flex gap-3 align-items-center">
                                <div className="me-1">
                                    <IoClose size={20} onClick={() => { removeFile(i, item.uuid) }} className="text-secondary opacity-50 hover-effect cursor-pointer close" />
                                </div>
                                <div>
                                    <img src={DocIcon} width={40} height={40} alt="" />
                                </div>
                                <div>
                                    <p className="font-medium font-16 mb-0 text-break" >{item.document_name}</p>
                                    <span className="text-secondary">{item.file_size}</span>
                                </div>
                            </div>
                        </Card>
                    })}
                </div>
                <Button className="btn-brand-1 w-100 mb-3 mt-3" type="button" onClick={() => { onSave() }} id="save_file_btn" disabled={(docList.length > 4 || docList.length == 0) ? true : false}> Upload</Button>
            </Modal.Body>
        </Modal>
    );
};

export default UploadDocumentModal;
