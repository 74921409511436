import { Card, Nav } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import UserIcon from "../../../assets/images/user-icon.svg"
import iconHome from "../../../assets/images/icon-home.svg"
import { useEffect, useState } from "react";

interface propData {
    activeTab: string;
    changeTab: Function;
    status: any;
}

const NavTabs = (props: propData) => {
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompleteHouseHold": false,
        "isCompleteParent1": false,
        "isCompleteParent2": false,
        "isCompleteSibling": false
    });

    useEffect(() => {
        setPageStatus(props.status);
    }, [props.status]);

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    };


    return (
        <>
            <Card className="card-shadow bg-light rounded-start-0 h-100" style={{ backgroundColor: "#F9F9F9" }}>
                <Nav variant="pills" className="flex-column tab-style-2">
                    <Nav.Item onClick={() => { props.activeTab != "living" && props.changeTab("living"); }}>
                        <Nav.Link eventKey="living" className=" rounded-top-4 rounded-xs-0 rounded-start-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconHome} className="icon" alt="" /> Living situation</span>
                                {complitionStatus(pageStatus.isCompleteHouseHold)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "1stparent" && props.changeTab("1stparent"); }}>
                        <Nav.Link eventKey="1stparent" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={UserIcon} className="icon" alt="" /> 1st Parent</span>
                                {complitionStatus(pageStatus.isCompleteParent1)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "2ndparent" && props.changeTab("2ndparent"); }}>
                        <Nav.Link eventKey="2ndparent" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={UserIcon} className="icon" alt="" /> 2nd Parent</span>
                                {complitionStatus(pageStatus.isCompleteParent2)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "siblings" && props.changeTab("siblings"); }}>
                        <Nav.Link eventKey="siblings" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={UserIcon} className="icon" alt="" /> Siblings</span>
                                {complitionStatus(pageStatus.isCompleteSibling)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>

                </Nav>
            </Card>
        </>
    )
}
export default NavTabs;
