import Logo from '../../assets/images/login-logo.svg'
import { Form, Button, InputGroup, Container } from "react-bootstrap"
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import facebookIcon from '../../assets/images/facebook-icon.svg'
import LinkedinIcon from '../../assets/images/linkedin-icon.svg'
import googleIcon from '../../assets/images/google-icon.svg'
import "./login.scss";
import { Controller, useForm } from 'react-hook-form';
import { API_OTPInterface, loginFormInterface, meCallInterface } from '../../OpenInterfaces';
import { Label } from '../Common/Label/Label';
import { useEffect, useState } from 'react';
import WebService from '../../Services/WebService';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { USER_INFO, USER_LOGIN_SUCCESS, setDataInRedux } from '../../action/CommonAction';
import { useAuth0 } from "@auth0/auth0-react";
import Loader from '../Common/Loader/Loader';
import SuccesIcon from '../../assets/images/icon-success.svg';
import { osName, browserName } from 'react-device-detect';
// import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import CometChatWebService from '../../Services/CometChatWebService';

const Login = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { handleSubmit, formState: { errors, isValid }, control, watch, setValue, reset } = useForm<loginFormInterface>({});
    const watchAllFields = watch();
    const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?])[A-Za-z\d~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?]{8,20}$/;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
    const is_social = localStorage.getItem("is_social");
    const [isVerifyEmail, setVerifyEmail] = useState<boolean>(false);
    const [userToken, setUserToken] = useState<string>("");
    const [isRemberMe, setIsRemberMe] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            localStorage.clear();
            // document.cookie.split(';').forEach(function (c) {
            //     document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
            // });
            validateToken();
        }
        if (is_social) {
            localStorage.removeItem("is_social");
            logout({ logoutParams: { returnTo: window.location.origin } })
        }
        const username = getCookie("username");
        const password = getCookie("pass");

        if (username && password) {
            reset({ email: username, password: password });
        } else {
            saveCookie("username", "");
            saveCookie("pass", "");
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!id && isAuthenticated && user && !isLoading && !token && !is_social) {
            validateSocailUser();
        }
    }, [isAuthenticated, user]);

    const saveCookie = (cname: string, cvalue: string) => {
        const d = new Date();
        d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    };

    function getCookie(cname: string) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    };

    const validateSocailUser = () => {
        WebService.postAPI({ action: "check-social-user", body: { social_id: user?.sub } })
            .then((res: any) => {
                if (res?.is_user_exist) {
                    localStorage.setItem("token", res?.access_token);
                    userMeCall(res.access_token);
                } else {
                    localStorage.setItem("is_social", "true");
                    navigate("/complete-profile")
                };
            })
            .catch((e) => { });
    };

    const validateToken = () => {
        WebService.postAPI({ action: "verify-email", body: { token: id } })
            .then((res: any) => {
                if (!res?.is_valid) {
                    navigate("/login")
                } else {
                    setVerifyEmail(true);
                    setUserToken(res?.access_token)
                }
            })
            .catch((e) => { });
    };

    const userMeCall = async (token: string) => {
        await WebService.getAPI<meCallInterface>({ action: "me", id: "login_btn" })
            .then(async (res: meCallInterface) => {
                toast.success("You are now logged in.");
                // await createCometChatUser(res.result);
                res?.result?.is_pending_question && localStorage.setItem("pending_ques", "true")
                dispatch({ type: USER_LOGIN_SUCCESS, payload: { access_token: token }, });
                dispatch(setDataInRedux({ type: USER_INFO, value: res.result }));
                navigate("/dashboard");
            })
            .catch(() => {
                typeof window !== "undefined" && localStorage.clear();
                // document.cookie.split(';').forEach(function (c) {
                //     document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                // });
            });
    };

    const onlogin = (data: loginFormInterface) => {
        const obj: any = {
            user_agent: browserName,
            platform: osName
        }
        WebService.postAPI<API_OTPInterface>({ action: "login", body: { ...data, ...obj }, id: "login_btn", })
            .then((res: API_OTPInterface) => {
                localStorage.setItem("token", res?.access_token);
                userMeCall(res.access_token);
                if (isRemberMe) {
                    saveCookie("username", data.email);
                    saveCookie("pass", data.password);
                };
            })
            .catch((e: any) => { });
    };

    const createCometChatUser = async (data: any) => {
        const abc: any = {
            uid: data.uuid,
            name: data.firstName + " " + data.lastName,
            role: data.role
        };

        if (data.avtar) {
            abc.avatar = data.avtar
        }

        CometChatWebService.postAPI({ action: "users", body: abc, id: "login_btn", })
            .then(async (res: any) => {
                await commetLogin(data.uuid);
            })
            .catch(async (e: any) => {
                if (e.code == "ERR_UID_ALREADY_EXISTS") {
                    await commetLogin(data.uuid)
                }
            });
    };

    async function commetLogin(uid: string) {
        try {
            // CometChatUIKit.login(uid)?.then((loggedInUser: any) => { })
        }
        catch (error) { }
    };


    return (
        <>
            <Loader show={isLoading} />
            <div className="login-page">
                <Container>
                    {isVerifyEmail ?
                        <div className="row g-0 justify-content-center align-items-center min-vh-100">
                          
                            <div className="col-lg-12 d-flex align-items-center justify-content-center login-wrap-min-h">
                                <div className="mb-3 w-100">
                                    <div className="px-lg-5 mb-3">

                                        <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                            <div className="text-center">
                                                <img src={SuccesIcon} width={100} className="mb-4" />
                                                <h3 className="mb-4 font-bold font-28">Email verified successfully</h3>
                                            </div>
                                            <Button className="btn btn-brand-1 w-100" id='login_btn' onClick={() => {
                                                if (!userToken) return;
                                                localStorage.setItem("token", userToken);
                                                userMeCall(userToken);
                                            }}>Continue</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <form onSubmit={handleSubmit(onlogin)}>
                            <div className="row g-0 justify-content-center   min-vh-100 align-items-center">

                                <div className="col-lg-12 d-flex justify-content-center ">

                                    <div className="  w-100">

                                        <div className="px-lg-5 ">
                                            {/* <div className="my-5 pt-lg-3 text-center">
                                                <img src={Logo} width="110" alt="Logo" />
                                            </div> */}
                                            <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                                <div className='p-2'>
                                                <div className="text-center">
                                                    <h2 className="mb-2 font-bold font-36">Welcome back!</h2>
                                                    <p className=" text-secondary font-14 mb-4">Don’t have an account? <Link className="text-brand font-medium text-decoration-underline" to='/get-started'>Sign up</Link></p>
                                                </div>

                                                {/* Email */}
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    rules={{
                                                        required: "Please Enter Email",
                                                        pattern: {
                                                            value: emailRegex,
                                                            message: "Enter valid email address",
                                                        }
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur },
                                                        fieldState: { isTouched, isDirty }
                                                    }) => (
                                                        <div className='mb-4'>
                                                            <Form.Group className="overlap-label" controlId="email">
                                                                <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control type="text" className={`${watchAllFields.email ? 'border-brand' : ''}`} onChange={onChange} onBlur={onBlur} value={watchAllFields.email} />
                                                            </Form.Group>
                                                            {
                                                                (errors["email"]?.message || Boolean(errors["email"]?.message) || (isTouched && !watchAllFields.email) || (watchAllFields.email && !emailRegex.test(watchAllFields.email))) &&
                                                                <div className="login-error">
                                                                    <Label
                                                                        title={(errors.email?.message || watchAllFields.email
                                                                            ? "Enter valid email address"
                                                                            : "Please Enter Email.")}
                                                                        modeError={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                />
                                                {/* Password */}
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{
                                                        required: "Please Enter Password",
                                                        pattern: {
                                                            value: passwordRegex,
                                                            message: "Between 8 to 20 characters and at least one upper case, lower case, number and special character."
                                                        }
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur },
                                                        fieldState: { isTouched }
                                                    }) => (
                                                        <div className="mb-3">
                                                            <InputGroup className="overlap-label">
                                                                <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control type={showPassword ? "text" : "password"} className={`${watchAllFields.password ? 'border-brand' : ''}`} onChange={onChange} onBlur={onBlur} value={watchAllFields.password} />
                                                                <InputGroup.Text id="basic-addon2" className={`${watchAllFields.password ? 'border-brand cursor-pointer' : 'cursor-pointer'}`} onClick={() => setShowPassword(!showPassword)}> {showPassword ? <BsEyeSlash size={16} /> : <BsEye size={16} />}</InputGroup.Text>
                                                            </InputGroup>
                                                            {
                                                                (errors["password"]?.message || Boolean(errors["password"]?.message) || (isTouched && !watchAllFields.password) || (watchAllFields.password && !passwordRegex.test(watchAllFields.password))) &&
                                                                <div className="login-error">
                                                                    <Label
                                                                        title={(errors.password?.message || watchAllFields.password
                                                                            ? "Between 8 to 20 characters and at least one upper case, lower case, number and special character." : "Please Enter Password.")}
                                                                        modeError={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                />

                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="remember"
                                                        label="Remember me"
                                                        className="font-14 manage-alignment"
                                                        checked={isRemberMe}
                                                        onChange={() => setIsRemberMe(!isRemberMe)}
                                                    />
                                                    <Link to="/forgot-password" className="text-brand font-14 font-medium text-decoration-underline">Forgot Password?</Link>
                                                </div>
                                                <div className="text-center">
                                                    <Button type='submit'
                                                        id='login_btn'
                                                        disabled={!isValid}
                                                        className="btn btn-brand-1 w-100">Sign in</Button>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center font-14 my-2">
                                                    <hr className="w-100" /> or  <hr className="w-100" />
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <Button type='button' className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100" onClick={() => loginWithRedirect()} style={{ height: "50px" }} > <img src={googleIcon} width="20" alt="icon" className="menu-icon" /></Button>
                                                    <Button type='button' className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100" onClick={() => loginWithRedirect()}> <img src={facebookIcon} width="20" alt="icon" className="menu-icon" /></Button>
                                                    <Button type='button' className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100" onClick={() => loginWithRedirect()}> <img src={LinkedinIcon} width="20" alt="icon" className="menu-icon" /></Button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </Container>
            </div>
        </>
    )

}
export default Login;