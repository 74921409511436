import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import WebService from "../../../Services/WebService";
import TextEditor from "../../../components/Common/TextEditor/Editor";
import CardLoader from "../../../components/Common/CardLoader";
import { Label } from "../../../components/Common/Label/Label";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../../Services/HelperService";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface AdditionalInfoForm {
    is_share_anyting: string;
    events_impacted_you: string;
    qualifications_not_reflected: string;
    additional_information: string;
}

interface propData {
    isUpdate: Function;
    tab?: string;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

const AdditionalInfo = (props: propData) => {
    const { handleSubmit, register, watch, formState: { errors }, setValue, control, reset } = useForm<AdditionalInfoForm>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showError1, setShowError1] = useState<boolean>(false);
    const [showError2, setShowError2] = useState<boolean>(false);
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.tab == "additionalInfo" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.tab == "additionalInfo" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'additionalInfo');
        } else {
            props.isFormDirty(false, 'additionalInfo');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.tab == "additionalInfo" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);

    useEffect(() => {
        if (props.tab == "additionalInfo") {
            reset({});
            getAdditionalInfo();
        }
    }, [props.tab]);

    const getAdditionalInfo = () => {
        setLoading(true);
        WebService.getAPI({
            action: `get/writing/user-additional-info`,
        })
            .then((res: any) => {
                reset(res.result);
                setLoading(false);
            })
            .catch((e) => { setLoading(false); });
    };

    const onSave = async (data: any) => {
        await WebService.postAPI<any>({
            action: `save/user/writing-additional-info`,
            body: data,
            id: "writing_additional_sample_btn",
        })
            .then((res: any) => {
                props.isUpdate();
                toast.success(res.message);
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };

    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            {props.tab == "additionalInfo" && <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col style={{ maxWidth: "624px" }}>
                        <h4 className="font-medium font-20 mb-3 pb-1">Additional Information</h4>
                        {
                            isLoading ? <CardLoader /> :
                                <>
                                    <Form className="form-style-2">
                                        <p className="font-16">Events like COVID-19 and natural disasters can leave a lasting mark on communities. If necessary, use this space to talk about how these situations have affected you. Colleges are interested in understanding how your health, safety, family life, future goals, and educational journey, including your access to stable technology and a quiet place to study, have been influenced by these disruptions.</p>

                                        <Form.Group className="mb-4">
                                            <Form.Label>Do you wish to share anything on this topic?<span className="text-danger">*</span></Form.Label>
                                            <Form.Check
                                                type="radio"
                                                id="Yes1"
                                                label="Yes"
                                                value={"YES"}
                                                {...register("is_share_anyting", { required: false })}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="Yes2"
                                                label="No"
                                                value={"NO"}
                                                onClick={() => { setValue("events_impacted_you", ""); }}
                                                {...register("is_share_anyting", { required: false })}
                                            />
                                        </Form.Group>

                                        {watchAllFields.is_share_anyting == "YES" &&
                                            <div>
                                                <p className="font-16">Please use this space to describe how these events have impacted you.<span className="text-danger">*</span></p>
                                                <TextEditor
                                                    wordLimit={650} minWords={250}
                                                    data={watchAllFields.events_impacted_you || ""}
                                                    onChange={(data: any) => { setValue("events_impacted_you", data); }}
                                                    updateWordCount={(wordCount: number) => { if (wordCount > 650) { setShowError1(true) } else { setShowError1(false); } }}
                                                />
                                                {
                                                    watchAllFields.events_impacted_you && showError1 &&
                                                    <div className="login-error mb-3">
                                                        <Label
                                                            title={("Please enter less than 650 words.")}
                                                            modeError={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <Form.Group className="mb-4">
                                            <Form.Label>Do you wish to provide details of circumstances or qualifications not reflected in your Open account?<span className="text-danger">*</span></Form.Label>
                                            <Form.Check
                                                type="radio"
                                                id="Ydses1"
                                                label="Yes"
                                                value={"YES"}
                                                {...register("qualifications_not_reflected", { required: false })}
                                            />
                                            <Form.Check
                                                type="radio"
                                                id="Yessd2"
                                                label="No"
                                                value={"NO"}
                                                onClick={() => { setValue("additional_information", ""); }}
                                                {...register("qualifications_not_reflected", { required: false })}
                                            />
                                        </Form.Group>

                                        {watchAllFields.qualifications_not_reflected == "YES" && <div>
                                            <p className="font-16">You may use the space below to provide any additional information you wish to share.<span className="text-danger">*</span></p>
                                            <TextEditor
                                                skey={`${new Date().getTime() + 55}`}
                                                wordLimit={650} minWords={250}
                                                data={watchAllFields.additional_information || ""}
                                                onChange={(data: any) => { setValue("additional_information", data); }}
                                                updateWordCount={(wordCount: number) => { wordCount > 650 ? setShowError2(true) : setShowError2(false) }}
                                            />
                                            {
                                                watchAllFields.additional_information && showError2 &&
                                                <div className="login-error mb-3">
                                                    <Label
                                                        title={("Please enter less than 650 words.")}
                                                        modeError={true}
                                                    />
                                                </div>
                                            }
                                        </div>}
                                    </Form>
                                    <div className="text-center">
                                        <Button
                                            type="submit"
                                            id="writing_additional_sample_btn"
                                            className="btn btn-brand-1 w-100"
                                            disabled={showError1 || showError2}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </>
                        }
                    </Col>
                </Row>
            </form>}
        </>
    );
};
export default AdditionalInfo;
