import { Row, Col, Button, Form } from "react-bootstrap"
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import OpenDatePicker from "../../../components/Common/OpenDatePicker/OpenDatePicker";
import HelperService from "../../../Services/HelperService";
import { TOFELInterface } from "../../../OpenInterfaces";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
    activeTab: any;
    isUpdate: Function;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

const ToeflIBTTest = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<TOFELInterface>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const scoreOption: any[] = HelperService.getNumberList(0, 30, 1).reverse();
    const numberOption: any[] = HelperService.getNumberList(1, 5, 1);
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.activeTab == "TOEFLiBT" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.activeTab == "TOEFLiBT" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'TOEFLiBT');
        } else {
            props.isFormDirty(false, 'TOEFLiBT');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.activeTab == "TOEFLiBT" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);

    useEffect(() => {
        if (props.activeTab == "TOEFLiBT") {
            reset({});
            getDatail();
        }
    }, [props.activeTab]);

    const getDatail = () => {
        WebService.getAPI({
            action: `get/standarized/test/detail?type=TOEFL_IBT_TEST`,
        })
            .then((res: any) => {
                if (res.result) {
                    reset(res.result);
                    setValue("test_future_expect", res.result.test_future_expect.toString())
                }
            })
            .catch((e) => { });
    };

    const onSave = async (data: any) => {
        await WebService.postAPI({
            action: "save/tests?type=TOEFL_IBT_TEST",
            body: data,
            id: "TOEFL_test_btn",
        })
            .then((res: any) => {
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };


    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col xl={10}>
                        <h4 className="font-medium font-20 mb-4">TOEFL iBT</h4>
                        <Form className="form-style-2">
                            <Form.Group className="mb-4">
                                <Form.Label>Number of times you have already taken the TOEFL iBT<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name={`test_already_taken`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                        <div className="react-select">
                                            <OpenSelect
                                                sakey="test_already_taken"
                                                options={numberOption}
                                                selected={watchAllFields.test_already_taken}
                                                onChange={(data: any) => { field.onChange(data.id); }}
                                                isSearchable={true}
                                                placeholder="Choose an option"
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label> Number of future TOEFL iBT sittings you expect<span className="text-danger">*</span></Form.Label>
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="0"
                                    value={"0"}
                                    {...register("test_future_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="1"
                                    value={"1"}
                                    {...register("test_future_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="2"
                                    value={"2"}
                                    {...register("test_future_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="3"
                                    value={"3"}
                                    {...register("test_future_expect", { required: false })}
                                />
                            </Form.Group>

                            <Row>
                                {/* Reading */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest reading score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`test_reading_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="test_reading_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.test_reading_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Reading score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="test_reading_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.test_reading_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Speaking */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest speaking score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`test_speaking_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="test_speaking_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.test_speaking_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label> Speaking score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="test_speaking_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.test_speaking_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Listning */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest listening score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`test_listening_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="test_listening_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.test_listening_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Listening score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="test_listening_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.test_listening_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Writing */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest writing score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`test_writing_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="test_writing_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.test_writing_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Writing score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="test_writing_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.test_writing_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Total */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest TOEFL iBT total score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`test_total_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="test_total_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.test_total_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label> TOEFL iBT total score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="test_total_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.test_total_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <div className="text-center">
                            <Button type="submit" id="TOEFL_test_btn" className="btn btn-brand-1 w-100">
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
}
export default ToeflIBTTest;
