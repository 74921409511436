import {
  Form,
  Button,
  Row,
  Col,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import HelperService from "../../Services/HelperService";
import { Label } from "../Common/Label/Label";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";

interface GPAForm {
  class_size: any;
  reporting_class_rank: string;
  gpa_scale_reporting: any;
  cumulative_gpa: any;
  gpa_weighting: string;
  freshman_gpa: any;
  sophomore_gpa: any;
  junior_third_year_gpa: any;
  senior_fourth_year_gpa: any;
  not_sure_unweighted_gpa: string;
}

const GPAScaleOptions = [
  { id: "4", value: "4" },
  { id: "5", value: "5" },
  { id: "6", value: "6" },
  { id: "7", value: "7" },
  { id: "8", value: "8" },
  { id: "9", value: "9" },
  { id: "10", value: "10" },
  { id: "11", value: "11" },
  { id: "12", value: "12" },
  { id: "13", value: "13" },
  { id: "14", value: "14" },
  { id: "15", value: "15" },
  { id: "16", value: "16" },
  { id: "17", value: "17" },
  { id: "18", value: "18" },
  { id: "19", value: "19" },
  { id: "20", value: "20" },
  { id: "100", value: "100" },
  { id: "None", value: "None" },
];

interface propData {
  isUpdate: Function;
  tab: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const gradesAlternate = [
  { name: "A+,A", value: "A+,A", range: "GPA range: 3.9 - 4.0" },
  { name: "A, A-", value: "A, A-", range: "GPA range: 3.7 - 3.9" },
  { name: "A-,B+", value: "A-,B+", range: "GPA range: 3.4 - 3.6" },
  { name: "B+,B", value: "B+,B", range: "GPA range: 3.1 - 3.3" },
  { name: "B, B-", value: "B, B-", range: "GPA range: 2.7 - 3.0" },
  { name: "B-, C+", value: "B-, C+", range: "GPA range: 2.4- 2.6" },
  { name: "C+, C", value: "C+, C", range: "GPA range: 2.1 - 2.3" },
  { name: "C, C-", value: "C, C-", range: "GPA range: 1.8 - 2.0" },
  { name: "C-, D+", value: "C-, D+", range: "GPA range: 1.3 - 1.6" },
  { name: "D+, D, D-", value: "D+, D, D-", range: "GPA range: 0.7 - 1.2" },
];

const GPAInfo = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset } = useForm<GPAForm>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "gpa" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "gpa" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'gpa');
    } else {
      props.isFormDirty(false, 'gpa');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "gpa" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "gpa") {
      reset({});
      getGpaData();
    }
  }, [props.tab]);

  const getGpaData = () => {
    WebService.getAPI({ action: `get/user-grade/details` })
      .then((res: any) => {
        reset(res.result);
        setValue("gpa_scale_reporting", res.result?.gpa_scale_reporting);
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    WebService.postAPI({
      action: "save/user-grade/details",
      body: data,
      id: "gpa-btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const getGradeRange = (val: string) => {
    let range: string = "";
    gradesAlternate.map((item: any) => {
      if (item.value == val) {
        range = item.range;
      }
    });
    return range;
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col className="form-style-2" style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">GPA</h4>
            <Form.Group className="mb-4">
              <Form.Label>
                What is your graduating class size (roughly)?
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                className={`${watchAllFields.class_size ? "border-brand" : ""}`}
                {...register("class_size")}
                min={0}
                onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>How does your school report class rank?</Form.Label>
              <Form.Check
                type="radio"
                id="Exact"
                label="Exact"
                value={"EXACT"}
                {...register("reporting_class_rank", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Decile"
                label="Decile"
                value={"DECILE"}
                {...register("reporting_class_rank", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Quintile"
                label="Quintile"
                value={"QUINTILE"}
                {...register("reporting_class_rank", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Quartile"
                label="Quartile"
                value={"QUARTILE"}
                {...register("reporting_class_rank", { required: false })}
              />
              <Form.Check
                type="radio"
                id="None"
                label="None"
                value={"NONE"}
                {...register("reporting_class_rank", { required: false })}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>What is your school's GPA scale?</Form.Label>
              <Controller
                control={control}
                name="gpa_scale_reporting"
                rules={{ required: false }}
                render={({ field }: any) => (
                  <>
                    <div className="react-select1">
                      <OpenSelect
                        sakey="gpa_scale_reporting"
                        isSearchable={true}
                        placeholder="Choose an option"
                        options={GPAScaleOptions}
                        selected={watchAllFields.gpa_scale_reporting}
                        onChange={(e: any) => {
                          field.onChange(e.id);
                        }}
                      />
                    </div>
                  </>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Is your GPA weighted?<span className="text-danger">*</span>
              </Form.Label>
              <Form.Check
                type="radio"
                id="Weighted"
                label="Weighted"
                value={"WEIGHTED"}
                {...register("gpa_weighting", { required: false })}
                onClick={() => setValue("not_sure_unweighted_gpa", "")}
              />
              <Form.Check
                type="radio"
                id="Unweighted"
                label="Unweighted"
                value={"UNWEIGHTED"}
                onClick={() => setValue("cumulative_gpa", "")}
                {...register("gpa_weighting", { required: false })}
              />
            </Form.Group>

            {watchAllFields.gpa_weighting &&
              watchAllFields.gpa_weighting == "UNWEIGHTED" && (
                <Form.Group className="mb-4">
                  <Form.Label>
                    What is your cumulative GPA?
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className={`${watchAllFields.cumulative_gpa ? "border-brand" : ""
                      }`}
                    {...register("cumulative_gpa", { min: 0 })}
                    min={0}
                    max={100}
                    onKeyPress={(e) => HelperService.allowNewDecimalValue(e)}
                    onKeyUp={(e) => HelperService.formateUptoTwoDecimal(e)}
                  />
                  {watchAllFields.cumulative_gpa &&
                    watchAllFields.cumulative_gpa > 100 && (
                      <div className="login-error">
                        <Label
                          title={
                            watchAllFields.cumulative_gpa
                              ? "Please Enter Valid GPA."
                              : "Please Enter GPA."
                          }
                          modeError={true}
                        />
                      </div>
                    )}
                </Form.Group>
              )}
            {watchAllFields.gpa_weighting &&
              watchAllFields.gpa_weighting == "WEIGHTED" && (
                <div className="mb-3">
                  <div className="d-flex justify-content-between">
                    <p className="font-14 font-light mb-4 col-lg-9">
                      If you're uncertain about your unweighted GPA, open can
                      assist you in estimating it. What grades do you typically
                      receive?<span className="text-danger">*</span>
                    </p>
                    {watchAllFields &&
                      watchAllFields.not_sure_unweighted_gpa && (
                        <p className="text-secondary font-14  ">
                          {getGradeRange(
                            watchAllFields.not_sure_unweighted_gpa
                          )}
                        </p>
                      )}
                  </div>

                  <ToggleButtonGroup
                    type="radio"
                    name="not_sure_unweighted_gpa"
                    onChange={(e: any) =>
                      setValue("not_sure_unweighted_gpa", e)
                    }
                    value={watchAllFields.not_sure_unweighted_gpa}
                    className="select-option-btns square font-14 grade-options-btns"
                  >
                    {gradesAlternate.map((item: any, index: number) => {
                      return (
                        <ToggleButton
                          key={index}
                          id={`tbg-radio-${index}`}
                          value={item.value}
                        >
                          {item.name}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>
              )}

            <Form.Group className="mb-4">
              <Form.Label>Freshman/first year GPA</Form.Label>
              <Form.Control
                {...register("freshman_gpa")}
                type="text"
                className={`${watchAllFields.freshman_gpa ? "border-brand" : ""}`}
                min={0}
                onKeyPress={(e) => HelperService.allowNewDecimalValue(e)}
                onKeyUp={(e) => HelperService.formateUptoTwoDecimal(e)}
              />
              {watchAllFields.freshman_gpa &&
                watchAllFields.freshman_gpa > 100 && (
                  <div className="login-error">
                    <Label
                      title={
                        watchAllFields.freshman_gpa
                          ? "Please Enter Valid GPA."
                          : "Please Enter GPA."
                      }
                      modeError={true}
                    />
                  </div>
                )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Sophomore/second year GPA</Form.Label>
              <Form.Control
                {...register("sophomore_gpa")}
                type="text"
                className={`${watchAllFields.sophomore_gpa ? "border-brand" : ""}`}
                min={0}
                onKeyPress={(e) => HelperService.allowNewDecimalValue(e)}
                onKeyUp={(e) => HelperService.formateUptoTwoDecimal(e)}
              />
              {watchAllFields.sophomore_gpa &&
                watchAllFields.sophomore_gpa > 100 && (
                  <div className="login-error">
                    <Label
                      title={
                        watchAllFields.sophomore_gpa
                          ? "Please Enter Valid GPA."
                          : "Please Enter GPA."
                      }
                      modeError={true}
                    />
                  </div>
                )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Junior/third year GPA</Form.Label>
              <Form.Control
                {...register("junior_third_year_gpa")}
                type="text"
                className={`${watchAllFields.junior_third_year_gpa ? "border-brand" : ""}`}
                min={0}
                onKeyPress={(e) => HelperService.allowNewDecimalValue(e)}
                onKeyUp={(e) => HelperService.formateUptoTwoDecimal(e)}
              />
              {watchAllFields.junior_third_year_gpa &&
                watchAllFields.junior_third_year_gpa > 100 && (
                  <div className="login-error">
                    <Label
                      title={
                        watchAllFields.junior_third_year_gpa
                          ? "Please Enter Valid GPA."
                          : "Please Enter GPA."
                      }
                      modeError={true}
                    />
                  </div>
                )}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Senior/fourth year GPA</Form.Label>
              <Form.Control
                {...register("senior_fourth_year_gpa")}
                type="text"
                className={`${watchAllFields.senior_fourth_year_gpa ? "border-brand" : ""}`}
                min={0}
                onKeyPress={(e) => HelperService.allowNewDecimalValue(e)}
                onKeyUp={(e) => HelperService.formateUptoTwoDecimal(e)}
              />
              {watchAllFields.senior_fourth_year_gpa &&
                watchAllFields.senior_fourth_year_gpa > 100 && (
                  <div className="login-error">
                    <Label
                      title={
                        watchAllFields.senior_fourth_year_gpa
                          ? "Please Enter Valid GPA."
                          : "Please Enter GPA."
                      }
                      modeError={true}
                    />
                  </div>
                )}
            </Form.Group>
            <div className="text-center">
              <Button
                type="submit"
                id="gpa-btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default GPAInfo;
