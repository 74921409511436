import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { Label } from "../Common/Label/Label";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import AddSchoolAddress from "./AddSchoolAddress";
import editIcon from "../../assets/images/icon-edit.svg";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";
import AsyncUnivarsityPaginationSelect from "../Common/OpenTagSelect/AsyncUnivarsityPaginationSelect";

interface propData {
  isUpdate: Function;
  type: any;
  tab: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
};

interface formData {
  school_college_id: any;
  college_from_date: string;
  college_to_date: string;
  why_left_secondary_high_school: string;
  school: any;
  school_address: string;
  school_country_id: string;
  school_state_id: string;
  school_state_code: string;
  school_city: string;
  school_street: string;
  school_zip: string;
  school_address_1: string;
  school_address_2: string;
  school_address_3: string;
};

const data1: formData = {
  school_college_id: "",
  college_from_date: "",
  college_to_date: "",
  why_left_secondary_high_school: "",
  school: null,
  school_address: "",
  school_country_id: "",
  school_state_id: "",
  school_state_code: "",
  school_city: "",
  school_street: "",
  school_zip: "",
  school_address_1: "",
  school_address_2: "",
  school_address_3: "",
};

const OtherSchool = (props: propData) => {
  const navigate = useNavigate();
  const [openSchoolAddress, setOpenSchoolAddress] = useState<boolean>(false);
  const [array, setArray] = useState<formData[]>([{ ...data1 }, { ...data1 }, { ...data1 }, { ...data1 }, { ...data1 }]);
  const [takenCourse, setTakenCourse] = useState<number>(-1);
  const [addressIndex, setAddressIndex] = useState<number>(-1);
  const [editAddress, setEditAddress] = useState<formData>({} as formData);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "additional" && isChange && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "additional" && isChange) {
      props.isFormDirty(true, 'additional');
    } else {
      props.isFormDirty(false, 'additional');
    }
  }, [isChange]);

  useEffect(() => {
    if (props.tab == "additional" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "additional") {
      getOtherSchoolData();
    }
  }, [props.tab]);

  const getOtherSchoolData = () => {
    WebService.getAPI({
      action: `get/user/other-school-college/details?type=${props.type}`,
    })
      .then((res: any) => {
        setTakenCourse(res.no_of_school);
        if (res.user_other_school_college && res.user_other_school_college.length > 0) {
          res.user_other_school_college.map((item: formData, i: number) => {
            array[i].college_from_date = item.college_from_date;
            array[i].college_to_date = item.college_to_date;
            array[i].why_left_secondary_high_school = item.why_left_secondary_high_school;
            if (item.school) {
              array[i].school_college_id = { ...item.school, ...{ value: item.school.id, label: item.school.name } };
              array[i].school = item.school;
              array[i].school_address = item?.school.country_id == 239 ? `${item?.school?.address || ""}, ${item?.school?.city_obj?.name || ""}, ${item?.school?.state_obj?.code || ""}, ${item?.school?.zip || ""}` : `${item?.school?.school_address_1 || ""}, ${item?.school?.city_obj?.name || ""}, ${item?.school?.zip || ""}`;
              array[i].school_address_1 = item?.school?.school_address_1;
              array[i].school_address_2 = item?.school?.school_address_2;
              array[i].school_address_3 = item?.school?.school_address_3;
              array[i].school_country_id = item?.school?.country_id;
              array[i].school_state_id = item?.school?.state_obj?.id;
              array[i].school_state_code = item?.school?.state_obj?.code;
              array[i].school_city = item?.school?.city_obj?.name;
              array[i].school_street = item?.school?.address;
              array[i].school_zip = item?.school?.zip;
            };
          });
          setArray([...array]);
        }
      })
      .catch((e) => { });
  };

  const onSave = async () => {
    const userOtherSchool = [];
    for (let i in array) {
      if (Number(i) < takenCourse) {
        const SchoolCollegeID = array[i].school_college_id.value;
        const CollegeFromDate = array[i].college_from_date;
        const CollegeToDate = array[i].college_to_date;
        const LeftHighSchool = array[i].why_left_secondary_high_school;
        userOtherSchool.push({
          school_college_id: SchoolCollegeID,
          college_from_date: CollegeFromDate,
          college_to_date: CollegeToDate,
          why_left_secondary_high_school: LeftHighSchool,
          school_address_1: array[i].school_address_1,
          school_address_2: array[i].school_address_2,
          school_address_3: array[i].school_address_3,
          school_country_id: array[i].school_country_id,
          school_state_id: array[i].school_state_id,
          school_state_code: array[i].school_state_code,
          school_city: array[i].school_city,
          school_street: array[i].school_street,
          school_zip: array[i].school_zip
        });
      }
    }

    const requestBody = { user_other_school_college: userOtherSchool, no_of_school: takenCourse };

    await WebService.postAPI({
      action: `save/user/other-school-college/details?type=${props.type}`,
      body: requestBody,
      id: "OtherSchool-btn"
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        setIsChange(false);
      })
      .catch((e: any) => { });
  };

  const onClose = (type: boolean, data: any) => {
    if (type && data && addressIndex > -1) {
      array[addressIndex].school_country_id = data.country_id;
      array[addressIndex].school_state_id = data.state_id;
      array[addressIndex].school_state_code = data.state_code;
      array[addressIndex].school_city = data.city;
      array[addressIndex].school_street = data.street;
      array[addressIndex].school_zip = data.zipcode;
      array[addressIndex].school_address_1 = data.address_1;
      array[addressIndex].school_address_2 = data.address_2;
      array[addressIndex].school_address_3 = data.address_3;
      if (data.country_id == 239) {
        array[addressIndex].school_address = `${data?.street || ""}, ${data?.city || ""}, ${data?.state_code || ""}, ${data?.zipcode || ""}`;
      } else {
        array[addressIndex].school_address = `${data?.address_1 || ""}, ${data?.city || ""}, ${data?.zipcode || ""}`;
      }
      setArray([...array]);
    };
    setOpenSchoolAddress(false);
    setAddressIndex(-1);
    setEditAddress({} as formData);
    setIsChange(true);
  };

  const onChangeSchool = (e: any, i: number) => {
    if (e && e.value) {
      if (isNaN(e.value)) {
        array[i].school_address = '';
        array[i].school_address_1 = '';
        array[i].school_address_2 = '';
        array[i].school_address_3 = '';
        array[i].school_country_id = '';
        array[i].school_state_id = '';
        array[i].school_state_code = '';
        array[i].school_city = '';
        array[i].school_street = '';
        array[i].school_zip = '';
        setAddressIndex(i);
        setTimeout(() => { setOpenSchoolAddress(true); }, 100);
      } else {
        array[i].school_address = `${e?.address || ""}, ${e?.city_obj?.name || ""}, ${e?.state_obj ? e?.state_obj?.code : e?.state || ""}, ${e?.zipcode || e?.zip || ""}`;
      }
      array[i].school_college_id = e;
      setArray([...array]);
    };
  };

  const isDisable = () => {
    let isDisable = false;
    array.map((item: formData) => {
      if (item.school_college_id && isNaN(item.school_college_id) && !item.school_address) {
        isDisable = true;
      };
    });
    return isDisable;
  };


  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave();
          WebService.removeLoader('Save_and_exit_btn');
        }
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <Row>
        <Col className="form-style-2" style={{ maxWidth: "624px" }}>
          <h4 className="font-medium font-20 mb-3 pb-1">Additional high/secondary schools</h4>

          <Form.Group className="mb-4">
            <p className="font-16">
              If you attened more than one high/secondary schools, how many?<span className="text-danger">*</span>
            </p>
            <Form.Check
              type="radio"
              id="0"
              label="0"
              value={0}
              checked={takenCourse == 0 ? true : false}
              onClick={() => { setTakenCourse(0); setIsChange(true); }}
            />
            <Form.Check
              type="radio"
              id="1"
              label="1"
              value={1}
              checked={takenCourse == 1 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(1) }}
            />
            <Form.Check
              type="radio"
              id="2"
              label="2"
              value={2}
              checked={takenCourse == 2 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(2) }}
            />
            <Form.Check
              type="radio"
              id="3"
              label="3"
              value={3}
              checked={takenCourse == 3 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(3) }}
            />
            <Form.Check
              type="radio"
              id="4"
              label="4"
              value={4}
              checked={takenCourse == 4 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(4) }}
            />
            <Form.Check
              type="radio"
              id="5"
              label="5"
              value={5}
              checked={takenCourse == 5 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(5) }}
            />
          </Form.Group>

          {array.map((_, index: number) => {
            if (index < takenCourse) {
              return (
                <div>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      High/secondary school {index + 2}<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="form-style  mb-3">
                      <AsyncUnivarsityPaginationSelect
                        placeholder="Choose an option"
                        sakey="current_high_school"
                        selected={_.school_college_id}
                        type="SCHOOL"
                        onChange={(e: any) => { setIsChange(true); onChangeSchool(e, index); }}
                        isCustom={true}
                      />
                    </div>
                  </Form.Group>

                  {
                    _.school_college_id && _.school_college_id.type == "CUSTOM" ?
                      <Form.Group className="mb-4">
                        <InputGroup>
                          <Form.Control className={`${_.school_address ? "border-brand border-end-0 rounded-end-0" : "border-end-0 rounded-end-0"}`} type={"text"} placeholder="School address" disabled value={_.school_address} />
                          <InputGroup.Text id="basic-addon2" className={`${_.school_address ? "border-brand cursor-pointer bg-transparent" : "cursor-pointer bg-transparent"}`} onClick={() => { setAddressIndex(index); setEditAddress(_); setOpenSchoolAddress(true); }}>
                            <img className="cursor-pointer" src={editIcon} alt="icon" />
                          </InputGroup.Text>
                        </InputGroup>
                        {(!_.school_address) && <div className="login-error"><Label title={"Please enter school address"} modeError={true} /></div>}
                      </Form.Group>
                      :
                      <Form.Group className="mb-4">
                        <Form.Control className={`${_.school_address ? "border-brand" : ""}`} type="text" placeholder="School address" disabled value={_.school_address} />
                      </Form.Group>
                  }

                  <Form.Group className="mb-4">
                    <Form.Label>
                      High/secondary school {index + 2} from date<span className="text-danger">*</span>
                    </Form.Label>
                    <OpenDatePicker
                      type="monthandyear"
                      selected={_.college_from_date}
                      maxData={new Date()}
                      onChange={(e: any) => { setIsChange(true); array[index].college_from_date = e; array[index].college_to_date = ""; setArray([...array]) }}
                    />
                    <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>
                      High/secondary school {index + 2} to date<span className="text-danger">*</span>
                    </Form.Label>
                    <OpenDatePicker
                      type="monthandyear"
                      selected={_.college_to_date}
                      minData={new Date(_.college_from_date)}
                      onChange={(e: any) => { setIsChange(true); array[index].college_to_date = e; setArray([...array]) }}
                    />
                    <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                  </Form.Group>

                  {/* ///////// Textarea ///////////// */}
                  <Form.Group className="mb-4 ">
                    <Form.Label>
                      Please provide details about why you left each of the
                      above high/secondary schools.<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      className={`${_.why_left_secondary_high_school ? "border-brand h-auto" : "h-auto"}`}
                      value={_.why_left_secondary_high_school}
                      onChange={(e: any) => { setIsChange(true); array[index].why_left_secondary_high_school = e.target.value; setArray([...array]) }}
                    />
                  </Form.Group>
                </div>
              );
            }
          })}
          <div className="text-center">
            <Button
              type="button"
              onClick={() => onSave()}
              disabled={isDisable()}
              id="OtherSchool-btn"
              className="btn btn-brand-1 w-100"
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>

      {openSchoolAddress && <AddSchoolAddress show={openSchoolAddress} isClose={onClose} schoolName={editAddress && editAddress?.school_college_id && editAddress?.school_college_id.label} data={editAddress} />}
    </>
  );
};

export default OtherSchool;