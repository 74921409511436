
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { USER_LOGIN_SUCCESS } from "./action/CommonAction";
import './App.scss';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);



const App = () => {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    getLocalState();
    // eslint-disable-next-line
  }, []);

  const getLocalState = () => {
    const value = localStorage.getItem("token");
    if (value) {
      dispatch({ type: USER_LOGIN_SUCCESS, payload: { access_token: value } });
    };
  };


  return (
    <>
      <div className="App">
        <ToastContainer hideProgressBar={true} />
        <Navigation />
      </div>
    </>
  );
};
export default App;
