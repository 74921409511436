import { Form, Button, InputGroup } from "react-bootstrap"
import { Link } from "react-router-dom";
import Logo from '../../assets/images/open-logo.svg'
import SuccesIcon from '../../assets/images/icon-success.svg'
import "./login.scss";
const Success = () => {
    return (
        <>
            <div className="login-page">
                <div className="row g-0 justify-content-center align-items-center min-vh-100">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className="mb-3 w-100">
                            <div className="px-lg-5 mb-3">
                                <div className="mb-4 text-center">
                                    <img src={Logo} width="110" alt="Logo" />
                                </div>
                                <div className="card p-4 form-style-2 border-0 rounded-4 shadow">
                                    <div className="text-center">
                                        <img src={SuccesIcon} width={100} className="mb-4" />
                                        <h3 className="mb-4 font-bold">Password reset successfully</h3>
                                    </div>
                                    <Link to="/login" className="btn btn-brand-1 w-100">Log in</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Success;