import { Row, Col, Button, Form } from "react-bootstrap"
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import OpenDatePicker from "../../../components/Common/OpenDatePicker/OpenDatePicker";
import HelperService from "../../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
    activeTab: any;
    isUpdate: Function;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

const IBSubjectTest = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const scoreOption: any[] = HelperService.getNumberList(1, 7, 1).reverse();
    const numberOption: any[] = HelperService.getNumberList(0, 10, 1);
    const data: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [subjectList, setSubjectList] = useState<any[]>([]);
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.activeTab == "IBSubjectTests" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.activeTab == "IBSubjectTests" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'IBSubjectTests');
        } else {
            props.isFormDirty(false, 'IBSubjectTests');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.activeTab == "IBSubjectTests" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);


    useEffect(() => {
        if (props.activeTab == "IBSubjectTests") { reset({}); getDatail(); getSubjectList(); }
    }, [props.activeTab]);

    const getDatail = () => {
        WebService.getAPI({
            action: `get/standarized/test/detail?type=IB_SUBJECT_TEST`,
        })
            .then((res: any) => {
                setValue("ib_taken", res.no_of_ib_test)
                if (res.result.length) {
                    res.result.map((item: any, j: number) => {
                        setValue(`date_planned_taken${j + 1}`, item.date_planned_taken);
                        setValue(`subject${j + 1}`, item.subject);
                        setValue(`level_of_test${j + 1}`, item.level_of_test);
                        setValue(`score_received${j + 1}`, item.score_received);
                    })
                }
            })
            .catch((e) => { });
    };

    const getSubjectList = () => {
        WebService.getAPI({
            action: "subject/list?type=IB_SUBJECT",
        }).then((res: any) => {
            if (res.list && res.list.length > 0) {
                setSubjectList(
                    res.list.map((item: any) => {
                        return {
                            ...item,
                            ...{ ...{ value: item.name } },
                        };
                    })
                );
            }
        });
    };

    const onSave = async (reqData: any) => {
        let obj: any = {
            user_ib_cambridge: [],
            no_of_ib_test: reqData.ib_taken
        };
        data.map((item: any) => {
            if (reqData.ib_taken >= item) {
                obj.user_ib_cambridge.push(
                    {
                        date_planned_taken: reqData[`date_planned_taken${item}`],
                        subject: reqData[`subject${item}`],
                        level_of_test: reqData[`level_of_test${item}`],
                        score_received: reqData[`score_received${item}`]
                    }
                )
            }
        });
        await WebService.postAPI({
            action: "save/tests?type=IB_SUBJECT_TEST",
            body: obj,
            id: "IBSubject_test_btn",
        })
            .then((res: any) => {
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };

    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col style={{ maxWidth: "624px" }}>
                        <h4 className="font-medium font-20 mb-3 pb-1">IB Subject Tests</h4>
                        <Form className="form-style-2">
                            <Form.Group className="mb-4">
                                <Form.Label>Number of IB Tests you wish to report, including tests you expect to take<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name={`ib_taken`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                        <div className="react-select">
                                            <OpenSelect
                                                sakey="ib_taken"
                                                options={numberOption}
                                                selected={watchAllFields.ib_taken}
                                                onChange={(data: any) => { field.onChange(data.id); }}
                                                isSearchable={true}
                                                placeholder="Choose an option"
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            {
                                watchAllFields.ib_taken > 0 && data.map((item: number) => {
                                    return <>
                                        {
                                            watchAllFields.ib_taken >= item &&
                                            <>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Date taken or planned<span className="text-danger">*</span></Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`date_planned_taken${item}`}
                                                        rules={{ required: false }}
                                                        render={({ field }: any) => (
                                                            <OpenDatePicker
                                                                type="date"
                                                                selected={watchAllFields[`date_planned_taken${item}`]}
                                                                onChange={(e: any) => field.onChange(e)}
                                                            />
                                                        )}
                                                    />
                                                    <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                                </Form.Group>

                                                <Form.Group className="mb-4">
                                                    <Form.Label>Subject<span className="text-danger">*</span></Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`subject${item}`}
                                                        rules={{ required: false }}
                                                        render={({ field }: any) => (
                                                            <div className="react-select">
                                                                <OpenSelect
                                                                    sakey={`subject${item}`}
                                                                    options={subjectList}
                                                                    selected={watchAllFields[`subject${item}`]}
                                                                    onChange={(data: any) => { field.onChange(data.id); }}
                                                                    isSearchable={true}
                                                                    placeholder="Choose an option"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Level of test</Form.Label>
                                                    <Form.Check
                                                        type="radio"
                                                        id="Exact"
                                                        label="Higher level (HL)"
                                                        value={"H_LEVEL"}
                                                        {...register(`level_of_test${item}`, { required: false })}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="Exact"
                                                        label="Standard level (SL)"
                                                        value={"S_LEVEL"}
                                                        {...register(`level_of_test${item}`, { required: false })}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-4">
                                                    <Form.Label>Score received<span className="text-danger">*</span></Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`score_received${item}`}
                                                        rules={{ required: false }}
                                                        render={({ field }: any) => (
                                                            <div className="react-select">
                                                                <OpenSelect
                                                                    sakey={`score_received${item}`}
                                                                    options={scoreOption}
                                                                    selected={watchAllFields[`score_received${item}`]}
                                                                    onChange={(data: any) => { field.onChange(data.id); }}
                                                                    isSearchable={true}
                                                                    placeholder="Choose an option"
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>
                                            </>
                                        }
                                    </>
                                })
                            }
                        </Form>
                        <div className="text-center">
                            <Button type="submit" id="IBSubject_test_btn" className="btn btn-brand-1 w-100">
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
}
export default IBSubjectTest;
