import { Button, Col, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import CardLoader from "../../components/Common/CardLoader";
import { userInfoInterface } from "../../OpenInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";


interface PropData {
    show: boolean;
    isClose: Function;
    id: string;
    uni_id: string;
};

const ViewPreviewModal = (props: PropData) => {
    const [docList, setDocList] = useState<any[]>([]);
    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);

    useEffect(() => {
        if (props.show && props.id) {
            getDocuments();
        }
    }, [props.show])

    const closeClick = (type?: boolean) => {
        setDocList([]);
        props.isClose(type);
    };

    const getDocuments = () => {
        setIsLoading(true);
        WebService.getAPI({ action: `download/pdf/${props.id}?university_id=${props.uni_id}` })
            .then((res: any) => {
                setIsLoading(false);
                setPdfUrl(res.url);
                setFileName(res.file_name);
            })
            .catch((e: any) => { setIsLoading(false); });
    };

    const onUploadFile = (data: any, name: string, size: string) => {
        var isImageExte = /(\.(jpg|jpeg|png))$/i;
        let file_type: string = "PDF";
        if (isImageExte.test(name)) {
            file_type = 'IMAGE'
        }
        docList.push({ document_name: name, file_size: `${size} KB`, file: data, file_type: file_type });
        setDocList([...docList]);
    };


    return (
        <Modal show={props.show} centered onHide={() => closeClick()}
            scrollable
            backdrop="static"
            keyboard={false}
            className="size-960">
            <Modal.Header closeButton><span className="font-24">Preview</span></Modal.Header>
            <Modal.Body>
                {isLoading && <CardLoader />}
                {!isLoading && pdfUrl && <iframe name={fileName} src={pdfUrl} width="100%" height="500px"></iframe>}
            </Modal.Body>
            <Modal.Footer>
                <Row className=" justify-content-center w-100">
                    <Col lg={4}>
                        {<a href={pdfUrl} target="_blank" download={"Document"}><Button className="btn-brand-1 w-100"> Download</Button></a>}
                    </Col>

                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewPreviewModal;
