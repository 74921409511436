import moment from "moment";
import ReactTimeAgo from 'react-time-ago';

const HelperService = {

  addMinutes(date: Date, minutes: number) {
    if (date && minutes) {
      let data = moment(new Date(date)).add(minutes, 'minutes')
      return data;
    } else {
      return new Date();
    }
  },

  addDays(date: Date, days: number) {
    if (date && days) {
      let data: Date | any = moment(date).add(days, 'days')
      return data;
    } else {
      return new Date();
    }
  },

  getDiffinDays(date1: Date, date2: Date) {
    if (date1 && date2) {
      let data = moment(date1).diff(date2, 'days')
      return data;
    } else {
      return 0;
    }
  },

  addHours(date: Date, hour: number) {
    if (date && hour) {
      let data = moment(date).add(hour, 'hours')
      return data;
    } else {
      return new Date();
    }
  },

  getYearList(count: number, startYear?: any) {
    const yearList = [];
    const year = startYear || new Date().getFullYear();
    for (let i = 0; i < count; i += 1) {
      yearList.push({ id: Number(year) - i, value: Number(year) - i })
    }
    // return yearList.sort((a, b) => a.id - b.id);
    return yearList;
  },

  getNumberList(start: number, end: number, diff: number) {
    const dif = diff.toString().split(".");
    const List: any[] = [];
    if (dif && dif.length == 1) {
      for (let i = start; i <= end; i += diff) {
        List.push({ id: Number(i).toString(), value: Number(i).toString() });
      }
    } else if (dif && dif.length > 1) {
      for (let i = start; i <= end; i = Number(parseFloat((i + diff).toString()).toFixed(dif.length - 1))) {
        List.push({ id: parseFloat(i.toString()).toFixed(dif.length - 1), value: parseFloat(i.toString()).toFixed(dif.length - 1) })
      }
    }
    // return yearList.sort((a, b) => a.id - b.id);
    return List;
  },


  getPairedYearList(count: number, startYear?: any) {
    const yearList = [];
    const year = startYear || new Date().getFullYear() - count;
    for (let i = 0; i < count; i += 1) {
      yearList.push({ id: `${Number(year) - (i + 1)}-${Number(year) - i}`, value: `${Number(year) - (i + 1)}-${Number(year) - i}` })
    }
    // return yearList.sort((a, b) => a.id - b.id);
    return yearList;
  },

  addYear(date: any, year: number) {
    if (date && (year || year == 0)) {
      let data = moment(date).add(year, 'years').format()
      return data;
    } else {
      return new Date();
    }
  },

  removeYear(date: any, year: number) {
    if (date && year) {
      let data = moment(date).subtract(year, 'years').format()
      return data;
    } else {
      return new Date();
    }
  },

  generateOTP() {
    var digits = '0123456789';
    let OTP = '';
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  },

  allowOnlyAlphabetsValues(e: any) {
    var numbers = /^[a-zA-Z ]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
    // if (e.currentTarget.value.length > 11) {
    //   e.preventDefault();
    //   return false;
    // }
  },

  allowOnlyNumericValue(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 11) {
      e.preventDefault();
      return false;
    }
  },

  countWords(str: string) {
    if (str) {
      return str.trim().split(/\s+/).length;
    } else {
      return 0;
    }
  },

  allowOnlyNumericValueUpto1(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 0) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto2(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 1) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto3(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 2) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto4(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 3) {
      e.preventDefault();
      return false;
    }
  },

  allowOnlyNumericValueUpto8(e: any) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }

    if (e.currentTarget.value.length > 7) {
      e.preventDefault();
      return false;
    }
  },

  maxNumber(e: any, maxNumber: number) {
    if (maxNumber && e.currentTarget.value.length > maxNumber) {
      e.preventDefault();
      return false;
    }
  },

  contactFormatter: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getFormattedContact(
        e.currentTarget.value ? e.currentTarget.value.replaceAll("-", "") : ""
      );
    }
  },

  allowNewDecimalValue(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode != 46 || e.target.value.indexOf(".") != -1)
    ) {
      e.preventDefault();
      return false;
    }
    if (e.target.value != null && e.target.value.indexOf(".") > -1 && e.target.value.split(".")[1].length > 1 && (e.target.selectionStart == e.target.value.length ||
      e.target.selectionStart == e.target.value.length - 1 ||
      e.target.selectionStart == e.target.value.length - 2)
    ) {
      e.preventDefault();
      return false;
    }
  },

  allowFourDecimalValue(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;

    if (
      (keyCode < 48 || keyCode > 57) &&
      (keyCode != 46 || e.target.value.indexOf(".") != -1)
    ) {
      e.preventDefault();
      return false;
    }

    if (
      e.target.value != null &&
      e.target.value.indexOf(".") > -1 &&
      e.target.value.split(".")[1].length > 1 &&
      (e.target.selectionStart == e.target.value.length ||
        e.target.selectionStart == e.target.value.length - 1 ||
        e.target.selectionStart == e.target.value.length - 2)
    ) {
      e.preventDefault();
      return false;
    }
    if (e.target.value > 99.99999) {
      // e.preventDefault();
      e.currentTarget.value = 99.9999;
    }
  },

  checkValue(e: any) {
    if (e.target.value > 99.99999) {
      e.currentTarget.value = 99.99;
    }
  },

  formateDecimal(value: any) {
    return value ? parseFloat(value).toFixed(2) : "";
  },

  removeDecimal(value: any) {
    return value ? Math.floor(value) : 0;
  },

  formateUptoTwoDecimal(e: any) {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    if (e && e.target && e.target.value.indexOf(".") != -1 && e.target.value.split(".")[1].length > 1 && keyCode != 46) {
      e.currentTarget.value = this.formateDecimal(e.target.value);
    }
  },

  isValidEmail(email: string) {
    return email
      ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
      : true;
  },

  getFormattedContact: function (e: any) {
    if (e) {
      const match = e.toString().replace(/\D+/g, "");
      const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match;
      const part2 = match.length > 3 ? `-${match.substring(3, 6)}` : "";
      const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
      var x = part1 + "" + part2 + "" + part3;
      return x;
    } else {
      return e;
    }
  },

  timeFormatter: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getFormattedTime(
        e.currentTarget.value ? e.currentTarget.value.replaceAll(":", "") : ""
      );
    }
  },

  getFormattedTime: function (e: any) {
    if (e) {
      const match = e.replace(/\D+/g, "");
      const part1 = match.length > 1 ? `${match.substring(0, 2)}` : match;
      const part2 = match.length > 2 ? `:${match.substring(2, 4)}` : "";
      var part3 = "";
      if ((e.length >= 4 && e.includes('p')) || (e.length >= 4 && e.includes('P'))) {
        var part3 = " PM"
      } else if ((e.length >= 4 && e.includes('a')) || (e.length >= 4 && e.includes('A'))) {
        var part3 = " AM"
      }
      var x = part1 + "" + part2 + "" + part3;
      return x;
    } else {
      return e;
    }
  },

  getCurrencyFormatter: function (e: any) {
    if (e || e == 0) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(e);
    } else {
      return e;
    }
  },

  getAmmountFormatter: function (e: any) {
    if (e || e == 0) {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        // minimumFractionDigits: 2,
      }).format(e);
    } else {
      return e;
    }
  },

  currencyFormat: function (e: any) {
    e.currentTarget.value = this.formatAmount(
      e.currentTarget.value ? e.currentTarget.value.replaceAll(",", "") : ""
    );
  },

  formatAmount: function (e: any) {
    if (e > 0) {
      var numb =
        e.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
        (e.indexOf(".") == -1 ? ".00" : "");
      return numb;
    } else {
      return "";
    }
  },

  getFormatedDateInUs(d: any) {
    return d ? moment.utc(d).format("MM-DD-YY") : "";
  },

  getFormatedDate(d: any) {
    return d ? moment.utc(d).format("MM/DD/YY") : "";
  },

  getFormatedDatebyYYYY(d: any) {
    return d ? moment.utc(d).format("MM/DD/YYYY") : "";
  },

  getFormatedDateWithYear(d: any) {
    return d ? moment.utc(d).format("YYYY-MM-DD") : "";
  },

  getFormatedDateandYear(d: any) {
    return d ? moment.utc(d).format("YYYY-M-D") : "";
  },

  getFormatedDateAndTime(dt: any) {
    return moment.utc(dt).format("MM/DD/YY, hh:mm A");
  },

  getFormatedDateAndTimeWithoutUTC(dt: any) {
    return moment(dt).format("MM/DD/YY, hh:mm A");
  },

  getFormatedYear(dt: any) {
    return moment(dt).format("YYYY");
  },


  getMonthNumber(dt: any) {
    return moment(dt).month();
  },

  getFormatedDateForDatePicker(dt: any) {
    return moment(dt).format("LL");
  },

  getFormatedDateForPlanner(dt: any) {
    let str: string = moment(dt).format("ddd") + ","
    str += " " + moment(dt).format("LL");
    return str;
  },

  getFormatedDateForPlannerUpdated(dt: any) {
    let str: string = moment(dt).format("ddd") + "," + moment(dt).format("MMM") + " " + moment(dt).format("DD") + "," + moment(dt).format("YYYY");
    return str;
  },

  getFormatedDateForCalender(dt: any) {
    let str: string = moment(dt).format("ddd") + ","
    str = str.toUpperCase() + " " + moment(dt).format("ll");
    return str;
  },

  getFormatedDateForDashboardPlanner(dt: any) {
    let str: string = moment(dt).format("ddd") + "," + moment(dt).format("MMM") + " " + moment(dt).format("DD");
    return str;
  },


  // ll

  getFormatedDateForDashboard(dt: any) {
    let str: string = moment(dt).format("ddd") + ","
    str += " " + moment(dt).format("dddd , MMMM Do");
    // return str;
    return moment(dt).format("dddd , MMMM Do");
  },

  getFormatedDateForTimePicker(dt: any) {
    return moment.utc(dt).format("YYYY-MM-DD hh:mm:ss");
  },

  getFormatedDateForSorting(dt: any) {
    if (dt) {
      return moment(dt).format("YYYY-MM-DD");
    }
  },

  getFormatedDateWithMonthAndYear(dt: any) {
    if (dt) {
      return moment(dt).format("MMMM YYYY");
    }
  },

  getFormatedDateForDetail(dt: any) {
    if (dt) {
      return moment(dt).format("YYYY-M-D");
    }
  },

  getFormateTimeFromTime(t: any) {
    return moment(t, 'hh:mm A').format("HH:mm:ss");
  },

  getFormatedTime(d: any) {
    return d ? moment.utc(d).format("hh:mm A") : "";
  },

  getFormatedDateWithoutUTC(dt: any) {
    return moment(dt).format("YYYY-MM-DD hh:mm:ss");
  },

  getFormatedTimeWithoutUTC(d: any) {
    return d ? moment(d).format("hh:mm A") : "";
  },

  getFormatedTimeWithSecond(d: any) {
    return d ? moment.utc(d).format("hh:mm:ss") : "";
  },

  getFormatedDays(dt: any) {
    return moment.utc(dt).format("dddd");
  },

  removeHtml(data: string) {
    if (data) {
      return data
        .replace(/<\/?[^>]+(>|$)/g, " ")
        .replace(/\&nbsp;/g, "")
        .replaceAll("&amp;", "&")
        .replaceAll("&quot;", '"')
        .replaceAll("&#39;", "'")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .trim();
    }

    return "";
  },

  capitalizeFirst(string: string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return ""
    };
  },

  isSameDate(date1: any, date2: any) {
    return moment(date1).isSame(moment(date2), 'D')
  },

  isEmptyObject(data: object) {
    return Object.keys(data).length === 0;
  },

  getString(data: string, size: number) {
    if (data.length > size - 3) {
      return data.substring(0, size - 3) + "...";
    }

    return data;
  },

  validCreditCard(number: any) {
    var data = number.replaceAll("-", "");
    var isValid = "";
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    if (visaRegEx.test(data)) {
      isValid = "VISA";
    } else if (mastercardRegEx.test(data)) {
      isValid = "MASTERCARD";
    } else if (amexpRegEx.test(data)) {
      isValid = "AMERICANEXPRESS";
    } else if (discovRegEx.test(data)) {
      isValid = "DISCOVERCARD";
    }
    return isValid;
  },

  formatCreditCard: function (e: any) {
    if (e.currentTarget.value) {
      e.currentTarget.value = this.getformatCreditCard(
        e.currentTarget.value ? e.currentTarget.value.replaceAll("-", "") : ""
      );
    }
  },

  getCompanyIcon: function (e: any) {
    if (e) {
      let logo = e.toUpperCase().split(" ");
      if (logo.length >= 2) {
        return `${logo[0].charAt(0)}${logo[1].charAt(0)}`;
      } else if (logo.length == 1) {
        return `${logo[0].charAt(0)}${logo[0].charAt(1)}}`;
      } else {
        return "";
      }
    }
  },

  getTimes(startTime: any, endTime: any, interval: number) {
    let allTimes = [];
    while (startTime < endTime) {
      allTimes.push(startTime.format("YYYY-MM-DD HH:mm:ss"));
      startTime.add(interval, "minutes");
    }

    return allTimes;
  },

  getformatCreditCard(e: any) {
    if (e) {
      const match = e.replace(/\D+/g, "");
      const part1 = match.length > 3 ? `${match.substring(0, 4)}` : match;
      const part2 = match.length > 4 ? `-${match.substring(4, 8)}` : "";
      const part3 = match.length > 8 ? `-${match.substring(8, 12)}` : "";
      const part4 = match.length > 12 ? `-${match.substring(12, 16)}` : "";
      var x = part1 + "" + part2 + "" + part3 + "" + part4;
      return x;
    } else {
      return e;
    }
  },

  getShadeColor(color: string, percent: any) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(255, Math.max(0, parseInt(color, 16) + percent)).toString(
              16
            )
          ).substr(-2)
        )
    );
  },

  getFromThen(value: any) {
    if (value) {
      var text = value.split(" ")
      var str = '';
      var isAdd = false;
      if (text.length > 0) {
        for (var i in text) {
          if (text[i] == 'then') {
            isAdd = true
          }
          if (isAdd) {
            str += `${text[i]} `
          }
        }
      }
      return str;
    }
  },

  capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },

  filter(template: string, values: any) {
    if (!values || !values.length || !template) {
      return template;
    }
    return this.toFormattedString(false, template, values);
  },

  toFormattedString(useLocale: boolean, format: any, values: any) {
    var result = '';

    for (var i = 0; ;) {
      // Find the next opening or closing brace
      var open = format.indexOf('{', i);
      var close = format.indexOf('}', i);
      if ((open < 0) && (close < 0)) {
        // Not found: copy the end of the string and break
        result += format.slice(i);
        break;
      }
      if ((close > 0) && ((close < open) || (open < 0))) {

        if (format.charAt(close + 1) !== '}') {
          throw new Error('format stringFormatBraceMismatch');
        }

        result += format.slice(i, close + 1);
        i = close + 2;
        continue;
      }

      // Copy the string before the brace
      result += format.slice(i, open);
      i = open + 1;

      // Check for double braces (which display as one and are not arguments)
      if (format.charAt(i) === '{') {
        result += '{';
        i++;
        continue;
      }

      if (close < 0) throw new Error('format stringFormatBraceMismatch');

      // Find the closing brace

      // Get the string between the braces, and split it around the ':' (if any)
      var brace = format.substring(i, close);
      var colonIndex = brace.indexOf(':');
      var argNumber = parseInt((colonIndex < 0) ? brace : brace.substring(0, colonIndex), 10);

      if (isNaN(argNumber)) throw new Error('format stringFormatInvalid');

      var argFormat = (colonIndex < 0) ? '' : brace.substring(colonIndex + 1);

      var arg = values[argNumber];
      if (typeof (arg) === "undefined" || arg === null) {
        arg = '';
      }

      // If it has a toFormattedString method, call it.  Otherwise, call toString()
      if (arg.toFormattedString) {
        result += arg.toFormattedString(argFormat);
      } else if (useLocale && arg.localeFormat) {
        result += arg.localeFormat(argFormat);
      } else if (arg.format) {
        result += arg.format(argFormat);
      } else
        result += arg.toString();

      i = close + 1;
    }

    return result;
  },

  getSubstring(string: string, char1: string, char2: string) {
    return string.slice(
      string.indexOf(char1) + char1.length,
      string.lastIndexOf(char2),
    )
  },

  getFutureTimeDifference(out_time: any) {
    const diffTime = new Date(out_time).getTime() - new Date().getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      return "Deadline was " + this.getFormatedDateForDashboardPlanner(out_time);
    } else if (diffDays == 0) {
      return this.getFormatedTime(out_time);
    } else if (diffDays == 1) {
      return this.getFormatedTime(out_time);
    } else {
      return "Deadline " + this.getFormatedDateForDashboardPlanner(out_time);
    }
  },

  getTimeago(data: any) {
    let userDate = data ? data : new Date();
    return <ReactTimeAgo date={userDate} locale="en-US" locales={["en-US", "en-IN"]} future={false} />
  },

  getFutureTimeDifferenceForFeed(out_time: any) {
    const diffTime = new Date(out_time).getTime() - new Date().getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays < 0) {
      return "Expired";
    } else if (diffDays == 0) {
      return "Expiring Today";
    } else if (diffDays == 1) {
      return "Expiring Tomorrow";
    } else {
      return "Expiring in " + diffDays + " days";
    }
  }

};

export default HelperService;