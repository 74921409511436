import { Card, Nav } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import iconUsers from "../../../assets/images/icon-team.svg"
import iconCap from "../../../assets/images/GraduationHat-color.svg"
import iconList from "../../../assets/images/icon-list-color.svg"
import iconStar from "../../../assets/images/icon-star.svg"
import iconNavigator from "../../../assets/images/icon-navigator.svg"

interface propData {
    activeTab: string;
    changeTab: Function;
    status: any;
}



const NavTabs = (props: propData) => {
    const navigate = useNavigate();
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompleteCurrentSchool": false,
        "isCompleteAdditionalSchool": false,
        "isCompleteCourseCollegeUniversities": false,
        "isCompleteGpa": false,
        "isCompleteHonorsRecognition": false,
        "isCompleteCommunityOrganizations": false,
        "isCompleteCourseGrade": false,
        "isCompleteFuturePlan": false
    });

    useEffect(() => {
        setPageStatus(props.status);
    }, [props.status]);

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    };


    return (
        <>
            <Card className="card-shadow bg-light rounded-start-0 h-100" style={{ backgroundColor: "#F9F9F9" }}>
                <Nav variant="pills" className="flex-column tab-style-2">
                    <Nav.Item onClick={() => { props.activeTab != "recent" && props.changeTab("recent"); }} className="w-100">
                        <Nav.Link eventKey="recent">
                            <div className="d-flex justify-content-between align-items-center gap-3">
                                <div className=" text-truncate">
                                    <img src={iconCap} className="icon" alt="" /> <span className="col-8">Most recent or current high/secondary school</span></div>
                                {complitionStatus(pageStatus.isCompleteCurrentSchool)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "additional" && props.changeTab("additional"); }}>
                        <Nav.Link eventKey="additional" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <div className=" text-truncate"><img src={iconCap} className="icon" alt="" /> Additional high/secondary schools </div>
                                {complitionStatus(pageStatus.isCompleteAdditionalSchool)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "courses" && props.changeTab("courses"); }}>
                        <Nav.Link eventKey="courses" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconCap} className="icon" alt="" /> Courses at Colleges or Universities</span>
                                {complitionStatus(pageStatus.isCompleteCourseCollegeUniversities)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "gpa" && props.changeTab("gpa"); }}>
                        <Nav.Link eventKey="gpa" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconList} className="icon" alt="" /> GPA</span>
                                {complitionStatus(pageStatus.isCompleteGpa)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "grades" && props.changeTab("grades"); }}>
                        <Nav.Link eventKey="grades" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconList} className="icon" alt="" /> Courses & grades</span>
                                {complitionStatus(pageStatus.isCompleteCourseGrade)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "honors" && props.changeTab("honors"); }}>
                        <Nav.Link eventKey="honors" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconStar} className="icon" alt="" /> Honors & Recognitions</span>
                                {complitionStatus(pageStatus.isCompleteHonorsRecognition)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "community" && props.changeTab("community"); }}>
                        <Nav.Link eventKey="community" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconUsers} className="icon" alt="" /> Community-based organisations</span>
                                {complitionStatus(pageStatus.isCompleteCommunityOrganizations)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "career" && props.changeTab("career"); }}>
                        <Nav.Link eventKey="career" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconNavigator} className="icon" alt="" /> Career aspirations</span>
                                {complitionStatus(pageStatus.isCompleteFuturePlan)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card>
        </>
    )
}
export default NavTabs;
