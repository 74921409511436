import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Label } from "../../components/Common/Label/Label";
import WebService from "../../Services/WebService";
import logo from "../../assets/images/login-logo.svg";
import userAvtar from "../../assets/images/avtar-male.png";
import OpenUniversitySelect from "../../components/Common/OpenTagSelect/OpenUniversitySelect";

interface PropData {
  show: boolean;
  isClose: Function;
  id?: string;
  type: string;
  doc_id: string;
}

interface requestForm {
  first_name: string;
  last_name: string;
  type: string; //REPORT,LOR
  email: string;
  school_id: string;
  additional_message: string;
  university_id: string;
  university_document_id: string;
}

const RequestSchoolReportModal = (props: PropData) => {
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    control,
    reset,
  } = useForm<requestForm>({});
  const watchAllFields = watch();
  const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;

  useEffect(() => {
    if (props.show) {
      reset({});
      setValue("additional_message", "");
      setValue("email", "");
      setValue("first_name", "");
      setValue("last_name", "");
      setValue("school_id", "");
      setSuccess(false);
    }
  }, [props.show]);

  const closeClick = (type?: boolean) => {
    reset({});
    props.isClose(type);
  };

  const onSave = (data: requestForm) => {
    data.type = props.type;
    data.university_document_id = props.doc_id;
    data.university_id = props.id || "";
    WebService.postAPI({
      action: "request/document",
      body: data,
      id: "save_btn",
    })
      .then(async (res: any) => {
        toast.success(res.message);
        setSuccess(true);
      })
      .catch((e: any) => {});
  };

  return (
    <Modal
      className="modal-size-660"
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header>
        <div className="text-center w-100">
          <img src={logo} width={55} alt="" className="mx-auto" />
        </div>
      </Modal.Header>
      <Modal.Body>
        {isSuccess ? (
          <div className="text-center">
            <h4 className="font-24 mb-4">Success!</h4>
            <p className="font-14 mb-4">
              {props.type == "LOR"
                ? `You requested a letter of recommendation from ${watchAllFields.first_name} ${watchAllFields.last_name}. We will notify you about the results!`
                : `You requested a school report from ${watchAllFields.first_name} ${watchAllFields.last_name}. We will notify you about the results!`}
            </p>
            <Button
              className="btn-brand-1 w-100"
              onClick={() => closeClick(true)}
            >
              {" "}
              OK
            </Button>
          </div>
        ) : (
          <>
            <h4 className="font-24 mb-2">
              {props.type == "LOR"
                ? "Request a letter of recommendation"
                : "Request a school report"}
            </h4>
            <p className="font-14 mb-4">
              {props.type == "LOR"
                ? "Fill all forms to request a letter of recommendation."
                : "Fill all forms to request a school report from your school counselor"}
            </p>
            <form onSubmit={handleSubmit(onSave)}>
              <div className="mt-4">
                <p className="font-14 text-secondary">Suggested counselors: </p>
                <div className="mb-4 d-flex gap-3">
                  <div className="counselors-btn disabled">
                    <img src={userAvtar} className="prof-pic" alt="" />
                    <span className="name">Sarah Lovett</span>
                  </div>
                  <div className="counselors-btn selected">
                    <img src={userAvtar} className="prof-pic" alt="" />
                    <span className="name">Sarah Lovett</span>
                  </div>
                  <div className="counselors-btn">
                    <img src={userAvtar} className="prof-pic" alt="" />
                    <span className="name">Sarah Lovett</span>
                  </div>
                </div>
              </div>
              <Row className="form-style-2 mb-4">
                <Col lg={6} className="mb-3">
                  <Form.Group className="mb-4">
                    <Form.Label>
                      {props.type == "LOR"
                        ? "First name"
                        : "Counselor’s first name"}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.first_name ? "border-brand" : ""}`}
                      value={watchAllFields.first_name}
                      {...register("first_name", {
                        required: true,
                        pattern: /^[A-Za-z][A-Za-z_ ]{1,30}$/,
                      })}
                    />
                    {errors.first_name && (
                      <Label
                        title={"Please enter first name"}
                        modeError={true}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {props.type == "LOR"
                        ? "Last name"
                        : "Counselor’s last name"}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.last_name ? "border-brand" : ""}`}
                      value={watchAllFields.last_name}
                      {...register("last_name", {
                        required: true,
                        pattern: /^[A-Za-z][A-Za-z_ ]{1,30}$/,
                      })}
                    />
                    {errors.last_name && (
                      <Label
                        title={"Please enter last name"}
                        modeError={true}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {props.type == "LOR"
                        ? "Email address"
                        : "Counselor’s email address"}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className={`${watchAllFields.email ? "border-brand" : ""}`}
                      value={watchAllFields.email}
                      {...register("email", {
                        required: true,
                        pattern: emailRegex,
                      })}
                    />
                    {errors.email && (
                      <Label title={"Please enter email"} modeError={true} />
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>
                      {props.type == "LOR"
                        ? "School/organization"
                        : "Counselor's School"}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`school_id`}
                      rules={{ required: true }}
                      render={({ field }: any) => (
                        <OpenUniversitySelect
                          type={
                            props.type == "REPORT" ? "SCHOOL" : "UNIVERSITY"
                          }
                          isCustom={true}
                          selected={watchAllFields.school_id}
                          placeholder="Choose an option"
                          onChange={(e: any) => {
                            field.onChange(e.id);
                          }}
                        />
                      )}
                    />
                    {errors.school_id && (
                      <div className="login-error">
                        <Label
                          title={"Please select school"}
                          modeError={true}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label>
                      Additional message<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      className={`${watchAllFields.additional_message ? "border-brand h-auto mb-2":"h-auto mb-2"}`}
                      value={watchAllFields.additional_message}
                      {...register("additional_message", {
                        required: true,
                        validate: (value: any) => {
                          if (value && value.length > 250) {
                            return false;
                          } else {
                            return true;
                          }
                        },
                      })}
                    />
                    <div className="d-flex justify-content-between font-12 text-secondary">
                      <span>Add a message to your teacher</span>
                      <span>
                        {(watchAllFields.additional_message &&
                          watchAllFields.additional_message.length) ||
                          0}
                        /250 characters
                      </span>
                    </div>
                    {(errors.additional_message ||
                      (watchAllFields.additional_message &&
                        watchAllFields.additional_message.length > 250)) && (
                      <Label
                        title={
                          !watchAllFields.additional_message
                            ? "Please enter additional message"
                            : "Please enter less than 250 characters"
                        }
                        modeError={true}
                      />
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className="btn-brand-1 w-100 mb-3"
                type="submit"
                id="save_btn"
              >
                {" "}
                Request
              </Button>
              <Button
                className="btn-outline-brand w-100"
                type="button"
                onClick={() => closeClick()}
              >
                Cancel
              </Button>
            </form>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RequestSchoolReportModal;
