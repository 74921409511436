import { Row, Col, Button, Form } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import HelperService from "../../../Services/HelperService";
import { Label } from "../../../components/Common/Label/Label";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";
import { useBlocker, useNavigate } from "react-router-dom";

interface propData {
  activeTab: any;
  isUpdate: Function;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

interface formData {
  psat_math: string;
  psat_reading_writing: string;
  psat_taken: string;
}

const PSATTest = (props: propData) => {
  const { handleSubmit, register, watch, control, reset, formState: { errors } } = useForm<formData>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const scoreOption: any[] = HelperService.getNumberList(200, 800, 10).reverse();
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.activeTab == "PSATTests" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.activeTab == "PSATTests" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'PSATTests');
    } else {
      props.isFormDirty(false, 'PSATTests');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.activeTab == "PSATTests" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.activeTab == "PSATTests") {
      reset({});
      getDatail();
    }
  }, [props.activeTab]);

  const getDatail = () => {
    WebService.getAPI({
      action: `get/standarized/test/detail?type=PSAT_TEST`,
    })
      .then((res: any) => {
        if (res.result) {
          reset(res.result);
        }
      })
      .catch((e) => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI({
      action: "save/tests?type=PSAT_TEST",
      body: data,
      id: "PSAT_test_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">PSAT</h4>
            <Form className="form-style-2">
              <Form.Group className="mb-4">
                <Form.Label>
                  Math score<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name={`psat_math`}
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select">
                      <OpenSelect
                        sakey="psat_math"
                        options={scoreOption}
                        selected={watchAllFields.psat_math}
                        onChange={(data: any) => {
                          field.onChange(data.id);
                        }}
                        isSearchable={true}
                        placeholder="Choose an option"
                      />
                    </div>
                  )}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  Reading & writing score<span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name={`psat_reading_writing`}
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select">
                      <OpenSelect
                        sakey="psat_reading_writing"
                        options={scoreOption}
                        selected={watchAllFields.psat_reading_writing}
                        onChange={(data: any) => {
                          field.onChange(data.id);
                        }}
                        isSearchable={true}
                        placeholder="Choose an option"
                      />
                    </div>
                  )}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  {" "}
                  Number of times I've taken the PSAT
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  {...register("psat_taken", { min: 1 })}
                  min={1}
                  type="text"
                  className={`${watchAllFields.psat_taken ? "border-brand" : ""}`}
                  onKeyPress={(e) =>
                    HelperService.allowOnlyNumericValueUpto4(e)
                  }
                />
                {errors.psat_taken && (
                  <div className="login-error">
                    <Label
                      title={"Please enter valid number."}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>
            </Form>
            <div className="text-center">
              <Button
                type="submit"
                id="PSAT_test_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default PSATTest;
