
const PrivacyPolicy = () => {
    return (
        <div className="page-container">
            <p className="font-20 font-medium">Privacy Policy</p>
            <p>
                I may update these Privacy Policies from time to time. Thus, you
                are advised to review this page periodically for any changes. I
                will notify you of any changes by posting the new or updated
                Privacy Policies on this page. These changes are effective
                immediately, after they are posted on this page.
            </p>

        </div>
    );
};
export default PrivacyPolicy;





