import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useRef, useState } from "react";
import HelperService from "../../../Services/HelperService";

interface propData {
    wordLimit?: number;
    updateWordCount?: Function;
    characterLimit?: number;
    minWords?: number;
    minCharacters?: number;
    data: string;
    onChange: Function;
    skey?: string;
}

const TextEditor = (props: propData) => {
    const [editorConfiguration, setEditorConfiguration] = useState<any>({
        wordCount: {
            onUpdate: (stats: any) => {
                // Prints the current content statistics.
            },
            onReady: (stats: any) => {
                setWordCount(stats.words);
                setCharacterCount(stats.characters);
            }
        }
    });
    const [editorData, setEditorData] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [characterCount, setCharacterCount] = useState(0);

    useEffect(() => {
        setEditorData(props.data);
    }, [props.data])

    return (
        <>
            <div className="editor-box" >
                <CKEditor
                    // key={props.skey ? props.skey : new Date().getTime()}
                    editor={Editor}
                    config={editorConfiguration}
                    data={editorData}
                    onChange={(event: any, editor: any) => {
                        const data = editor.getData();
                        if (props.wordLimit || props.characterLimit) {
                            let statscharacters: number = 0;
                            let statswords: number = 0;
                            editor.plugins.get('WordCount').on('update', (evt: any, stats: any) => {
                                setWordCount(stats.words);
                                setCharacterCount(stats.characters);
                                props.updateWordCount && props.updateWordCount(stats.words);
                                statscharacters = stats.characters;
                                statswords = stats.words;
                            });
                            // let newData = data ? HelperService.removeHtml(event.value) : "";
                            // statswords = newData.split(" ").length
                            if (props.wordLimit) {
                                if (statswords <= props.wordLimit) {
                                    setEditorData(data);
                                    props.onChange(data, statswords);
                                } else {
                                    editor.setData(editorData);
                                }
                            }
                            if (props.characterLimit) {
                                if (statscharacters <= props.characterLimit) {
                                    setEditorData(data);
                                    props.onChange(data, statscharacters);
                                } else {
                                    editor.setData(editorData);
                                }
                            }
                        } else {
                            setEditorData(data);

                        }
                    }}
                    onReady={(editor: any) => {
                        if (props.wordLimit || props.characterLimit) {
                            editor.plugins.get('WordCount').on('update', (evt: any, stats: any) => {
                                setWordCount(stats.words);
                                setCharacterCount(stats.characters);
                            });
                        }
                    }}
                />
                {props.wordLimit &&
                    < div className="d-flex justify-content-between">
                        <p className="font-12 text-secondary mb-2">Min: {props.minWords ? props.minWords : 0} words / Max: {props.wordLimit} words</p>
                        <p className="font-12 text-secondary mb-2">{wordCount ? wordCount : 0}/{props.wordLimit} words</p>
                    </div >
                }
                {props.characterLimit &&
                    < div className="d-flex justify-content-between">
                        <p className="font-12 text-secondary mb-2">Min: {props.minCharacters ? props.minCharacters : 0} words / Max: {props.characterLimit} words</p>
                        <p className="font-12 text-secondary mb-2">{characterCount ? characterCount : 0}/{props.characterLimit} words</p>
                    </div >
                }
            </div>
        </>
    );
};
export default TextEditor;


// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import { useEffect, useRef, useState } from "react";
// import HelperService from "../../../Services/HelperService";

// interface propData {
//     wordLimit?: number;
//     characterLimit?: number;
//     minWords?: number;
//     minCharacters?: number;
//     data: string;
//     onChange: Function;
//     skey?: string;
// }

// const TextEditor = (props: propData) => {
//     const [editorConfiguration, setEditorConfiguration] = useState<any>({
//         wordCount: {
//             onUpdate: (stats: any) => {
//                 // Prints the current content statistics.
//                 // console.log(stats.words, stats.characters);
//             }
//         }
//     });
//     const [editorData, setEditorData] = useState("");
//     const [wordCount, setWordCount] = useState(0);
//     const [characterCount, setCharacterCount] = useState(0);
//     const editorDataCompute = useRef<string>('');

//     useEffect(() => {
//         !editorData && setEditorData(props.data);
//     }, [props.data])

//     return (
//         <>
//             <div className="editor-box" >
//                 <CKEditor
//                     // key={props.skey ? props.skey : new Date().getTime()}
//                     editor={Editor}
//                     config={editorConfiguration}
//                     data={editorData}
//                     onChange={async (event: any, editor: any) => {
//                         const data = editor.getData();
//                         if (props.wordLimit || props.characterLimit) {
//                             let statscharacters: number = 0;
//                             let statswords: number = 0;
//                             await editor.plugins.get('WordCount').on('update', (evt: any, stats: any) => {
//                                 setWordCount(stats.words);
//                                 setCharacterCount(stats.characters);
//                                 if (props.wordLimit) {
//                                     if (stats.words <= props.wordLimit) {
//                                         // setEditorData(data);
//                                         editorDataCompute.current = data;
//                                         props.onChange(data, stats.words);
//                                         console.log('stats- ---- IF');
//                                         return;
//                                     } else {
//                                         editor.setData(editorDataCompute.current);
//                                         editor.focus();
//                                         console.log('stats- ---- ELSE');
//                                         return;
//                                     }
//                                 }
//                                 if (props.characterLimit) {
//                                     if (stats.characters <= props.characterLimit) {
//                                         setEditorData(data);
//                                         props.onChange(data, stats.characters);
//                                     } else {
//                                         editor.setData(editorData);
//                                     }
//                                 }
//                             });

//                         } else {
//                             setEditorData(data);
//                             props.onChange(data);
//                         }
//                     }}
//                     onReady={(editor: any) => {
//                         if (props.wordLimit || props.characterLimit) {
//                             editor.plugins.get('WordCount').on('update', (evt: any, stats: any) => {
//                                 setWordCount(stats.words);
//                                 setCharacterCount(stats.characters);
//                             });
//                         }
//                     }}
//                 />
//                 {props.wordLimit &&
//                     < div className="d-flex justify-content-between">
//                         <p className="font-12 text-secondary mb-2">Min: {props.minWords ? props.minWords : 0} words / Max: {props.wordLimit} words</p>
//                         <p className="font-12 text-secondary mb-2">{wordCount ? wordCount : 0}/{props.wordLimit} words</p>
//                     </div >
//                 }
//                 {props.characterLimit &&
//                     < div className="d-flex justify-content-between">
//                         <p className="font-12 text-secondary mb-2">Min: {props.minCharacters ? props.minCharacters : 0} words / Max: {props.characterLimit} words</p>
//                         <p className="font-12 text-secondary mb-2">{characterCount ? characterCount : 0}/{props.characterLimit} words</p>
//                     </div >
//                 }
//             </div>
//         </>
//     );
// };
// export default TextEditor;

