import { Card, Row, Col, Tab, Dropdown } from "react-bootstrap"
import menuIcon from "../../../assets/images/menu-icon.svg"
import NavTabs from "./NavTabs";
import { useEffect, useState } from "react";
import WebService from "../../../Services/WebService";
import { useLocation, useNavigate } from "react-router-dom";
import Household from "../../../components/my-details/Household";
import Parent from "../../../components/my-details/Parent";
import Siblings from "../../../components/my-details/Siblings";

//Page Title
//     
const hashData = ["siblings", "2ndparent", "1stparent", "living"]

const MyDetailFamily = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>('');
    const [pageStatus, setPageStatus] = useState<any>({ "isCompleteHouseHold": false, "isCompleteParent1": false, "isCompleteParent2": false, "isCompleteSibling": false });
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmTabChange, setConfirmTabChange] = useState<string>('');

    useEffect(() => {
        getPageStatus();
        if (location.hash) {
            let isValidHash = false;
            hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setActiveTab(item);
                    isValidHash = true;
                }
            });
            !isValidHash && setActiveTab('living');
        } else { setActiveTab('living'); }

        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "My Family";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=FAMILY" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const onChangeTab = (tab: string) => {
        if (isFormDirty) {
            setConfirmTabChange(tab);
        } else {
            setActiveTab(tab);
            navigate(`#${tab}`, { replace: true });
        }
    };

    const updateFormStatus = (e: boolean, tab: string) => {
        if (tab == activeTab) {
            setIsFormDirty(e);
        }
    };


    return (
        <>
            <div className="page-my-details page-container">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-05">
                        <Col>
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">

                                <Tab.Content>
                                    <Tab.Pane eventKey="living">
                                        {activeTab == "living" && <Household isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="1stparent">
                                        {activeTab == "1stparent" && <Parent type={1} isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="2ndparent">
                                        {activeTab == "2ndparent" && <Parent type={2} isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="siblings">
                                        {activeTab == "siblings" && <Siblings isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                </Tab.Content>

                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <div className="d-lg-block d-none d-flex h-100">
                                <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                            </div>
                            <Dropdown className="d-lg-none d-block mob-tab-menu">
                                <Dropdown.Toggle className="btn btn-tabs-nav"><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailFamily;
