import {
  Form,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import ExitConfirmModal from "./ExitConfirmModal";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";

interface DemographicInfo {
  gender: any;
  another_gender: any;
  legal_sex: any;
  pronouns: any;
  armed_force_status: any;
  is_hispanic_latino: any;
  race_ethnicity: any[];
  white_background: any[];
  specify_other_white_background: string;
  /////
  tribal_identity: string;
  federally_recognized_tribe: string;
  country_name: string;
  other_specify_asia: string;
  black_african_background: any[];
  specify_other_black_african_background: string;
  hawaiian_other_pacific_islander_background: any[];
  specify_hawaiian_other_pacific_islander_background: string;
  other_pronouns: string;
  start_service_date: string;
  end_service_date: string;
}

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const asinaOption = [
  { id: 'Cambodia', value: 'Cambodia' },
  { id: 'China', value: 'China' },
  { id: 'India', value: 'India' },
  { id: 'Japan', value: 'Japan' },
  { id: 'Korea', value: 'Korea' },
  { id: 'Malaysia', value: 'Malaysia' },
  { id: 'Pakistan', value: 'Pakistan' },
  { id: 'Philippines', value: 'Philippines' },
  { id: 'Vietnam', value: 'Vietnam' },
  { id: 'Other East Asia', value: 'Other East Asia' },
  { id: 'Other South Asia', value: 'Other South Asia' },
  { id: 'Other Southeast Asia', value: 'Other Southeast Asia' },
];

const DemographicInfo = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, resetField, formState: { errors }, } = useForm<DemographicInfo>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "background" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "background" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'background');
    } else {
      props.isFormDirty(false, 'background');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "background" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange])



  useEffect(() => {
    if (props.tab == "background") {
      reset({});
      getDemographicDetail()
    };
  }, [props.tab]);

  const getDemographicDetail = () => {
    WebService.getAPI({
      action: `personal/demographics-detail`,
    })
      .then((res: any) => {
        reset(res.result);
        JSON.parse(res.result.white_background);
      })
      .catch((e) => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI<any>({
      action: "save-demographics",
      body: data,
      id: "demo_save_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const resetEtnicityFields = (type: string, e?: any) => {
    if (e.target.value == "Prefer not to say") {
      setValue("race_ethnicity", ["Prefer not to say"]);
      return;
    }

    if (
      watchAllFields &&
      watchAllFields.race_ethnicity &&
      watchAllFields.race_ethnicity.length > 0
    ) {
      let isExist: boolean = false;
      watchAllFields.race_ethnicity.map((item: any) => {
        if (item == e.target.value) {
          isExist = true;
        }
      });
      if (isExist) {
        if (type == "American Indian or Alaska Native") {
          resetField("tribal_identity");
          resetField("federally_recognized_tribe");
        }
        if (type == "Asian or Indian") {
          resetField("country_name");
          resetField("other_specify_asia");
        }
        if (type == "Black or African American") {
          resetField("black_african_background");
          resetField("specify_other_black_african_background");
        }
        if (type == "Native Hawaiian or Other Pacific Islander") {
          resetField("hawaiian_other_pacific_islander_background");
          resetField("specify_hawaiian_other_pacific_islander_background");
        }
        if (type == "White") {
          resetField("white_background");
          resetField("specify_other_white_background");
        }
        if (type == "White") {
          resetField("white_background");
          resetField("specify_other_white_background");
        }
      }
    }
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <h4 className="font-medium font-20 mb-3 pb-1">Background information</h4>
            <Form.Group className="mb-4">
              <p className="font-16">
                Gender<span className="text-danger">*</span>
              </p>
              <Form.Check
                type="radio"
                id="Male"
                label="Male"
                value={"MALE"}
                {...register("gender", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Female"
                label="Female"
                value={"FEMALE"}
                {...register("gender", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Nonbinary"
                label="Non-binary/third gender"
                value={"NON_BINARY"}
                {...register("gender", { required: false })}
              />
              <Form.Check
                type="radio"
                id="nottosay"
                label="Prefer not to say"
                value={"PREFER_NOT_TO_SAY"}
                onClick={() => setValue("another_gender", "")}
                {...register("gender", { required: false })}
              />
              <Form.Check
                type="radio"
                id="self-describe"
                label="Prefer to self-describe"
                value={"PREFER_TO_SELF_DESCRIBE"}
                {...register("gender", { required: false })}
              />
            </Form.Group>

            {watchAllFields.gender == "PREFER_TO_SELF_DESCRIBE" && (
              <Form.Group className="mb-4">
                <Form.Control
                  type="text"
                  className={`${watchAllFields.another_gender ? "border-brand" : ""
                    }`}
                  {...register("another_gender", { required: false })}
                />
                {errors.another_gender && (
                  <div className="login-error">
                    <Label title={"Please Enter Gender."} modeError={true} />
                  </div>
                )}
              </Form.Group>
            )}

            <Form.Group className="mb-4">
              <p className="font-16">
                Legal Sex (sex you were assigned at birth)
                <span className="text-danger">*</span>
                <OverlayTrigger
                  trigger="hover"
                  placement="right"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <p className="text-secondary mb-0 font-16 font-family">
                          Why is reporting your legal sex a requirement?
                        </p>
                        <p className="text-secondary mb-0 font-16 font-family">
                          Legal sex is what's listed as your sex on things like
                          your ID, driver's license, or passport.
                        </p>
                        <p className="text-secondary mb-0 font-16 font-family">
                          Universities need to know this because there are rules
                          in the US that require them to ask. Some places,
                          especially colleges, must report this information to
                          the government whether their students are male or
                          female. If you don't fit into these categories, it's a
                          good idea to talk directly with the college to
                          understand how they'll use your information.
                        </p>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <a href="#" className="ms-1">
                    <BsFillQuestionCircleFill style={{ color: "#DBDBDB" }} />
                  </a>
                </OverlayTrigger>
              </p>
              <Form.Check
                type="radio"
                id="Female"
                label="Female"
                value={"FEMALE"}
                {...register("legal_sex", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Male"
                label="Male"
                value={"MALE"}
                {...register("legal_sex", { required: false })}
              />
              <Form.Check
                type="radio"
                id="otherlegalsex"
                label="X or another legal sex"
                value={"ANOTHER_SEX"}
                {...register("legal_sex", { required: false })}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <p className="font-16">How I identify (pronouns I prefer)</p>
              <Form.Check
                type="radio"
                id="She/Her"
                label="She/Her"
                value={"SHE_HER"}
                {...register("pronouns")}
              />
              <Form.Check
                type="radio"
                id="She/Her"
                label="He/Him"
                value={"HE_HIS"}
                {...register("pronouns")}
              />

              <Form.Check
                type="radio"
                id="They/Them"
                label="They/Them"
                value={"THEY_THEM"}
                {...register("pronouns")}
              />
              <Form.Check
                type="radio"
                id="Addpronouns"
                label="Add another set of pronouns"
                value={"ANOTHER_SET"}
                {...register("pronouns")}
              />
            </Form.Group>

            {watchAllFields.pronouns == "ANOTHER_SET" && (
              <Form.Group className="mb-4">
                <p className="font-16">Enter pronouns</p>
                <Form.Control
                  type="text"
                  className={`${watchAllFields.other_pronouns ? "border-brand" : ""
                    }`}
                  {...register("other_pronouns", { required: false })}
                />
                {errors.other_pronouns && (
                  <div className="login-error">
                    <Label title={"Please Enter Gender."} modeError={true} />
                  </div>
                )}
              </Form.Group>
            )}

            <Form.Group className="mb-4">
              <p className="font-16">U.S. Armed Forces status</p>
              <Form.Check
                type="radio"
                id="None"
                label="None"
                value={"NONE"}
                onClick={() => { setValue("start_service_date", ""); setValue("end_service_date", "") }}
                {...register("armed_force_status")}
              />
              <Form.Check
                type="radio"
                id="Currentlyserving"
                label="Currently serving"
                value={"CURRENTLY_SERVING"}
                {...register("armed_force_status")}
              />
              <Form.Check
                type="radio"
                id="Previouslyserved"
                label="Previously served"
                value={"PREVIOUSLY_SERVED"}
                {...register("armed_force_status")}
              />
              <Form.Check
                type="radio"
                id="Currentdependent"
                label="Parent currently serving"
                value={"CURRENT_DEPENDENT"}
                {...register("armed_force_status")}
              />
            </Form.Group>

            {watchAllFields.armed_force_status && watchAllFields.armed_force_status != "NONE" && <> <Form.Group className="mb-4">
              <Form.Label>Start year of actual or projected service</Form.Label>
              <OpenDatePicker
                type="year"
                selected={watchAllFields.start_service_date}
                placeholderText={"Pick a year"}
                onChange={(e: any) => {
                  setValue("start_service_date", e);
                  if (
                    watchAllFields.end_service_date &&
                    new Date(watchAllFields.end_service_date) < new Date(e)
                  ) {
                    setValue("end_service_date", "");
                  }
                }}
              />
            </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>End year of actual or projected service</Form.Label>
                <OpenDatePicker
                  type="year"
                  selected={watchAllFields.end_service_date}
                  placeholderText={"Pick a year"}
                  minData={
                    watchAllFields.start_service_date
                      ? new Date(watchAllFields.start_service_date)
                      : new Date()
                  }
                  onChange={(e: any) => {
                    setValue("end_service_date", e);
                  }}
                />
              </Form.Group></>}

            <Form.Group className="mb-4">
              <p className="mb-2">
                Please share how you personally identify. (You can choose one or
                more options){" "}
              </p>
              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race1"
                label="American Indian or Alaska Native"
                value={"American Indian or Alaska Native"}
                onClick={(e: any) =>
                  resetEtnicityFields("American Indian or Alaska Native", e)
                }
                {...register("race_ethnicity", { required: false })}
              />
              {watchAllFields.race_ethnicity &&
                watchAllFields.race_ethnicity.length > 0 &&
                watchAllFields.race_ethnicity.map((item: any) => {
                  if (item == "American Indian or Alaska Native") {
                    return (
                      <div className="ms-4">
                        <Form.Group className="mb-2">
                          <Form.Label className="font-12">
                            Please tell us about your tribal identity or
                            affiliation
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={`${watchAllFields.tribal_identity
                              ? "border-brand"
                              : ""
                              }`}
                            {...register("tribal_identity", {
                              required: false,
                            })}
                          />
                          {errors.tribal_identity && (
                            <div className="login-error">
                              <Label
                                title={"Please Enter Tribal Identity."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-2">
                          <Form.Label className="font-12">
                            {" "}
                            Are you enrolled in a federally recognized tribe?
                          </Form.Label>
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="racsdfe1"
                            label="Yes"
                            value={"Yes"}
                            {...register("federally_recognized_tribe", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="rwegace1"
                            label="No"
                            value={"No"}
                            {...register("federally_recognized_tribe", {
                              required: false,
                            })}
                          />
                          {errors.federally_recognized_tribe && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                      </div>
                    );
                  }
                })}

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race2"
                label="Asian or Indian"
                value={"Asian or Indian"}
                onClick={(e: any) => resetEtnicityFields("Asian or Indian", e)}
                {...register("race_ethnicity", { required: false })}
              />
              {watchAllFields.race_ethnicity &&
                watchAllFields.race_ethnicity.length > 0 &&
                watchAllFields.race_ethnicity.map((item: any) => {
                  if (item == "Asian or Indian") {
                    return (
                      <div className="ms-4">
                        {/* <Form.Group className="mb-2">
                          <Form.Select
                            {...register("country_name", { required: false })}
                          >
                            <option value={""}>Choose an option</option>
                            {asinaOption.map((item: any) => {
                              return (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </Form.Select>
                          {errors.country_name && (
                            <div className="login-error">
                              <Label
                                title={"Please Select Asian County."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group> */}

                        <Form.Group className="mb-2">
                          <Form.Label>
                            Country<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name="country_name"
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <>
                                <div className="react-select1">
                                  <OpenSelect
                                    sakey="country_name"
                                    isSearchable={true}
                                    placeholder="Choose an option"
                                    options={asinaOption}
                                    selected={watchAllFields.country_name}
                                    onChange={(e: any) => {
                                      field.onChange(e.id);
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          />
                        </Form.Group>

                        {(watchAllFields.country_name == "Other East Asia" ||
                          watchAllFields.country_name == "Other South Asia" ||
                          watchAllFields.country_name ==
                          "Other Southeast Asia") && (
                            <Form.Group className="mb-2">
                              <Form.Label className="font-12">
                                {" "}
                                {`Please Specify ${watchAllFields.country_name}`}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${watchAllFields.other_specify_asia
                                  ? "border-brand"
                                  : ""
                                  }`}
                                {...register("other_specify_asia", {
                                  required: false,
                                })}
                              />
                              {errors.other_specify_asia && (
                                <div className="login-error">
                                  <Label
                                    title={"Above Field is Required."}
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </Form.Group>
                          )}
                      </div>
                    );
                  }
                })}

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race3"
                label="Black or African American"
                value={"Black or African American"}
                onClick={(e: any) =>
                  resetEtnicityFields("Black or African American", e)
                }
                {...register("race_ethnicity", { required: false })}
              />
              {watchAllFields.race_ethnicity &&
                watchAllFields.race_ethnicity.length > 0 &&
                watchAllFields.race_ethnicity.map((item: any) => {
                  if (item == "Black or African American") {
                    return (
                      <div className="ms-4">
                        <Form.Group className="mb-2">
                          <Form.Label className="font-12">
                            Which best describes your Black or African American
                            background ?
                          </Form.Label>
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="racsge1"
                            label="U.S. /African American"
                            value={"U.S. /African American"}
                            {...register("black_african_background", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="racsgde1"
                            label="Africa"
                            value={"Africa"}
                            {...register("black_african_background", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="rdfgace1"
                            label="Carribean"
                            value={"Carribean"}
                            {...register("black_african_background", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="rdfgfdgce1"
                            label="Other"
                            value={"Other"}
                            {...register("black_african_background", {
                              required: false,
                            })}
                          />
                          {errors.black_african_background && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        {watchAllFields.black_african_background &&
                          watchAllFields.black_african_background.length > 0 &&
                          watchAllFields.black_african_background.map(
                            (ite: any) => {
                              return (
                                <>
                                  {ite == "Other" && (
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        Specify other Black or African American
                                        background
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className={`${watchAllFields.specify_other_black_african_background
                                          ? "border-brand"
                                          : ""
                                          }`}
                                        {...register(
                                          "specify_other_black_african_background",
                                          { required: true }
                                        )}
                                      />
                                      {errors.specify_other_black_african_background && (
                                        <div className="login-error">
                                          <Label
                                            title={"Above Field is Required."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>
                                  )}
                                </>
                              );
                            }
                          )}
                      </div>
                    );
                  }
                })}

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race4"
                label="Hispanic or Latino"
                value={"Hispanic or Latino"}
                onClick={(e: any) =>
                  resetEtnicityFields("Hispanic or Latino", e)
                }
                {...register("race_ethnicity", { required: false })}
              />

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race5"
                label="Native Hawaiian or Other Pacific Islander"
                value={"Native Hawaiian or Other Pacific Islander"}
                onClick={(e: any) =>
                  resetEtnicityFields(
                    "Native Hawaiian or Other Pacific Islander",
                    e
                  )
                }
                {...register("race_ethnicity", { required: false })}
              />
              {watchAllFields.race_ethnicity &&
                watchAllFields.race_ethnicity.length > 0 &&
                watchAllFields.race_ethnicity.map((item: any) => {
                  if (item == "Native Hawaiian or Other Pacific Islander") {
                    return (
                      <div className="ms-4">
                        <Form.Group className="mb-2">
                          <Form.Label className="font-12">
                            Which best describes your Native Hawaiian or Other
                            Pacific Islander background?
                          </Form.Label>
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="radfgfce1"
                            label="Guam"
                            value={"Guam"}
                            {...register(
                              "hawaiian_other_pacific_islander_background",
                              { required: false }
                            )}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="rdgdfgace1"
                            label="Hawaii"
                            value={"Hawaii"}
                            {...register(
                              "hawaiian_other_pacific_islander_background",
                              { required: false }
                            )}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="radfgce1"
                            label="Samoa"
                            value={"Samoa"}
                            {...register(
                              "hawaiian_other_pacific_islander_background",
                              { required: false }
                            )}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="resrrgace1"
                            label="Other Pacific Islands (excluding Philippines)"
                            value={
                              "Other Pacific Islands (excluding Philippines)"
                            }
                            {...register(
                              "hawaiian_other_pacific_islander_background",
                              { required: false }
                            )}
                          />
                          {errors.hawaiian_other_pacific_islander_background && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        {watchAllFields.hawaiian_other_pacific_islander_background &&
                          watchAllFields
                            .hawaiian_other_pacific_islander_background.length >
                          0 &&
                          watchAllFields.hawaiian_other_pacific_islander_background.map(
                            (ite: any) => {
                              if (
                                ite ==
                                "Other Pacific Islands (excluding Philippines)"
                              ) {
                                return (
                                  <Form.Group className="mb-2">
                                    <Form.Label className="font-12">
                                      {" "}
                                      Specify other Native Hawaiian or Other
                                      Pacific Islander background
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={`${watchAllFields.specify_hawaiian_other_pacific_islander_background
                                        ? "border-brand"
                                        : ""
                                        }`}
                                      {...register(
                                        "specify_hawaiian_other_pacific_islander_background",
                                        { required: false }
                                      )}
                                    />
                                    {errors.specify_hawaiian_other_pacific_islander_background && (
                                      <div className="login-error">
                                        <Label
                                          title={"Above Field is Required."}
                                          modeError={true}
                                        />
                                      </div>
                                    )}
                                  </Form.Group>
                                );
                              }
                            }
                          )}
                      </div>
                    );
                  }
                })}

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race6"
                label="White"
                value={"White"}
                onClick={(e: any) => resetEtnicityFields("White", e)}
                {...register("race_ethnicity", { required: false })}
              />
              {watchAllFields.race_ethnicity &&
                watchAllFields.race_ethnicity.length > 0 &&
                watchAllFields.race_ethnicity.map((item: any) => {
                  if (item == "White") {
                    return (
                      <div className="ms-4">
                        <Form.Group className="mb-2">
                          <Form.Label className="font-12">
                            Which best describes your White background?
                          </Form.Label>
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="raasgce1"
                            label="Europe"
                            value={"Europe"}
                            {...register("white_background", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="rachdfe1"
                            label="Middle East"
                            value={"Middle East"}
                            {...register("white_background", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="agrarce1"
                            label="Other"
                            value={"Other"}
                            {...register("white_background", {
                              required: false,
                            })}
                          />
                          {errors.white_background && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        {watchAllFields.white_background &&
                          watchAllFields.white_background.length > 0 &&
                          watchAllFields.white_background.map((ite: any) => {
                            return (
                              <>
                                {ite == "Other" && (
                                  <Form.Group className="mb-2">
                                    <Form.Label className="font-12">
                                      Specify other White Background
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={`${watchAllFields.specify_other_white_background
                                        ? "border-brand"
                                        : ""
                                        }`}
                                      {...register(
                                        "specify_other_white_background",
                                        { required: false }
                                      )}
                                    />
                                    {errors.specify_other_white_background && (
                                      <div className="login-error">
                                        <Label
                                          title={"Above Field is Required."}
                                          modeError={true}
                                        />
                                      </div>
                                    )}
                                  </Form.Group>
                                )}
                              </>
                            );
                          })}
                      </div>
                    );
                  }
                })}

              <Form.Check
                className="label-font-12"
                type="checkbox"
                id="race8"
                label="Prefer not to say"
                value={"Prefer not to say"}
                name="race_ethnicity"
                onClick={(e: any) =>
                  resetEtnicityFields("Prefer not to say", e)
                }
                // {...register("race_ethnicity", { required: false })}
                checked={
                  watchAllFields.race_ethnicity &&
                    watchAllFields.race_ethnicity.length > 0 &&
                    watchAllFields.race_ethnicity.filter((item: any) => {
                      return item == "Prefer not to say";
                    }).length > 0
                    ? true
                    : false
                }
              />

              {errors.race_ethnicity && (
                <div className="login-error">
                  <Label title={"Please Select Ethnicity."} modeError={true} />
                </div>
              )}
            </Form.Group>

            <div className="text-center">
              <Button
                type="submit"
                id="demo_save_btn"
                // disabled={!isValid}
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default DemographicInfo;
