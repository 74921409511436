import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Popover,Dropdown
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/open-logo.svg";
import "./onboarding.scss";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { Controller, useForm } from "react-hook-form";
import HelperService from "../../Services/HelperService";
import { Label } from "../Common/Label/Label";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import CardLoader from "../Common/CardLoader";

interface formData {
  country_id: any;
  zipcode: number;
  state_id: any;
  city: string;
  international_us_resident: string;
  gender: string;
  another_gender: string;
  race_ethnicity: any[];
  tribal_identity: string;
  federally_recognized_tribe: string;
  country_name: string;
  other_specify_asia: string;
  black_african_background: any[];
  specify_other_black_african_background: string;
  hawaiian_other_pacific_islander_background: any[];
  specify_hawaiian_other_pacific_islander_background: string;
  white_background: any[];
  specify_other_white_background: string;
  financial_aid: string;
  family_college_budgeting: any;
  family_household_income: string;
  nationality: any;
  family_spend_college_us: string;
  is_seeking_scholarships: string;
  zipcode_obj: any;
}

const DemographicsPage1 = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    setValue,
    register,
    resetField,
    reset,
  } = useForm<formData>({});
  const watchAllFields = watch();
  const asinaOption = [
    "Cambodia",
    "China",
    "India",
    "Japan",
    "Korea",
    "Malaysia",
    "Pakistan",
    "Philippines",
    "Vietnam",
    "Other East Asia",
    "Other South Asia",
    "Other Southeast Asia",
  ];
  const incomeOption = [
    "Less than $60,000 per year",
    "$60,001 -  $100,000 per year",
    "$100,001 - $150,000 per year",
    "$150,001 - $200,000 per year",
    "$200,001 - $300,000 per year",
    "$300,001 - $400,000 per year",
    "More than $400,000 per year",
    "I'm not sure",
  ];
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [questionDetail, setQuestionDetail] = useState<any>();
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(false);
  const [curentPage, setCurentPage] = useState<number>(0);

  useEffect(() => {
    getCountryOption();
    getQuestionList();
    // eslint-disable-next-line
  }, []);

  const getQuestionList = async () => {
    setIsDisableSave(true);
    await WebService.getAPI({
      action: "personal/demographics-detail",
      id: "get_started",
    })
      .then((res: any) => {
        handlePage(res.result?.page_id);
        setQuestionDetail(res.result);
        reset(res.result);
        setCurentPage(res.result?.page_id);
        res.result?.country_id && getStateOption(res.result?.country_id);
        if (res.result.family_college_budgeting == "Prefer not to say") {
          setIsDisable(true);
        }
        setIsDisableSave(false);
      })
      .catch(() => {
        setIsDisableSave(false);
      });
  };

  const handlePage = (page: number) => {
    if (page == 8) return;
    if (page == 14) {
      navigate("/dashboard");
    } else if (page < 7) {
      navigate("/questions");
    }
  };

  const getCountryOption = () => {
    WebService.getAPI({ action: "countries" })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex(
            (item: any) => item.name === "United States"
          );

          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            setCountryOptions(
              updatedList.map((item: any) => {
                return { ...item, ...{ value: item.name } };
                //label: item.name,
              })
            );
          } else {
            setCountryOptions(
              res.list.map((item: any) => {
                return { ...item, ...{ value: item.name } };
              })
            );
          }
        }
      })
      .catch((e: any) => { });
  };

  const getStateOption = (id: string) => {
    WebService.postAPI({ action: `states`, body: { country_id: id } })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          setStateOptions(
            res.list.map((item: any) => {
              return { ...item, ...{ value: item.name } };
            })
          );
        }
      })
      .catch((e: any) => { });
  };

  const resetEtnicityFields = (type: string, e?: any) => {
    if (e.target.value == "Prefer not to say") {
      setValue("race_ethnicity", ["Prefer not to say"]);
      return;
    }

    if (
      watchAllFields &&
      watchAllFields.race_ethnicity &&
      watchAllFields.race_ethnicity.length > 0
    ) {
      let isExist: boolean = false;
      watchAllFields.race_ethnicity.map((item: any) => {
        if (item == e.target.value) {
          isExist = true;
        }
      });
      if (isExist) {
        if (type == "American Indian or Alaska Native") {
          resetField("tribal_identity");
          resetField("federally_recognized_tribe");
        }
        if (type == "Asian or Indian") {
          resetField("country_name");
          resetField("other_specify_asia");
        }
        if (type == "Black or African American") {
          resetField("black_african_background");
          resetField("specify_other_black_african_background");
        }
        if (type == "Native Hawaiian or Other Pacific Islander") {
          resetField("hawaiian_other_pacific_islander_background");
          resetField("specify_hawaiian_other_pacific_islander_background");
        }
        if (type == "White") {
          resetField("white_background");
          resetField("specify_other_white_background");
        }
        if (type == "White") {
          resetField("white_background");
          resetField("specify_other_white_background");
        }
      }
    }
  };

  const onSave = (data: formData) => {
    if (isDisable) {
      data.family_college_budgeting = "Prefer not to say";
    }
    WebService.postAPI({
      action: curentPage == 8 ? "add-demographics" : "update-demographics",
      body: data,
      id: "continue-btn",
    })
      .then((res: any) => {
        navigate("/test-score");
      })
      .catch((e) => { });
  };

  const getZipCodeData = (zip: string) => {
    WebService.getAPI({ action: `zipcode?zipcode=${zip}` })
      .then((res: any) => {
        if (res.result) {
          setValue("state_id", res.result.city.state_id);
          setTimeout(() => {
            setValue("city", res.result.city.id);
          }, 100);
        }
      })
      .catch((e: any) => { });
  };
  // on hover show pophover 
  const [show1, setShow1] = useState(false);
  const handleMouseEnter1 = () => setShow1(true);
  const handleMouseLeave1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleMouseEnter2 = () => setShow2(true);
  const handleMouseLeave2 = () => setShow2(false);

  const [show3, setShow3] = useState(false);
  const handleMouseEnter3 = () => setShow3(true);
  const handleMouseLeave3 = () => setShow3(false);
  return (
    <>
      <div className="page-onboarding pb-5 bg-brand-light">
        <Container className="px-lg-0">
          <div className="text-center py-5">
            <img src={Logo} width="58" alt="Logo" className="logo my-lg-3" />
          </div>
          <div className="row min-vh-100 form-style-2 justify-content-center justify-content-between">
            <Col className="mb-lg-0 mb-4 left-tab-col">
              <Card className="card-shadow mb-5 steps-main-card card-padding mo-no-card-style">
                <div className="d-sm-block d-flex gap-3">
                  <Card
                    className="steps-card mb-3 active cursor-pointer"
                    onClick={() => navigate("/demographics")}
                  >
                    <div className="side-border"></div>
                    <p className="title">Background Information</p>
                    <p className="description">All about you</p>
                  </Card>
                  <Card
                    className={
                      "steps-card mb-3" +
                      (curentPage > 8 ? " cursor-pointer" : "")
                    }
                    onClick={() => {
                      curentPage > 8 && navigate("/test-score");
                    }}
                  >
                    <div className="side-border"></div>
                    <p className="title">Admissions Test Scores</p>
                    <p className="description">
                      Tests scores you'd like to list{" "}
                    </p>
                  </Card>
                  <Card
                    className={
                      "steps-card mb-3" +
                      (curentPage > 9 ? " cursor-pointer" : "")
                    }
                    onClick={() => {
                      curentPage > 9 && navigate("/grades");
                    }}
                  >
                    <div className="side-border"></div>
                    <p className="title">GPA (Grade Point Average)</p>
                    <p className="description">Your academic progress</p>
                  </Card>
                  <Card
                    className={
                      "steps-card mb-3" +
                      (curentPage > 10 ? " cursor-pointer" : "")
                    }
                    onClick={() => {
                      curentPage > 10 && navigate("/coursework");
                    }}
                  >
                    <div className="side-border"></div>
                    <p className="title">Coursework</p>
                    <p className="description">Classes, honors, and APs</p>
                  </Card>
                  <Card
                    className={
                      "steps-card mb-3" +
                      (curentPage > 11 ? " cursor-pointer" : "")
                    }
                    onClick={() => {
                      curentPage > 11 && navigate("/extracurriculars");
                    }}
                  >
                    <div className="side-border"></div>
                    <p className="title">Extracurriculars and Activities</p>
                    <p className="description">
                      Things you do outside of class
                    </p>
                  </Card>
                  <Card
                    className={
                      "steps-card mb-3" +
                      (curentPage > 12 ? " cursor-pointer" : "")
                    }
                    onClick={() => {
                      curentPage > 12 && navigate("/network");
                    }}
                  >
                    <div className="side-border"></div>
                    <p className="title">Groups, Community & Networking</p>
                    <p className="description">
                      Chat, connect, and grow your network
                    </p>
                  </Card>
                </div>
              </Card>
            </Col>

            <Col className="right-tab-content-col">
              <Card className="card-shadow card-padding mo-no-card-style">
                <div className="d-flex align-items-center pb-lg-3">
                  <h2 className="mb-4  font-bold h2 font-36 font-xs-24">
                    Your Background Information
                  </h2>
                  <p className="text-secondary font-22 ms-2 font-light">
                    Continued
                  </p>
                </div>
                <p className="mb-3 pb-lg-3 font-light">
                  Only a few additional questions regarding your background.{" "}
                </p>
                {isDisableSave ? (
                  <CardLoader />
                ) : (
                  <>
                    <p className="pb-3 font-light">Mailing address</p>
                    <form onSubmit={handleSubmit(onSave)}>
                      <div className=" ">
                        <Form.Group className="mb-4">
                          <Form.Label>
                            My home country is...
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name="country_id"
                            rules={{ required: true }}
                            render={({ field }: any) => (
                              <div className="form-style">
                                <OpenSelect
                                  sakey="country_id"
                                  isSearchable={true}
                                  selected={watchAllFields.country_id}
                                  options={countryOptions}
                                  onChange={(e: any) => {
                                    field.onChange(e.id);
                                    getStateOption(e.id);
                                    setValue("city", "");
                                    setValue("state_id", "");
                                    setValue("city", "");
                                    setValue("zipcode", "" as any);
                                  }}
                                />
                              </div>
                            )}
                          />
                          {errors.country_id && (
                            <div className="login-error">
                              <Label
                                title={"Please Select Home Country."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                        {watchAllFields && watchAllFields.country_id && (
                          <>
                            {watchAllFields.country_id == 239 && (
                              <div className="d-flex gap-3">
                                <Form.Group className="mb-4 w-100">
                                  <Form.Label>
                                    Zip code
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Controller
                                    control={control}
                                    name="zipcode"
                                    rules={{ required: true }}
                                    render={({ field }: any) => (
                                      <Form.Control
                                        type="text"
                                        className={`${watchAllFields.zipcode ? "border-brand" : ""}`}
                                        onKeyPress={(e) => HelperService.allowOnlyNumericValue(e)}
                                        {...register("zipcode", { required: false })}
                                        maxLength={5}
                                      />
                                    )}
                                  />
                                  {errors.zipcode && (
                                    <div className="login-error">
                                      <Label
                                        title={"Please enter zipcode."}
                                        modeError={true}
                                      />
                                    </div>
                                  )}
                                </Form.Group>
                                <Form.Group className="mb-4 w-100">
                                  <Form.Label>
                                    City<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={`${watchAllFields.city ? "border-brand" : ""
                                      }`}
                                    type="text"
                                    value={watchAllFields.city}
                                    {...register("city", { required: true })}
                                    onKeyPress={(e) =>
                                      HelperService.allowOnlyAlphabetsValues(e)
                                    }
                                  />
                                  {errors.city && (
                                    <div className="login-error">
                                      <Label
                                        title={"Please enter city."}
                                        modeError={true}
                                      />
                                    </div>
                                  )}
                                </Form.Group>
                                <Form.Group className="mb-4 w-100">
                                  <Form.Label>
                                    State<span className="text-danger">*</span>
                                  </Form.Label>
                                  <Controller
                                    control={control}
                                    name="state_id"
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                      <div className="react-select1">
                                        <OpenSelect
                                          sakey="state_id"
                                          isSearchable={true}
                                          options={stateOptions}
                                          selected={watchAllFields.state_id}
                                          onChange={(e: any) => {
                                            field.onChange(e.id);
                                          }}
                                          placeholder={"Select State"}
                                        />
                                      </div>
                                    )}
                                  />
                                  {errors.state_id && (
                                    <div className="login-error">
                                      <Label
                                        title={"Please Select State."}
                                        modeError={true}
                                      />
                                    </div>
                                  )}
                                </Form.Group>
                              </div>
                            )}
                            {watchAllFields.country_id != 239 && (
                              <Form.Group className="mb-4 w-100">
                                <Form.Label>
                                  City<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`${watchAllFields.city ? "border-brand" : ""
                                    }`}
                                  value={watchAllFields.city}
                                  {...register("city", {
                                    required: true,
                                  })}
                                  onKeyPress={(e) =>
                                    HelperService.allowOnlyAlphabetsValues(e)
                                  }
                                />
                                {errors.city && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please enter city."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </Form.Group>
                            )}
                          </>
                        )}

                        <Form.Group className="mb-4">
                          <p className="font-14 mb-2">
                            Are you applying as an international student or as a
                            US resident?
                          </p>
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            label="I am applying as a US resident"
                            value={"I am applying as a US resident"}
                            id="iHave"
                            {...register("international_us_resident", {
                              required: false,
                            })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            label="I am an international student"
                            value={"I am an international student"}
                            id="iPlan"
                            {...register("international_us_resident", {
                              required: false,
                            })}
                          />
                          {errors.international_us_resident && (
                            <div className="login-error">
                              <Label
                                title={"Please Enter City."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <p className="mb-2">
                            Gender
                            <Dropdown drop="end" className="custom-pophover ms-1" 
                             onMouseEnter={handleMouseEnter1}
                             onMouseLeave={handleMouseLeave1}
                             show={show1}
                            >
                              <Dropdown.Toggle  id="dropdown-basic"  >
                                <BsFillQuestionCircleFill style={{ color: "#DBDBDB" }} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="pophover-Whatdoyouplan">
                                      You could have an advantage in the
                                      admissions process if there aren't many
                                      others of your gender in your chosen
                                      major.
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <OverlayTrigger
                              trigger="hover"
                              placement="right"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <p className="text-secondary mb-0 font-16 font-family">
                                      You could have an advantage in the
                                      admissions process if there aren't many
                                      others of your gender in your chosen
                                      major.
                                    </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <a href="#" className="ms-1">
                                <BsFillQuestionCircleFill
                                  style={{ color: "#DBDBDB" }}
                                />
                              </a>
                            </OverlayTrigger> */}
                          </p>
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="Male"
                            label="Male"
                            value={"MALE"}
                            onClick={() => setValue("another_gender", "")}
                            {...register("gender", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="Female"
                            label="Female"
                            value={"FEMALE"}
                            onClick={() => setValue("another_gender", "")}
                            {...register("gender", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="Nonbinary"
                            label="Non-binary / third gender"
                            value={"NON_BINARY"}
                            onClick={() => setValue("another_gender", "")}
                            {...register("gender", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="nottosay"
                            label="Prefer not to say"
                            value={"PREFER_NOT_TO_SAY"}
                            onClick={() => setValue("another_gender", "")}
                            {...register("gender", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            id="self-describe"
                            label="Prefer to self-describe"
                            value={"PREFER_TO_SELF_DESCRIBE"}
                            {...register("gender", { required: false })}
                          />
                          {errors.gender && (
                            <div className="login-error">
                              <Label
                                title={"Please Select Gender."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                        {watchAllFields.gender == "PREFER_TO_SELF_DESCRIBE" && (
                          <Form.Group className="mb-4">
                            <Form.Control
                              className={`${watchAllFields.another_gender
                                ? "border-brand"
                                : ""
                                }`}
                              type="text"
                              {...register("another_gender", {
                                required: false,
                              })}
                            />
                            {errors.another_gender && (
                              <div className="login-error">
                                <Label
                                  title={"Please Enter Gender."}
                                  modeError={true}
                                />
                              </div>
                            )}
                          </Form.Group>
                        )}

                        <Form.Group className="mb-4">
                          <p className="mb-2">
                            Race or ethnicity
                            <Dropdown drop="end" className="custom-pophover ms-1" 
                             onMouseEnter={handleMouseEnter2}
                             onMouseLeave={handleMouseLeave2}
                             show={show2}
                            >
                              <Dropdown.Toggle  id="dropdown-basic"  >
                                <BsFillQuestionCircleFill style={{ color: "#DBDBDB" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="pophover-Raceorethnicity">
                                After the Supreme Court's 2023 ruling,
                                race and ethnicity can't influence
                                admissions decisions, but some
                                institutions might use them for outreach
                                and scholarships.
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <OverlayTrigger
                              trigger="hover"
                              placement="right"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <p className="text-secondary mb-0 font-16 font-family">
                                      After the Supreme Court's 2023 ruling,
                                      race and ethnicity can't influence
                                      admissions decisions, but some
                                      institutions might use them for outreach
                                      and scholarships.
                                    </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <a href="#" className="ms-1">
                                <BsFillQuestionCircleFill
                                  style={{ color: "#DBDBDB" }}
                                />
                              </a>
                            </OverlayTrigger> */}
                          </p>
                          <p className="text-secondary font-12 mb-1">
                            Check all that apply
                          </p>
                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race1"
                            label="American Indian or Alaska Native"
                            value={"American Indian or Alaska Native"}
                            onClick={(e: any) =>
                              resetEtnicityFields(
                                "American Indian or Alaska Native",
                                e
                              )
                            }
                            {...register("race_ethnicity", { required: false })}
                          />

                          {watchAllFields.race_ethnicity &&
                            watchAllFields.race_ethnicity.length > 0 &&
                            watchAllFields.race_ethnicity.map((item: any) => {
                              if (item == "American Indian or Alaska Native") {
                                return (
                                  <div className="ms-4">
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        Please tell us about your tribal
                                        identity or affiliation
                                      </Form.Label>
                                      <Form.Control
                                        className={`${watchAllFields.tribal_identity
                                          ? "border-brand"
                                          : ""
                                          }`}
                                        type="text"
                                        {...register("tribal_identity", {
                                          required: false,
                                        })}
                                      />
                                      {errors.tribal_identity && (
                                        <div className="login-error">
                                          <Label
                                            title={
                                              "Please Enter Tribal Identity."
                                            }
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        {" "}
                                        Are you enrolled in a federally
                                        recognized tribe?
                                      </Form.Label>
                                      <Form.Check
                                        className="label-font-12"
                                        type="radio"
                                        id="race1"
                                        label="Yes"
                                        value={"Yes"}
                                        {...register(
                                          "federally_recognized_tribe",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="radio"
                                        id="race1"
                                        label="No"
                                        value={"No"}
                                        {...register(
                                          "federally_recognized_tribe",
                                          { required: false }
                                        )}
                                      />
                                      {errors.federally_recognized_tribe && (
                                        <div className="login-error">
                                          <Label
                                            title={"Above Field is Required."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                );
                              }
                            })}

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race2"
                            label="Asian or Indian"
                            value={"Asian or Indian"}
                            onClick={(e: any) =>
                              resetEtnicityFields("Asian or Indian", e)
                            }
                            {...register("race_ethnicity", { required: false })}
                          />
                          {watchAllFields.race_ethnicity &&
                            watchAllFields.race_ethnicity.length > 0 &&
                            watchAllFields.race_ethnicity.map((item: any) => {
                              if (item == "Asian or Indian") {
                                return (
                                  <div className="ms-4">
                                    <Form.Group className="mb-2">
                                      <Form.Select
                                        {...register("country_name", {
                                          required: false,
                                        })}
                                      >
                                        <option value={""}>
                                          Choose an option
                                        </option>
                                        {asinaOption.map((item: any) => {
                                          return (
                                            <option value={item} key={item}>
                                              {item}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                      {errors.country_name && (
                                        <div className="login-error">
                                          <Label
                                            title={
                                              "Please Select Asian County."
                                            }
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>

                                    {(watchAllFields.country_name ==
                                      "Other East Asia" ||
                                      watchAllFields.country_name ==
                                      "Other South Asia" ||
                                      watchAllFields.country_name ==
                                      "Other Southeast Asia") && (
                                        <Form.Group className="mb-2">
                                          <Form.Label className="font-12">
                                            {" "}
                                            {`Please Specify ${watchAllFields.country_name}`}
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            {...register("other_specify_asia", {
                                              required: false,
                                            })}
                                          />
                                          {errors.other_specify_asia && (
                                            <div className="login-error">
                                              <Label
                                                title={"Above Field is Required."}
                                                modeError={true}
                                              />
                                            </div>
                                          )}
                                        </Form.Group>
                                      )}
                                  </div>
                                );
                              }
                            })}

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race3"
                            label="Black or African American"
                            value={"Black or African American"}
                            onClick={(e: any) =>
                              resetEtnicityFields(
                                "Black or African American",
                                e
                              )
                            }
                            {...register("race_ethnicity", { required: false })}
                          />
                          {watchAllFields.race_ethnicity &&
                            watchAllFields.race_ethnicity.length > 0 &&
                            watchAllFields.race_ethnicity.map((item: any) => {
                              if (item == "Black or African American") {
                                return (
                                  <div className="ms-4">
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        Which best describes your Black or
                                        African American background ?
                                      </Form.Label>
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="U.S. /African American"
                                        value={"U.S. /African American"}
                                        {...register(
                                          "black_african_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Africa"
                                        value={"Africa"}
                                        {...register(
                                          "black_african_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Carribean"
                                        value={"Carribean"}
                                        {...register(
                                          "black_african_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Other"
                                        value={"Other"}
                                        {...register(
                                          "black_african_background",
                                          { required: false }
                                        )}
                                      />
                                      {errors.black_african_background && (
                                        <div className="login-error">
                                          <Label
                                            title={"Above Field is Required."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>

                                    {watchAllFields.black_african_background &&
                                      watchAllFields.black_african_background
                                        .length > 0 &&
                                      watchAllFields.black_african_background.map(
                                        (ite: any) => {
                                          return (
                                            <>
                                              {ite == "Other" && (
                                                <Form.Group className="mb-2">
                                                  <Form.Label className="font-12">
                                                    Specify other Black or
                                                    African American background
                                                  </Form.Label>
                                                  <Form.Control
                                                    className={`${watchAllFields.specify_other_black_african_background
                                                      ? "border-brand"
                                                      : ""
                                                      }`}
                                                    type="text"
                                                    {...register(
                                                      "specify_other_black_african_background",
                                                      { required: true }
                                                    )}
                                                  />
                                                  {errors.specify_other_black_african_background && (
                                                    <div className="login-error">
                                                      <Label
                                                        title={
                                                          "Above Field is Required."
                                                        }
                                                        modeError={true}
                                                      />
                                                    </div>
                                                  )}
                                                </Form.Group>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                );
                              }
                            })}

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race4"
                            label="Hispanic or Latino"
                            value={"Hispanic or Latino"}
                            onClick={(e: any) =>
                              resetEtnicityFields("Hispanic or Latino", e)
                            }
                            {...register("race_ethnicity", { required: false })}
                          />

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race5"
                            label="Native Hawaiian or Other Pacific Islander"
                            value={"Native Hawaiian or Other Pacific Islander"}
                            onClick={(e: any) =>
                              resetEtnicityFields(
                                "Native Hawaiian or Other Pacific Islander",
                                e
                              )
                            }
                            {...register("race_ethnicity", { required: false })}
                          />
                          {watchAllFields.race_ethnicity &&
                            watchAllFields.race_ethnicity.length > 0 &&
                            watchAllFields.race_ethnicity.map((item: any) => {
                              if (
                                item ==
                                "Native Hawaiian or Other Pacific Islander"
                              ) {
                                return (
                                  <div className="ms-4">
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        Which best describes your Native
                                        Hawaiian or Other Pacific Islander
                                        background?
                                      </Form.Label>
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Guam"
                                        value={"Guam"}
                                        {...register(
                                          "hawaiian_other_pacific_islander_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Hawaii"
                                        value={"Hawaii"}
                                        {...register(
                                          "hawaiian_other_pacific_islander_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Samoa"
                                        value={"Samoa"}
                                        {...register(
                                          "hawaiian_other_pacific_islander_background",
                                          { required: false }
                                        )}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Other Pacific Islands (excluding Philippines)"
                                        value={
                                          "Other Pacific Islands (excluding Philippines)"
                                        }
                                        {...register(
                                          "hawaiian_other_pacific_islander_background",
                                          { required: false }
                                        )}
                                      />
                                      {errors.hawaiian_other_pacific_islander_background && (
                                        <div className="login-error">
                                          <Label
                                            title={"Above Field is Required."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>

                                    {watchAllFields.hawaiian_other_pacific_islander_background &&
                                      watchAllFields
                                        .hawaiian_other_pacific_islander_background
                                        .length > 0 &&
                                      watchAllFields.hawaiian_other_pacific_islander_background.map(
                                        (ite: any) => {
                                          if (
                                            ite ==
                                            "Other Pacific Islands (excluding Philippines)"
                                          ) {
                                            return (
                                              <Form.Group className="mb-2">
                                                <Form.Label className="font-12">
                                                  {" "}
                                                  Specify other Native Hawaiian
                                                  or Other Pacific Islander
                                                  background
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={`${watchAllFields.specify_hawaiian_other_pacific_islander_background
                                                    ? "border-brand"
                                                    : ""
                                                    }`}
                                                  {...register(
                                                    "specify_hawaiian_other_pacific_islander_background",
                                                    { required: false }
                                                  )}
                                                />
                                                {errors.specify_hawaiian_other_pacific_islander_background && (
                                                  <div className="login-error">
                                                    <Label
                                                      title={
                                                        "Above Field is Required."
                                                      }
                                                      modeError={true}
                                                    />
                                                  </div>
                                                )}
                                              </Form.Group>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                );
                              }
                            })}

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race6"
                            label="White"
                            value={"White"}
                            onClick={(e: any) =>
                              resetEtnicityFields("White", e)
                            }
                            {...register("race_ethnicity", { required: false })}
                          />
                          {watchAllFields.race_ethnicity &&
                            watchAllFields.race_ethnicity.length > 0 &&
                            watchAllFields.race_ethnicity.map((item: any) => {
                              if (item == "White") {
                                return (
                                  <div className="ms-4">
                                    <Form.Group className="mb-2">
                                      <Form.Label className="font-12">
                                        Which best describes your White
                                        background?
                                      </Form.Label>
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Europe"
                                        value={"Europe"}
                                        {...register("white_background", {
                                          required: false,
                                        })}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Middle East"
                                        value={"Middle East"}
                                        {...register("white_background", {
                                          required: false,
                                        })}
                                      />
                                      <Form.Check
                                        className="label-font-12"
                                        type="checkbox"
                                        id="race1"
                                        label="Other"
                                        value={"Other"}
                                        {...register("white_background", {
                                          required: false,
                                        })}
                                      />
                                      {errors.white_background && (
                                        <div className="login-error">
                                          <Label
                                            title={"Above Field is Required."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </Form.Group>

                                    {watchAllFields.white_background &&
                                      watchAllFields.white_background.length >
                                      0 &&
                                      watchAllFields.white_background.map(
                                        (ite: any) => {
                                          return (
                                            <>
                                              {ite == "Other" && (
                                                <Form.Group className="mb-2">
                                                  <Form.Label className="font-12">
                                                    Specify other White
                                                    Background
                                                  </Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    className={`${watchAllFields.specify_other_white_background
                                                      ? "border-brand"
                                                      : ""
                                                      }`}
                                                    {...register(
                                                      "specify_other_white_background",
                                                      { required: false }
                                                    )}
                                                  />
                                                  {errors.specify_other_white_background && (
                                                    <div className="login-error">
                                                      <Label
                                                        title={
                                                          "Above Field is Required."
                                                        }
                                                        modeError={true}
                                                      />
                                                    </div>
                                                  )}
                                                </Form.Group>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                  </div>
                                );
                              }
                            })}

                          <Form.Check
                            className="label-font-12"
                            type="checkbox"
                            id="race8"
                            label="Prefer not to say"
                            value={"Prefer not to say"}
                            name="race_ethnicity"
                            onClick={(e: any) =>
                              resetEtnicityFields("Prefer not to say", e)
                            }
                            // {...register("race_ethnicity", { required: false })}
                            checked={
                              watchAllFields.race_ethnicity &&
                                watchAllFields.race_ethnicity.length > 0 &&
                                watchAllFields.race_ethnicity.filter(
                                  (item: any) => {
                                    return item == "Prefer not to say";
                                  }
                                ).length > 0
                                ? true
                                : false
                            }
                          />

                          {errors.race_ethnicity && (
                            <div className="login-error">
                              <Label
                                title={"Please Select Ethnicity."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <p className="font-14 mb-2">
                            Will you need financial aid?
                            <Dropdown drop="end" className="custom-pophover ms-1" 
                             onMouseEnter={handleMouseEnter3}
                             onMouseLeave={handleMouseLeave3}
                             show={show3}
                            >
                              <Dropdown.Toggle  id="dropdown-basic"  >
                                <BsFillQuestionCircleFill style={{ color: "#DBDBDB" }} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="pophover-Willyouneedfinancial">
                                  For first-time, first-year undergrads,
                                      86.4% successfully apply for and receive
                                      financial aid.
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <OverlayTrigger
                              trigger="hover"
                              placement="right"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <p className="text-secondary mb-0 font-16 font-family">
                                      For first-time, first-year undergrads,
                                      86.4% successfully apply for and receive
                                      financial aid.
                                    </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <a href="#" className="ms-1">
                                <BsFillQuestionCircleFill
                                  style={{ color: "#DBDBDB" }}
                                />
                              </a>
                            </OverlayTrigger> */}
                          </p>
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            label="Yes"
                            id="iplantToApply"
                            value={"Yes"}
                            {...register("financial_aid", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            label="No"
                            id="do_not_plan"
                            value={"No"}
                            {...register("financial_aid", { required: false })}
                          />
                          <Form.Check
                            className="label-font-12"
                            type="radio"
                            label="I’m not sure yet"
                            id="dontKnow"
                            value={"I’m not sure yet"}
                            {...register("financial_aid", { required: false })}
                          />
                          {errors.financial_aid && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <p className="font-14 mb-3">
                            What is your family planning on budgeting for
                            college each year?
                          </p>
                          <div className="d-flex justify-content-between">
                            <p className="text-secondary mb-1">
                              Select a Value
                            </p>
                            {watchAllFields.family_college_budgeting > 0 && (
                              <p className="text-secondary mb-1">
                                ${" "}
                                {HelperService.getAmmountFormatter(
                                  watchAllFields.family_college_budgeting
                                )}
                              </p>
                            )}
                          </div>
                          <div className="range-slider">
                            <Controller
                              control={control}
                              name="family_college_budgeting"
                              // rules={{ required: true }}
                              render={({ field }: any) => (
                                <Slider
                                  value={
                                    watchAllFields.family_college_budgeting
                                  }
                                  orientation="horizontal"
                                  onChange={(e: any) => {
                                    !isDisable && field.onChange(e);
                                  }}
                                  min={0}
                                  max={100000}
                                  step={500}
                                  disabled={isDisable}
                                  tooltip={false}
                                  className={isDisable ? "opacity-25" : ""}
                                />
                              )}
                            />
                          </div>
                          <div className="font-14 text-end">
                            <span
                              className="text-link cursor-pointer text-brand"
                              onClick={() => {
                                setIsDisable(!isDisable);
                              }}
                            >
                              Prefer {!isDisable && "not"} to say
                            </span>
                          </div>
                          {errors.family_college_budgeting && (
                            <div className="login-error">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-4">
                          <p className="font-14 mb-2">
                            What is the annual income level of your family?
                          </p>
                          <Form.Select
                            {...register("family_household_income", {
                              required: false,
                            })}
                          >
                            <option value={""}>Choose an option</option>
                            {incomeOption.map((item: any) => {
                              return (
                                <option value={item} key={item}>
                                  {item}
                                </option>
                              );
                            })}
                          </Form.Select>
                          {errors.family_household_income && (
                            <div className="login-error mt-2">
                              <Label
                                title={"Above Field is Required."}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>

                        <Row className=" justify-content-center mt-4">
                          <Col lg={7} className="text-center">
                            <Button
                              type="submit"
                              className="btn btn-brand-1 w-100 w-100 btn-lg"
                              style={{ maxWidth: "400px" }}
                              id="continue-btn"
                              disabled={isDisableSave || !isValid}
                            >
                              Continue
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </form>
                  </>
                )}
              </Card>
            </Col>
          </div>
        </Container>
      </div>
    </>
  );
};
export default DemographicsPage1;
