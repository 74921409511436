import { useEffect, useState } from "react";
import { Container, Navbar, Dropdown, ProgressBar } from "react-bootstrap"
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { HiBell } from "react-icons/hi";
import { BiSolidLeftArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { VscMenu } from "react-icons/vsc";
import ProfilePic from "../../assets/images/profile-user.png"
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { USER_INFO, USER_LOGOUT, setDataInRedux } from "../../action/CommonAction";
import { meCallInterface, userInfoInterface } from "../../OpenInterfaces";
import { RootState } from "../../config/Store";
import WebService from "../../Services/WebService";
// import CardLoader from "../Common/CardLoader";
// import { CometChat } from '@cometchat/chat-sdk-javascript';
// import { messaging } from "../../firebase/firebaseConfig";
// import { getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import LogoutModal from "../Common/LogoutModal/LogoutModal";

interface PropData {
    toggle: any
};

const Header = (props: PropData) => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const [isActive, setIsActive] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);
    const [notificationList, setNotificationList] = useState<any[]>([]);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [isShowLogoutModal, setIsShowLogoutModal] = useState<boolean>(false);

    useEffect(() => {
        userMeCall();
        getNotificationList();
        // requestPermission();
        // getGetMessageCount();
        const interval = setInterval(() => {
            userOnline();
            // getGetMessageCount();
        }, 10000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    const userMeCall = () => {
        WebService.getAPI<meCallInterface>({ action: "me" })
            .then((res: meCallInterface) => {
                dispatch(setDataInRedux({ type: USER_INFO, value: res.result }));
            })
            .catch(() => { });
    };

    const userOnline = () => {
        WebService.postAPI({ action: `user/online_offline` })
            .then((res: any) => { })
            .catch(() => { });
    };

    const goBack = () => {
        window.history.back();
    };

    const toggleMobMenu = () => {
        props.toggle(!isActive)
        setIsActive(!isActive);
    };

    const onLogout = async () => {
        setIsShowLogoutModal(true);
    };

    const getNotificationList = () => {
        setLoading(true);
        WebService.getAPI({ action: `notification/list` })
            .then((res: any) => {
                setNotificationList(res.list);
                setLoading(false);
            })
            .catch(() => { setLoading(false); });
    };

    const getGetMessageCount = async () => {
        // await CometChat.getUnreadMessageCount().then((res: any) => {
        //     if (res) {
        //         let count: number = 0;
        //         res.groups && Object.entries(res.groups).map((item: any) => {
        //             count += item[1];
        //         });
        //         res.users && Object.entries(res.users).map((item: any) => {
        //             count += item[1];
        //         });
        //         setUnreadCount(count)
        //     }
        // });
    };

    // async function requestPermission() {
    //     //requesting permission using Notification API
    //     const permission = await Notification.requestPermission();
    //     if (permission === "granted") {
    //         const token = await getToken(messaging, {
    //             vapidKey: 'BKIrETA4-vmrmjji5DPwxFY6XrLhMU5IHsgyEh47ciHjKJ9zKC9wsprB-21e8r-W67EC-vaROt0FyilN7Gjo8qQ',
    //         });
    //         //We can send token to server
    //     } else if (permission === "denied") {
    //         //notifications are blocked
    //         alert("You denied for the notification");
    //     }
    // };

    // onMessage(messaging, (payload) => {
    //     toast.success(payload.notification?.title);
    // });


    return (
        <>
            <LogoutModal show={isShowLogoutModal} isClose={() => setIsShowLogoutModal(false)} />
            <header className="site-header">
                <Container fluid className='header-nav'>
                    <Navbar expand="lg" className=" flex-nowrap">
                        <button className="btn menu-btn d-sm-none d-inline" onClick={toggleMobMenu}><VscMenu size={22} /></button>
                        <div className="page-title-wrap text-truncate" >
                            <a className="goto-back cursor-pointer" id="back_nav"><BiSolidLeftArrow size={16} /></a>
                            <h2 className="page-title text-truncate" id="page_title">  </h2>
                            <div className="font-14 text-secondary ms-2" id="sub_title"> </div>
                            <div className="d-flex gap-3 align-items-center d-none" style={{ width: "160px" }} id='bar-content'>
                                <ProgressBar  now={60} id="profile-progress-bar" /> <span className="font-bold font-16 text-brand" id="profile-progress-text"></span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center ms-auto gap-3 header-nav-menu" >
                            {/* <a href="" className="menu-item"><HiBell size={30} className="icon" /></a>
                            <a href="/messages" className="menu-item"><IoChatbubbleEllipsesSharp size={30} className="icon" /></a> */}
                            {/* <a href="" className="menu-item"><HiBell size={30} className="icon" /></a> */}
                            <Dropdown className="notification-dd">
                                <Dropdown.Toggle onMouseDown={() => { getNotificationList() }}>
                                    <HiBell size={30} className="icon" />
                                    {
                                        notificationList && notificationList.length > 0 && <div className="count">{notificationList.length}</div>
                                    }
                                </Dropdown.Toggle>
                                {/* <Dropdown.Menu align="end" className="">
                                    <p className="font-bold font-16 px-3 mb-1">Notification Center</p>
                                    {loading && <CardLoader />}
                                    {
                                        !loading && notificationList && notificationList.length > 0 && notificationList.map((item: any) => {
                                            return <Dropdown.Item>
                                                <div className="d-flex gap-2 align-items-center">
                                                    <img src={iconNotification} width={24} height={24} alt="" />
                                                    <div className="text-truncate">
                                                        <p className="mb-0 text-dark text-truncate">{item.message}</p>
                                                        <p className="mb-0 font-12 text-secondary">{HelperService.getTimeago(item.created_at)}</p>
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                        })
                                    }


                                     <Dropdown.Item>
                                        <div className="d-flex gap-2 align-items-center">
                                            <img src={iconNotification} width={24} height={24} alt="" />
                                            <div className="text-truncate">
                                                <p className="mb-0 text-dark text-truncate">Math Exam today at 10:00 AM</p>
                                                <p className="mb-0 font-12 text-secondary">1:23 AM</p>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <div className="d-flex gap-2 align-items-center">
                                            <img src={iconMsg} width={24} height={24} alt="" />
                                            <div className="text-truncate">
                                                <p className="mb-0 text-dark text-truncate">You have 7 new messages.</p>
                                                <p className="mb-0 font-12 text-secondary">0:32 AM</p>
                                            </div>
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <div className="d-flex gap-2 align-items-center">
                                            <img src={iconConnect} width={24} height={24} alt="" />
                                            <div className="text-truncate">
                                                <p className="mb-0 text-dark text-truncate">Alice Ryder wants to connect for discus in detail</p>
                                                <p className="mb-0 font-12 text-secondary">Yesterday 12:39 AM</p>
                                            </div>
                                        </div>
                                    </Dropdown.Item> 

                                </Dropdown.Menu> */}
                            </Dropdown>
                            <div className=" position-relative msg-menu">
                                {/* <a href="/messages" className="menu-item"><IoChatbubbleEllipsesSharp size={30} className="icon" /></a> */}
                                <a href="javascript:void(0)" className="menu-item"><IoChatbubbleEllipsesSharp size={30} className="icon" /></a>
                                {unreadCount > 0 && <div className="count">{unreadCount}</div>}
                            </div>

                            <Dropdown className="profile-dd">
                                <Dropdown.Toggle>
                                    <img src={user_info && user_info?.avtar ? user_info?.avtar : ProfilePic} width={26} height={26} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                    <Dropdown.Item onClick={() => navigate("/my-profile")}>My Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={() => navigate("/settings")}>Settings</Dropdown.Item>
                                    <Dropdown.Item onClick={() => onLogout()}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Navbar>
                </Container>
            </header >

        </>
    )
}
export default Header;