import { Card, Row, Col, Button, Form, Container } from "react-bootstrap"
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import UploadFile from "../../components/Common/UploadFile/UploadFile";
import DocIcon from '../../assets/images/doc-icon.svg';
import { IoClose } from "react-icons/io5";
import { set } from "react-hook-form";

const RequestReportOrRecommendation = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<string>("");
    const [fileSize, setFileSize] = useState<string>("");
    const [isSchoolReport, setIsSchoolReport] = useState<boolean>(false);
    const [filtType, setFileType] = useState<string>("");
    const [isRender, setIsRender] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [userId, setUserId] = useState<string>("");

    useEffect(() => {
        if (!id) {
            navigate('/')
        } else {
            getDetail();
            if (window.location.pathname.includes('recommendation-letter-request')) {
                setIsSchoolReport(false);
            } else if (window.location.pathname.includes('school-report-request')) {
                setIsSchoolReport(true);
            };
        }
        setIsRender(true);
        // eslint-disable-next-line
    }, []);

    const getDetail = () => {
        WebService.getAPI({ action: `get/requester/detail?uuid=${id}` })
            .then((res: any) => {
                setName(res?.result.requester_name ? res?.result.requester_name : '');
                setUserId(res?.result.id ? res?.result.id : '');
            })
            .catch((error: any) => {
                toast.error(error.message);
            });
    };

    const onUploadFile = (data: any, name: string, size: string) => {
        const blob = URL.createObjectURL(data);
        var isImageExte = /(\.(jpg|jpeg|png))$/i;
        var isVideo = /(\.(mp4))$/i;
        var isPdf = /(\.(pdf))$/i;
        let type = "";

        if (isImageExte.test(name)) {
            type = "IMAGE";
        } else if (isVideo.test(name)) {
            type = "VIDEO";
        } else if (isPdf.test(name)) {
            type = "PDF";
        }
        setFile(data);
        setFileName(name);
        setFileSize(size);
        setFileType(type);
    };

    const saveDocuments = () => {
        WebService.fileUploadAPI({
            action: `upload/requester/document`,
            key: "file",
            file: file,
            body: { file_type: filtType, uuid: id, user_id: userId },
            id: isSchoolReport ? `submit_doc_request` : `submit_doc_request3`
        })
            .then((res: any) => {
                toast.success(res.message);
                navigate('/');
            })
            .catch((error: any) => { });
    };

    const removeFile = () => {
        setFile(null);
        setFileName("");
        setFileSize("");
    };


    return (
        <>
            {isRender && <div className="login-page d-flex  " >
                <Container>
                    <Row className=" justify-content-center min-vh-100 align-items-center">
                        <Col lg="10">
                            {
                                isSchoolReport ?
                                    <Card className=" card-shadow">
                                        <Card.Body className="p-4">
                                            <h3 className="font-24">School report request</h3>
                                            <p>Your student {name} is requesting a school report. Lorem ipsum dolor sit amet consectetur. Senectus quis sed quam ultrices eget. Ultrices dictum arcu varius amet a hendrerit in arcu augue. Vitae sodales nec ipsum curabitur ac nisl. Lectus curabitur eu imperdiet euismod bibendum quisque sapien massa a.</p>
                                            {
                                                file ?
                                                    <Card className={`border p-2 d-inline-block px-3 mb-3 me-2`}>
                                                        <div className="d-flex gap-3 align-items-center">
                                                            <div className="me-1">
                                                                <IoClose size={20} onClick={() => { removeFile() }} className="text-secondary opacity-50 hover-effect cursor-pointer close" />
                                                            </div>
                                                            <div>
                                                                <img src={DocIcon} width={40} height={40} alt="" />
                                                            </div>
                                                            <div>
                                                                <p className="font-medium font-16 mb-0">{fileName}</p>
                                                                <span className="text-secondary">{fileSize}KB</span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    :
                                                    <>
                                                        <UploadFile key={new Date().getTime()} onChange={(data: any, name: string, size: string) => { onUploadFile(data, name, size) }} acceptFileType={["jpg", "jpeg", "pdf", "png"]} message="Upload or Drag and Drop your transcript" acceptedSize={5} />
                                                        <div className="text-center mb-3">
                                                            <span className="font-12 text-secondary">Lorem ipsum lorem ipsum lorem ipsum</span>
                                                        </div>
                                                    </>
                                            }
                                            <div className="text-center mb-3">
                                                <Button className="btn btn-brand-1 px-5" disabled={file ? false : true} type="button" id="submit_doc_request" onClick={() => saveDocuments()}>Submit</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <Card className=" card-shadow">
                                        <Card.Body className="p-4">
                                            <h3 className="font-24">Recommendation letter request</h3>
                                            <p>Your student {name} is requesting a recommendation letter. Lorem ipsum dolor sit amet consectetur. Senectus quis sed quam ultrices eget. Ultrices dictum arcu varius amet a hendrerit in arcu augue. Vitae sodales nec ipsum curabitur ac nisl. Lectus curabitur eu imperdiet euismod bibendum quisque sapien massa a.</p>

                                            {
                                                file ?
                                                    <Card className={`border p-2 d-inline-block px-3 mb-3 me-2`}>
                                                        <div className="d-flex gap-3 align-items-center">
                                                            <div className="me-1">
                                                                <IoClose size={20} onClick={() => { removeFile() }} className="text-secondary opacity-50 hover-effect cursor-pointer close" />
                                                            </div>
                                                            <div>
                                                                <img src={DocIcon} width={40} height={40} alt="" />
                                                            </div>
                                                            <div>
                                                                <p className="font-medium font-16 mb-0">{fileName}</p>
                                                                <span className="text-secondary">{fileSize}KB</span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                    :
                                                    <>
                                                        <Form.Check
                                                            type="switch"
                                                            id="joinNetwork"
                                                            label="Make private"
                                                            className="mb-3"
                                                        />
                                                        <UploadFile key={new Date().getTime()} onChange={(data: any, name: string, size: string) => { onUploadFile(data, name, size) }} acceptFileType={["jpg", "jpeg", "pdf", "png"]} message="Upload or Drag and Drop your transcript" acceptedSize={5} />
                                                        <div className="text-center mb-3">
                                                            <span className="font-12 text-secondary">Lorem ipsum lorem ipsum lorem ipsum</span>
                                                        </div>
                                                    </>
                                            }

                                            <div className="text-center mb-3">
                                                <Button className="btn btn-brand-1 px-5" disabled={file ? false : true} type="button" id="submit_doc_request3" onClick={() => saveDocuments()}>Submit</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                            }

                        </Col>
                    </Row>
                    {/* } */}
                </Container>
            </div >}
        </>
    );
}
export default RequestReportOrRecommendation;