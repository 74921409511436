import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IoWarning, IoWarningOutline } from "react-icons/io5";

interface propsData {
    show: boolean;
    isClose: Function;
    isDelete: Function;
    title?: string;
    subTitle?: string;
    modalTitle?: string;
    type?: string;
    deleteButtonText?: string;
    cancelButtonText?: string;
}

const DeleteModal = ({ show, isClose, isDelete, title, subTitle, modalTitle, type, deleteButtonText, cancelButtonText }: propsData) => {
    const [modalType, setModalType] = useState<string>(type || "");

    useEffect(() => {
        type && setModalType(type);
    }, [type]);


    return (
        <>
            <Modal show={show} onHide={() => isClose()} centered>
                {modalTitle && <Modal.Header closeButton className="pt-3">
                    <Modal.Title>{modalTitle ? modalTitle : "Delete"}</Modal.Title>
                </Modal.Header>}
                <Modal.Body className={modalTitle ? "pt-0" : "pt-3"}>
                    {
                        modalType == "accountDelete" &&
                        <div className="text-center">
                            <h3 className="font-20 mt-3">We're sad to see you go, your account has been deleted</h3>
                            <Button className="btn-brand-1" onClick={() => isClose()}>Ok</Button>
                        </div>
                    }
                    {
                        modalType == "" &&
                        <div className="text-center">
                            <IoWarningOutline size={50} className="text-danger mb-3" />
                            <h3 className="font-20">{title ? title : "Are you sure?"}</h3>
                            <p className="text-secondary mb-0 font-14">{subTitle ? subTitle == "hide" ? "" : subTitle : "Are you sure, you want delete?"}</p>
                            <div className="d-flex gap-3 justify-content-center mt-4">
                                <Button className="btn-outline-brand" onClick={() => isClose()}> &nbsp;&nbsp;&nbsp; &nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                                <Button className="btn-brand-1" id="delete_btn" onClick={() => isDelete()}> {deleteButtonText ? deleteButtonText : "Yes, Delete"}</Button>
                            </div>
                        </div>
                    }
                    {
                        modalType == "EVENT" &&
                        <div>
                            {/* <IoWarningOutline size={50} className="text-danger mb-3" /> */}
                            {title && <h3 className="font-20">{title}</h3>}
                            <p className="text-secondary mb-0 font-14">{subTitle ? subTitle == "hide" ? "" : subTitle : "Are you sure, you want delete?"}</p>
                            {/* <div className="d-flex gap-3 justify-content-center mt-4"> */}
                            <Button className="btn-danger w-100 mt-4" id="delete_btn" onClick={() => isDelete()}> {deleteButtonText ? deleteButtonText : "Yes"}</Button>
                            <Button className="btn-outline-light text-secondary w-100 mt-2" onClick={() => isClose()}> &nbsp;&nbsp;&nbsp; &nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                            {/* </div> */}
                        </div>
                    }
                    {
                        modalType == "COMMUNITY" &&
                        <div className="text-center">
                            <IoWarning size={50} className="text-danger mb-3" />
                            {title && <h3 className="font-20">{title}</h3>}
                            <p className="text-secondary mb-0 font-14">{subTitle ? subTitle == "hide" ? "" : subTitle : "Are you sure, you want delete?"}</p>
                            {/* <div className="d-flex gap-3 justify-content-center mt-4"> */}
                            <Button className="btn-danger w-100 mt-4" id="delete_btn" onClick={() => isDelete()}> {deleteButtonText ? deleteButtonText : "Yes"}</Button>
                            <Button className="btn-outline-light text-secondary w-100 mt-2" onClick={() => isClose()}>{cancelButtonText ? cancelButtonText : <> &nbsp;&nbsp;&nbsp; &nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</>}</Button>
                            {/* </div> */}
                        </div>
                    }
                    {
                        modalType == "INTERESTED_SCHOOL" &&
                        <div className="mt-3">
                            <div className="text-center mb-4">
                                <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.2558 0.706188C19.9753 -0.801959 23.4348 0.188939 24.9545 2.88418L39.4921 28.1144C39.812 28.8675 39.952 29.4799 39.992 30.116C40.072 31.6024 39.552 33.0471 38.5322 34.1589C37.5124 35.2667 36.1326 35.9207 34.6329 36H5.35781C4.73791 35.9623 4.11802 35.8216 3.53811 35.6036C0.638603 34.4344 -0.761161 31.1446 0.41864 28.2928L15.0562 2.86635C15.5561 1.97256 16.316 1.20164 17.2558 0.706188ZM19.9953 24.5452C19.0355 24.5452 18.2356 25.3379 18.2356 26.2912C18.2356 27.2405 19.0355 28.0352 19.9953 28.0352C20.9552 28.0352 21.735 27.2405 21.735 26.2694C21.735 25.3201 20.9552 24.5452 19.9953 24.5452ZM19.9953 12.1808C19.0355 12.1808 18.2356 12.9517 18.2356 13.905V19.5115C18.2356 20.4627 19.0355 21.2574 19.9953 21.2574C20.9552 21.2574 21.735 20.4627 21.735 19.5115V13.905C21.735 12.9517 20.9552 12.1808 19.9953 12.1808Z" fill="#FF3A29" />
                                </svg>
                            </div>

                            <p className="font-16 font-medium">If you remove Stanford University from your schools of interest, this action will also remove:</p>
                            <ul className="ps-3 mb-4">
                                <li className="font-16">All relevant planner tasks</li>
                                <li className="font-16">School communities and groups</li>
                                <li className="font-16">Documents submitted</li>
                            </ul>
                            <Button className="btn-danger w-100 mt-4" id="delete_btn" onClick={() => isDelete()}> {deleteButtonText ? deleteButtonText : " Remove from my list"}</Button>
                            <Button className="btn-outline-light text-secondary w-100 mt-2" onClick={() => isClose()}>{cancelButtonText ? cancelButtonText : <> &nbsp;&nbsp;&nbsp; &nbsp;Cancel&nbsp;&nbsp;&nbsp;&nbsp;</>}</Button>

                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )

}
export default DeleteModal;