import { Routes, Route, Navigate, createBrowserRouter, createRoutesFromElements, RouterProvider, json } from "react-router-dom";
import React, { Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import { BrowserRouter as Router } from 'react-router-dom';
import Main from "../components/Main";
import { useSelector } from "react-redux";
import { RootState } from "../config/Store";
import { UserState } from "../reducer/AuthReducer";
import Loader from "../components/Common/Loader/Loader";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import CheckEmail from "../components/Signup/CheckEmail";
import ResetPassword from "../components/Login/ResetPassword";
import Success from "../components/Login/Success";
import GetStarted from "../components/Signup/GetStarted";
import Signup from "../components/Signup/Signup";
import VerifyEmail from "../components/Signup/VerifyEmail";
import { ProtectedRouteProps, RoutesInterface } from "../OpenInterfaces";
import SocialSignup from "../components/Signup/SocialSignup";
import DemographicsPage1 from "../components/Onboarding/DemographicsPage1";
import Terms from "../pages/CMS/Terms";
import PrivacyPolicy from "../pages/CMS/PrivacyPolicy";
import MyDetailAboutMe from "../pages/MyDetails/MyDetailAboutMe/MyDetailAboutMe";
import MyDetailFamily from "../pages/MyDetails/MyDetailFamily/MyDetailFamily";
import MyDetailEducation from "../pages/MyDetails/MyDetailEducation/MyDetailEducation";
import MyDetailTestTaken from "../pages/MyDetails/MyDetailTestTaken/MyDetailTestTaken";
import MyDetailExtraCurricular from "../pages/MyDetails/MyDetailExtraCurricular/MyDetailExtraCurricular";
import MyDetailWritingSamples from "../pages/MyDetails/MyDetailWritingSamples/MyDetailWritingSamples";
import MyCalender from "../pages/Calender/MyCalender";
import ChangeEmailSuccess from "../components/Login/ChangeEmailSuccess";
import ConversationWithMessage from "../pages/Message/Pages/ConversationWithMessage";
import CreateEvent from "../pages/Events/CreateEvent/CreateEvent";
import ViewEvents from "../pages/Events/ViewEvents/ViewEvents";
import EventReport from "../pages/Events/ViewEvents/EventReport";

import Discover from "../pages/Discover/Discover";
import CreateNewCommunity from "../pages/Community/CreateNewCommunity";
import EditCommunity from "../pages/Community/EditCommunity";
import RequirementsTracker from "../pages/ReqTracker/RequirementsTracker";
import ReqUniversityDetails from "../pages/ReqTracker/ReqUniversityDetails";
import PopularCommunities from "../pages/Discover/PopularCommunities";
import KnowTheme from "../pages/Discover/KnowTheme";

import Counsellors from "../components/HighSchoolOnBoarding/Counsellors";
import CounsellorNetwork from "../components/HighSchoolOnBoarding/ CounsellorNetwork ";
import HighSchoolAssociation from "../components/HighSchoolOnBoarding/HighSchoolAssociation";
import GrowYourNetwork from "../components/HighSchoolOnBoarding/GrowYourNetwork";
import BadgesLeadBoard from "../pages/Profile/Components/BadgesLeadBoard";
import DiscussionDetail from "../pages/Discussions/DiscussionDetail";

import UniversityReview from "../pages/UniversityDetails/UniversityReview";
import MySchools from "../pages/UniversitySearch/MySchools";
import PerformanceTracker from "../pages/PerformanceTracker/PerformanceTracker";
import ProfilePerformance from "../pages/ProfilePerformance/ProfilePerformance";
import EventPostDetail from "../pages/Events/EventFeeds/EventPostDetail";
import CommunityPostDetail from "../pages/Community/CommunityFeeds/CommunityPostDetail";
import UserPostDetail from "../pages/NewsFeed/UserPostDetail";
import RequestReportOrRecommendation from "../pages/ReqTracker/RequestReportOrRecommendation";
import EmailRedirect from "../components/EmailRedirect";
import AppDeleteUser from "../components/Common/AppDeleteUser";

const Welcome = React.lazy(() => import("../components/Onboarding/Welcome"));
const Question1 = React.lazy(() => import("../components/Onboarding/Question_1"));
const Question2 = React.lazy(() => import("../components/Onboarding/Question_2"));
const PointsEarned = React.lazy(() => import("../components/Onboarding/PointsEarned"));
const QuestionsList = React.lazy(() => import("../components/Onboarding/QuestionsList"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const SearchUniversity = React.lazy(() => import("../pages/UniversitySearch/UniversitySearch"));
const UniversityDetails = React.lazy(() => import("../pages/UniversityDetails/UniversityDetails"));
const MyMatches = React.lazy(() => import("../pages/MyMatches/MyMatches"));
const MyApplications = React.lazy(() => import("../pages/MyApplications/MyApplications"));
const Myinformation = React.lazy(() => import("../pages/MyApplications/MyInformation"));
const FinalReview = React.lazy(() => import("../pages/MyApplications/FinalReview"));
const SubmitApp = React.lazy(() => import("../pages/MyApplications/SubmitApplication"));
const Documents = React.lazy(() => import("../pages/Documents/Documents"));
const MyPlanner = React.lazy(() => import("../pages/Planner/MyPlanner"));
const MyConnectins = React.lazy(() => import("../pages/MyConnections/MyConnections"));
const MyProfile = React.lazy(() => import("../pages/Profile/MyProfile"));
const StudentProfile = React.lazy(() => import("../pages/Profile/StudentProfile"));
const Community = React.lazy(() => import("../pages/Community/Community"));
const NewCommunity = React.lazy(() => import("../pages/Community/CreateCommunity"));
const Discussions = React.lazy(() => import("../pages/Discussions/Discussions"));
const Settings = React.lazy(() => import("../pages/Settings/Settings"));
const ParentsProfile = React.lazy(() => import("../pages/Profile/ParentsProfile"));
const ProfDashboard = React.lazy(() => import("../pages/Profile/ProfDashboard"));
const Verification = React.lazy(() => import("../pages/Profile/Verification"));
const VerificationTeacher = React.lazy(() => import("../pages/Profile/VerificationTeacher"));
const MyDetails = React.lazy(() => import("../pages/MyDetails/MyDetails"));
const Feed = React.lazy(() => import("../pages/NewsFeed/Feed"));
const EventDetalPrivate = React.lazy(() => import("../pages/NewsFeed/EventDetalPrivate"));

const MyBadges = React.lazy(() => import("../pages/Profile/Components/MyBadges"));

// New My Details 
const MyDetails_New = React.lazy(() => import("../pages/MyDetails_New/MyDetails"));
const AbouteMe = React.lazy(() => import("../pages/MyDetails_New/AboutMe/AboutMe"));
const MyFamily = React.lazy(() => import("../pages/MyDetails_New/MyFamily/MyFamily"));
const MyEducationDetails = React.lazy(() => import("../pages/MyDetails_New/MyEducationDetails/MyEducationDetails"));
const StandardizedTests = React.lazy(() => import("../pages/MyDetails_New/StandardizedTests/StandardizedTests"));
const ExtracurricularsActivity = React.lazy(() => import("../pages/MyDetails_New/Extracurriculars/Extracurriculars"));
const WritingSamples = React.lazy(() => import("../pages/MyDetails_New/WritingSamples/WritingSamples"));

// New Onboarding page 
const Welocme_new = React.lazy(() => import("../components/OnBoarding_2/Welcome"));
const GenralInfo = React.lazy(() => import("../components/OnBoarding_2/GenralInfo"));
const HowAbout = React.lazy(() => import("../components/OnBoarding_2/HowAbout"));
const SchoolConsidering = React.lazy(() => import("../components/OnBoarding_2/SchoolsConsidering"));
const MoreImp = React.lazy(() => import("../components/OnBoarding_2/MoreImportant"));
const Demographics = React.lazy(() => import("../components/OnBoarding_2/Demographics"));
const DemographicsPage2 = React.lazy(() => import("../components/Onboarding/DemographicsPage2"));
const TestScores = React.lazy(() => import("../components/Onboarding/TestScores"));
const Grades = React.lazy(() => import("../components/Onboarding/Grades"));
const Coursework = React.lazy(() => import("../components/Onboarding/Coursework"));
const Extracurriculars = React.lazy(() => import("../components/Onboarding/Extracurriculars"));
const Network = React.lazy(() => import("../components/Onboarding/Network"));
const Calculate = React.lazy(() => import("../components/OnBoarding_2/Calculate"));
const Onboarding_6 = React.lazy(() => import("../components/OnBoarding_2/Onboardiing_6"));
const BgInfo = React.lazy(() => import("../components/OnBoarding_2/BgInfo"));

const Navigation = () => {
  const login: any = useSelector<RootState, UserState>((state: any) => state.userLogin);

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: localStorage.getItem("token") != null,
    authenticationPath: "/",
  };

  const PublicRoute: RoutesInterface[] = [
    { path: "/login/:id", component: <Login /> },
    { path: "/login", component: <Login /> },
    { path: "/forgot-password", component: <ForgotPassword /> },
    { path: "/get-started", component: <GetStarted /> },
    { path: "/create-account", component: <Signup /> },
    { path: "/complete-profile", component: < SocialSignup /> },
    { path: "/verify-email", component: <VerifyEmail /> },
    { path: "/check-email", component: <CheckEmail /> },
    { path: "/welcome", component: <Welcome /> },
  ];

  const commonRoutes: RoutesInterface[] = [
    { path: "/set-password/:id", component: <ResetPassword /> },
    { path: "/success", component: <Success /> },
    { path: "/questions", component: <QuestionsList /> },
    { path: "/privacy-policy", component: <PrivacyPolicy /> },
    { path: "/terms-and-conditions", component: <Terms /> },
    { path: "/change/email/:id", component: <ChangeEmailSuccess /> },
    { path: "/verification/:id", component: <Verification /> },
    { path: "/verify-activity/:id", component: <VerificationTeacher /> },
    { path: "/school-report-request/:id", component: <RequestReportOrRecommendation /> },
    { path: "/recommendation-letter-request/:id", component: <RequestReportOrRecommendation /> },
    { path: "/genral-info", component: <GenralInfo /> },
    { path: "/how-about", component: <HowAbout /> },
    { path: "/school-considering", component: <SchoolConsidering /> },
    { path: "/more-important", component: <MoreImp /> },
    { path: "/demographics", component: <DemographicsPage2 /> },
    { path: "/demographics-additional", component: <DemographicsPage1 /> },
    { path: "/test-score", component: <TestScores /> },
    { path: "/grades", component: <Grades /> },
    { path: "/coursework", component: <Coursework /> },
    { path: "/extracurriculars", component: <Extracurriculars /> },
    { path: "/network", component: <Network /> },
    { path: "/calculate", component: <Calculate /> },
    { path: "/onboarding-6", component: <Onboarding_6 /> },
    { path: "/bg-info", component: <BgInfo /> },
    { path: "/email-redirect", component: <EmailRedirect /> },
    { path: "/delete-user-account", component: <AppDeleteUser /> },
  ];

  const PrivateRoutes: RoutesInterface[] = [
    { path: "/dashboard", component: <Dashboard /> },
    // { path: "/question-1", component: <Question1 /> },
    // { path: "/question-2", component: <Question2 /> },
    // { path: "/you-earned", component: <PointsEarned /> },
    // { path: "/messages", component: <ConversationWithMessage /> },
    // { path: "/search-university", component: <SearchUniversity /> },
    // { path: "/university-detail/:id", component: <UniversityDetails /> },
    // { path: "/my-matches", component: <MyMatches /> },
    // { path: "/my-applications/", component: <MyApplications /> },
    // { path: "/my-applications/my-information", component: <Myinformation /> },
    // { path: "/my-applications/final-review", component: <FinalReview /> },
    // { path: "/my-applications/submit-application", component: <SubmitApp /> },
    // { path: "/documents", component: <Documents /> },
    { path: "/my-planner", component: <MyPlanner /> },
    { path: "/my-planner/:id", component: <MyPlanner /> },
    { path: "/my-calendar", component: <MyCalender /> },
    // { path: "/my-connections", component: <MyConnectins /> },
    { path: "/my-profile", component: <MyProfile /> },
    // { path: "/student-profile", component: <StudentProfile /> },
    // { path: "/event/:id", component: <ViewEvents /> },
    // { path: "/event/create-event", component: <CreateEvent /> },
    // { path: "/event/edit-event/:id", component: <CreateEvent /> },
    // { path: "/event-report/:id", component: <EventReport /> },
    // { path: "/event/-post/:id", component: <EventPostDetail /> },
    // { path: "/user-post/:id", component: <UserPostDetail /> },
    // { path: "/community/:id", component: <Community /> },
    // { path: "/community-post/:id", component: <CommunityPostDetail /> },
    // { path: "/community/create-community", component: <NewCommunity /> },
    // { path: "/community/edit-community/:id", component: <EditCommunity /> },
    // // { path: "/community/admin-view/:id", component: <CommunityAdminView /> },
    // { path: "/discussions/:id", component: <Discussions /> },
    // { path: "/discussion-detail/:id", component: <DiscussionDetail /> },
    { path: "/settings", component: <Settings /> },
    // { path: "/parents-profile", component: <ParentsProfile /> },
    // { path: "/profile-dashboard", component: <ProfDashboard /> },
    // { path: "/my-details", component: <MyDetails /> }, 
    // { path: "/feed", component: <Feed /> },

    // ------------ New UI Pages -----------
    // { path: "/my-profile/badges", component: <MyBadges /> },
    // { path: "/my-profile/badges-leadboard", component: <BadgesLeadBoard /> },
    // { path: "/event-details-private", component: <EventDetalPrivate /> },
    // { path: "/report", component: <EventReport /> },
    // { path: "/discover", component: <Discover /> },
    // { path: "/creat-new-community", component: <CreateNewCommunity /> },
    // { path: "/edit-community", component: <EditCommunity /> },
    { path: "/requirements-tracker", component: <RequirementsTracker /> },
    { path: "/requirements-tracker/university/:id", component: <ReqUniversityDetails /> },
    // { path: "/discover/popular-communities", component: <PopularCommunities /> },
    // { path: "/discover/know-theme", component: <KnowTheme /> },
    { path: "/my-schools", component: <MySchools /> },
    { path: "/university-review/:id", component: <UniversityReview /> },
    // { path: "/performance-tracker", component: <PerformanceTracker /> },
    // { path: "/profile-performance", component: <ProfilePerformance /> },

    // Highschool onboarding 
    // { path: "/highschool-onboarding/step-6", component: <Counsellors /> },
    // { path: "/highschool-onboarding/step-9", component: <CounsellorNetwork /> },
    // { path: "/highschool-onboarding/hig/school-association", component: <HighSchoolAssociation /> },
    // { path: "/highschool-onboarding/grow-your-network", component: <GrowYourNetwork /> },

    //My Detail new
    { path: "/my-details", component: <MyDetails /> },
    { path: "/my-details/about-me", component: <MyDetailAboutMe /> },
    { path: "/my-details/family", component: <MyDetailFamily /> },
    { path: "/my-details/education", component: <MyDetailEducation /> },
    { path: "/my-details/tests", component: <MyDetailTestTaken /> },
    { path: "/my-details/extracurriculars", component: <MyDetailExtraCurricular /> },
    { path: "/my-details/writing-samples", component: <MyDetailWritingSamples /> },
    // { path: "/my-details-new/about-me", component: <AbouteMe /> },
    // { path: "/my-details-new/my-family", component: <MyFamily /> },
    // { path: "/my-details-new/my-education-detail", component: <MyEducationDetails /> },
    // { path: "/my-details-new/standardized-tests", component: <StandardizedTests /> },
    // { path: "/my-details-new/extracurriculars", component: <ExtracurricularsActivity /> },
    // { path: "/my-details-new/writing-samples", component: <WritingSamples /> },
  ];

  let router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" key={new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Welcome />} />
        <Route path="*" key={new Date().getTime() + new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Navigate replace to="/" />} />
        < Route path="/" element={< ProtectedRoute {...defaultProtectedRouteProps} outlet={< Main />} />}>
          {
            PrivateRoutes.map((item: RoutesInterface) => {
              return <Route path={item.path} key={item.path} element={<Suspense fallback={<><Loader show={true} /></>}> {item?.component} </Suspense>}>
                {
                  item.subMenu && item.subMenu.length > 0 && item.subMenu.map((ite: any) => {
                    return (
                      <Route path={ite.path} element={
                        <Suspense fallback={<><Loader show={true} /></>}>
                          {ite?.component}
                        </Suspense>} action={() => json({ ok: true })} />
                    )
                  })
                }
              </Route>
            })
          }
        </Route >

        {
          PublicRoute.map((item: RoutesInterface) => {
            return <Route path={item.path} key={item.path} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : item.component} />
          })
        }

        {
          commonRoutes.map((item: RoutesInterface) => {
            return <Route path={item.path} key={item.path} element={<Suspense fallback={<><Loader show={true} /></>}> {item.component} </Suspense>} />
          })
        }
      </>
    )
  );

  return (
    <div id="main-wraper">

      {/* <Router>
        <Routes>
          <Route path="/" key={new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Welcome />} />
          <Route path="*" key={new Date().getTime() + new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Navigate replace to="/" />} />

          {
            PublicRoute.map((item: RoutesInterface) => {
              return <Route path={item.path} key={item.path} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : item.component} />
            })
          }

          {
            commonRoutes.map((item: RoutesInterface) => {
              return <Route path={item.path} key={item.path} element={<Suspense fallback={<><Loader show={true} /></>}> {item.component} </Suspense>} />
            })
          }
        </Routes>
      </Router> */}
      <RouterProvider router={router} />
      {/* <Routes>
        <Route path="/" key={new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Welcome />} />
        <Route path="*" key={new Date().getTime() + new Date().getTime()} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : <Welcome />} />

        {PublicRoute.map((item: RoutesInterface) => {
          return <Route path={item.path} key={item.path} element={defaultProtectedRouteProps.isAuthenticated || login.loginSuccess ? (<Navigate replace to="/dashboard" />) : item.component} />
        })}

        {commonRoutes.map((item: RoutesInterface) => {
          return <Route path={item.path} key={item.path} element={<Suspense fallback={<><Loader show={true} /></>}> {item.component} </Suspense>} />
        })}

        < Route path="/" element={< ProtectedRoute {...defaultProtectedRouteProps} outlet={< Main />} />}>
          {
            PrivateRoutes.map((item: RoutesInterface) => {
              return <Route path={item.path} key={item.path} element={<Suspense fallback={<><Loader show={true} /></>}> {item?.component} </Suspense>}>
                {
                  item.subMenu && item.subMenu.length > 0 && item.subMenu.map((ite: any) => {
                    return (
                      <Route path={ite.path} element={
                        <Suspense fallback={<><Loader show={true} /></>}>
                          {ite?.component}
                        </Suspense>} action={() => json({ ok: true })} />
                    )
                  })
                }
              </Route>
            })
          }
        </Route >
      </Routes > */}

    </div >
  );
};
export default Navigation;