// import "./OpenTagSelect.scss";
// import React, { useState, Fragment, useEffect, useRef } from "react";
// import HelperService from "../../../Services/HelperService";
// import useClickOutside from "../../../hook/useClickOutside";
// import { BiChevronDown } from "react-icons/bi";
// import loader from "../../../assets/images/loader.gif";
// import WebService from "../../../Services/WebService";


// interface PropData {
//     placeholder?: string;
//     selected: any;
//     onChange: any;
//     type?: string;
//     sakey?: string;
//     value?: string;
//     isHideArrow?: boolean;
//     isDisable?: boolean;
//     forceClose?: boolean;
//     isCustomInput?: boolean;
//     max?: number;
//     column?: number;
//     isLoading?: boolean;
//     selectedObj?: any;
//     isCustom?: boolean;
// }

// export interface Options {
//     id: any;
//     code?: string;
//     value: string;
//     value2?: string;
//     parentCode?: string;
//     object?: any;
//     icon?: string;
// }

// const OpenUniversitySelect = (props: PropData) => {
//     let textInput = React.createRef<HTMLInputElement>();
//     const [placeholder, setPlaceholder] = useState(props.placeholder);
//     const [options, setOptions] = useState<any[]>([]);
//     const [initialOption, setInitialOption] = useState<any[]>([]);
//     const [customOption, setCustomOption] = useState<any[]>([]);
//     const [selectedOption, setSelectedOption] = useState(props.selected);
//     const [isFocus, setIsFocus] = useState(false);
//     const [search, setSearch] = useState("");
//     const keyword = useRef("");
//     const [isOpenTop, setIsOpenTop] = useState(false);
//     let index = -1;
//     let selectedValue = "";
//     const [userInput, setUserInput] = useState<any>();
//     const [isLoading, setLoding] = useState<boolean>(false);
//     const [isLoadingOption, setLodingOption] = useState<boolean>(true);
//     const [selectedIndex, setSelectedIndex] = useState(index);

//     useEffect(() => {
//         getUniversityList();
//         // eslint-disable-next-line
//     }, []);

//     useEffect(() => {
//         if (props.selectedObj) {
//             let isSelectedExist: boolean = false;
//             const tempData = options.map((item: any) => {
//                 if (props.selected && props.selected == item.id) {
//                     isSelectedExist = true;
//                 }
//                 return { ...item, ...{ value: item.name } }
//             });

//             if (!isSelectedExist && props.selectedObj) {
//                 tempData.push({ ...props.selectedObj, ...{ value: props.selectedObj.name } });
//             }
//             updateOptions(tempData, customOption)
//         }
//     }, [props.selectedObj]);

//     useEffect(() => {
//         if (props.selected != selectedOption) {
//             setSelectedOption(props.selected);
//         };



//         options.length && options.map((value, i: number) => {
//             if (value.id == props.selected) {
//                 index = i;
//                 selectedValue = value.value;
//                 if (userInput != selectedValue) {
//                     setUserInput(selectedValue);
//                 }
//             }
//         });

//         if (props.selected != selectedOption && options.length) {
//             for (var i in options) {
//                 if (options[i].id == props.selected) {
//                     if (props.onChange) {
//                         props.onChange(options[i]);
//                     }
//                 }
//             }
//         };

//         if (!props.selected) {
//             setUserInput("");
//         }
//     }, [props.selected, options]);

//     useEffect(() => {
//         if (props.forceClose) {
//             setIsFocus(false);
//         }
//     }, [props.forceClose]);

//     let domNode = useClickOutside(() => {
//         setIsFocus(false);
//         if (search) {
//             if (props.isCustomInput) {
//                 setUserInput(search);
//                 setSelectedOption(search);
//                 props.onChange(search);
//             }
//         }
//     }, this);

//     const checkPossition = () => {
//         var topHeight = domNode.current.getBoundingClientRect().y;
//         var bottomHeight =
//             window.innerHeight - domNode.current.getBoundingClientRect().y;
//         if (bottomHeight > 300 || topHeight <= 280) {
//             setIsOpenTop(false);
//         } else {
//             setIsOpenTop(true);
//         }
//     };

//     let optionsListComponent;
//     let searchOption: Options[] = [];
//     const optiosnData = props.isCustom ?
//         <ul className={"options " + (isOpenTop ? "open-top" : "")}>
//             <li onMouseDown={() => onAddCustom()}>
//                 <div className="option">Add : {search} - {options.length}</div>
//             </li>
//         </ul>
//         :
//         <div className="no-options">
//             <em>No data found</em>
//         </div>;
//     const loaaderData = <div className="no-suggestions options">
//         <div style={{ textAlign: "center" }}>
//             <img
//                 style={{ position: "relative" }}
//                 src={loader}
//                 alt="No loader found"
//             />
//             <div style={{ position: "relative", color: "black" }}>
//                 Loading...
//             </div>
//         </div>
//     </div>


//     if (searchOption.length) {
//         optionsListComponent = (
//             <ul className={"options " + (isOpenTop ? "open-top" : "")}>
//                 {searchOption.map((suggestion: Options, index) => {
//                     let className;
//                     if (index === selectedIndex) {
//                         className = "option-active";
//                     }

//                     return (
//                         <li
//                             className={className}
//                             key={index}
//                             onMouseDown={() => onSelect(suggestion)}
//                         >
//                             {suggestion.icon ? (
//                                 <div className="row-icon-view">
//                                     <img src={suggestion.icon} className="row-icons" />
//                                 </div>
//                             ) : suggestion.code ? (
//                                 props.column == 3 ? (
//                                     <div className="row option">
//                                         <div className="col-md-2 code-div"> {suggestion.code} </div>
//                                         <div className="col-md-5">{suggestion.value}</div>
//                                         <div className="col-md-5">{suggestion.value2}</div>
//                                     </div>
//                                 ) : (
//                                     <div className="row option">
//                                         <div className="col-md-5 code-div"> {suggestion.code} </div>
//                                         <div className="col-md-7">{suggestion.value}</div>
//                                     </div>
//                                 )
//                             ) : (
//                                 <div className="option">{suggestion.value}</div>
//                             )}
//                         </li>
//                     );
//                 })}
//             </ul>
//         );
//     } else {
//         optionsListComponent = isLoading ? loaaderData : optiosnData;
//     };

//     const onSelect = (e: Options) => {
//         setSearch("");
//         keyword.current = "";
//         setIsFocus(false);
//         setUserInput(e.value);
//         setSelectedOption(e.id);
//         options.map((value, i: number) => {
//             if (value.id === e.id) {
//                 index = i;
//                 selectedValue = value.value;
//                 if (props.onChange) {
//                     props.onChange(e);
//                 }
//                 setSelectedIndex(index);
//             }
//         });
//     };

//     const handleKey = (e: any) => {
//         if (e.keyCode === 40) {
//             if (selectedIndex < options.length - 1)
//                 setSelectedIndex(selectedIndex + 1);
//         } else if (e.keyCode === 38) {
//             if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
//         } else if (e.keyCode === 13) {
//             options.map((value: Options, i: number) => {
//                 if (selectedIndex === i) {
//                     onSelect(value);
//                 }
//             });
//         }
//     };

//     const checkOption = (enterValue: any) => {
//         // setIsFocus(false);
//         var isFound = false;
//         options.map((value: Options, i: number) => {
//             if (value.id === enterValue || value.value === enterValue) {
//                 setUserInput(enterValue);
//                 setSelectedOption(enterValue);
//                 onSelect(value);
//                 props.onChange(value);
//                 isFound = true;
//             }
//         });

//         if (props.isCustomInput && enterValue && !isFound) {
//             setUserInput(enterValue);
//             setSelectedOption(enterValue);
//             var temp: Options = { id: enterValue, value: enterValue };
//             onSelect(temp);
//             props.onChange(temp);
//         }

//         setSearch("");
//         keyword.current = "";
//     };

//     const getUniversityList = () => {
//         if (!isLoading) {
//             setLoding(true);
//             setTimeout(() => {
//                 WebService.getAPI({ action: `university/list?limit=&keyword=${keyword.current ? keyword.current : ""}&university_type=${props.type}&limit=50` })
//                     .then((res: any) => {
//                         let isSelectedExist: boolean = false;
//                         const tempData = res.list.map((item: any) => {
//                             if (!keyword.current && props.selected && props.selected == item.id) {
//                                 isSelectedExist = true;
//                             }
//                             return { ...item, ...{ value: item.name } }
//                         });
//                         if (!isSelectedExist && props.selectedObj && !keyword.current) {
//                             tempData.push({ ...props.selectedObj, ...{ value: props.selectedObj.name } });
//                         }
//                         setLoding(false);
//                         updateOptions(tempData, customOption)
//                         if (initialOption.length == 0) {
//                             setInitialOption(tempData);

//                         }

//                     })
//                     .catch(() => { setLoding(false); });
//             }, 500);
//         }
//     };

//     const onAddCustom = () => {
//         setLodingOption(false);
//         props.onChange({ id: search, value: search });
//         setSelectedOption(search);
//         setUserInput(search);
//         setIsFocus(false);

//         let customTemp: any[] = [...customOption];
//         customTemp.push({ id: search, value: search })
//         setCustomOption([...customTemp])
//         keyword.current = "";
//         setSearch("");


//         setLodingOption(true);
//         var temp = [...initialOption];
//         updateOptions(temp, customTemp)
//     };

//     const updateOptions = (data: any[], customData: any[]) => {
//         const temp = [...data, ...customData];
//         setOptions(temp);
//     };





//     return (
//         <>
//             {<Fragment>
//                 <div className={props.selected ? "selected react-select-open truncate-data" : "react-select-open truncate-data"}>
//                     <div ref={domNode} id={props.sakey ? props.sakey : "selectId"} key={props.sakey} className={props.isDisable === true ? "disabled-select" : "select w-100 custom-select"}>
//                         <div className={"form-style " + (isFocus ? "zindex" : "")} tabIndex={0}>
//                             <input
//                                 ref={textInput}
//                                 className="form-control text-truncate"
//                                 value={isFocus ? keyword.current : userInput}
//                                 type="text"
//                                 onKeyPress={(e: any) => {
//                                     keyword.current = e.target.value;
//                                     setSearch(e.target.value)
//                                     if (props.max) {
//                                         HelperService.maxNumber(e, props.max)
//                                     }
//                                 }}
//                                 onBlur={(e) => checkOption(e.target.value)}
//                                 onKeyDown={(e) => { handleKey(e); }}
//                                 onClick={() => { setIsFocus(!isFocus); }}
//                                 onMouseDown={() => {
//                                     if (!props.isDisable) {
//                                         if (!isFocus) {
//                                             checkPossition();
//                                         }
//                                     }
//                                 }}
//                                 disabled={props.isDisable}
//                                 placeholder={placeholder}
//                                 onChange={(e) => {
//                                     keyword.current = e.target.value;
//                                     setSearch(e.target.value)
//                                     setIsFocus(true)

//                                     setTimeout(() => {
//                                         getUniversityList();
//                                     }, 1000)
//                                 }}
//                             />
//                             {!props.isHideArrow && !isFocus ? (
//                                 <div className="down-arrow">
//                                     <svg width="10" height="6" className="icon" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                         <path d="M3.34025 5.70097C3.30642 5.66801 3.16175 5.54355 3.04275 5.42763C2.29433 4.74797 1.06933 2.97495 0.695417 2.04696C0.635333 1.90603 0.508167 1.54972 0.5 1.35935C0.5 1.17694 0.542 1.00304 0.627167 0.837108C0.746167 0.630256 0.933417 0.46432 1.1545 0.373396C1.30792 0.314864 1.767 0.22394 1.77517 0.22394C2.27742 0.133016 3.0935 0.0830078 3.99533 0.0830078C4.85458 0.0830078 5.63742 0.133016 6.14725 0.20746C6.15542 0.215984 6.72592 0.306908 6.92133 0.406356C7.27833 0.588772 7.5 0.94508 7.5 1.32639V1.35935C7.49125 1.60769 7.26958 2.12993 7.26142 2.12993C6.88692 3.00791 5.722 4.74001 4.94792 5.43615C4.94792 5.43615 4.749 5.6322 4.62475 5.71745C4.44625 5.85042 4.22517 5.91634 4.00408 5.91634C3.75733 5.91634 3.5275 5.8419 3.34025 5.70097Z" fill="#929295" />
//                                     </svg>
//                                 </div>
//                             ) : (
//                                 ""
//                             )}


//                             {
//                                 isFocus && isLoadingOption && props.isDisable == false &&
//                                 <>
//                                     {isLoading && loaaderData}
//                                     {!isLoading && options && options.length > 0 &&
//                                         <ul className={"options " + (isOpenTop ? "open-top" : "")}>
//                                             {options.map((suggestion: Options, index) => {
//                                                 let className;
//                                                 if (index === selectedIndex) {
//                                                     className = "option-active";
//                                                 }
//                                                 return (
//                                                     <li
//                                                         className={className}
//                                                         key={index}
//                                                         onMouseDown={() => onSelect(suggestion)}
//                                                     >
//                                                         {suggestion.icon ? (
//                                                             <div className="row-icon-view">
//                                                                 <img src={suggestion.icon} className="row-icons" />
//                                                             </div>
//                                                         ) : suggestion.code ? (
//                                                             props.column == 3 ? (
//                                                                 <div className="row option">
//                                                                     <div className="col-md-2 code-div"> {suggestion.code} </div>
//                                                                     <div className="col-md-5">{suggestion.value}</div>
//                                                                     <div className="col-md-5">{suggestion.value2}</div>
//                                                                 </div>
//                                                             ) : (
//                                                                 <div className="row option">
//                                                                     <div className="col-md-5 code-div"> {suggestion.code} </div>
//                                                                     <div className="col-md-7">{suggestion.value}</div>
//                                                                 </div>
//                                                             )
//                                                         ) : (
//                                                             <div className="option">{suggestion.value}</div>
//                                                         )}
//                                                     </li>
//                                                 );
//                                             })}
//                                         </ul>
//                                     }
//                                     {!isLoading && options.length == 0 && optiosnData}
//                                 </>
//                             }



//                             {/* {isFocus && isLoadingOption && props.isDisable == false ?
//                                 isLoading ? loaaderData : (options && options.length > 0) ?
//                                     <ul className={"options " + (isOpenTop ? "open-top" : "")}>
//                                         {options.map((suggestion: Options, index) => {
//                                             let className;
//                                             if (index === selectedIndex) {
//                                                 className = "option-active";
//                                             }
//                                             return (
//                                                 <li
//                                                     className={className}
//                                                     key={index}
//                                                     onMouseDown={() => onSelect(suggestion)}
//                                                 >
//                                                     {suggestion.icon ? (
//                                                         <div className="row-icon-view">
//                                                             <img src={suggestion.icon} className="row-icons" />
//                                                         </div>
//                                                     ) : suggestion.code ? (
//                                                         props.column == 3 ? (
//                                                             <div className="row option">
//                                                                 <div className="col-md-2 code-div"> {suggestion.code} </div>
//                                                                 <div className="col-md-5">{suggestion.value}</div>
//                                                                 <div className="col-md-5">{suggestion.value2}</div>
//                                                             </div>
//                                                         ) : (
//                                                             <div className="row option">
//                                                                 <div className="col-md-5 code-div"> {suggestion.code} </div>
//                                                                 <div className="col-md-7">{suggestion.value}</div>
//                                                             </div>
//                                                         )
//                                                     ) : (
//                                                         <div className="option">{suggestion.value}</div>
//                                                     )}
//                                                 </li>
//                                             );
//                                         })}
//                                     </ul>
//                                     :
//                                     optiosnData : ""} */}
//                         </div>
//                     </div>
//                 </div>
//             </Fragment>}
//         </>
//     );
// };

// OpenUniversitySelect.defaultProps = {
//     placeholder: "Choose an option",
//     selected: "",
//     sakey: new Date().getTime(),
//     type: "ARROW",
//     isHideArrow: false,
//     options: [],
//     isDisable: false,
//     isCustom: false,
// };

// export default OpenUniversitySelect;



/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


import "./OpenTagSelect.scss";
import React, { useState, Fragment, useEffect, useRef } from "react";
import HelperService from "../../../Services/HelperService";
import useClickOutside from "../../../hook/useClickOutside";
import { BiChevronDown } from "react-icons/bi";
import loader from "../../../assets/images/loader.gif";
import WebService from "../../../Services/WebService";


interface PropData {
    placeholder?: string;
    selected: any;
    onChange: any;
    type?: string;
    sakey?: string;
    value?: string;
    isHideArrow?: boolean;
    isDisable?: boolean;
    forceClose?: boolean;
    isCustomInput?: boolean;
    max?: number;
    column?: number;
    isLoading?: boolean;
    selectedObj?: any;
    isCustom?: boolean;
}

export interface Options {
    id: any;
    code?: string;
    value: string;
    value2?: string;
    parentCode?: string;
    object?: any;
    icon?: string;
}

const OpenUniversitySelect = (props: PropData) => {
    let textInput = React.createRef<HTMLInputElement>();
    const [placeholder, setPlaceholder] = useState(props.placeholder);
    const options = useRef<any[]>([]);
    const [initialOption, setInitialOption] = useState<any[]>([]);
    const [customOption, setCustomOption] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState(props.selected);
    const [isFocus, setIsFocus] = useState(false);
    const [search, setSearch] = useState("");
    const keyword = useRef("");
    const [isOpenTop, setIsOpenTop] = useState(false);
    let index = -1;
    let selectedValue = "";
    const [userInput, setUserInput] = useState<any>();
    const [isLoading, setLoding] = useState<boolean>(false);
    const [isLoadingOption, setLodingOption] = useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = useState(index);
    const timeCompute = useRef<any>(null);

    useEffect(() => {
        getUniversityList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.selectedObj) {
            let isSelectedExist: boolean = false;
            const tempData = options.current.map((item: any) => {
                if (props.selected && props.selected == item.id) {
                    isSelectedExist = true;
                }
                return { ...item, ...{ value: item.name } }
            });

            if (!isSelectedExist && props.selectedObj) {
                tempData.push({ ...props.selectedObj, ...{ value: props.selectedObj.name } });
            }
            updateOptions(tempData, customOption)
        }
    }, [props.selectedObj]);

    useEffect(() => {
        if (props.selected != selectedOption) {
            setSelectedOption(props.selected);
        };
        options.current.length && options.current.map((value, i: number) => {
            if (value.id == props.selected) {
                index = i;
                selectedValue = value.value;
                if (userInput != selectedValue) {
                    setUserInput(selectedValue);
                }
            }
        });

        if (props.selected != selectedOption && options.current.length) {
            for (var i in options.current) {
                if (options.current[i].id == props.selected) {
                    if (props.onChange) {
                        // props.onChange(options.current[i]);
                    }
                }
            }
        };

        if (!props.selected) {
            setUserInput("");
        };
    }, [props.selected, options.current]);

    useEffect(() => {
        if (props.forceClose) {
            setIsFocus(false);
        }
    }, [props.forceClose]);

    let domNode = useClickOutside(() => {
        setIsFocus(false);
        if (search) {
            if (props.isCustomInput) {
                setUserInput(search);
                setSelectedOption(search);
                props.onChange(search);
            }
        }
    }, this);

    const checkPossition = () => {
        var topHeight = domNode.current.getBoundingClientRect().y;
        var bottomHeight =
            window.innerHeight - domNode.current.getBoundingClientRect().y;
        if (bottomHeight > 300 || topHeight <= 280) {
            setIsOpenTop(false);
        } else {
            setIsOpenTop(true);
        }
    };

    let optionsListComponent;
    let searchOption: Options[] = [];
    const optiosnData = props.isCustom ?
        <ul className={"options " + (isOpenTop ? "open-top" : "")}>
            <li onMouseDown={() => onAddCustom()}>
                <div className="option">Add : {search}</div>
            </li>
        </ul>
        :
        <div className="no-options">
            <em>No data found</em>
        </div>;
    const loaaderData = <div className="no-suggestions options">
        <div style={{ textAlign: "center" }}>
            <img
                style={{ position: "relative" }}
                src={loader}
                alt="No loader found"
            />
            <div style={{ position: "relative", color: "black" }}>
                Loading...
            </div>
        </div>
    </div>


    if (searchOption.length) {
        optionsListComponent = (
            <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                {searchOption.map((suggestion: Options, index) => {
                    let className;
                    if (index === selectedIndex) {
                        className = "option-active";
                    }

                    return (
                        <li
                            className={className}
                            key={index}
                            onMouseDown={() => onSelect(suggestion)}
                        >
                            {suggestion.icon ? (
                                <div className="row-icon-view">
                                    <img src={suggestion.icon} className="row-icons" />
                                </div>
                            ) : suggestion.code ? (
                                props.column == 3 ? (
                                    <div className="row option">
                                        <div className="col-md-2 code-div"> {suggestion.code} </div>
                                        <div className="col-md-5">{suggestion.value}</div>
                                        <div className="col-md-5">{suggestion.value2}</div>
                                    </div>
                                ) : (
                                    <div className="row option">
                                        <div className="col-md-5 code-div"> {suggestion.code} </div>
                                        <div className="col-md-7">{suggestion.value}</div>
                                    </div>
                                )
                            ) : (
                                <div className="option">{suggestion.value}</div>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        optionsListComponent = isLoading ? loaaderData : optiosnData;
    };

    const onSelect = (e: Options) => {
        setSearch("");
        keyword.current = "";
        setIsFocus(false);
        setUserInput(e.value);
        setSelectedOption(e.id);
        options.current.map((value, i: number) => {
            if (value.id === e.id) {
                index = i;
                selectedValue = value.value;
                if (props.onChange) {
                    props.onChange(e);
                }
                setSelectedIndex(index);
            }
        });
    };

    const handleKey = (e: any) => {
        if (e.keyCode === 40) {
            if (selectedIndex < options.current.length - 1)
                setSelectedIndex(selectedIndex + 1);
        } else if (e.keyCode === 38) {
            if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
        } else if (e.keyCode === 13) {
            options.current.map((value: Options, i: number) => {
                if (selectedIndex === i) {
                    onSelect(value);
                }
            });
        }
    };

    const checkOption = (enterValue: any) => {
        // setIsFocus(false);
        var isFound = false;
        options.current.map((value: Options, i: number) => {
            if (value.id === enterValue || value.value === enterValue) {
                setUserInput(enterValue);
                setSelectedOption(enterValue);
                onSelect(value);
                // props.onChange(value);
                isFound = true;
            }
        });

        if (props.isCustomInput && enterValue && !isFound) {
            setUserInput(enterValue);
            setSelectedOption(enterValue);
            var temp: Options = { id: enterValue, value: enterValue };
            onSelect(temp);
            props.onChange(temp);
        }

        setSearch("");
        keyword.current = "";
    };

    const getUniversityList = () => {
        let tempTime = new Date().getTime();
        timeCompute.current = new Date().getTime();
        setLoding(true);
        WebService.getAPI({ action: `university/list?keyword=${keyword.current ? keyword.current : ""}&university_type=${props.type}&limit=50` })
            .then((res: any) => {
                if(timeCompute.current != tempTime) return;
                let isSelectedExist: boolean = false;
                const tempData = res.list.map((item: any) => {
                    if (!keyword.current && props.selected && props.selected == item.id) {
                        isSelectedExist = true;
                    }
                    return { ...item, ...{ value: item.name } }
                });
                if (!isSelectedExist && props.selectedObj && !keyword.current) {
                    tempData.push({ ...props.selectedObj, ...{ value: props.selectedObj.name } });
                }
                setLoding(false);
                updateOptions(tempData, customOption)
                if (initialOption.length == 0) {
                    setInitialOption(tempData);
                }
            })
            .catch(() => { setLoding(false); });
    };

    const onAddCustom = () => {
        setLodingOption(false);
        props.onChange({ id: search, value: search });
        setSelectedOption(search);
        setUserInput(search);
        setIsFocus(false);
        let customTemp: any[] = [...customOption];
        customTemp.push({ id: search, value: search })
        setCustomOption([...customTemp])
        keyword.current = "";
        setSearch("");
        setLodingOption(true);
        var temp = [...initialOption];
        updateOptions(temp, customTemp)
    };

    const updateOptions = (data: any[], customData: any[]) => {
        const temp = [...data, ...customData];
        options.current = data.length ? temp : [];
    };

    return (
        <>
            {<Fragment>
                <div className={props.selected ? "selected react-select-open truncate-data" : "react-select-open truncate-data"}>
                    <div ref={domNode} id={props.sakey ? props.sakey : "selectId"} key={props.sakey} className={props.isDisable === true ? "disabled-select" : "select w-100 custom-select"}>
                        <div className={"form-style " + (isFocus ? "zindex" : "")} tabIndex={0}>
                            <input
                                ref={textInput}
                                className="form-control text-truncate"
                                value={isFocus ? keyword.current : userInput}
                                type="text"
                                onKeyPress={(e: any) => {
                                    keyword.current = e.target.value;
                                    setSearch(e.target.value)
                                    if (props.max) {
                                        HelperService.maxNumber(e, props.max)
                                    }
                                }}
                                onBlur={(e) => checkOption(e.target.value)}
                                onKeyDown={(e) => { handleKey(e); }}
                                onClick={() => { setIsFocus(!isFocus); }}
                                onMouseDown={() => {
                                    if (!props.isDisable) {
                                        if (!isFocus) {
                                            checkPossition();
                                        }
                                    }
                                }}
                                disabled={props.isDisable}
                                placeholder={placeholder}
                                onChange={(e) => {
                                    keyword.current = e.target.value;
                                    setSearch(e.target.value)
                                    setIsFocus(true)

                                    setTimeout(() => {
                                        getUniversityList();
                                    }, 1000)
                                }}
                            />
                            {!props.isHideArrow && !isFocus ? (
                                <div className="down-arrow">
                                    <svg width="10" height="6" className="icon" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.34025 5.70097C3.30642 5.66801 3.16175 5.54355 3.04275 5.42763C2.29433 4.74797 1.06933 2.97495 0.695417 2.04696C0.635333 1.90603 0.508167 1.54972 0.5 1.35935C0.5 1.17694 0.542 1.00304 0.627167 0.837108C0.746167 0.630256 0.933417 0.46432 1.1545 0.373396C1.30792 0.314864 1.767 0.22394 1.77517 0.22394C2.27742 0.133016 3.0935 0.0830078 3.99533 0.0830078C4.85458 0.0830078 5.63742 0.133016 6.14725 0.20746C6.15542 0.215984 6.72592 0.306908 6.92133 0.406356C7.27833 0.588772 7.5 0.94508 7.5 1.32639V1.35935C7.49125 1.60769 7.26958 2.12993 7.26142 2.12993C6.88692 3.00791 5.722 4.74001 4.94792 5.43615C4.94792 5.43615 4.749 5.6322 4.62475 5.71745C4.44625 5.85042 4.22517 5.91634 4.00408 5.91634C3.75733 5.91634 3.5275 5.8419 3.34025 5.70097Z" fill="#929295" />
                                    </svg>
                                </div>
                            ) : (
                                ""
                            )}


                            {
                                isFocus && isLoadingOption && props.isDisable == false &&
                                <>
                                    {isLoading && loaaderData}
                                    {!isLoading && options.current && options.current.length > 0 &&
                                        <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                                            {options.current.map((suggestion: Options, index) => {
                                                let className;
                                                if (index === selectedIndex) {
                                                    className = "option-active";
                                                }
                                                return (
                                                    <li
                                                        className={className}
                                                        key={index}
                                                        onMouseDown={() => onSelect(suggestion)}
                                                    >
                                                        {suggestion.icon ? (
                                                            <div className="row-icon-view">
                                                                <img src={suggestion.icon} className="row-icons" />
                                                            </div>
                                                        ) : suggestion.code ? (
                                                            props.column == 3 ? (
                                                                <div className="row option">
                                                                    <div className="col-md-2 code-div"> {suggestion.code} </div>
                                                                    <div className="col-md-5">{suggestion.value}</div>
                                                                    <div className="col-md-5">{suggestion.value2}</div>
                                                                </div>
                                                            ) : (
                                                                <div className="row option">
                                                                    <div className="col-md-5 code-div"> {suggestion.code} </div>
                                                                    <div className="col-md-7">{suggestion.value}</div>
                                                                </div>
                                                            )
                                                        ) : (
                                                            <div className="option">{suggestion.value}</div>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    }
                                    {!isLoading && options.current.length == 0 && optiosnData}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>}
        </>
    );
};

OpenUniversitySelect.defaultProps = {
    placeholder: "Choose an option",
    selected: "",
    sakey: new Date().getTime(),
    type: "ARROW",
    isHideArrow: false,
    options: [],
    isDisable: false,
    isCustom: false,
};

export default OpenUniversitySelect;
