import { SET_IS_REFRESH, SET_SIGNUP_TYPE, SET_SIGNUP_DATA, USER_INFO, CALENDER_DATE } from "../action/CommonAction";
import { signupFormInterface, userInfoInterface } from "../OpenInterfaces";


export interface isRefreshState {
  isRefresh: any;
}

export interface reduxState {
  signUpType: string;
  isRefresh: boolean;
  signupData: signupFormInterface;
  user_info: userInfoInterface;
}

const initialState: reduxState = {
  signUpType: "",
  isRefresh: false,
  signupData: {} as signupFormInterface,
  user_info: {} as userInfoInterface
}


interface Action {
  type: string;
  payload?: any;
}

export const commonReducerData = (state: reduxState = initialState, action: Action) => {
  switch (action.type) {
    case SET_SIGNUP_TYPE: return { ...initialState, ...{ signUpType: action.payload } };
    case SET_IS_REFRESH: return { ...initialState, ...{ isRefresh: action.payload } };
    case SET_SIGNUP_DATA: return { ...initialState, ...{ signupData: action.payload } };
    case USER_INFO: return { ...initialState, ...{ user_info: action.payload } };
    default: return state;
  }
};

export const calendarDateData = (state: Date = new Date(), action: Action) => {
  switch (action.type) {
    case CALENDER_DATE:
      return action.payload;
    default:
      return state;
  }
};
