import { Button, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/images/open-logo.svg'
import "./signup.scss";
import { reduxState } from "../../reducer/CommonReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { useEffect } from "react";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";


const VerifyEmail = () => {
    const navigate = useNavigate();
    const commonData: reduxState = useSelector<RootState, reduxState>((state: any) => state.commonData);

    useEffect(() => {
        if (!commonData?.signupData?.email) {
            navigate("/login")
        };
        // eslint-disable-next-line
    }, [])

    const userEmail = function () {
        if (!commonData?.signupData?.email) return;
        return commonData?.signupData?.email.replace(/(.{2})(.*)(?=@)/,
            function (gp1, gp2, gp3) {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += "*";
                }
                return gp2;
            });
    };

    const onResendEmail = () => {
        WebService.postAPI({ action: "resend-email", body: { email: commonData?.signupData?.email, type: "RESEND_MAIL" }, id: "resen_btn" })
            .then((res: any) => {
                toast.success(res.message)
            })
            .catch((e) => { });
    };

    return (
        <>
            <div className="login-page">
                <Container>
                    <div className="row g-0 justify-content-center min-vh-100 align-items-center">
                        
                        <div className="col-lg-12 d-flex justify-content-center">

                            <div className="w-100 ">
                                <div className="px-lg-5  ">

                                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                        <div className="text-center">
                                            <h2 className="mb-3 font-bold font-36">Verify your email</h2>
                                            <p className=" text-secondary font-14 mb-4 text-start font-light">We have sent a verification email to  {userEmail()}.</p>
                                            <p className=" text-secondary font-14 mb-2 text-start mb-3 font-light">Didn’t receive the email? Check spam or promotion folder or click Resend email.</p>
                                        </div>
                                        <Button className="btn btn-brand-1 w-100 " id="resen_btn" onClick={() => onResendEmail()}>Resend email</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default VerifyEmail;