import { Form, Button, Row, Col } from "react-bootstrap";
import { useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { TbTrash } from "react-icons/tb";
import { Link, useBlocker, useNavigate } from "react-router-dom";
import deleteIcon from "../../assets/images/delete-icon-red.svg"
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

interface propData {
  isUpdate: Function;
  tab: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const Honours = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [honorCount, setHonorCount] = useState<any[]>([])
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "honors" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "honors" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'honors');
    } else {
      props.isFormDirty(false, 'honors');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "honors" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "honors") {
      reset({});
      HonoursData();
    }
  }, [props.tab]);

  const HonoursData = () => {
    WebService.getAPI({
      action: `get/user-honours/detail`,
    })
      .then((res: any) => {
        setValue("isReportHonours", res.isReportHonours);
        if (res.user_honours && res.user_honours.length > 0) {
          let temp: any[] = []
          res.user_honours.map((item: any, index: number) => {
            setValue(`honours_title${index + 1}`, item.honours_title);
            setValue(`grade_level${index + 1}`, item.grade_level);
            setValue(`level_of_recognition${index + 1}`, item.level_of_recognition);
            temp.push(index + 1)
          });
          setHonorCount(temp);
        }
      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    const userHonour = [];
    for (let i in honorCount) {
      const userObj = {
        honours_title: data[`honours_title${honorCount[i]}`],
        grade_level: data[`grade_level${honorCount[i]}`],
        level_of_recognition: data[`level_of_recognition${honorCount[i]}`]
      }
      userHonour.push(userObj);
    }

    const requestBody = { user_honours: userHonour, isReportHonours: data.isReportHonours };

    await WebService.postAPI({
      action: "save/user-honours/detail",
      body: requestBody,
      id: "honour-btn",
    })
      .then((res: any) => {
        props.isUpdate();
        toast.success(res.message);
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const addSection = () => {
    if (honorCount.length == 0) {
      setHonorCount([1])
    } else {
      honorCount.push(honorCount[honorCount.length - 1] + 1)
      setHonorCount([...honorCount])
    }

  };

  const removeSection = (index: number) => {
    const updatedisAnotherHonour = [...honorCount];
    updatedisAnotherHonour.splice(index, 1);
    setHonorCount(updatedisAnotherHonour);
    setValue(`honours_title${index + 1}`, "");
    setValue(`grade_level${index + 1}`, "");
    setValue(`level_of_recognition${index + 1}`, "");
  };

  const resetAllFields = () => {
    if (honorCount.length > 0) {
      for (let i = 1; i <= honorCount[honorCount.length - 1]; i++) {
        setValue(`honours_title${i}`, "");
        setValue(`grade_level${i}`, "");
        setValue(`level_of_recognition${i}`, "");
      }
    }
    setHonorCount([])
  };


  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col className="form-style-2" style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">Honors & Recognitions</h4>

            <Form.Group className="mb-4">
              <Form.Label>
                Have you received any honors or recognitions for your academic or extracurricular achievements?<span className="text-danger">*</span>
              </Form.Label>
              <Form.Check
                type="radio"
                id="YES"
                label="Yes"
                value={"YES"}
                onClick={() => honorCount.length == 0 && addSection()}
                {...register("isReportHonours", { required: false })}
              />
              <Form.Check
                type="radio"
                id="NO"
                label="No"
                value={"NO"}
                onClick={() => { resetAllFields(); }}
                {...register("isReportHonours", { required: false })}
              />
            </Form.Group>

            {watchAllFields.isReportHonours === "YES" && honorCount.map((_, index) => (
              <div key={index}>
                <Row className=" align-items-center">
                  <Col lg={11}>
                    <Form.Group className="mb-4">
                      <Form.Label>
                        Honors {index + 1} title<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className={`${watchAllFields[`honours_title${_}`] ? "border-brand" : ""}`}
                        value={watchAllFields[`honours_title${_}`]}
                        {...register(`honours_title${_}`, { required: false })}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg={1}>
                    <img src={deleteIcon} height={20} className="cursor-pointer text-danger" alt="" onClick={() => removeSection(index)} />
                  </Col>
                </Row>

                <Form.Group className="mb-4">
                  <p className="font-16">Grade level</p>
                  <Form.Check
                    type="radio"
                    id="9"
                    label="9"
                    value={"9"}
                    {...register(`grade_level${_}`, { required: false })}

                  />
                  <Form.Check
                    type="radio"
                    id="10"
                    label="10"
                    value={"10"}
                    {...register(`grade_level${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="11"
                    label="11"
                    value={"11"}
                    {...register(`grade_level${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="12"
                    label="12"
                    value={"12"}
                    {...register(`grade_level${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="Post graduate"
                    label="Post graduate"
                    value={"Post graduate"}
                    {...register(`grade_level${_}`, { required: false })}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <p className="font-16">Level(s) of recognition</p>
                  <Form.Check
                    type="radio"
                    id="School"
                    label="School"
                    value={"SCHOOL"}
                    {...register(`level_of_recognition${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="State/Regional"
                    label="State/Regional"
                    value={"STATE_REGIONAL"}
                    {...register(`level_of_recognition${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="National"
                    label="National"
                    value={"NATIONAL"}
                    {...register(`level_of_recognition${_}`, { required: false })}
                  />
                  <Form.Check
                    type="radio"
                    id="Internationsl"
                    label="International"
                    value={"INTERNATIONAL"}
                    {...register(`level_of_recognition${_}`, { required: false })}
                  />
                </Form.Group>
              </div>
            ))}

            {watchAllFields.isReportHonours === "YES" && honorCount.length <= 9 && (
              <Link onClick={addSection} className="text-link" to={""}>
                + Add another
              </Link>
            )}
            <div className="text-center mt-4">
              <Button
                type="submit"
                id="honour-btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Honours;
