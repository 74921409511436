import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const Language = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors }, } = useForm<any>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [languageOptions, setLanguageOptions] = useState<any[]>([]);
  const array = [1, 2, 3, 4, 5];
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "language" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "language" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'language');
    } else {
      props.isFormDirty(false, 'language');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "language" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "language") {
      reset({});
      setValue("no_of_language", 0);
      getLanguageList();
      getLanguageData();
    }
  }, [props.tab]);

  const getLanguageList = () => {
    WebService.getAPI({
      action: `languages`,
    }).then((res: any) => {
      setLanguageOptions(
        res.list.map((item: any) => {
          return { ...item, ...{ value: item.language } };
        })
      );
    });
  };

  const getLanguageData = () => {
    WebService.getAPI({
      action: `user-language/detail`
    }).then((res: any) => {
      if (res.user_languages && res.user_languages.length > 0) {
        setValue("no_of_language", res.user_languages.length);
        res.user_languages.map((item: any, i: number) => {
          setValue(`language_id${i + 1}`, item.language_id)
          setValue(`language_proficiency${i + 1}`, item.language_proficiency)
        })
      } else {
        setValue("no_of_language", 0);
      }
    }).catch((e) => { });
  };

  const onSave = async (data: any) => {
    const userLanguages = [];
    for (let i in array) {
      if (array[i] <= watchAllFields.no_of_language) {
        const languageId = data[`language_id${array[i]}`];
        const languageProficiency = data[`language_proficiency${array[i]}`];
        userLanguages.push({ language_id: languageId, language_proficiency: languageProficiency });
      }
    }

    const requestBody = { user_languages: userLanguages };

    await WebService.postAPI({
      action: "save/user-language",
      body: requestBody,
      id: "lan_save-btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const resetOtherFields = (id: number) => {
    array.map((item: any) => {
      if (item > id) {
        setValue(`language_id${item}`, "")
        setValue(`language_proficiency${item}`, "")
      }
    })
  }


  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <h4 className="font-medium font-20 mb-3 pb-1">Language(s) I understand</h4>

            <Form.Group className="mb-4">
              <p className="font-16">
                How many languages do you have some degree of communication skills?<span className="text-danger">*</span>
              </p>
              <Form.Check
                type="radio"
                id="1"
                label="1"
                value={"1"}
                checked={watchAllFields.no_of_language == "1" ? true : false}
                {...register("no_of_language", { required: false })}
                onClick={() => resetOtherFields(1)}
              />
              <Form.Check
                type="radio"
                id="2"
                label="2"
                value={"2"}
                checked={watchAllFields.no_of_language == "2" ? true : false}
                {...register("no_of_language", { required: false })}
                onClick={() => resetOtherFields(2)}
              />
              <Form.Check
                type="radio"
                id="3"
                label="3"
                value={"3"}
                checked={watchAllFields.no_of_language == "3" ? true : false}
                {...register("no_of_language", { required: false })}
                onClick={() => resetOtherFields(3)}
              />
              <Form.Check
                type="radio"
                id="4"
                label="4"
                checked={watchAllFields.no_of_language == "4" ? true : false}
                value={"4"}
                {...register("no_of_language", { required: false })}
                onClick={() => resetOtherFields(4)}
              />
              <Form.Check
                type="radio"
                id="5"
                label="5"
                value={"5"}
                checked={watchAllFields.no_of_language == "5" ? true : false}
                {...register("no_of_language", { required: false })}
                onClick={() => resetOtherFields(5)}
              />
            </Form.Group>

            {array.map((_, index) => {
              if (_ <= watchAllFields.no_of_language) {
                return (
                  <div key={index}>
                    <Form.Group className="mb-4">
                      <Form.Label> Select language<span className="text-danger">*</span></Form.Label>
                      <Controller
                        control={control}
                        name={`language_id${_}`}
                        rules={{ required: false }}
                        render={({ field }: any) => (
                          <>
                            <div className="react-select1">
                              <OpenSelect
                                sakey={`language_id${_}`}
                                isSearchable={true}
                                placeholder="Choose an option"
                                options={languageOptions}
                                selected={watchAllFields[`language_id${_}`]}
                                onChange={(e: any) => {
                                  field.onChange(e.id);
                                }}
                              />
                            </div>
                          </>
                        )}
                      />
                      {errors[`language_id${_}`] && (
                        <div className="login-error">
                          <Label
                            title={"Please Select Languages."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <p className="font-16">
                        Language proficiency<span className="text-danger">*</span>
                      </p>
                      <Form.Check
                        type="checkbox"
                        id={`FirstLanguage${_}`}
                        label="First language"
                        value={`FIRST_LANGUAGE`}
                        {...register(`language_proficiency${_}`, { required: false })}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`Speak${_}`}
                        label="Speak"
                        value={`SPEAK`}
                        {...register(`language_proficiency${_}`, { required: false })}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`Read${_}`}
                        label="Read"
                        value={`READ`}
                        {...register(`language_proficiency${_}`, { required: false })}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`Write${_}`}
                        label="Write"
                        value={`WRITE`}
                        {...register(`language_proficiency${_}`, { required: false })}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`SpokenAtHome${_}`}
                        label="Spoken at home"
                        value={`SPOKEN_HOME`}
                        {...register(`language_proficiency${_}`, { required: false })}
                      />
                      {errors[`language_proficiency${_}`] && (
                        <div className="login-error">
                          <Label
                            title={"Please Select Language Proficiency."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </div>
                );
              }
            })}

            <div className="text-center">
              <Button
                type="submit"
                id="lan_save-btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Language;



