import { Card, Nav } from "react-bootstrap"
import { FaCheck } from "react-icons/fa6";
import UserIcon from "../../../assets/images/user-icon.svg"
import iconHome from "../../../assets/images/icon-home.svg"
import iconCall from "../../../assets/images/icon-call.svg"
import iconBgInfo from "../../../assets/images/icon-chart.svg"
import iconFaq from "../../../assets/images/icon-faq.svg"
import iconEarth from "../../../assets/images/icon-earth.svg"
import { useEffect, useState } from "react";

interface propData {
    activeTab: string;
    changeTab: Function;
    status: any;
}


const NavTabs = (props: propData) => {
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompletePersonalInformation": false,
        "isCompleteAddress": false,
        "isCompleteContactDetail": false,
        "isCompleteDemographics": false,
        "isCompleteLanguage": false,
        "isCompleteGeography": false
    });

    useEffect(() => {
        setPageStatus(props.status);
    }, [props.status]);

    const complitionStatus = (status: any) => {
        if (!status) return <></>;
        if (status == "COMPLETED") {
            return <FaCheck size={16} className="text-brand" />
        } else if (status == "INPROGRESS") {
            return <span className="font-12 text-secondary font-light">In progress</span>
        } else if (status == "NOT_STARTED") {
            return <span className="font-12 text-secondary font-light">Not started</span>
        }
    }

    return (
        <>
            <Card className="tab-nav-card-shadow rounded-start-0 h-100" style={{ backgroundColor: "#F9F9F9" }}>
                <Nav variant="pills" className="flex-column tab-style-2">
                    <Nav.Item onClick={() => { props.activeTab != "basics" && props.changeTab("basics"); }}>
                        <Nav.Link eventKey="basics" className=" rounded-top-4 rounded-xs-0 rounded-start-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={UserIcon} className="icon" alt="" /> The basics</span>
                                {complitionStatus(pageStatus.isCompletePersonalInformation)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "address" && props.changeTab("address"); }}>
                        <Nav.Link eventKey="address">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconHome} className="icon" alt="" /> Address</span>
                                {complitionStatus(pageStatus.isCompleteAddress)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "contact" && props.changeTab("contact"); }}>
                        <Nav.Link eventKey="contact">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconCall} className="icon" alt="" /> Contact info</span>
                                {complitionStatus(pageStatus.isCompleteContactDetail)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "background" && props.changeTab("background"); }}>
                        <Nav.Link eventKey="background">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconBgInfo} className="icon" alt="" /> Background information</span>
                                {complitionStatus(pageStatus.isCompleteDemographics)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "language" && props.changeTab("language"); }}>
                        <Nav.Link eventKey="language">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconFaq} className="icon" alt="" />Language(s) I understand</span>
                                {complitionStatus(pageStatus.isCompleteLanguage)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => { props.activeTab != "location" && props.changeTab("location"); }}>
                        <Nav.Link eventKey="location">
                            <div className="d-flex justify-content-between align-items-center">
                                <span><img src={iconEarth} className="icon" alt="" />Location and Citizenship</span>
                                {complitionStatus(pageStatus.isCompleteGeography)}
                            </div>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card>
        </>
    )
}
export default NavTabs;
