import { Form, Button, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenUniversitySelect from "../Common/OpenTagSelect/OpenUniversitySelect";
import OpenDatePicker from "../Common/OpenDatePicker/OpenDatePicker";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";

interface propData {
  isUpdate: Function;
  tab: string;
  type: any;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const OtherCollege = (props: propData) => {
  const navigate = useNavigate();
  const [array, setArray] = useState<any[]>([{ school_college_id: "", college_from_date: "", college_to_date: "", college_course_detail: [], college_degree_earned: "" }, { school_college_id: "", college_from_date: "", college_to_date: "", college_course_detail: [], college_degree_earned: "" }, { school_college_id: "", college_from_date: "", college_to_date: "", college_course_detail: [], college_degree_earned: "" }]);
  const [takenCourse, setTakenCourse] = useState<number>(-1);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "courses" && isChange && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "courses" && isChange) {
      props.isFormDirty(true, 'courses');
    } else {
      props.isFormDirty(false, 'courses');
    }
  }, [isChange]);

  useEffect(() => {
    if (props.tab == "courses" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "courses") {
      getOtherSchoolData();
    }
  }, [props.tab]);

  const getOtherSchoolData = () => {
    WebService.getAPI({
      action: `get/user/other-school-college/details?type=${props.type}`,
    })
      .then((res: any) => {
        setTakenCourse(res.no_of_college);
        if (
          res.user_other_school_college &&
          res.user_other_school_college.length > 0
        ) {
          res.user_other_school_college.map((item: any, index: number) => {
            array[index].school_college_id = item.school_college_id;
            array[index].college_from_date = item.college_from_date;
            array[index].college_to_date = item.college_to_date;
            array[index].college_course_detail = item.college_course_detail;
            array[index].college_degree_earned = item.college_degree_earned;
          })
          setArray([...array])
        }
      })
      .catch((e) => { });
  };

  const onSave = async () => {
    const userOtherCollege = [];
    for (let i in array) {
      if (Number(i) < Number(takenCourse)) {
        const SchoolCollegeID = array[i].school_college_id;
        const CollegeFromDate = array[i].college_from_date;
        const CollegeToDate = array[i].college_to_date;
        const CollegeCourseDetail = array[i].college_course_detail;
        const DegreeEarned = array[i].college_degree_earned;
        userOtherCollege.push({
          school_college_id: SchoolCollegeID,
          college_from_date: CollegeFromDate,
          college_to_date: CollegeToDate,
          college_course_detail: CollegeCourseDetail,
          college_degree_earned: DegreeEarned,
        });
      }
    }

    const requestBody = { user_other_school_college: userOtherCollege, no_of_college: takenCourse };

    await WebService.postAPI({
      action: `save/user/other-school-college/details?type=${props.type}`,
      body: requestBody,
      id: "OtherCollege-btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        setIsChange(false);
      })
      .catch((e: any) => { });
  };

  const onChangeCoursedetails = (type: string, index: number) => {
    if (array[index].college_course_detail && array[index].college_course_detail.length) {
      let temp: any[] = [];
      let isExist: boolean = false;
      array[index].college_course_detail.map((item: any) => {
        if (item == type) {
          isExist = true;
        } else {
          temp.push(item)
        }
      });
      if (!isExist) {
        temp.push(type)
      }
      array[index].college_course_detail = [...temp];
    } else {
      array[index].college_course_detail = [type];
    }
    setArray([...array])
  };

  const isChecked = (type: string, index: number) => {
    let isExist: boolean = false;
    if (array[index].college_course_detail && array[index].college_course_detail.length) {
      array[index].college_course_detail.map((item: any) => {
        if (item == type) {
          isExist = true;
        }
      });
    }
    return isExist;
  };


  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave();
          WebService.removeLoader('Save_and_exit_btn');
        }
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <Row>
        <Col className="form-style-2" style={{ maxWidth: '624px' }}>
          <h4 className="font-medium font-20 mb-3 pb-1">Courses at Colleges or Universities</h4>
          <Form.Group className="mb-4">
            <p className="font-16">
              Have you taken courses at a college or university? If so, how many colleges?<span className="text-danger">*</span>
            </p>
            <Form.Check
              type="radio"
              id="0"
              label="0"
              value={0}
              checked={takenCourse == 0 ? true : false}
              onClick={() => { setIsChange(true); setTakenCourse(0) }}
            />
            <Form.Check
              type="radio"
              id="1"
              label="1"
              value={1}
              checked={
                takenCourse == 1 ? true : false
              }
              onClick={() => { setIsChange(true); setTakenCourse(1) }}
            />
            <Form.Check
              type="radio"
              id="2"
              label="2"
              value={2}
              checked={
                takenCourse == 2 ? true : false
              }
              onClick={() => { setIsChange(true); setTakenCourse(2) }}
            />
            <Form.Check
              type="radio"
              id="3"
              label="3"
              value={3}
              checked={
                takenCourse == 3 ? true : false
              }
              onClick={() => { setIsChange(true); setTakenCourse(3) }}
            />
          </Form.Group>

          {array.map((_: any, index: number) => {
            return (
              index < takenCourse &&
              <div key={`${new Date().getTime()}_${index}`}>
                <Form.Group className="mb-4">
                  <Form.Label>
                    College {index + 1}<span className="text-danger">*</span>
                  </Form.Label>
                  <OpenUniversitySelect
                    type="UNIVERSITY"
                    selected={_.school_college_id}
                    placeholder="Choose an option"
                    onChange={(e: any) => { setIsChange(true); array[index].school_college_id = e.id; setArray([...array]) }}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <p className="font-16">Course details<span className="text-danger">*</span></p>
                  <Form.Check
                    type="checkbox"
                    id="DualEnrollment"
                    label="Dual enrollment with high school"
                    value={"DUAL_ENROLLMENT"}
                    checked={isChecked("DUAL_ENROLLMENT", index)}
                    onChange={(e: any) => { setIsChange(true); onChangeCoursedetails(e.target.value, index); }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="SummerProgram"
                    label="Summer program"
                    value={"SUMMER_PROGRAM"}
                    checked={isChecked("SUMMER_PROGRAM", index)}
                    onChange={(e: any) => { setIsChange(true); onChangeCoursedetails(e.target.value, index); }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="CreditAwarded"
                    label="Credit awarded directly by college"
                    value={"CREDIT_AWARDED"}
                    checked={isChecked("CREDIT_AWARDED", index)}
                    onChange={(e: any) => { setIsChange(true); onChangeCoursedetails(e.target.value, index); }}
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    College {index + 1} from date<span className="text-danger">*</span>
                  </Form.Label>
                  <OpenDatePicker
                    type="date"
                    selected={_.college_from_date}
                    maxData={new Date()}
                    onChange={(e: any) => { setIsChange(true); array[index].college_from_date = e; array[index].college_to_date = ""; setArray([...array]) }}
                  />
                  <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    College {index + 1} to date<span className="text-danger">*</span>
                  </Form.Label>
                  <OpenDatePicker
                    type="date"
                    selected={_.college_to_date}
                    // maxData={new Date()}
                    minData={new Date(_.college_from_date)}
                    onChange={(e: any) => { setIsChange(true); array[index].college_to_date = e; setArray([...array]) }}
                  />
                  <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                </Form.Group>

                <Form.Group className="mb-4">
                  <p className="font-16">Degree earned</p>
                  <Form.Check
                    type="radio"
                    id="AA"
                    label="AA"
                    value={"AA"}
                    name="college_degree_earned"
                    checked={_.college_degree_earned == "AA" ? true : false}
                    onChange={(e: any) => { setIsChange(true); array[index].college_degree_earned = e.target.value; setArray([...array]) }}
                  />
                  <Form.Check
                    type="radio"
                    id="AS"
                    label="AS"
                    value={"AS"}
                    name="college_degree_earned"
                    checked={_.college_degree_earned == "AS" ? true : false}
                    onChange={(e: any) => { setIsChange(true); array[index].college_degree_earned = e.target.value; setArray([...array]) }}
                  />
                  <Form.Check
                    type="radio"
                    id="BA"
                    label="BA"
                    value={"BA"}
                    name="college_degree_earned"
                    checked={_.college_degree_earned == "BA" ? true : false}
                    onChange={(e: any) => { setIsChange(true); array[index].college_degree_earned = e.target.value; setArray([...array]) }}
                  />
                  <Form.Check
                    type="radio"
                    id="BS"
                    label="BS"
                    value={"BS"}
                    name="college_degree_earned"
                    checked={_.college_degree_earned == "BS" ? true : false}
                    onChange={(e: any) => { setIsChange(true); array[index].college_degree_earned = e.target.value; setArray([...array]) }}
                  />
                </Form.Group>
              </div>
            );
          })}


          <div className="text-center">
            <Button
              type="button"
              onClick={() => onSave()}
              id="OtherCollege-btn"
              className="btn btn-brand-1 w-100"
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default OtherCollege;





