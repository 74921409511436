import { Button, Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/open-logo.svg'
import "./signup.scss";
import { reduxState } from "../../reducer/CommonReducer";
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { useEffect } from "react";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";


const CheckEmail = () => {
    const navigate = useNavigate();
    const commonData: reduxState = useSelector<RootState, reduxState>((state: any) => state.commonData);

    useEffect(() => {
        if (!commonData?.signupData?.email) {
            navigate("/login")
        }
         // eslint-disable-next-line
    }, []);

    const userEmail = function () {
        if (!commonData?.signupData?.email) return;
        return commonData?.signupData?.email.replace(/(.{2})(.*)(?=@)/,
            function (gp1, gp2, gp3) {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += "*";
                }
                return gp2;
            });
    };

    const onResendEmail = () => {
        WebService.postAPI({ action: "resend-email", body: { email: commonData?.signupData?.email,type:"RESEND_FORGOT_EMAIL" }, id: "resen_btn" })
            .then((res: any) => {
                toast.success(res.message)
            })
            .catch((e) => { });
    };

    return (
        <>
            <div className="login-page">
                <Container>
                    <div className="row g-0 justify-content-center align-items-center min-vh-100">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center">
                            <div className="  w-100">
                                <div className="px-lg-5  ">
                                    
                                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow">
                                        <div className="text-center">
                                            <h2 className="mb-3 font-bold">Check your email</h2>
                                            <p className=" text-secondary font-14 mb-4 text-start">We have sent an email with password reset information to {userEmail()}.</p>
                                            <p className=" text-secondary font-14 mb-2 text-start mb-3">Didn’t receive the email? Check spam or promotion folder or</p>
                                        </div>
                                        <Button className="btn btn-brand-1 w-100 mb-3" id="resen_btn" onClick={() => onResendEmail()}>Resend email</Button>
                                        <Link to="/login" className="btn btn-outline-brand w-100">Back to log in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default CheckEmail;