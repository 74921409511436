import loader from "../../assets/images/loader.gif";

const CardLoader = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <img
                style={{ position: "relative" }}
                src={loader}
                alt="No loader found"
            />
            <div style={{ position: "relative", color: "black" }}>
                Loading...
            </div>
        </div>
    )
}
export default CardLoader;