// 


import { Button, Card, Col, Form, InputGroup, Modal, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import logo from "../../assets/images/login-logo.svg";
import { CiSearch } from "react-icons/ci";
import DocIcon from '../../assets/images/doc-icon.svg';
import { IoClose } from "react-icons/io5";
import UploadFile from "../../components/Common/UploadFile/UploadFile";
import PDFFile from '../../assets/images/pdf.png';
import { Controller, set, useForm } from "react-hook-form";
import { Label } from "../../components/Common/Label/Label";
import OpenDatePicker from "../../components/Common/OpenDatePicker/OpenDatePicker";
import StarRatings from "react-star-ratings";
import { Rating } from 'react-simple-star-rating'


interface PropData {
    show: boolean;
    isClose: Function;
    id: string;
    name: string;
};

const modalTitleData = [
    { id: 1, value: "How are you connected to {{$}}?", isUni: true },
    { id: 2, value: "What type of student are/were you?" },
    { id: 3, value: "Did you take any online courses?" },
    { id: 4, value: "How would you rate your overall experience at {{$}}?", isUni: true },
    { id: 5, value: "Rate your experience within each of these categories" },
    { id: 6, value: "Rate your experience within each of these categories" },
    { id: 7, value: "Tell us about your overall experience with {{$}}?", isUni: true },
    { id: 8, value: "How would you rate your return on investment?" }
];

const ReviewUniversity = (props: PropData) => {
    const [reviewStatus, setReviewStatus] = useState<number>(1);
    const [howConnect, setHowConnect] = useState<string>('');
    const [studentType, setStudentType] = useState<string>('');
    const [isOnlineCourse, setIsOnlineCourse] = useState<string>('');
    const [overallRate, setOverallRate] = useState<number>(-1);
    const [academicRate, setAcademicRate] = useState<number>(-1);
    const [proffesorRate, setProffesorRate] = useState<number>(-1);
    const [diversityRate, setDiversityRate] = useState<number>(-1);
    const [studentListRate, setStudentListRate] = useState<number>(-1);
    const [housingRate, setHousingRate] = useState<number>(-1);
    const [saftyRate, setSaftyRate] = useState<number>(-1);
    const [campusLifeRate, setCampusLifeRate] = useState<number>(-1);
    const [campusFoodRate, setCampusFoodRate] = useState<number>(-1);
    const [athleticsRate, setAthleticsRate] = useState<number>(-1);
    const [partySceneRate, setPartySceneRate] = useState<number>(-1);
    const [localAreaRate, setLocalAreaRate] = useState<number>(-1);
    const [roi, setROIRate] = useState<number>(-1);
    const [about, setAbout] = useState<string>('');

    useEffect(() => {
        if (props.show) {
            resetReview();
        }
    }, [props.show])

    const closeClick = (type?: boolean) => {
        resetReview();
        props.isClose(type);
    };

    const resetReview = () => {
        setReviewStatus(1);
        setHowConnect('');
        setStudentType('');
        setIsOnlineCourse('');
        setOverallRate(-1);
        setAcademicRate(-1);
        setProffesorRate(-1);
        setDiversityRate(-1);
        setStudentListRate(-1);
        setHousingRate(-1);
        setSaftyRate(-1);
        setCampusLifeRate(-1);
        setCampusFoodRate(-1);
        setAthleticsRate(-1);
        setLocalAreaRate(-1);
        setPartySceneRate(-1);
        setROIRate(-1);
        setAbout("");
    };

    const handleButton1 = () => {
        if (campusLifeRate > -1 && campusFoodRate > -1 && athleticsRate > -1 && partySceneRate > -1 && localAreaRate > -1) {
            return false;
        }
        return true;
    };

    const handleButton = () => {
        if (academicRate > -1 && proffesorRate > -1 && diversityRate > -1 && studentListRate > -1 && housingRate > -1 && saftyRate > -1) {
            return false;
        }
        return true;
    };

    const onSubmitReview = () => {
        WebService.postAPI({
            action: `add/university-review`,
            body: {
                university_id: props.id,
                how_connect: howConnect,
                student_type: studentType,
                online_course: isOnlineCourse,
                overall_rate: overallRate,
                academics_rate: academicRate,
                professors_rate: proffesorRate,
                diversity_rate: diversityRate,
                student_life_rate: studentListRate,
                housing_rate: housingRate,
                safety_rate: saftyRate,
                campus_life_rate: campusLifeRate,
                campus_food_rate: campusFoodRate,
                athletics_rate: athleticsRate,
                party_scene_rate: partySceneRate,
                local_area_rate: localAreaRate,
                roi_rate: roi,
                about: about
            },
            id: 'review_university_btn'
        })
            .then((res: any) => {
                toast.success(res.message);
                closeClick(true);
            })
            .catch(() => { closeClick(true); });
    };

    const getTitle = () => {
        let title = '';
        modalTitleData.map((res: any) => {
            if (res.id == reviewStatus) {
                title = res.value.replace('{{$}}', props.name);
            }
        });
        return title;
    };

    return (
        <Modal show={props.show} centered onHide={() => closeClick()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="font-24">{getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {reviewStatus == 1 &&
                    <>
                        <Form className="form-style-2">
                            <ToggleButtonGroup className="select-option-btns btn-l square font-16 row row-cols-2 w-100 gap-2 justify-content-between mx-auto" type="radio" name="Pennsylvania" onChange={(e: any) => setHowConnect(e)} value={howConnect}>

                                <ToggleButton id="Student-here" value={'STUDENT_HERE'} checked={howConnect == 'STUDENT_HERE' ? true : false} className="col-6 mb-2" style={{ maxWidth: "49%" }}>Student here</ToggleButton>

                                <ToggleButton id="work-here" value={'WORK_HERE'} checked={howConnect == 'WORK_HERE' ? true : false} className="col-6 mb-2" style={{ maxWidth: "49%" }}>Work here</ToggleButton>

                                <ToggleButton id="Alum" value={'ALUM'} checked={howConnect == 'ALUM' ? true : false} className="col-6" style={{ maxWidth: "49%" }}>Alum</ToggleButton>

                                <ToggleButton id="Other" value={'OTHER'} checked={howConnect == 'OTHERF' ? true : false} className="col-6" style={{ maxWidth: "49%" }}>Other</ToggleButton>
                            </ToggleButtonGroup>
                            <div className="mt-5">
                                <Button className="btn-brand-1 w-100 mb-2" disabled={howConnect ? false : true} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 2 &&
                    <>
                        <Form action="" className="form-style-2">
                            <div className="btn-group select-option-btns btn-l square font-16 row gap-0 w-100 gx-2" role="group">
                                <div className="col-lg-4 mb-lg-0 mb-3">
                                    <input type="radio" className="btn-check" name="StudentType" value='FULL_TIME' checked={studentType == 'FULL_TIME' ? true : false} id="Fulltime" onChange={(e: any) => setStudentType(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100" htmlFor="Fulltime">Full time</label>
                                </div>
                                <div className="col-lg-4 my-lg-0 my-3">
                                    <input type="radio" className="btn-check" name="StudentType" id="Parttime" value='PART_TIME' checked={studentType == 'PART_TIME' ? true : false} onChange={(e: any) => setStudentType(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100 px-2" htmlFor="Parttime">Part time/evening/weekends</label>
                                </div>
                                <div className="col-lg-4">
                                    <input type="radio" className="btn-check" name="StudentType" id="ProspectiveStd" value='PROSPECTIVE_STUDENT' checked={studentType == 'PROSPECTIVE_STUDENT' ? true : false} onChange={(e: any) => setStudentType(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100" htmlFor="ProspectiveStd">Prospective student</label>
                                </div>
                            </div>
                            <div className="mt-5 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={studentType ? false : true} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 3 &&
                    <>
                        <Form action="" className="form-style-2">
                            <div className="btn-group select-option-btns btn-l square font-16 row gap-0 w-100 gx-2" role="group">
                                <div className="col-lg-4">
                                    <input type="radio" className="btn-check" name="onlineCourses" id="yesAll" value='YES_ALL' checked={isOnlineCourse == 'YES_ALL' ? true : false} onChange={(e: any) => setIsOnlineCourse(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100" htmlFor="yesAll">Yes, all online</label>
                                </div>
                                <div className="col-lg-4 my-lg-0 my-3">
                                    <input type="radio" className="btn-check" name="onlineCourses" id="SomeOnline" value='SOME' checked={isOnlineCourse == 'SOME' ? true : false} onChange={(e: any) => setIsOnlineCourse(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100 px-2" htmlFor="SomeOnline">Some online</label>
                                </div>
                                <div className="col-lg-4">
                                    <input type="radio" className="btn-check" name="onlineCourses" id="None" value='NONE' checked={isOnlineCourse == 'NONE' ? true : false} onChange={(e: any) => setIsOnlineCourse(e.target.value)} />
                                    <label className="btn btn-outline-primary w-100" htmlFor="None">None</label>
                                </div>
                            </div>
                            <div className="mt-5 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={isOnlineCourse ? false : true} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 4 &&
                    <>
                        <Form action="" className="form-style-2">
                            <div className="d-flex justify-content-center my-4">
                                <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setOverallRate(rate); }} initialValue={overallRate > -1 ? overallRate : 0} />
                            </div>
                            <div className="mt-5 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={overallRate > -1 ? false : true} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 5 &&
                    <>
                        <Form action="" className="form-style-2">
                            <Row className=" mt-4">
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Academics</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setAcademicRate(rate); }} initialValue={academicRate > -1 ? academicRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Professors</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setProffesorRate(rate); }} initialValue={proffesorRate > -1 ? proffesorRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Diversity</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setDiversityRate(rate); }} initialValue={diversityRate > -1 ? diversityRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Student life</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setStudentListRate(rate); }} initialValue={studentListRate > -1 ? studentListRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Housing</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setHousingRate(rate); }} initialValue={housingRate > -1 ? housingRate : 0} />
                                </Col>
                                <Col lg={4} >
                                    <p className="font-16 font-light mb-2">Safety</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setSaftyRate(rate); }} initialValue={saftyRate > -1 ? saftyRate : 0} />
                                </Col>
                            </Row>
                            <div className="mt-5 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={handleButton()} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 6 &&
                    <>
                        <Form action="" className="form-style-2">
                            <Row className=" mt-4 justify-content-center">
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Campus life</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setCampusLifeRate(rate); }} initialValue={campusLifeRate > -1 ? campusLifeRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Campus food</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setCampusFoodRate(rate); }} initialValue={campusFoodRate > -1 ? campusFoodRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Athletics</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setAthleticsRate(rate); }} initialValue={athleticsRate > -1 ? athleticsRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Party scene</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setPartySceneRate(rate); }} initialValue={partySceneRate > -1 ? partySceneRate : 0} />
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <p className="font-16 font-light mb-2">Local area</p>
                                    <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setLocalAreaRate(rate); }} initialValue={localAreaRate > -1 ? localAreaRate : 0} />
                                </Col>

                            </Row>
                            <div className="mt-5 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={handleButton1()} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 7 &&
                    <>
                        <Form className="form-style-2">
                            <textarea className="form-control h-auto mb-2" name="" id="" rows={10} value={about} onChange={(e: any) => setAbout(e.target.value)}></textarea>
                            <div className="d-flex justify-content-between">
                                <span className="font-12 text-secondary">Min: 10 words / Max: 200 words</span>
                                <span className="font-12 text-secondary">{about ? about.split(" ").length : 0}/200 words</span>
                            </div>
                            {(about && (about.split(" ").length > 200 || about.split(" ").length < 11)) && (
                                <Label title={"Please enter min. 10 and max. 200 words"} modeError={true} />
                            )}
                            <div className="mt-4 gap-3 d-flex">
                                <Button className="btn-outline-brand w-100" onClick={() => { setReviewStatus(reviewStatus - 1) }}>Back</Button>
                                <Button className="btn-brand-1 w-100 mb-2" disabled={(!about || (about && (about.split(" ").length > 200 || about.split(" ").length < 11))) ? true : false} onClick={() => { setReviewStatus(reviewStatus + 1) }}>Next</Button>
                            </div>
                        </Form>
                    </>}
                {reviewStatus == 8 &&
                    <>
                        <Form className="form-style-2">
                            <div className="d-flex justify-content-center my-4">
                                <Rating allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { setROIRate(rate); }} initialValue={roi > -1 ? roi : 0} />
                            </div>
                            <div className="mt-5">
                                <Button className="btn-brand-1 w-100 mb-2" disabled={roi > -1 ? false : true} id='review_university_btn' onClick={() => { onSubmitReview() }}>Complete</Button>
                            </div>
                        </Form>
                    </>}
            </Modal.Body>
        </Modal>
    );
};

export default ReviewUniversity;
