// OpenTagSelect

import "./OpenTagSelect.scss";
import React, { useState, Fragment, useEffect } from "react";
import useClickOutside from "../../../hook/useClickOutside";
import { BiCheck, BiChevronDown } from "react-icons/bi";
import HelperService from "../../../Services/HelperService";
import { IoCloseOutline } from "react-icons/io5";
import loader from "../../../assets/images/loader.gif";


interface PropData {
    placeholder?: string;
    options: Options[];
    selected: any[];
    onChange: any;
    isDisable?: boolean;
    isHideArrow?: boolean;
    sakey?: string;
    selectLimit?: number
    max?: number;
    onCustomAdd?: Function;
    updateOption?: Function;
    isCustom?: boolean;
    isLoading?: boolean;
    allowAllLeters?: boolean;
}

export interface Options {
    id: any;
    IconPath?: string;
    value: string;
    code?: string;
    object?: any;
    isSelected?: boolean;
    isDisabled?: boolean;
}

const OpenTagSelect = (props: PropData) => {
    let textInput = React.createRef<HTMLInputElement>();
    const [options, setOptions] = useState<Options[]>(props.options);
    const [isFocus, setIsFocus] = useState(false);
    const [isOpenTop, setIsOpenTop] = useState(false);
    const [userInput, setUserInput] = useState(props.placeholder);
    const [search, setSearch] = useState("");
    const [selectedOption, setSelectedOption] = useState(props.selected);
    let index = -1;
    const [selectedIndex, setSelectedIndex] = useState(index);
    const nameRegex = /^[A-Za-z#][A-Za-z_ ]{1,100}$/;

    useEffect(() => {
        let selectedValue = "";
        let tempOption: Options[] = [];
        props.options.map((option) => {
            var obj = { ...option };
            props.selected && props.selected.map((select) => {
                if (option.id === select.id) {
                    obj.isDisabled = false;
                    if (!selectedValue) {
                        selectedValue = option.value;
                    }
                    obj.isSelected = true;
                }
            });
            let limit: any = props.selectLimit ? props.selectLimit : props.options.length
            if (!obj.isSelected && props.selected.length >= limit) {
                obj.isDisabled = true;
            }

            tempOption.push(obj);
        });

        if (selectedValue) {
            setUserInput(
                selectedValue +
                (props.selected.length > 1
                    ? " ...(" + props.selected.length + ")"
                    : "")
            );
        } else {
            setUserInput(props.placeholder);
        }
        setOptions(tempOption);
    }, [props.selected, props.options]);

    let domNode = useClickOutside(() => {
        setIsFocus(false);
    }, this);

    const checkPossition = () => {
        var topHeight = domNode.current.getBoundingClientRect().y;
        var bottomHeight =
            window.innerHeight - domNode.current.getBoundingClientRect().y;
        if (bottomHeight > 300 || topHeight <= 280) {
            setIsOpenTop(false);
        } else {
            setIsOpenTop(true);
        }
    };

    let optionsListComponent;

    let searchOption: Options[] = search
        ? options.filter(function (option) {
            return option.value.toLowerCase().startsWith(search.toLowerCase());
        }) : options;

    if (searchOption.length) {
        optionsListComponent = (
            <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                {searchOption.map((suggestion: Options, index) => {
                    return (
                        <li
                            className={
                                (suggestion.isSelected ? "option-active" : "") +
                                " " +
                                (suggestion.isDisabled ? "option-disabled" : "")
                            }
                            key={index}
                            onMouseDown={() => onSelect(suggestion)}
                        >
                            <div className="row-icon-view">
                                {suggestion.code && <div className="option pe-1" style={{ width: "62px" }}> {suggestion.code} </div>}
                                <div className="option ">{suggestion.value}</div>
                                {suggestion.isSelected ? (
                                    <BiCheck
                                        color="#fff"
                                        style={{ width: 40, height: 30 }}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    } else if (props.allowAllLeters ? search.trim() : nameRegex.test(search)) {
        const optiosnData = props.isCustom && props.selected.length != props.selectLimit ?
            <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                <li onMouseDown={() => { onAddNewTag() }} >
                    <div className="row-icon-view">
                        <div className="option">Add {" "} {search}</div>
                    </div>
                </li>
            </ul>
            : <div className="no-options">
                <em>No search result found</em>
            </div>;
        const loaaderData = <div className="no-suggestions">
            <div style={{ textAlign: "center" }}>
                <img
                    style={{ position: "relative" }}
                    src={loader}
                    alt="No loader found"
                />
                <div style={{ position: "relative", color: "black" }}>
                    Loading...
                </div>
            </div>
        </div>
        optionsListComponent = props.isLoading ? loaaderData : optiosnData;
    };



    const onSelect = (e: Options) => {
        let selectedValue: string = "";
        let tempSelectedOption: Options[] = [];
        var count = 0;
        options.map((option: Options) => {
            if (option.id == e.id) {
                if (!option.isSelected) {
                    tempSelectedOption.push(option);
                    if (!selectedValue) {
                        selectedValue = option.value;
                    }
                    count++;
                }
            } else {
                if (option.isSelected) {
                    tempSelectedOption.push(option);
                    if (!selectedValue) {
                        selectedValue = option.value;
                    }
                    count++;
                }
            }
        });

        if (selectedValue) {
            setUserInput(selectedValue + (count > 1 ? " ...(" + count + ")" : ""));
        } else {
            setUserInput(props.placeholder);
        }

        let isNewOption: Boolean = false;

        setOptions(
            options.filter((option: any) => {
                if (option.id === e.id && option.isNew) {
                    isNewOption = true;
                }
                if (option.id === e.id && !option.isNew) {
                    return { ...option, isSelected: !option.isSelected, }
                } else if (!option.isNew) {
                    return {
                        ...option, isDisabled: !option.isSelected && count >= (props.selectLimit ? props.selectLimit : 3) ? true : false,
                    }
                }
            })
        );

        if (isNewOption) {
            props.updateOption && props.updateOption(e.id)
        }

        if (props.onChange) {
            props.onChange(tempSelectedOption);
        }
    };

    const checkOption = (enterValue: any) => {
        // setIsFocus(false);
        var isFound = false;
        options.map((value: Options, i: number) => {
            if (value.id === enterValue || value.value === enterValue) {
                setUserInput(enterValue);
                setSelectedOption(enterValue);
                onSelect(value);
                props.onChange(value);
                isFound = true;
            }
        });

        // if (enterValue && !isFound) {
        //     setUserInput(enterValue);
        //     setSelectedOption(enterValue);
        //     var temp: any = { id: enterValue, value: enterValue };
        //     onSelect(temp);
        //     props.onChange(temp);
        // }

        setSearch("");
    };

    const handleKey = (e: any) => {
        if (e.keyCode === 40) {
            if (selectedIndex < options.length - 1)
                setSelectedIndex(selectedIndex + 1);
        } else if (e.keyCode === 38) {
            if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
        } else if (e.keyCode === 13) {
            options.map((value: Options, i: number) => {
                if (selectedIndex === i) {
                    onSelect(value);
                }
            });
        }
    };

    const onAddNewTag = () => {
        props.onCustomAdd && props.onCustomAdd({ id: search, value: search, isNew: true })
        setSearch("");
        setIsFocus(false);
    };

    return (
        <>
            <div>
                {
                    isFocus && props.selected && props.selected.length > 0 && props.selected.map((item: any, index: number) => {
                        return (
                            < div className="badge tag-badge opacity-50 m-1" key={index}> {item.value} < IoCloseOutline size={17}
                                onClick={() => onSelect(item)}
                            /></div>
                        )
                    })
                }
            </div>
            <Fragment>
                <div ref={domNode} id={props.sakey ? props.sakey : "mutliSelectId"} key={props.sakey}
                    className={props.isDisable === true ? "disabled-select" : "select w-100"}>
                    {isFocus && <div className={"form-style " + (isFocus ? "zindex" : "")} tabIndex={0}>
                        <input
                            ref={textInput}
                            className="form-control text-truncate"
                            value={search}
                            type="text"
                            maxLength={100}
                            autoFocus={true}
                            onKeyPress={(e) =>
                                props.max ? HelperService.maxNumber(e, props.max) : ""
                            }
                            onBlur={(e) => checkOption(e.target.value)}
                            // onKeyDown={(e) => {
                            //     handleKey(e);
                            // }}
                            // onClick={() => {
                            //     setIsFocus(!isFocus);
                            // }}
                            onMouseDown={() => {
                                if (!isFocus) {
                                    checkPossition();
                                }
                                // setIsFocus(true);
                            }}
                            disabled={props.isDisable}
                            placeholder={userInput}
                            onChange={(e) => {
                                if (!isFocus) {
                                    setIsFocus(true);
                                }
                                setSearch(e.target.value)
                            }}
                        />
                        {isFocus ? optionsListComponent : ""}
                    </div>}

                    {!isFocus &&
                        <div className={props.selected && props.selected.length > 0 ? "selected react-select-open" : ""}
                            onClick={(e: any) => {
                                if (!props.isDisable && e && e.target && e.target.id != "universitty-chip") {
                                    setIsFocus(true)
                                }
                            }} style={{ height: "auto !important" }}>
                            <div className="form-control select-div d-flex flex-wrap p-1 h-auto" style={{ minHeight: "44px" }}>
                                {
                                    (props.selected && props.selected.length > 0) ? props.selected.map((item: any, index: number) => {
                                        return (
                                            < div className="badge tag-badge opacity-50 m-1" id="universitty-chip" key={index}> {item.value} <IoCloseOutline id="universitty-chip" size={17}
                                                onClick={() => { !props.isDisable && onSelect(item) }}
                                            /></div>
                                        )
                                    })
                                        :
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <span className="px-2 text-secondary">{props.placeholder}</span>
                                            {!props.isHideArrow && <div className="px-1">
                                                {/* <BiChevronDown id="img_downarrow" size={20} /> */}
                                                <svg width="10" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.34025 5.70097C3.30642 5.66801 3.16175 5.54355 3.04275 5.42763C2.29433 4.74797 1.06933 2.97495 0.695417 2.04696C0.635333 1.90603 0.508167 1.54972 0.5 1.35935C0.5 1.17694 0.542 1.00304 0.627167 0.837108C0.746167 0.630256 0.933417 0.46432 1.1545 0.373396C1.30792 0.314864 1.767 0.22394 1.77517 0.22394C2.27742 0.133016 3.0935 0.0830078 3.99533 0.0830078C4.85458 0.0830078 5.63742 0.133016 6.14725 0.20746C6.15542 0.215984 6.72592 0.306908 6.92133 0.406356C7.27833 0.588772 7.5 0.94508 7.5 1.32639V1.35935C7.49125 1.60769 7.26958 2.12993 7.26142 2.12993C6.88692 3.00791 5.722 4.74001 4.94792 5.43615C4.94792 5.43615 4.749 5.6322 4.62475 5.71745C4.44625 5.85042 4.22517 5.91634 4.00408 5.91634C3.75733 5.91634 3.5275 5.8419 3.34025 5.70097Z" fill="#929295" />
                                                </svg>

                                            </div>}
                                        </div>
                                }

                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        </>
    );
};

OpenTagSelect.defaultProps = {
    placeholder: "Add tags",
    selected: "",
    isSearchable: false,
    sakey: new Date().getTime(),
    type: "ARROW",
    isHideArrow: false,
    options: [],
};

export default OpenTagSelect;
