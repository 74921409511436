import { Card, Table, Button, ProgressBar, Modal } from "react-bootstrap";
import { BiSortAlt2 } from "react-icons/bi";
import UniversImg1 from '../../assets/images/university-1.png'
import UniversImg2 from '../../assets/images/university-2.png'
import UniversImg3 from '../../assets/images/university-3.png'
import DeleteIcon from '../../assets/images/delete-icon.svg'
import { useEffect, useState } from "react";
import { RiDraggable } from "react-icons/ri";
import { TbAlertTriangleFilled } from "react-icons/tb";


const schoolData = [
    {
        Rank: 1,
        SchoolName: "The University of Pennsylvania",
        img: UniversImg1,
        Location: 'Philadelphia, PA',
        Admissions: 5.6,
        MyChances: 95,
        Cost: "85,588",
        Distance: 2.50,
        NationalRating: 4
    },
    {
        Rank: 2,
        SchoolName: "California Institute of Technology",
        img: UniversImg2,
        Location: 'Pasadena, CA',
        Admissions: 4.6,
        MyChances: 75,
        Cost: "55,774",
        Distance: 8.50,
        NationalRating: 6
    },
    {
        Rank: 3,
        SchoolName: "MIT",
        img: UniversImg3,
        Location: 'Boston, MA',
        Admissions: 5.6,
        MyChances: 70,
        Cost: "88,155",
        Distance: 7.50,
        NationalRating: 7
    },
    {
        Rank: 4,
        SchoolName: "Columbia University",
        img: UniversImg1,
        Location: 'New York, NY',
        Admissions: 4.1,
        MyChances: 72,
        Cost: "86,097",
        Distance: 77.3,
        NationalRating: 12
    },
    {
        Rank: 5,
        SchoolName: "Brown University",
        img: UniversImg2,
        Location: 'Providence, PI',
        Admissions: 5.5,
        MyChances: 78,
        Cost: "83,686",
        Distance: 244,
        NationalRating: 9
    }
]


const MySchools = () => {
    //Page Title
    useEffect(() => {
        const element = document.getElementById('page_title');
        var BackArrow: any = document.getElementById("back_nav");
        if (element) {
            element.textContent = 'My Schools';
        }

        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
         // eslint-disable-next-line
    }, []);


    const [showAlert, setShowAlert] = useState(false);
    const handleCloseAler = () => setShowAlert(false);
    const handleShowAlert = () => setShowAlert(true);

    return (
        <>
            <div className="page-my-applications page-container">
                <Card className="card-shadow">
                    <Card.Body className=" ">
                        <div className="d-lg-flex justify-content-between align-items-end">
                            <div className=" mb-lg-0 mb-3">
                                <h4 className="font-18 mb-2">Schools of Interest </h4>
                                <p className="text-secondary mb-0">Your schools of interest will be populated here. You can reorder them based on your preference.</p>
                            </div>

                        </div>

                    </Card.Body>
                    <div className="table-responsive">
                        <Table className="table-style ">
                            <thead>
                                <tr>

                                    <th style={{ minWidth: "85px" }}><BiSortAlt2 className="sort-icon" /> Rank</th>
                                    <th style={{ minWidth: "250px" }}><BiSortAlt2 className="sort-icon" /> School Name</th>
                                    <th style={{ minWidth: "95px" }}><BiSortAlt2 className="sort-icon" /> Admissions</th>
                                    <th style={{ minWidth: "250px" }}><BiSortAlt2 className="sort-icon" />My chances</th>
                                    <th style={{ minWidth: "95px" }}><BiSortAlt2 className="sort-icon" />Cost</th>
                                    <th className=" text-nowrap"><BiSortAlt2 className="sort-icon" />Distance</th>
                                    <th className="text-nowrap text-center"><BiSortAlt2 className="sort-icon" />National Rating</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    schoolData.map((data:any,index:number) => {
                                        return (
                                            <tr>
                                                <td width={50}>
                                                    <div className="d-flex align-items-center">
                                                        <RiDraggable size={20} className="hover-effect opacity-50 cursor-pointer" />
                                                        <span className="ms-3">{data.Rank}</span>
                                                    </div>
                                                </td>
                                                <td width={400}>
                                                    <div className="d-flex gap-2">
                                                        <img src={data.img} width={40} height={40} className=" rounded-circle object-fit-cover" alt="" />
                                                        <div>
                                                            <p className="font-medium mb-0 text-dark">{data.SchoolName}</p>
                                                            <p className="text-secondary mb-0">{data.Location}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td> {data.Admissions}% </td>
                                                <td>
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <ProgressBar now={data.MyChances} visuallyHidden className="w-100" />
                                                        <span className="font-medium text-secondary me-3">{data.MyChances}%</span>
                                                    </div>
                                                </td>
                                                <td>${data.Cost}</td>
                                                <td className="text-secodary">{data.Distance} miles</td>
                                                <td className="text-secodary text-center">#{data.NationalRating}</td>
                                                <td className="text-end"> <a className="action-btn cursor-pointer opacity-50 hover-effect" onClick={handleShowAlert}> <img src={DeleteIcon} width={16} alt="" /> </a> </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </div>

            <Modal show={showAlert} onHide={handleCloseAler} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="font-16">Delete school from my list</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="text-center">
                        <TbAlertTriangleFilled size={50} className=" mb-4" style={{color: "#FF3A29"}} />
                    </div>
                    <p className="font-medium font-16">If you remove Stanford University from your schools of interest, this action will also remove:</p>
                    <ul className="mb-4 font-16 ps-3">
                        <li className="mb-2">All relevant planner tasks</li>
                        <li className="mb-2">School communities and groups</li>
                        <li>Documents submitted</li>
                    </ul>

                    <Button className="btn-danger w-100 mb-2" onClick={handleCloseAler}>Remove from my list</Button>
                    <Button className="w-100 btn-outline-light" onClick={handleCloseAler}>Cancel</Button>
                </Modal.Body>

            </Modal>
        </>
    )
}
export default MySchools;