import { useState } from "react";
import { PiPlus } from "react-icons/pi";
import { toast } from "react-toastify";
import HelperService from "../../../Services/HelperService";

interface PropData {
    onChange: Function;
    message?: string;
    acceptFileType?: any[];
    acceptedSize?: number;
    hidePlusIcon?: boolean;
    sakey?: string;
}

const UploadFile = (props: PropData) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        files && files.length && handleFile(files[0]);
    };

    const onChangeFile = (e: any) => {
        const files = e.target.files[0];
        handleFile(files);
    };

    const handleFile = (file: any) => {
        if (!file) { return; }
        var sizeInBytes = file.size;
        var sizeInKb = sizeInBytes / 1024;
        var sizeInMb = sizeInKb / 1024;
        if (props.acceptedSize && (sizeInMb > props.acceptedSize)) {
            toast.warn(`file must be less then ${props.acceptedSize} MB!`, { theme: "colored" });
            return;
        } else if (sizeInMb > 10) {
            toast.warn("file must be less then 10 MB!", { theme: "colored" });
            return;
        }

        if (props.acceptFileType && props.acceptFileType.length) {
            let str: string = "";
            props.acceptFileType.map((item: any, i: number) => {
                str += i == 0 ? item : `|${item}`;
            });
            var p = "(\.(", s = "))$"
            var reg = new RegExp(p + str + s, 'i');
            if (!reg.test(file.name)) {
                let str = props.acceptFileType.toString();
                toast.warn("File type should be " + str, { theme: "colored" });
                return;
            };
        }
        props.onChange(file, file.name, HelperService.removeDecimal(sizeInKb));
    };


    return (
        <>
            <label
                htmlFor={`upload_doc_${props.sakey ? props.sakey : new Date().getTime()}`}
                className={`file-drop-zone w-100  ${isDragging ? 'dragging' : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {!props.hidePlusIcon && <PiPlus className="mb-1" />}
                <p className="font-14 p-0 m-0 text-secondary">{props.message ? props.message : "Upload or Drag and drop files here"}</p>
            </label>
            <input type="file" key={`${new Date().getTime()}_${props.sakey ? props.sakey : new Date().getTime()}`} hidden name="upload_doc" id={`upload_doc_${props.sakey ? props.sakey : new Date().getTime()}`} onChange={onChangeFile} />
        </>
    );
};

export default UploadFile;


