import "./OpenTagSelect.scss";
import React, { useState, Fragment, useEffect } from "react";
import HelperService from "../../../Services/HelperService";
import useClickOutside from "../../../hook/useClickOutside";
import loader from "../../../assets/images/loader.gif";

interface PropData {
    placeholder?: string;
    options: Options[];
    selected: any;
    isSearchable?: boolean;
    onChange: any;
    type?: string;
    sakey?: string;
    disValue?: string;
    disCode?: string;
    value?: string;
    isHideArrow?: boolean;
    isDisable?: boolean;
    forceClose?: boolean;
    isCustomInput?: boolean;
    max?: number;
    column?: number;
    isLoading?: boolean;
    isCustom?: boolean;
}

export interface Options {
    id: any;
    code?: string;
    value: string;
    value2?: string;
    parentCode?: string;
    object?: any;
    icon?: string;
    disable?: boolean;
}

const OpenSelect = (props: PropData) => {
    let textInput = React.createRef<HTMLInputElement>();
    const [placeholder, setPlaceholder] = useState(props.placeholder);
    const [options, setOptions] = useState(props.options);
    const [selectedOption, setSelectedOption] = useState(props.selected);
    const [isFocus, setIsFocus] = useState(false);
    const [search, setSearch] = useState("");
    const [isOpenTop, setIsOpenTop] = useState(false);
    let index = -1;
    let selectedValue = "";
    const [userInput, setUserInput] = useState(props.isSearchable ? "" : props.placeholder);

    useEffect(() => {
        setOptions(props.options);
        if (props.selected != selectedOption) {
            setSelectedOption(props.selected);
        }

        props.options.map((value, i: number) => {
            if (value.id == props.selected) {
                index = i;
                selectedValue = value.value;
                if (userInput != selectedValue) {
                    setUserInput(value.code && props.column == 3 ? `${value.value} : ${value.code}` : selectedValue);
                }
            }
        });

        if (props.selected != selectedOption) {
            for (var i in props.options) {
                if (props.options[i].id == props.selected) {
                    if (props.onChange) {
                        // props.onChange(props.options[i]);
                    }
                }
            }
        }

        if (!props.selected) { setUserInput(props.selected); }

        if (props.selected == "resetsawin") {
            setSearch("");
            setUserInput("");
            setUserInput(props.isSearchable ? "" : props.placeholder);
        }
    }, [props.selected, props.options]);

    useEffect(() => {
        if (props.forceClose) {
            setIsFocus(false);
        }
    }, [props.forceClose]);

    const [selectedIndex, setSelectedIndex] = useState(index);

    let domNode = useClickOutside(() => {
        setIsFocus(false);
        if (props.isSearchable && search) {
            if (props.isCustomInput) {
                setUserInput(search);
                setSelectedOption(search);
                props.onChange(search);
            }
        }
    }, this);

    const checkPossition = () => {
        var topHeight = domNode.current.getBoundingClientRect().y;
        var bottomHeight =
            window.innerHeight - domNode.current.getBoundingClientRect().y;
        if (bottomHeight > 300 || topHeight <= 280) {
            setIsOpenTop(false);
        } else {
            setIsOpenTop(true);
        }
    };

    let optionsListComponent;
    let searchOption: Options[] = search ? [...options.filter(function (option) { return option.value.toString().toLowerCase().startsWith(search.toLowerCase()); }), ...options.filter(function (option) { return !option.value.toString().toLowerCase().startsWith(search.toLowerCase()) && option.value.toString().toLowerCase().includes(search.toLowerCase()); })] : options;

    if (searchOption.length) {
        optionsListComponent = (
            <ul className={"options " + (isOpenTop ? "open-top" : "")}>
                {searchOption.map((suggestion: Options, index) => {
                    let className;
                    if (index === selectedIndex) { className = "option-active"; }

                    return (
                        <li
                            className={suggestion.disable ? `${className} cursor-default` : className}
                            style={{ opacity: suggestion.disable ? "0.5" : "1", cursor: suggestion.disable ? "default" : "pointer" }}
                            key={index}
                            onMouseDown={() => { !suggestion.disable && onSelect(suggestion) }}
                        >
                            {suggestion.icon ? (
                                <div className="d-flex">
                                    <div className="row-icon-view">
                                        <img src={suggestion.icon} className="row-icons" />
                                    </div>
                                    <div className="option">{suggestion.value}</div>
                                </div>
                            ) :
                                props.column == 3 && suggestion.code ? (
                                    props.column == 3 ? (
                                        <div className="option option-multi">
                                            <div className="font-medium code-div"> {suggestion.code} </div>
                                            <div className="font-medium">{suggestion.value}</div>
                                            <div className="" style={{ whiteSpace: "pre-wrap" }}>{suggestion.value2}</div>
                                        </div>
                                    ) : (
                                        <div className="row option">
                                            <div className="col-md-5 code-div"> {suggestion.code} </div>
                                            <div className="col-md-7">{suggestion.value}</div>
                                        </div>
                                    )
                                ) :
                                    (<div className="option">{suggestion.value}</div>)}
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        const optiosnData = <div className="no-options">
            <em>No data found</em>
        </div>;
        const customData = <ul className={"options " + (isOpenTop ? "open-top" : "")}>
            <li
                className={'cursor-default'}
                key={index}
                onMouseDown={() => { onSelect({ id: search, value: search }) }}
            ><div className="option">Add: {search}</div>
            </li>
        </ul>
        const loaaderData = <div className="no-suggestions">
            <div style={{ textAlign: "center" }}>
                <img
                    style={{ position: "relative" }}
                    src={loader}
                    alt="No loader found"
                />
                <div style={{ position: "relative", color: "black" }}>
                    Loading...
                </div>
            </div>
        </div>
        optionsListComponent = props.isLoading ? loaaderData : props.isCustom ? customData : optiosnData;
    }

    const onSelect = (e: Options) => {
        setSearch("");
        setIsFocus(false);
        setUserInput(e.code && props.column == 3 ? `${e.value} : ${e.code}` : e.value);
        setSelectedOption(e.id);
        options.map((value, i: number) => {
            if (value.id == e.id) {
                index = i;
                selectedValue = value.value;
                if (props.onChange) {
                    props.onChange(e);
                }
                setSelectedIndex(index);
            }
        });
    };

    const handleKey = (e: any) => {
        if (e.keyCode === 40) {
            if (selectedIndex < options.length - 1)
                setSelectedIndex(selectedIndex + 1);
        } else if (e.keyCode === 38) {
            if (selectedIndex > 0) setSelectedIndex(selectedIndex - 1);
        } else if (e.keyCode === 13) {
            options.map((value: Options, i: number) => {
                if (selectedIndex === i) {
                    onSelect(value);
                }
            });
        }
    };

    const checkOption = (enterValue: any) => {
        // setIsFocus(false);
        var isFound = false;
        options.map((value: Options, i: number) => {
            if (value.id === enterValue || value.value === enterValue) {
                setUserInput(enterValue);
                setSelectedOption(enterValue);
                onSelect(value);
                props.onChange(value);
                isFound = true;
            }
        });

        if (props.isCustomInput && enterValue && !isFound) {
            setUserInput(enterValue);
            setSelectedOption(enterValue);
            var temp: Options = { id: enterValue, value: enterValue };
            onSelect(temp);
            props.onChange(temp);
        }

        setSearch("");
    };

    return (
        <>
            <Fragment>
                <div className={props.selected ? "selected react-select-open truncate-data" : "react-select-open truncate-data"}>
                    <div
                        ref={domNode}
                        id={props.sakey ? props.sakey : "selectId"}
                        key={props.sakey}
                        className={props.isDisable === true ? "disabled-select w-100 truncate-data" : "select w-100 custom-select"}
                    >
                        {props.isSearchable ? (
                            <div
                                className={"form-style " + (isFocus ? "zindex" : "")}
                                tabIndex={0}
                            >
                                <input
                                    ref={textInput}
                                    className="form-control text-truncate"
                                    value={isFocus ? search : userInput}
                                    type="text"
                                    onKeyPress={(e) =>
                                        props.max ? HelperService.maxNumber(e, props.max) : ""
                                    }
                                    onBlur={(e) => checkOption(e.target.value)}
                                    onKeyDown={(e) => {
                                        handleKey(e);
                                    }}
                                    onClick={() => {
                                        setIsFocus(true);
                                    }}
                                    onMouseDown={() => {
                                        if (!props.isDisable) {
                                            if (!isFocus) {
                                                checkPossition();
                                            }
                                            // setIsFocus(true);
                                        }
                                    }}
                                    disabled={props.isDisable}
                                    placeholder={placeholder}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                {!props.isHideArrow && !isFocus ? (
                                    <div className="down-arrow" onClick={() => { setIsFocus(!isFocus); }}>
                                        <svg width="10" height="6" className="icon" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.34025 5.70097C3.30642 5.66801 3.16175 5.54355 3.04275 5.42763C2.29433 4.74797 1.06933 2.97495 0.695417 2.04696C0.635333 1.90603 0.508167 1.54972 0.5 1.35935C0.5 1.17694 0.542 1.00304 0.627167 0.837108C0.746167 0.630256 0.933417 0.46432 1.1545 0.373396C1.30792 0.314864 1.767 0.22394 1.77517 0.22394C2.27742 0.133016 3.0935 0.0830078 3.99533 0.0830078C4.85458 0.0830078 5.63742 0.133016 6.14725 0.20746C6.15542 0.215984 6.72592 0.306908 6.92133 0.406356C7.27833 0.588772 7.5 0.94508 7.5 1.32639V1.35935C7.49125 1.60769 7.26958 2.12993 7.26142 2.12993C6.88692 3.00791 5.722 4.74001 4.94792 5.43615C4.94792 5.43615 4.749 5.6322 4.62475 5.71745C4.44625 5.85042 4.22517 5.91634 4.00408 5.91634C3.75733 5.91634 3.5275 5.8419 3.34025 5.70097Z" fill="#929295" />
                                        </svg>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {isFocus && props.isDisable == false ? optionsListComponent : ""}
                            </div>
                        ) : (
                            <div
                                className={"form-style " + (isFocus ? "zindex" : "")}
                                onKeyDown={(e) => {
                                    handleKey(e);
                                }}
                                onClick={() => {
                                    setIsFocus(!isFocus);
                                }}
                                tabIndex={0}
                                onMouseDown={() => {
                                    if (!props.isDisable) {
                                        if (!isFocus) {
                                            checkPossition();
                                        }
                                    }
                                }}
                            >
                                <div className="down-arrow">
                                    <span>{userInput}</span>
                                    {!props.isHideArrow || isFocus ? (
                                        <div className="right-icon">
                                            {/* <BiChevronDown id="img_downarrow" className="downarrow" /> */}
                                            <svg width="10" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.34025 5.70097C3.30642 5.66801 3.16175 5.54355 3.04275 5.42763C2.29433 4.74797 1.06933 2.97495 0.695417 2.04696C0.635333 1.90603 0.508167 1.54972 0.5 1.35935C0.5 1.17694 0.542 1.00304 0.627167 0.837108C0.746167 0.630256 0.933417 0.46432 1.1545 0.373396C1.30792 0.314864 1.767 0.22394 1.77517 0.22394C2.27742 0.133016 3.0935 0.0830078 3.99533 0.0830078C4.85458 0.0830078 5.63742 0.133016 6.14725 0.20746C6.15542 0.215984 6.72592 0.306908 6.92133 0.406356C7.27833 0.588772 7.5 0.94508 7.5 1.32639V1.35935C7.49125 1.60769 7.26958 2.12993 7.26142 2.12993C6.88692 3.00791 5.722 4.74001 4.94792 5.43615C4.94792 5.43615 4.749 5.6322 4.62475 5.71745C4.44625 5.85042 4.22517 5.91634 4.00408 5.91634C3.75733 5.91634 3.5275 5.8419 3.34025 5.70097Z" fill="#929295" />
                                            </svg>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {isFocus && props.isDisable == false ? optionsListComponent : ""}
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        </>
    );
};

OpenSelect.defaultProps = {
    placeholder: "Choose an option",
    selected: "",
    isSearchable: false,
    sakey: new Date().getTime(),
    type: "ARROW",
    isHideArrow: false,
    options: [],
    isDisable: false,
    isCustom: false,
};

export default OpenSelect;
