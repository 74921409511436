import { useState, useEffect, useRef } from "react";
import DoenArrow from '../../../assets/images/dd-arrow.svg'
import WebService from "../../../Services/WebService";
import { components } from 'react-select';
import loader from "../../../assets/images/loader.gif";
import { AsyncPaginate } from "react-select-async-paginate";
import type { GroupBase, OptionsOrGroups } from "react-select";
import { FaCircle } from "react-icons/fa";
import Placeholder from '../../../assets/images/image-placeholder.png'


interface PropData {
    placeholder?: string;
    selected: any;
    isMulti?: boolean;
    isSearchable?: boolean;
    onChange: any;
    type?: string;
    sakey?: string;
    selectedObj?: any;
    isHideArrow?: boolean;
    isDisable?: boolean;
    max?: number;
    isCustom: boolean;
    isParent?: boolean;
}

type OptionType = {
    value: number;
    label: string;
};

const AsyncUnivarsityPaginationSelect = (props: PropData) => {
    const optionsCompute = useRef<any[]>([]);
    const nextOffset = useRef<number>(0);
    const [options, setOptions] = useState<any[]>([]);
    const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState(props.selected);
    const [inputValue, setIputValue] = useState<string>('');

    useEffect(() => {
        setSelectedOption(props.selected);
    }, [props.selected]);

    const DropdownIndicator = () => {
        return (
            props.isHideArrow ? <></> :
                <div className="down-arrow">
                    <img src={DoenArrow} alt="icon" width={12} className="icon" />
                </div>
        );
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<any>((resolve) => {
            WebService.getAPI({
                action: `university/list?limit=50&keyword=${inputValue ? inputValue : ""}&university_type=${props.type}&offset=${nextOffset.current}&parent_type=${props.isParent ? "PARENT" : ""}`,
                body: null,
            }).then((res: any) => {
                var array: any[];
                array = [];
                if (res.list.length > 0) {
                    let isSelectedExist: boolean = false;
                    res.list.forEach((element: any) => {
                        array.push({ ...element, ...{ "value": element.id, "label": element.name } });
                        if (!inputValue && props.selected && props.selected == element.id) {
                            setSelectedOption({ "value": element.id, "label": element.name });
                            isSelectedExist = true;
                        }
                    });
                    if (!isSelectedExist && props.selectedObj) {
                        setSelectedOption({ "value": props.selectedObj.id, "label": props.selectedObj.name });
                        array.push({ value: props.selectedObj.id, label: props.selectedObj.name });
                    }
                };
                nextOffset.current = res.next_offset;
                optionsCompute.current = [...array, ...defaultOptions];
                setOptions(optionsCompute.current)
                resolve({
                    options: array,
                    hasMore: res.next_offset > 0 ? true : false
                });
            }).catch((error) => {
                optionsCompute.current = [];
                setOptions(optionsCompute.current)
                resolve({
                    options: [],
                    hasMore: false
                });
            });
        });

    const loadOptions = async (inputValue: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>) => {
        let array: any = await promiseOptions(inputValue);
        return array
    };

    const Option = (data: any) => {
        if (data.data) {
            return (<components.Option {...data}>
                <div className="d-flex gap-2  news-list-card align-items-center cursor-pointer" onClick={() => props.onChange(data.data)}>
                    <div>
                        <img src={Placeholder} alt="" width={40} height={40} className="rounded-full object-fit-cover" />
                    </div>
                    <div>
                        <p className='font-medium mb-0  '>{data.data.label}</p>
                        <div className="d-flex gap-2">
                            <p className="mb-0"><span className=" text-secondary">Private</span></p>
                            <span className=" text-secondary mx-1 opacity-50"><FaCircle size={8} /></span>
                            <p className="mb-0"><span className=" text-secondary">{data.data?.city_obj?.name}, {data.data?.state_obj?.code}</span></p>
                            <span className=" text-secondary mx-1 opacity-50"><FaCircle size={8} /></span>
                            <p className="mb-0"><span className=" text-secondary">4 year</span></p>
                        </div>
                    </div>
                </div>
            </components.Option>)
        } else {
            return (
                <></>
            );
        }
    };

    const LoadingMessage = (data: any) => {
        return (
            <div className="no-suggestions">
                <div style={{ textAlign: "center" }}>
                    <img
                        style={{ position: "relative" }}
                        src={loader}
                        alt="No loader found"
                    />
                    <div style={{ position: "relative", color: "black" }}>
                        Loading...
                    </div>
                </div>
            </div>
        );
    };

    const NoOptionsMessage = (data: any) => {
        return (
            <>
                {
                    props.isCustom ?
                        <components.Option {...data} className="border-bottom">
                            <div className="d-flex gap-3  news-list-card align-items-center cursor-pointer" onClick={() => { data.setValue({ label: inputValue, value: inputValue, isNew: true, type: "CUSTOM" }); }}>
                                <div>
                                    <p className='font-medium mb-0 news-title '>Add: {inputValue}</p>
                                </div>
                            </div>
                        </components.Option>
                        :
                        <div className="no-options-async">
                            <div style={{ textAlign: "center", alignItems: "center" }}>
                                <div style={{ position: "relative", color: "black" }}>
                                    <em>No data found</em>
                                </div>
                            </div>
                        </div>
                }
            </>
        );
    };

    const LoadingIndicator = (data: any) => {
        return (
            <div className="no-suggestions">
                <div style={{ textAlign: "center" }}>
                    <img
                        style={{ position: "relative" }}
                        src={loader}
                        alt="No loader found"
                    />
                    <div style={{ position: "relative", color: "black" }}>
                        Loading...
                    </div>
                </div>
            </div>
        );
    };

    const colourStyles: any = {
        multiValue: (styles: any, { data }: any) => {
            return {
                ...styles,
                backgroundColor: '#8A9FD4',
                color: '#fff',
                borderRadius: '15px',
                padding: '3px 7px',
                lineHeight: '16px',
                cursor: 'pointer',
                transition: '0.4s',
            };
        },
        multiValueLabel: (styles: any, { data }: any) => {
            return ({
                ...styles,
                backgroundColor: '#8A9FD4',
                color: '#fff',
                borderRadius: '15px',
                padding: '3px 7px',
                lineHeight: '16px',
                cursor: 'pointer',
                transition: '0.4s',
            })
        },
        multiValueRemove: (styles: any, { data }: any) => ({
            ...styles,
            ':hover': {
                backgroundColor: '#8A9FD4',
            }
        }),
    };


    return (
        <>
            <div className={(props.isMulti ? selectedOption.length : selectedOption) ? "react-select-open selected react-university-select react-select" : "react-select-open react-university-select react-select"}>
                <AsyncPaginate
                    value={selectedOption}
                    loadOptions={loadOptions}
                    placeholder={props.placeholder}
                    key={props.sakey}
                    isDisabled={props.isDisable}
                    cacheUniqs={[inputValue]}
                    className="no-devider"
                    onInputChange={(e: any) => { nextOffset.current = 0; setIputValue(e); }}
                    inputValue={inputValue}
                    onChange={(e: any) => { props.onChange(e); }}
                    pageSize={50}
                    defaultOptions={true}
                    components={{ DropdownIndicator, Option, LoadingMessage, NoOptionsMessage }}
                    isMulti={props.isMulti}
                    isClearable={false}
                    styles={colourStyles}
                />
            </div>
        </>
    );
};

AsyncUnivarsityPaginationSelect.defaultProps = {
    placeholder: "Choose an option",
    selected: "",
    isSearchable: false,
    sakey: new Date().getTime(),
    isHideArrow: false,
    isDisable: false,
    isCustom: false,
};

export default AsyncUnivarsityPaginationSelect;