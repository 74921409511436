import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import logo from "../../assets/images/login-logo.svg";
import { CiSearch } from "react-icons/ci";
import DocIcon from '../../assets/images/doc-icon.svg';
import { IoClose } from "react-icons/io5";
import UploadFile from "../../components/Common/UploadFile/UploadFile";
import PDFFile from '../../assets/images/pdf.png';
import { Controller, useForm } from "react-hook-form";
import { Label } from "../../components/Common/Label/Label";
import OpenDatePicker from "../../components/Common/OpenDatePicker/OpenDatePicker";
import CardLoader from "../../components/Common/CardLoader";


interface PropData {
    show: boolean;
    isClose: Function;
    id?: string;
    data: any;
};

const SendApplicationModal = (props: PropData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors, isValid } } = useForm<any>({});
    const watchAllFields = watch();
    const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const [applicationStatus, setApplicationStatus] = useState<number>(1);
    const [isAcepted, setIsAcepted] = useState<boolean>(false);
    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (props.show) {
            reset({});
            setApplicationStatus(1);
            getDocuments();
        }
    }, [props.show]);

    const getDocuments = () => {
        setIsLoading(true);
        WebService.getAPI({ action: `download/pdf/${props.id}?university_id=${props.id}` })
            .then((res: any) => {
                setIsLoading(false);
                setPdfUrl(res.url);
            })
            .catch((e: any) => { setIsLoading(false); });
    };

    const closeClick = (type?: boolean) => {
        props.isClose(type);
    };

    const onSave = (data: any) => {
        if (applicationStatus === 2) {
            setValue('date', new Date());
            setApplicationStatus(applicationStatus + 1);
            return;
        }
        data.university_id = props.data?.id;
        WebService.postAPI({ action: "send/application", body: data, id: 'send_application_university' })
            .then((res: any) => {
                toast.success(res.message);
                closeClick(true);
            })
            .catch((e: any) => { });
    };


    return (
        <Modal show={props.show} centered onHide={() => closeClick()} scrollable backdrop="static" keyboard={false} className="size-960">
            <Modal.Header closeButton >
                <Modal.Title className="font-16">Send application to <span className="text-brand">{props.data && props.data?.name}</span></Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSave)}>
                <Modal.Body style={{ maxHeight: "calc(100vh - 260px)" }}>

                    <div className="step-wizard mb-4">
                        <div className="step-div completed">
                            <p className="step-name">Final review</p>
                            <p className="description">Short step description</p>
                        </div>

                        <div className={`step-div ${applicationStatus > 1 ? " completed" : ""}`}>
                            <p className="step-name">Recipient</p>
                            <p className="description">Short step description</p>
                        </div>

                        <div className={`step-div ${applicationStatus > 2 ? " completed" : ""}`}>
                            <p className="step-name">Signature & submission</p>
                            <p className="description">Short step description</p>
                        </div>
                    </div>

                    {applicationStatus === 1 && <>
                        {isLoading && <CardLoader />}
                        {!isLoading && pdfUrl && <iframe src={pdfUrl} width="100%" height="500px"></iframe>}
                    </>}

                    {applicationStatus === 2 && <>
                        <div className="form-style-2">
                            <p>Velit malesuada consectetur bibendum convallis interdum id. Pretium non euismod habitasse amet velit lectus cursus etiam. Libero nunc dignissim ut vulputate odio maecenas tempus egestas tellus. Posuere condimentum ac neque eleifend suspendisse risus. Ornare sit massa vulputate pretium tempor cursus suscipit fames.</p>
                            <Form.Group className="mb-4 overlap-label">
                                <Form.Label>Email<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" value={watchAllFields.email} {...register("email", {
                                    required: true,
                                    pattern: emailRegex,
                                })} />
                                {errors.email && (<Label title={"Please enter email"} modeError={true} />)}
                            </Form.Group>
                            <Form.Group className="mb-4 overlap-label">
                                <Form.Label>Additional message<span className="text-danger">*</span></Form.Label>
                                <Form.Control as="textarea" rows={5} className="h-auto" value={watchAllFields.additional_message} {...register("additional_message", { required: true })} />
                                <div className="d-flex justify-content-between font-12 text-secondary">
                                    <span>Additional message</span>
                                    <span>{watchAllFields.additional_message && watchAllFields.additional_message.length || 0}/250 characters</span>
                                </div>
                                {(errors.additional_message || (watchAllFields.additional_message && watchAllFields.additional_message.length > 250)) && (<Label title={!watchAllFields.additional_message ? "Please enter additional message" : "Please enter less than 250 characters"} modeError={true} />)}
                            </Form.Group>
                        </div>
                    </>}

                    {applicationStatus === 3 && <>
                        <p className="font-18">Please affirm the following before you send your application:</p>
                        <Form.Check
                            type="checkbox"
                            id="reviewed"
                            className="mandotroy mb-4"
                            label="I certify that all information submitted in the admission process - including this application and any other supporting materials - is my own work, factually true, and honestly presented, and that these documents will become the property of the institution to which I am applying and will not be returned to me. I understand that I may be subject to a range of possible disciplinary actions, including admission revocation, expulsion, or revocation of course credit, grades, and degree should the information I have certified be false*"
                            value={"1"}
                            {...register("affirm1", { required: true })}
                        />
                        <Form.Check
                            type="checkbox"
                            id="IAgree"
                            className="mandotroy mb-4"
                            label="I agree to notify the institutions to which I am applying immediately should there be any change to the information requested in this application*"
                            value={"2"}
                            {...register("affirm2", { required: true })}
                        />
                        <Form.Check
                            type="checkbox"
                            id="Iunderstand"
                            className="mandotroy mb-4"
                            label="I understand that once my application has been submitted it may not be altered in any way; I will need to contact the institution directly if I wish to provide additional information*"
                            value={"3"}
                            {...register("affirm3", { required: true })}
                        />
                        <Form.Check
                            type="checkbox"
                            id="Iacknowledge"
                            className="mandotroy mb-4"
                            label="I acknowledge that I have reviewed the application instructions for the college receiving this application. I understand that all offers of admission are conditional, pending receipt of final transcripts showing work comparable in quality to that upon which the offer was based, as well as honorable dismissal from the school*"
                            value={"4"}
                            {...register("affirm4", { required: true })}
                        />
                        <Form.Check
                            type="checkbox"
                            id="Iaffirm"
                            className="mandotroy mb-4"
                            label="I affirm that I will send an enrollment deposit (or equivalent) to only one institution; sending multiple deposits (or equivalent) may result in the withdrawal of my admission offers from all institutions. [Note: students may send an enrollment deposit (or equivalent) to a second institution where they have been admitted from the waitlist, provided that they inform the first institution that they will no longer be enrolling.*"
                            value={"5"}
                            {...register("affirm5", { required: true })}
                        />
                        <Row className=" row-cols-2 form-style-2">
                            <Col>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Signature<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" value={watchAllFields.signature} {...register("signature", { required: true })} />
                                    <p className="font-12 text-secondary">*type in your full name   </p>
                                    {errors.signature && (<Label title={"Please enter signature"} modeError={true} />)}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-4" controlId="formBasicEmail">
                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                    <Controller
                                        control={control}
                                        name={`date`}
                                        rules={{ required: true }}
                                        render={({ field }: any) => (
                                            <OpenDatePicker
                                                type="date"
                                                selected={watchAllFields.date ?? watchAllFields.date}
                                                placeholderText={"Pick a date"}
                                                minData={new Date(new Date())}
                                                maxData={new Date(new Date())}
                                                onChange={(e: any) => field.onChange(e)}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>}


                </Modal.Body>
                <Modal.Footer className="border-0" key={applicationStatus + new Date().getTime() + 10}>
                    {applicationStatus === 1 && <div className="w-100">
                        <Form.Check
                            type="checkbox"
                            id="reviewed"
                            label="I have reviewed the PDF copy of my application and wish to proceed with my submission"
                            checked={isAcepted}
                            onClick={() => setIsAcepted(!isAcepted)}
                        />
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="btn-outline-brand px-5" type='button' onClick={() => closeClick()}>Cancel</Button>
                            <Button key={applicationStatus + new Date().getTime()} className="btn-brand-1  px-5" type='button' disabled={!isAcepted} onClick={() => setApplicationStatus(applicationStatus + 1)}>Continue</Button>
                        </div>
                    </div>}

                    {applicationStatus === 2 && <div className="w-100">
                        <div className="d-flex justify-content-between mt-4">
                            <Button className="btn-outline-brand px-5" type='button' onClick={() => setApplicationStatus(applicationStatus - 1)}>Back</Button>
                            <Button key={applicationStatus + new Date().getTime()} className="btn-brand-1  px-5" type='submit' disabled={!isValid}>Continue</Button>
                        </div>
                    </div>}

                    {applicationStatus == 3 && <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <Button className="btn-outline-brand px-5" type='button' onClick={() => setApplicationStatus(applicationStatus - 1)}>Back</Button>
                            <Button key={applicationStatus + new Date().getTime()} className="btn-brand-1 px-5" id='send_application_university' type='submit' disabled={!isValid}>Send</Button>
                        </div>
                    </div>}

                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default SendApplicationModal;
