import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { useEffect, useState } from "react";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "./ExitConfirmModal";

interface Geography {
  birth_country_id: any;
  birth_city: any;
  birth_state_id: any;
  hold_valid_visa: any;
  citizenship_country_id: any;
  apply_us_visa: any;
  lived_in_usa: any;
  citizenship_status: any;
  social_security_number: number;
}

const citizenshipStatus = [
  { id: "US_NATIONAL", value: "I am a U.S. citizen or U.S. national" },
  { id: "US_DUAL", value: "I am a U.S. dual citizen" },
  { id: "US_PERMANENT", value: "I am a permanent U.S. resident" },
  { id: "US_REFUGEE", value: "I am a U.S. refugee or asylee" },
  {
    id: "UNDOCUMENTED_TEMPORARY_ENFORED",
    value:
      "DACA, undocumented, Deferrred Enfored Departure, or Temporary Protected Status",
  },
  { id: "NON_US_CITIZEN", value: "Citizen of non-US country" },
];

const Lived_In_us = [
  { id: "0", value: "0 Years" },
  { id: "1", value: "1 Year or less" },
  { id: "2", value: "2 Years" },
  { id: "3", value: "3 Years" },
  { id: "4", value: "4 Years" },
  { id: "5", value: "5 Years" },
  { id: "6", value: "6 Years" },
  { id: "7", value: "7 Years" },
  { id: "8", value: "8 Years" },
  { id: "9", value: "9 Years" },
  { id: "10", value: "10 Years" },
  { id: "11", value: "11 Years" },
  { id: "12", value: "12 Years" },
  { id: "13", value: "13 Years" },
  { id: "14", value: "14 Years" },
  { id: "15", value: "15 Years" },
  { id: "16", value: "16 Years" },
  { id: "17", value: "17 Years" },
  { id: "18", value: "18 Years" },
  { id: "19", value: "19 Years" },
  { id: "20", value: "20 Years" },
  { id: "MORE_20", value: "> 20 years" },
];

interface propData {
  isUpdate: Function;
  tab?: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

const Geography = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors }, } = useForm<Geography>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [countryOptions1, setCountryOptions1] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "location" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "location" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'location');
    } else {
      props.isFormDirty(false, 'location');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.tab == "location" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "location") {
      reset({});
      getGeography();
      getCountryOption();
    }
  }, [props.tab]);

  const getGeography = () => {
    WebService.getAPI({
      action: `personal/geography-detail`,
    })
      .then((res: any) => {
        reset(res.result);
        if (res.result.birth_country_id) {
          getStateOption(res.result.birth_country_id);
        }
      })
      .catch((e) => { });
  };

  const getCountryOption = () => {
    WebService.getAPI({ action: "countries" })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex(
            (item: any) => item.name === "United States"
          );

          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            setCountryOptions(
              updatedList.map((item: any) => {
                return { ...item, ...{ value: item.name } };
                //label: item.name,
              })
            );
          } else {
            setCountryOptions(
              res.list.map((item: any) => {
                return { ...item, ...{ value: item.name } };
              })
            );
          }
          let temp: any[] = [];
          res.list.map((item: any) => {
            if (item.id != 239) {
              temp.push({ ...item, ...{ value: item.name } });
            }
          });
          setCountryOptions1(temp);
        }
      })
      .catch((e: any) => { });
  };

  const getStateOption = (id: string) => {
    WebService.postAPI({ action: `states`, body: { country_id: id } })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          setStateOptions(
            res.list.map((item: any) => {
              return { ...item, ...{ value: item.name } };
              //label: item.name,
            })
          );
        }
      })
      .catch((e: any) => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI({
      action: "save/personal-geography",
      body: data,
      id: "geo_save_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: '624px' }}>
            <h4 className="font-medium font-20 mb-3 pb-1">
              Location and Citizenship
            </h4>
            <Form className="form-style-2">
              <Form.Group className="mb-4">
                <Form.Label>Country where I was born</Form.Label>
                <Controller
                  control={control}
                  name="birth_country_id"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="birth_country_id"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={countryOptions}
                          selected={watchAllFields.birth_country_id}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            getStateOption(e.id);
                          }}
                        />
                      </div>
                      {errors.birth_country_id && (
                        <div className="login-error">
                          <Label
                            title={"Please Select intendedMajor."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields &&
                watchAllFields.birth_country_id &&
                watchAllFields.birth_country_id == 239 && (
                  <Form.Group className="mb-4">
                    <Form.Label>State of birth</Form.Label>
                    <Controller
                      control={control}
                      name="birth_state_id"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="birth_state_id"
                            isSearchable={true}
                            options={stateOptions}
                            selected={watchAllFields.birth_state_id}
                            onChange={(e: any) => {
                              field.onChange(e.id);
                            }}
                            placeholder={"Select State"}
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                )}

              <Form.Group className="mb-4 w-100">
                <Form.Label> City where I was born</Form.Label>
                {/* <Form.Control type="text" {...register("city", { required: false })} /> */}
                <Form.Control
                  type="text"
                  className={`${watchAllFields.birth_city ? "border-brand" : ""}`}
                  value={watchAllFields.birth_city}
                  onKeyPress={(e) => HelperService.allowOnlyAlphabetsValues(e)}
                  {...register("birth_city", { required: false })}
                />

                {/* <Controller
                  control={control}
                  name="birth_city"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select1">
                      <OpenCitySelect country_id={watchAllFields.birth_country_id} selected={watchAllFields.birth_city} onChange={(e: any) => { field.onChange(e.id); }} placeholder={"Choose an option"} />
                    </div>
                  )}
                /> */}

                {errors.birth_city && (
                  <div className="login-error">
                    <Label title={"Please Enter City."} modeError={true} />
                  </div>
                )}
              </Form.Group>

              {/* <Form.Group className="mb-4">
                <Form.Label>
                  City where I was born
                </Form.Label>
                <Form.Control
                  type="text"
                  value={watchAllFields.birth_city}
                  {...register("birth_city", {
                    required: false,
                  })}
                />
              </Form.Group> */}

              <Form.Group className="mb-4">
                <Form.Label>
                  How many years have you lived in the United States?
                </Form.Label>
                <Controller
                  control={control}
                  name="lived_in_usa"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="lived_in_usa"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={Lived_In_us}
                          selected={watchAllFields.lived_in_usa}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>
                  Choose your citizenship category
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="citizenship_status"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <>
                      <div className="react-select1">
                        <OpenSelect
                          sakey="citizenship_status"
                          isSearchable={true}
                          placeholder="Choose an option"
                          options={citizenshipStatus}
                          selected={watchAllFields.citizenship_status}
                          onChange={(e: any) => {
                            field.onChange(e.id);
                            getStateOption(e.id);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </Form.Group>

              {watchAllFields.citizenship_status && (
                <>
                  {watchAllFields.citizenship_status == "US_NATIONAL" ? (
                    // <Form.Group className="mb-4">
                    //   <Form.Label>
                    //     Social Security Number, required if applying for financial aid via FAFSA
                    //   </Form.Label>
                    //   <Form.Control type="text" value={watchAllFields.social_security_number} {...register("social_security_number", { required: false, })} />
                    // </Form.Group>
                    <></>
                  ) : (
                    <>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          List of Countries of citizenship
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                          control={control}
                          name="citizenship_country_id"
                          rules={{ required: false }}
                          render={({ field }: any) => (
                            <>
                              <div className="react-select1">
                                <OpenSelect
                                  sakey="citizenship_country_id"
                                  isSearchable={true}
                                  placeholder="Choose an option"
                                  options={countryOptions1}
                                  selected={
                                    watchAllFields.citizenship_country_id
                                  }
                                  onChange={(e: any) => {
                                    field.onChange(e.id);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>
                          Do you intend to apply for a new or different U.S.
                          Visa?{" "}
                        </Form.Label>
                        <Form.Check
                          className="label-font-12"
                          type="radio"
                          id="Male"
                          label="Yes"
                          value={"YES"}
                          {...register("apply_us_visa", { required: false })}
                        />
                        <Form.Check
                          className="label-font-12"
                          type="radio"
                          id="Female"
                          label="No"
                          value={"NO"}
                          {...register("apply_us_visa", { required: false })}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4">
                        <Form.Label>
                          Do you currently hold a valid U.S. Visa?
                          <span className="text-danger">*</span>{" "}
                        </Form.Label>
                        <Form.Check
                          className="label-font-12"
                          type="radio"
                          id="yes1"
                          label="Yes"
                          value={"YES"}
                          {...register("hold_valid_visa", { required: false })}
                        />
                        <Form.Check
                          className="label-font-12"
                          type="radio"
                          id="yes2"
                          label="No"
                          value={"NO"}
                          {...register("hold_valid_visa", { required: false })}
                        />
                      </Form.Group>
                    </>
                  )}
                </>
              )}
              {/* 
              {
                watchAllFields.citizenship_status && watchAllFields.citizenship_status == "UNDOCUMENTED_TEMPORARY_ENFORED" &&
                <>
                  <Form.Group className="mb-4">
                    <Form.Label>List of Countries of citizenship<span className="text-danger">*</span></Form.Label>
                    <Controller
                      control={control}
                      name="citizenship_country_id"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <>
                          <div className="react-select1">
                            <OpenSelect
                              isSearchable={true}
                              placeholder="Choose an option"
                              options={countryOptions}
                              selected={watchAllFields.citizenship_country_id}
                              onChange={(e: any) => {
                                field.onChange(e.id);
                              }}
                            />
                          </div>
                        </>
                      )}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Do you intend to apply for a new or different U.S. Visa? </Form.Label>
                    <Form.Check
                      className="label-font-12"
                      type="radio"
                      id="Male"
                      label="Yes"
                      value={"YES"}
                      {...register("apply_us_visa", { required: false })}
                    />
                    <Form.Check
                      className="label-font-12"
                      type="radio"
                      id="Female"
                      label="No"
                      value={"NO"}
                      {...register("apply_us_visa", { required: false })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label>Do you currently hold a valid U.S. Visa?<span className="text-danger">*</span> </Form.Label>
                    <Form.Check
                      className="label-font-12"
                      type="radio"
                      id="yes1"
                      label="Yes"
                      value={"YES"}
                      {...register("hold_valid_visa", { required: false })}
                    />
                    <Form.Check
                      className="label-font-12"
                      type="radio"
                      id="yes2"
                      label="No"
                      value={"NO"}
                      {...register("hold_valid_visa", { required: false })}
                    />
                  </Form.Group>
                </>
              } */}
            </Form>

            <div className="text-center">
              <Button
                type="submit"
                id="geo_save_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Geography;
