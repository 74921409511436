import { useState, Fragment, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HelperService from "../../../Services/HelperService";
import calendarIcon from '../../../assets/images/calaendar-icon.svg'
import calendarIcon1 from '../../../assets/images/calendar-icon-color.svg'

interface PropData {
    onChange?: any;
    selected?: any;
    type?: string;
    minData?: any;
    minTime?: any;
    maxTime?: any;
    maxData?: any;
    placeholderText?: any;
    isDisabled?: boolean;
}

const OpenDatePicker = (props: PropData) => {
    const [startDate, setStartDate] = useState<any>();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();

    useEffect(() => {
        if (props.selected) {
            setStartDate(new Date(props.selected) as any);
        } else {
            setStartDate("")
        }
    }, [props.selected]);

    useEffect(() => {
        if (props.minData) {
            setMinDate(props.minData);
        }
        if (props.maxData) {
            setMaxDate(props.maxData)
        }
    }, [props.maxData, props.minData])

    return (
        <>
            <Fragment>
                {
                    props.type == "lable" &&
                    <DatePicker
                        selected={startDate}
                        peekNextMonth
                        dateFormat="mm/dd/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        customInput={
                            startDate
                                ? <span className="cursor-pointer">{HelperService.getFormatedDateForPlannerUpdated(startDate)}</span>
                                : <span className="text-secondary cursor-pointer"> {props.placeholderText && props.placeholderText} </span>
                        }
                        disabled={props.isDisabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(date: any) => {
                            setStartDate(date);
                            if (props.onChange) {
                                props.onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"));
                            }
                        }}
                    />
                }
                <div className={startDate ? "date-picker selected react-date-select" : "date-picker"}>
                    {props.type == "year" &&
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            showYearPicker
                            dateFormat="yyyy"
                            customInput={
                                <div className="cursor-pointer" style={{ width: "100%" }}>
                                    {startDate
                                        ? HelperService.getFormatedYear(startDate)
                                        : <span className="text-secondary">{props.placeholderText ? props.placeholderText : "Select"}</span>}
                                    <img src={startDate ? calendarIcon1 : calendarIcon} className="calander-icon" />
                                </div>
                            }
                            disabled={props.isDisabled}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date: any) => {
                                setStartDate(date);
                                if (props.onChange) {
                                    props.onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"));
                                }
                            }}
                            popperPlacement="bottom-end"
                        />
                    }
                    {
                        props.type == "date" &&
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            peekNextMonth
                            dateFormat="mm/dd/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            customInput={
                                <div className="cursor-pointer" style={{ width: "100%" }}>
                                    {startDate
                                        ? HelperService.getFormatedDateForDatePicker(startDate)
                                        : <span className="text-secondary"> {props.placeholderText && props.placeholderText} </span>}
                                    <img src={startDate ? calendarIcon1 : calendarIcon} className="calander-icon" />
                                </div>
                            }
                            disabled={props.isDisabled}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date: any) => {
                                setStartDate(date);
                                if (props.onChange) {
                                    props.onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"));
                                }
                            }}
                        />
                    }
                    {
                        props.type == "dateTime" &&
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            peekNextMonth
                            dateFormat="MM/dd/yyyy h:mm aa"
                            timeFormat="h:mm aa"
                            timeIntervals={15}
                            showTimeSelect
                            minTime={HelperService.isSameDate(new Date(startDate), props.minTime) ? props.minTime : ""}
                            maxTime={HelperService.isSameDate(new Date(startDate), props.maxTime) ? props.maxTime : ""}
                            // dropdownMode="select"
                            customInput={
                                <div className="cursor-pointer" style={{ width: "100%" }}>
                                    {startDate
                                        ? HelperService.getFormatedDateAndTimeWithoutUTC(startDate)
                                        : <span className="text-secondary"> {props.placeholderText && props.placeholderText} </span>}
                                    <img src={startDate ? calendarIcon1 : calendarIcon} className="calander-icon" />
                                </div>
                            }
                            disabled={props.isDisabled}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date: any) => {
                                setStartDate(date);
                                if (props.onChange) {
                                    props.onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"));
                                }
                            }}
                        />
                    }
                    {
                        props.type == "monthandyear" &&
                        <DatePicker
                            className="form-control"
                            selected={startDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            minTime={HelperService.isSameDate(new Date(startDate), props.minTime) ? props.minTime : ""}
                            maxTime={HelperService.isSameDate(new Date(startDate), props.maxTime) ? props.maxTime : ""}
                            // dropdownMode="select"
                            customInput={
                                <div className="cursor-pointer" style={{ width: "100%" }}>
                                    {startDate
                                        ? HelperService.getFormatedDateWithMonthAndYear(startDate)
                                        : <span className="text-secondary"> {props.placeholderText && props.placeholderText} </span>}
                                    <img src={startDate ? calendarIcon1 : calendarIcon} className="calander-icon" />
                                </div>
                            }
                            disabled={props.isDisabled}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date: any) => {
                                setStartDate(date);
                                if (props.onChange) {
                                    props.onChange(moment(date).format("YYYY-MM-DD HH:mm:ss"));
                                }
                            }}
                            popperPlacement="bottom-end"
                        />
                    }
                </div>
            </Fragment>
        </>
    );
};

OpenDatePicker.defaultProps = {
    placeholder: "Select",
    selected: "",
    isSearchable: false,
    key: new Date().getTime(),
    type: "date"
};

export default OpenDatePicker;


