import { Form, Button, InputGroup, Container } from "react-bootstrap"
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { API_OTPInterface, loginFormInterface } from '../../OpenInterfaces';
import { Label } from '../Common/Label/Label';
import { useState } from 'react';
import WebService from '../../Services/WebService';
import { osName, browserName } from 'react-device-detect';

const AppDeleteUser = () => {
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors, isValid }, control, watch } = useForm<loginFormInterface>({});
    const watchAllFields = watch();
    const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?])[A-Za-z\d~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?]{8,20}$/;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isVerifyEmail, setVerifyEmail] = useState<boolean>(false);

    const confirmDelete = () => {
        WebService.postAPI({ action: "user-account/delete", body: watchAllFields, id: "confirm_btn", })
            .then((res: any) => {
                navigate("/login");
            })
            .catch((e: any) => { });
    };

    const onlogin = (data: loginFormInterface) => {
        const obj: any = {
            user_agent: browserName,
            platform: osName
        }
        WebService.postAPI<API_OTPInterface>({ action: "login", body: { ...data, ...obj }, id: "login_btn", })
            .then((res: API_OTPInterface) => {
                setVerifyEmail(true)
            })
            .catch((e: any) => { });
    };


    return (
        <>
            <div className="login-page">
                <Container>
                    {isVerifyEmail ?
                        <div className="row g-0 justify-content-center align-items-center min-vh-100">
                            <div className="col-lg-12 d-flex align-items-center justify-content-center login-wrap-min-h">
                                <div className="mb-3 w-100">
                                    <div className="px-lg-5 mb-3">
                                        <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                            <div className="mt-3">
                                                <h3 className="font-20 mb-5">Are you sure you want to delete your account?</h3>
                                                <div className="text-center mb-4">
                                                    <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.2558 0.706188C19.9753 -0.801959 23.4348 0.188939 24.9545 2.88418L39.4921 28.1144C39.812 28.8675 39.952 29.4799 39.992 30.116C40.072 31.6024 39.552 33.0471 38.5322 34.1589C37.5124 35.2667 36.1326 35.9207 34.6329 36H5.35781C4.73791 35.9623 4.11802 35.8216 3.53811 35.6036C0.638603 34.4344 -0.761161 31.1446 0.41864 28.2928L15.0562 2.86635C15.5561 1.97256 16.316 1.20164 17.2558 0.706188ZM19.9953 24.5452C19.0355 24.5452 18.2356 25.3379 18.2356 26.2912C18.2356 27.2405 19.0355 28.0352 19.9953 28.0352C20.9552 28.0352 21.735 27.2405 21.735 26.2694C21.735 25.3201 20.9552 24.5452 19.9953 24.5452ZM19.9953 12.1808C19.0355 12.1808 18.2356 12.9517 18.2356 13.905V19.5115C18.2356 20.4627 19.0355 21.2574 19.9953 21.2574C20.9552 21.2574 21.735 20.4627 21.735 19.5115V13.905C21.735 12.9517 20.9552 12.1808 19.9953 12.1808Z" fill="#FF3A29" />
                                                    </svg>
                                                </div>
                                                <p className="font-16 font-medium">Your account will be deleted in 14 days. Logging back into your account within the next 14 days will restore your account.</p>
                                                <Button className="btn-danger w-100 mt-4" id="confirm_btn" onClick={() => confirmDelete()}>Delete account</Button>
                                                <Button className="btn-outline-light text-secondary w-100 mt-2" onClick={() => navigate("/login")}>Cancel</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <form onSubmit={handleSubmit(onlogin)}>
                            <div className="row g-0 justify-content-center   min-vh-100 align-items-center">
                                <div className="col-lg-12 d-flex justify-content-center ">
                                    <div className="mb-3 w-100">
                                        <div className="px-lg-5 mb-3">
                                            <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                                {/* Email */}
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    rules={{
                                                        required: "Please Enter Email",
                                                        pattern: {
                                                            value: emailRegex,
                                                            message: "Enter valid email address",
                                                        }
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur },
                                                        fieldState: { isTouched, isDirty }
                                                    }) => (
                                                        <div className='mb-4'>
                                                            <Form.Group className="overlap-label" controlId="email">
                                                                <Form.Label>Email<span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control type="text" className={`${watchAllFields.email ? 'border-brand' : ''}`} onChange={onChange} onBlur={onBlur} value={watchAllFields.email} />
                                                            </Form.Group>
                                                            {
                                                                (errors["email"]?.message || Boolean(errors["email"]?.message) || (isTouched && !watchAllFields.email) || (watchAllFields.email && !emailRegex.test(watchAllFields.email))) &&
                                                                <div className="login-error">
                                                                    <Label
                                                                        title={(errors.email?.message || watchAllFields.email
                                                                            ? "Enter valid email address"
                                                                            : "Please Enter Email.")}
                                                                        modeError={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                />
                                                {/* Password */}
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{
                                                        required: "Please Enter Password",
                                                        pattern: {
                                                            value: passwordRegex,
                                                            message: "Between 8 to 20 characters and at least one upper case, lower case, number and special character."
                                                        }
                                                    }}
                                                    render={({
                                                        field: { onChange, onBlur },
                                                        fieldState: { isTouched }
                                                    }) => (
                                                        <div className="mb-3">
                                                            <InputGroup className="overlap-label">
                                                                <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control type={showPassword ? "text" : "password"} className={`${watchAllFields.password ? 'border-brand' : ''}`} onChange={onChange} onBlur={onBlur} value={watchAllFields.password} />
                                                                <InputGroup.Text id="basic-addon2" className={`${watchAllFields.password ? 'border-brand cursor-pointer' : 'cursor-pointer'}`} onClick={() => setShowPassword(!showPassword)}> {showPassword ? <BsEyeSlash size={16} /> : <BsEye size={16} />}</InputGroup.Text>
                                                            </InputGroup>
                                                            {
                                                                (errors["password"]?.message || Boolean(errors["password"]?.message) || (isTouched && !watchAllFields.password) || (watchAllFields.password && !passwordRegex.test(watchAllFields.password))) &&
                                                                <div className="login-error">
                                                                    <Label
                                                                        title={(errors.password?.message || watchAllFields.password
                                                                            ? "Between 8 to 20 characters and at least one upper case, lower case, number and special character." : "Please Enter Password.")}
                                                                        modeError={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    )}
                                                />
                                                <div className="text-center">
                                                    <Button type='submit'
                                                        id='login_btn'
                                                        disabled={!isValid}
                                                        className="btn btn-brand-1 w-100">Submit</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </Container>
            </div>
        </>
    )
}
export default AppDeleteUser;