import { useEffect, useRef, useState } from "react";
import "./req-tracker.scss";
import { Card, Form, Table, Button, ProgressBar } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import { BiSortAlt2 } from "react-icons/bi";
import DeleteIcon from '../../assets/images/delete-icon.svg';
import WebService from "../../Services/WebService";
import InfiniteScroll from "react-infinite-scroll-component";
import CardLoader from "../../components/Common/CardLoader";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
import SendApplicationModal from "./SendApplicationModal";
import ViewPreviewModal from "./ViewPreviewModal";
import { userInfoInterface } from "../../OpenInterfaces";
import { RootState } from "../../config/Store";
import { useSelector } from "react-redux";
import HelperService from "../../Services/HelperService";
import { RiGraduationCapFill } from "react-icons/ri";

const RequirementsTracker = () => {
    const navigate = useNavigate();
    const nextOffset = useRef<number>(0);
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [universityList, setUniversityList] = useState<any[]>([]);
    const [universityId, setUniversityId] = useState<string>('');
    const [universityObj, setUniversityObj] = useState<any>(null);
    const [showAlert, setShowAlert] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const [showFinalReview, setShowFinalReview] = useState(false);
    const [removeSchoolId, setRemoveSchoolId] = useState<string>('');
    const keyword = useRef<string>('');

    useEffect(() => {
        const element: any = document.getElementById('page_title');
        if (element) { element.textContent = 'Requirements tracker'; };
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        getSchoolList(0);
        return () => {
            element.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        };
        // eslint-disable-next-line
    }, []);

    const handleCloseAlert = () => {
        setShowAlert(false)
    };

    const handleShowAlert = (id: string) => {
        setRemoveSchoolId(id);
        setShowAlert(true);
    };

    const handleClosePdf = () => {
        setUniversityId('');
        setShowPdf(false);
    };

    const handleShowPdf = (uuid: string, id: string) => {
        setUniversityId(id);
        setShowPdf(true);
    };

    const getSchoolList = (offset: number) => {
        nextOffset.current = offset;
        if (nextOffset.current < 0 || isLoading) { return; }
        nextOffset.current == 0 && setIsLoading(true);
        WebService.getAPI({ action: `get/school-interested/list?limit=10&offset=${offset}&keyword=${keyword.current}` })
            .then((res: any) => {
                if (res.list.length > 0) {
                    if (nextOffset.current == 0) {
                        setUniversityList([...res.list]);
                    } else {
                        setUniversityList([...universityList, ...res.list]);
                    }
                    nextOffset.current = res.next_offset;
                } else {
                    setUniversityList([]);
                }
                setIsLoading(false);
            })
            .catch((e: any) => { setIsLoading(false); });
    };

    const onRemoveSchool = () => {
        WebService.deleteAPI({ action: `delete/school-interested?university_id=${removeSchoolId}`, id: 'delete_btn' })
            .then((res: any) => {
                toast.success(res.message);
                setShowAlert(false);
                setRemoveSchoolId('');
                getSchoolList(0);
            })
            .catch((e: any) => { setShowAlert(false); });
    };

    const handleCloseFinalReview = (e: boolean) => {
        setShowFinalReview(false);
        setUniversityObj(null);
        e && getSchoolList(0);
    };

    const handleShowFinalReview = (data: any) => {
        setUniversityObj(data);
        setShowFinalReview(true);
    };


    return (
        <>
            <div className="page-req-tracker page-container">
                <Card className="card-shadow ">
                    <Card.Body className="p-4">
                        <div className="d-lg-flex mb-3 justify-content-between align-items-end mt-2">
                            <div className=" mb-lg-0 mb-3">
                                <h4 className="font-18 mb-1">Requirements tracker</h4>
                                <p className="text-secondary mb-0">Keep track of all your college applications here.</p>
                            </div>
                            <div className="text-lg-end">
                                <Link to="/my-details" className="btn-outline-brand me-4 px-3 text-center" style={{ width: "175px" }}>See my details</Link>
                                <Button className="btn-brand-1 px-3" onClick={() => { navigate('/search-university') }} style={{ width: "175px" }}>+ Add more schools</Button>
                            </div>
                        </div>
                        <div className="d-flex gap-3 flex-wrap">
                            <Form.Check type="checkbox" id="ViewAll" label="View all" checked={keyword.current == "" ? true : false} className="btn-checkbox"
                                onClick={() => { keyword.current = ""; getSchoolList(0) }} />
                            <Form.Check type="checkbox" id="InProgress" label="In progress" checked={keyword.current == "IN_PROGRESS" ? true : false} className="btn-checkbox"
                                onClick={() => { keyword.current = "IN_PROGRESS"; getSchoolList(0) }} />
                            <Form.Check type="checkbox" id="Completed" label="Completed" className="btn-checkbox" checked={keyword.current == "COMPLETED" ? true : false} onClick={() => { keyword.current = "COMPLETED"; getSchoolList(0) }} />
                        </div>
                    </Card.Body>
                    <div className="table-responsive">
                        {
                            isLoading ? <CardLoader /> :
                                <InfiniteScroll
                                    dataLength={universityList.length}
                                    next={() => { getSchoolList(nextOffset.current) }}
                                    hasMore={true}
                                    loader={nextOffset.current != -1 && universityList.length ? <CardLoader /> : <></>}
                                >
                                    <Table className="table-style ">
                                        <thead>
                                            <tr>
                                                <th style={{ minWidth: "440px" }}><BiSortAlt2 className="sort-icon" /> School</th>
                                                <th style={{ minWidth: "490px" }}><BiSortAlt2 className="sort-icon" />Application requirements</th>
                                                <th style={{ width: "490px" }}>  </th>
                                                <th style={{ minWidth: "80px" }}>  </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                universityList.map((list: any) => {
                                                    return (
                                                        <tr key={list.uuid}>
                                                            <td width={400}>
                                                                <div className="d-flex gap-2">
                                                                    <div>
                                                                        {list.photo ? <img src={list.photo} className="rounded-circle object-fit-cover" width={32} height={32} /> :
                                                                            <div className=" bg-brand-light rounded-circle p-2 d-flex border"><RiGraduationCapFill size={18} className=" text-brand-dark" /></div>}
                                                                    </div>
                                                                    <div>
                                                                        <p className="font-medium mb-0 text-dark cursor-pointer" onClick={() => { navigate(`/requirements-tracker/university/${list.uuid}`) }}>{list.name}</p>
                                                                        <p className={list.school_status === "COMPLETED" ? "text-success mb-0" : "text-secondary mb-0"}>{list.school_status && HelperService.capitalizeFirstLetter(list.school_status.replaceAll("_", " ").toLowerCase())}</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <ProgressBar now={list.application_completed} visuallyHidden className="w-100" />
                                                                    <span className="font-medium text-secondary me-3">{list.application_completed}%</span>
                                                                </div>
                                                            </td>
                                                            <td className="text-secodary">
                                                                {
                                                                    list.application_completed == 0 ? <span className="opacity-5 text-decoration-underline">Download</span> : <a href="javascript:void(0)" onClick={() => handleShowPdf(list.uuid, list.id)} className="text-link text-brand-3 me-4">Download</a>
                                                                }

                                                                {(list.is_send_application || list.application_completed < 100) ? <span className="opacity-5 text-decoration-underline">Send application</span> : <a href="javascript:void(0)" onClick={() => handleShowFinalReview(list)} className="text-link text-brand-3">Send application</a>}</td>
                                                            <td className="text-end"> <a href="javascript:void(0)" className="action-btn" onClick={() => handleShowAlert(list.id)}> <img src={DeleteIcon} width={16} alt="" /> </a> </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </InfiniteScroll>
                        }
                    </div >
                </Card >
            </div >

            <DeleteModal show={showAlert} modalTitle="Delete school from my list" isClose={handleCloseAlert} isDelete={() => { onRemoveSchool() }} type="INTERESTED_SCHOOL" />

            {showPdf && <ViewPreviewModal show={showPdf} isClose={handleClosePdf} id={user_info.uuid} uni_id={universityId} />}

            {showFinalReview && <SendApplicationModal show={showFinalReview} isClose={(e: boolean) => { handleCloseFinalReview(e) }} id={user_info.uuid} data={universityObj} />}
        </>
    );
}

export default RequirementsTracker;