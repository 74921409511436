import { Row, Col, Button, Form } from "react-bootstrap"
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import OpenDatePicker from "../../../components/Common/OpenDatePicker/OpenDatePicker";
import HelperService from "../../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
    activeTab: any;
    isUpdate: Function;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
};

interface formData {
    pte_taken: string;
    pte_expect: string;
    pte_listening_score: string;
    pte_listening_score_date: Date;
    pte_reading_score: string;
    pte_reading_score_date: Date;
    pte_speaking_score: string;
    pte_speaking_score_date: Date;
    pte_writing_score: string;
    pte_writing_score_date: Date;
    pte_grammar_score: string;
    pte_grammar_score_date: Date;
    pte_oral_fluency_score: string;
    pte_oral_fluency_score_date: Date;
    pte_pronunciation_score: string;
    pte_pronunciation_score_date: Date;
    pte_spelling_score: string;
    pte_spelling_score_date: Date;
    pte_vocabulary_score: string;
    pte_vocabulary_score_date: Date;
    pte_written_discourse_score: string;
    pte_written_discourse_score_date: Date;
}


// {
//     "pte_taken":3,
//     "pte_expect":1,
//     "pte_listening_score": 90,
//     "pte_listening_score_date":"2024-02-12",
//     "pte_reading_score":67,
//     "pte_reading_score_date":"2024-04-12",
//     "pte_speaking_score":73,
//     "pte_speaking_score_date":"2024-04-12",
//     "pte_writing_score":79,
//     "pte_writing_score_date":"2024-04-12",
//     "pte_grammar_score":85,
//     "pte_grammar_score_date":null,
//     "pte_oral_fluency_score": null,
//     "pte_oral_fluency_score_date":null,
//     "pte_pronunciation_score": null,
//     "pte_pronunciation_score_date":null,
//     "pte_spelling_score":null,
//     "pte_spelling_score_date":null,
//     "pte_vocabulary_score":null,
//     "pte_vocabulary_score_date":null,
//     "pte_written_discourse_score":null,
//     "pte_written_discourse_score_date":null
// }


const PTEAcadmicTest = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<formData>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const scoreOption: any[] = HelperService.getNumberList(10, 90, 1).reverse();
    const numberOption: any[] = HelperService.getNumberList(1, 5, 1);
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.activeTab == "PTEAcademicTest" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.activeTab == "PTEAcademicTest" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'PTEAcademicTest');
        } else {
            props.isFormDirty(false, 'PTEAcademicTest');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.activeTab == "PTEAcademicTest" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);

    useEffect(() => {
        if (props.activeTab == "PTEAcademicTest") {
            reset({});
            getDatail();
        }
    }, [props.activeTab]);

    const getDatail = () => {
        WebService.getAPI({
            action: `get/standarized/test/detail?type=PTE_ACADEMIC_TEST`,
        })
            .then((res: any) => {
                if (res.result) {
                    reset(res.result);
                    setValue("pte_expect", res.result.pte_expect.toString())
                }
            })
            .catch((e) => { });
    };

    const onSave = async (data: any) => {
        await WebService.postAPI({
            action: "save/tests?type=PTE_ACADEMIC_TEST",
            body: data,
            id: "pte_test_btn",
        })
            .then((res: any) => {
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };


    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col xl={10}>
                        <h4 className="font-medium font-20 mb-4">PTE Academic Tests</h4>
                        <Form className="form-style-2">
                            <Form.Group className="mb-4">
                                <Form.Label>Number of times you have already taken the PTE Academic Test<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name={`pte_taken`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                        <div className="react-select">
                                            <OpenSelect
                                                sakey="pte_taken"
                                                options={numberOption}
                                                selected={watchAllFields.pte_taken}
                                                onChange={(data: any) => { field.onChange(data.id); }}
                                                isSearchable={true}
                                                placeholder="Choose an option"
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Number of future PTE Academic Test sittings you expect<span className="text-danger">*</span></Form.Label>
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="0"
                                    value={"0"}
                                    {...register("pte_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="1"
                                    value={"1"}
                                    {...register("pte_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="2"
                                    value={"2"}
                                    {...register("pte_expect", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="3"
                                    value={"3"}
                                    {...register("pte_expect", { required: false })}
                                />
                            </Form.Group>

                            <Row>
                                {/* Listening */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest listening score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_listening_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_listening_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_listening_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Listening score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_listening_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_listening_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* reading */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest reading score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_reading_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_reading_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_reading_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Reading score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_reading_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_reading_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Speaking */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest speaking score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_speaking_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_speaking_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_speaking_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Speaking score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_speaking_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_speaking_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Writing */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest writing score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_writing_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_writing_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_writing_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Writing score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_writing_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_writing_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Grammar */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest grammar score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_grammar_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_grammar_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_grammar_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Grammar score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_grammar_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_grammar_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Fluency */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest oral fluency score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_oral_fluency_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_oral_fluency_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_oral_fluency_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Oral fluency score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_oral_fluency_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_oral_fluency_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Pronunciation */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest pronunciation score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_pronunciation_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_pronunciation_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_pronunciation_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Pronunciation score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_pronunciation_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_pronunciation_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Spelling */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest spelling score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_spelling_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_spelling_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_spelling_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Spelling score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_spelling_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_spelling_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Vocabulary */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest vocabulary score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_vocabulary_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_vocabulary_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_vocabulary_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Vocabulary score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_vocabulary_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_vocabulary_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>

                                {/* Total */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest written discourse score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`pte_written_discourse_score`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="pte_written_discourse_score"
                                                        options={scoreOption}
                                                        selected={watchAllFields.pte_written_discourse_score}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Written discourse score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="pte_written_discourse_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.pte_written_discourse_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <div className="text-center">
                            <Button type="submit" id="pte_test_btn" className="btn btn-brand-1 w-100">
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
}
export default PTEAcadmicTest;
