import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";
import { useState } from "react";
import { userInfoInterface } from "../OpenInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../config/Store";

const Main = () => {
  const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);
  const [isOpen, setOpen] = useState(false)

  const onToggle = (data: boolean) => {
    setOpen(data)
  }

  return (
    <>
      <div id="main-app" className="layout-veritcle-menu">
        <VerticalMenu isToggle={isOpen} clicked={() => setOpen(false)} />
        <div className="app-body">
          <Header toggle={onToggle} />
          {user_info && <Outlet />}
        </div>
      </div>
    </>
  );
};

export default Main;
