import { Form, Button, Row, Col } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import OpenTagSelect from "../Common/OpenTagSelect/OpenTagSelect";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

interface FutureForm {
  best_describes_i_am: any;
  highest_degree_intended_earn: any;
  career_interest_id: any;
};

const highestDegreeList = [
  { id: "Associate's (AA, AS)", value: "Associate's (AA, AS)" },
  { id: "Bachelor's (BA, BS)", value: "Bachelor's (BA, BS)" },
  { id: "Master's (MA, MS)", value: "Master's (MA, MS)" },
  { id: "Business (MBA, MAcc)", value: "Business (MBA, MAcc)" },
  { id: "Law (JD, LLM)", value: "Law (JD, LLM)" },
  { id: "Medicine (MD, DO, DVM, DDS)", value: "Medicine (MD, DO, DVM, DDS)" },
  { id: "Doctorate (PhD, EdD, etc)", value: "Doctorate (PhD, EdD, etc)" },
  { id: "Undecided", value: "Undecided" },
  { id: "Other", value: "Other" },
];

interface propData {
  isUpdate: Function;
  tab: string;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
};

const FuturePlans = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<FutureForm>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const currentYear = new Date().getFullYear();
  const [occupationOption, setOccupationOption] = useState<any[]>([]);
  const [confirmExit, setConfirmExit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.tab == "career" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.tab == "career" && (!HelperService.isEmptyObject(dirtyFields) || isUpdate)) {
      props.isFormDirty(true, 'career');
    } else {
      props.isFormDirty(false, 'career');
    }
  }, [dirtyFields, watchAllFields, isUpdate]);

  useEffect(() => {
    if (props.tab == "career" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.tab == "career") {
      reset({});
      getOccupationList();
      FuturePlanData();
    }
  }, [props.tab]);

  const getOccupationList = () => {
    WebService.getAPI({ action: `get/occupation/list` })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          let temp: any[] = [];
          res.list.map((item: any) => {
            temp.push({ ...item, ...{ value: item.name } });
          });
          setOccupationOption(temp);
        }
      })
      .catch(() => { });
  };

  const FuturePlanData = () => {
    WebService.getAPI({
      action: `get/user/future-plan/details`,
    })
      .then((res: any) => {
        reset(res.result);
        if (res.result.career_interest_id && res.result.career_interest_id.length) {
          let temp: any[] = res.result.career_interest_id.map((item: any) => {
            item.value = item.name;
            return { ...item };
          });
          setValue("career_interest_id", temp)
        }

      })
      .catch(() => { });
  };

  const onSave = async (data: any) => {
    if (data.career_interest_id && data.career_interest_id.length) {
      data.career_interest_id = data.career_interest_id.map((item: any) => {
        if (item.isNew) {
          item.name = item.value;
        }
        return { ...item };
      });
    }
    await WebService.postAPI({
      action: "save/future-plan/details",
      body: data,
      id: "Future-btn",
    })
      .then((res: any) => {
        setIsUpdate(false);
        props.isUpdate();
        toast.success(res.message);
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  const onAddHobbies = (newTag: any) => {
    setValue("career_interest_id", [
      ...watchAllFields.career_interest_id,
      { ...newTag, ...{ type: "CUSTOM" } },
    ]);
    occupationOption.unshift(newTag);
    setOccupationOption([...occupationOption]);
    setIsUpdate(true);
  };

  const onUpdateOption = (id: string) => {
    setOccupationOption(
      occupationOption.filter((item: any) => {
        if (item.id == id && !item.isNew) {
          return { ...item };
        } else if (item.id != id) {
          return { ...item };
        }
      })
    );

  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col className="form-style-2" style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">Career aspirations</h4>
            <Form.Group className="mb-4">
              <p className="font-16">
                How would you describe yourself?<span className="text-danger">*</span>
              </p>
              <Form.Check
                type="radio"
                id="first-year"
                label={`I am applying as a first-year student and plan to start college in ${currentYear
                  } or ${currentYear + 1}`}
                value={`Applying as a first-year student and plan to start college in ${currentYear
                  } or ${currentYear + 1}`}
                {...register("best_describes_i_am", { required: false })}
              />
              <Form.Check
                type="radio"
                id="startCollege"
                label={`I am planning to start college in ${currentYear + 1}`}
                value={`Planning to start college in ${currentYear + 1}`}
                {...register("best_describes_i_am", { required: false })}
              />

              <Form.Check
                type="radio"
                id="beyond"
                label={`I plan to start college in ${currentYear + 2
                  } or beyond`}
                value={`Planning to start college in ${currentYear + 2
                  } or beyond`}
                {...register("best_describes_i_am", { required: false })}
              />
              <Form.Check
                type="radio"
                id="Already"
                label="I am already a college student"
                value={"Already a college student"}
                {...register("best_describes_i_am", { required: false })}
              />
              {errors.best_describes_i_am && (
                <div className="login-error">
                  <Label
                    title={"Please Select an Option."}
                    modeError={true}
                  />
                </div>
              )}
            </Form.Group>

            <div>
              <Form.Group className="mb-4">
                <Form.Label>What is the highest degree you plan to earn?<span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="highest_degree_intended_earn"
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select1">
                      <OpenSelect
                        sakey="highest_degree_intended_earn"
                        isSearchable={true}
                        options={highestDegreeList}
                        selected={watchAllFields.highest_degree_intended_earn}
                        onChange={(e: any) => { field.onChange(e.id); }}
                        isCustom={false}
                        placeholder="Choose an option"
                      />
                    </div>
                  )}
                />
                {errors.highest_degree_intended_earn && (
                  <div className="login-error">
                    <Label
                      title={"Please Select Highest Degree Earned."}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>I plan to pursue a career in...<span className="text-danger">*</span></Form.Label>
                <p className="font-12 text-secondary mb-2">You can select up to 3</p>
                <OpenTagSelect
                  options={occupationOption}
                  selected={watchAllFields.career_interest_id ? watchAllFields.career_interest_id : []}
                  onChange={(data: any) => { !isUpdate && setIsUpdate(true); setValue("career_interest_id", data); }}
                  selectLimit={3}
                  isCustom={true}
                  onCustomAdd={(data: any) => onAddHobbies(data)}
                  updateOption={(id: string) => { onUpdateOption(id); }}
                  placeholder="Choose an option"
                />
                {errors.career_interest_id && (
                  <div className="login-error">
                    <Label
                      title={"Please Select selectedSport."}
                      modeError={true}
                    />
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="text-center">
              <Button type="submit" id="Future-btn" className="btn btn-brand-1 w-100">Save</Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default FuturePlans;