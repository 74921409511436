import axios from "axios";
import { toast } from 'react-toastify';

interface PropData {
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  type?: string;
  key?: any;
}

export const APP_ID = "2564137a4110b368";
export const REGION = "us";
export const AUTH_KEY = "e92051c2dd38a19d5b184a0c25ba34e0523c9a33";

const CometChatWebService = {

  postAPI: function <T>(props: PropData) {
    this.addLoader(props?.id);
    let url = this.getBaseUrl()
    return new Promise<T>((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      axios
        .post(
          `${url}${props.action}`,
          JSON.stringify(props.body),
          {
            headers: this.getHeaders(props.key),
          }
        )
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.clearLocalStorage()
            window.location.href = "/";
          }
          this.removeLoader(props?.id);
          reject(this.errorHandler(error, props.isShowError));
        });
    });
  },

  putAPI: function (props: PropData) {
    this.addLoader(props?.id);
    let url = this.getBaseUrl()
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      axios
        .put(`${url}${props.action}`, JSON.stringify(props.body), {
          headers: this.getHeaders(),
        })
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id);
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.clearLocalStorage()
            window.location.href = "/";
          }
          this.removeLoader(props?.id);
          reject(this.errorHandler(error));
        });
    });
  },

  getAPI: function <T>(props: PropData) {
    this.addLoader(props?.id)
    let url = this.getBaseUrl()
    return new Promise<T>((resolve, reject) => {
      axios
        .get(`${url}${props.action}`, {
          headers: this.getHeaders(),
        })
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id)
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.clearLocalStorage()
            window.location.href = "/";
          }
          this.removeLoader(props?.id)
          reject(this.errorHandler(error));
        });
    });
  },

  deleteAPI: function (props: PropData) {
    this.addLoader(props?.id)
    let url = this.getBaseUrl()
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url}${props.action}`, {
          headers: this.getHeaders(),
        })
        .then((response) => {
          resolve(response.data);
          this.removeLoader(props?.id)
        })
        .catch((error) => {
          if (error && error.response && error.response.status == 401) {
            this.clearLocalStorage()
            window.location.href = "/";
          }
          this.removeLoader(props?.id)
          reject(this.errorHandler(error));
        });
    });
  },

  getHeaders: function (beHalfId?: string) {
    if (typeof window !== 'undefined') {
      return beHalfId ?
        {
          accept: 'application/json',
          'content-type': 'application/json',
          apikey: AUTH_KEY,
          onBehalfOf: beHalfId
        }
        :
        {
          accept: 'application/json',
          'content-type': 'application/json',
          apikey: AUTH_KEY
        }
    }
  },

  errorHandler: function (error: any, showErrorOnPopup?: boolean) {
    if (error?.response) {
      error = error.response?.data?.error;
    }
    return error
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      this.clearLocalStorage()
      window.location.href = "/";
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occured. Please contact to administrator";
    } else {
      errorMessage = error.data.message;
    }
    if (!showErrorOnPopup) {
      toast.error(errorMessage);
    }
    return errorMessage;
  },

  addLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = true;
        var loader = document.createElement("img");
        loader.src = "/images/loading.gif";
        loader.className = "button-loader";
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },

  clearLocalStorage() {
    if (typeof window !== 'undefined') {
      localStorage.removeItem("token")
      localStorage.removeItem('loginUserImage')
      localStorage.removeItem('uuid')
      localStorage.removeItem('type')
    }
  },

  getBaseUrl() {
    return `https://${APP_ID}.api-${REGION}.cometchat.io/v3/`
  }
};

export default CometChatWebService;