import { useEffect, useState } from "react";
import Logo from "../../assets/images/open-logo.svg";
import { Form, Button, InputGroup, Modal, Container } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import facebookIcon from "../../assets/images/facebook-icon.svg";
import LinkedinIcon from "../../assets/images/linkedin-icon.svg";
import googleIcon from "../../assets/images/google-icon.svg";
import "./signup.scss";
import { Controller, useForm } from "react-hook-form";
import { SignupAPI, signupFormInterface } from "../../OpenInterfaces";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { reduxState } from "../../reducer/CommonReducer";
import { SET_SIGNUP_DATA, setDataInRedux } from "../../action/CommonAction";
import { Dispatch } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import PhoneNumberInput from "../Common/PhoneNumberInput/PhoneNumberInput";

const Signup = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    setValue,
  } = useForm<signupFormInterface>({});
  const watchAllFields = watch();
  const navigate = useNavigate();
  const commonData: any = useSelector<RootState, reduxState>(
    (state: any) => state.commonData
  );
  const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?])[A-Za-z\d~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?]{8,20}$/;
  const mobileRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  const nameRegex = /^[A-Za-z][A-Za-z_ ]{1,30}$/;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!commonData.signUpType || commonData.signUpType == "") {
      navigate("/get-started");
    }
    setValue("phone_code", "239");
    // eslint-disable-next-line
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = (type: string) => {
    if (type) {
      setType(type);
    }
    setShow(true);
  };

  const onSignup = (data: signupFormInterface) => {
    data.role = commonData.signUpType;
    data.phone = data.phone.replaceAll("-", "");
    WebService.postAPI<SignupAPI>({
      action: "signup",
      body: data,
      id: "create-account-btn",
    })
      .then((res: SignupAPI) => {
        toast.success(res?.message);
        dispatch(setDataInRedux({ type: SET_SIGNUP_DATA, value: data }));
        navigate("/verify-email");
      })
      .catch((e) => {});
  };

  return (
    <>
      <div className="login-page signup-page">
        <Container>
          <div className="row g-0 justify-content-center align-items-center min-vh-100">
            <div className="col-lg-12 d-flex align-items-center justify-content-center">
              <div className="mb-3 w-100">
                <div className="px-lg-5 mb-3">
                  {/* <div className="my-5 pt-lg-3 text-center">
                    <img src={Logo} width="110" alt="Logo" />
                  </div> */}
                  <form onSubmit={handleSubmit(onSignup)}>
                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                      <div className="p-2">
                      <div className="text-center pb-2">
                        <h3 className="mb-2 font-bold mb-4 font-36"> Create your account </h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          {/* First Name */}
                          <Controller
                            control={control}
                            name="firstName"
                            rules={{
                              required: "Please Enter First Name",
                              pattern: {
                                value: nameRegex,
                                message:
                                  "Between 3 to 30 characters, letters only.",
                              },
                            }}
                            render={({
                              field: { onChange, onBlur },
                              fieldState: { isTouched, isDirty },
                            }) => (
                              <div className="mb-4">
                                <Form.Group
                                  className="overlap-label"
                                  controlId="firstName"
                                >
                                  <Form.Label>
                                    First name
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={`${
                                      watchAllFields.firstName
                                        ? "border-brand"
                                        : ""
                                    }`}
                                    type="text"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                  />
                                </Form.Group>
                                {(errors["firstName"]?.message ||
                                  Boolean(errors["firstName"]?.message) ||
                                  (isTouched && !watchAllFields.firstName) ||
                                  (watchAllFields.firstName &&
                                    !nameRegex.test(
                                      watchAllFields.firstName
                                    ))) && (
                                  <div className="login-error">
                                    <Label
                                      title={
                                        errors.firstName?.message ||
                                        watchAllFields.firstName
                                          ? "Between 2 to 30 characters, letters only"
                                          : "Please Enter First Name."
                                      }
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-lg-6">
                          {/* Last Name */}
                          <Controller
                            control={control}
                            name="lastName"
                            rules={{
                              required: "Please Enter Last Name",
                              pattern: {
                                value: nameRegex,
                                message:
                                  "Between 2 to 30 characters, letters only.",
                              },
                            }}
                            render={({
                              field: { onChange, onBlur },
                              fieldState: { isTouched, isDirty },
                            }) => (
                              <div className="mb-4">
                                <Form.Group
                                  className="overlap-label"
                                  controlId="lastName"
                                >
                                  <Form.Label>
                                    Last name
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    className={`${
                                      watchAllFields.lastName
                                        ? "border-brand"
                                        : ""
                                    }`}
                                    type="text"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                  />
                                </Form.Group>
                                {(errors["lastName"]?.message ||
                                  Boolean(errors["lastName"]?.message) ||
                                  (isTouched && !watchAllFields.lastName) ||
                                  (watchAllFields.lastName &&
                                    !nameRegex.test(
                                      watchAllFields.lastName
                                    ))) && (
                                  <div className="login-error">
                                    <Label
                                      title={
                                        errors.lastName?.message ||
                                        watchAllFields.lastName
                                          ? "Between 2 to 30 characters, letters only"
                                          : "Please Enter Last Name."
                                      }
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      {/* Phone Number */}
                      <Controller
                        control={control}
                        name="phone"
                        rules={{
                          required: "Please Enter Phone Number",
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched },
                        }: any) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="phone"
                            >
                              <Form.Label>
                                Phone Number
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <PhoneNumberInput
                                selected={watchAllFields.phone_code}
                                value={watchAllFields.phone}
                                onChange={(e: any) => onChange(e)}
                                onBlur={onBlur}
                                onChangeCode={(e: any) =>
                                  setValue("phone_code", e)
                                }
                              />
                            </Form.Group>
                            {(errors["phone"]?.message ||
                              Boolean(errors["phone"]?.message) ||
                              (isTouched && !watchAllFields.phone)) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.phone?.message ||
                                    watchAllFields.phone
                                      ? "Please Enter valid Phone Number."
                                      : "Please Enter Phone Number."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Email */}
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: "Please Enter Email",
                          pattern: {
                            value: emailRegex,
                            message: "Enter valid email address",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched, isDirty },
                        }) => (
                          <div className="mb-4">
                            <Form.Group
                              className="overlap-label"
                              controlId="email"
                            >
                              <Form.Label>
                                Email<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                className={`${
                                  watchAllFields.email ? "border-brand" : ""
                                }`}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            </Form.Group>
                            {(errors["email"]?.message ||
                              Boolean(errors["email"]?.message) ||
                              (isTouched && !watchAllFields.email) ||
                              (watchAllFields.email &&
                                !emailRegex.test(watchAllFields.email))) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.email?.message ||
                                    watchAllFields.email
                                      ? "Enter valid email address"
                                      : "Please Enter Email."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Password */}
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: "Please Enter Password",
                          pattern: {
                            value: passwordRegex,
                            message:
                              "Between 8 to 20 characters and at least one upper case, lower case, number and special character.",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched },
                        }) => (
                          <div className="mb-4">
                            <InputGroup className="overlap-label">
                              <Form.Label>
                                Password<span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                className={`${
                                  watchAllFields.password ? "border-brand" : ""
                                }`}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                              <InputGroup.Text
                                id="basic-addon2"
                                className={`${
                                  watchAllFields.password
                                    ? "border-brand cursor-pointer"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {" "}
                                {showPassword ? (
                                  <BsEyeSlash size={16} />
                                ) : (
                                  <BsEye size={16} />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {(errors["password"]?.message ||
                              Boolean(errors["password"]?.message) ||
                              (isTouched && !watchAllFields.password) ||
                              (watchAllFields.password &&
                                !passwordRegex.test(
                                  watchAllFields.password
                                ))) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.password?.message ||
                                    watchAllFields.password
                                      ? "Between 8 to 20 characters and at least one upper case, lower case, number and special character."
                                      : "Please Enter Password."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      {/* Confirm Password */}
                      <Controller
                        control={control}
                        name="comfirmpassword"
                        rules={{
                          required: "Please Enter Password",
                          validate: (value: any) => {
                            const { password } = getValues();
                            return password === value || "Passwords must match";
                          },
                        }}
                        render={({
                          field: { onChange, onBlur },
                          fieldState: { isTouched },
                        }) => (
                          <div className="mb-3">
                            <InputGroup className="overlap-label">
                              <Form.Label>
                                Confirm Password
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                type={showPassword1 ? "text" : "password"}
                                className={`${
                                  watchAllFields.comfirmpassword
                                    ? "border-brand"
                                    : ""
                                }`}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                              <InputGroup.Text
                                id="basic-addon2"
                                className={`${
                                  watchAllFields.comfirmpassword
                                    ? "border-brand cursor-pointer"
                                    : "cursor-pointer"
                                }`}
                                onClick={() => setShowPassword1(!showPassword1)}
                              >
                                {" "}
                                {showPassword1 ? (
                                  <BsEyeSlash size={16} />
                                ) : (
                                  <BsEye size={16} />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {(errors["comfirmpassword"]?.message ||
                              Boolean(errors["comfirmpassword"]?.message) ||
                              (isTouched && !watchAllFields.comfirmpassword) ||
                              (watchAllFields.comfirmpassword &&
                                watchAllFields.password !=
                                  watchAllFields.comfirmpassword)) && (
                              <div className="login-error">
                                <Label
                                  title={
                                    errors.comfirmpassword?.message ||
                                    watchAllFields.comfirmpassword
                                      ? "Passwords Must Match"
                                      : "Please Enter Confirm Password."
                                  }
                                  modeError={true}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      />

                      <div className="font-14 d-flex flex-wrap">
                        <Form.Check
                          type="checkbox"
                          id="remember"
                          label="I agree to the"
                          className="font-14 manage-alignment"
                          checked={isAccepted}
                          onChange={() => setIsAccepted(!isAccepted)}
                        />
                        &nbsp;
                        <a
                          onClick={() => handleShow("term")}
                          className="text-brand font-14 font-medium cursor-pointer text-decoration-underline mt-1"
                        >
                          Terms and Conditions{" "}
                        </a>
                        &nbsp;<span className="mt-1">and</span>&nbsp;
                        <a
                          className="text-brand font-14 font-medium cursor-pointer mt-1 text-decoration-underline"
                          onClick={() => handleShow("policy")}
                        >
                          Privacy Policy
                        </a>
                      </div>
                      {isValid && !isAccepted && (
                        <div className="login-error">
                          <Label
                            title={"Please Accept Terms and Conditions"}
                            modeError={true}
                          />
                        </div>
                      )}
                      <div className="text-center mt-3">
                        <Button
                          className="btn btn-brand-1 w-100 mb-3"
                          type="submit"
                          disabled={!isValid || !isAccepted}
                          id="create-account-btn"
                        >
                          Create account
                        </Button>
                        <Link
                          to="/get-started"
                          className="btn btn-outline-brand w-100"
                        >
                          Go Back
                        </Link>
                      </div>
                      <div className="d-flex gap-2 align-items-center font-14 text-secondary my-2">
                        <hr className="w-100" /> or <hr className="w-100" />
                      </div>
                      <div className="d-flex gap-2">
                        <Button
                          type="button"
                          className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100"
                          onClick={() => loginWithRedirect()}
                        >
                          {" "}
                          <img
                            src={googleIcon}
                            width="20"
                            alt="icon"
                            className="menu-icon"
                          />
                        </Button>
                        <Button
                          type="button"
                          className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100"
                          onClick={() => loginWithRedirect()}
                        >
                          {" "}
                          <img
                            src={facebookIcon}
                            width="20"
                            alt="icon"
                            className="menu-icon"
                          />
                        </Button>
                        <Button
                          type="button"
                          className="btn-outline-secondary border-secondary-subtle py-2 bg-transparent w-100"
                          onClick={() => loginWithRedirect()}
                        >
                          {" "}
                          <img
                            src={LinkedinIcon}
                            width="20"
                            alt="icon"
                            className="menu-icon"
                          />
                        </Button>
                      </div>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* Terms Codions modal  */}
      <Modal show={show} onHide={handleClose} size="lg" centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title>
            {type == "term" && "Terms and Conditions"}{" "}
            {type == "policy" && "Privacy Policy"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type == "term" && (
            <>
              <p>
                Locks4phone is an application which contains ads and in-app
                purchases. This Service is provided by Sayee Shishir Karna . If
                you choose to use these Service, then you agree to the policies
                defined here. I will not use or share your information with
                anyone except as described in this Privacy Policy.
              </p>
              <p>About the Accessibility API Usages</p>
              <p>
                Locks4phone app uses accessibility services even when app is
                closed and not in use . Locks4phone app uses accessibility
                services to detect phones locked and unlocked conditions
                .Accessibility service is used to lock phone and provide lock
                functionality for immediate locks feature and scheduled locks
                feature . Accessibility services is also used to prevent opening
                settings and prevent uninstallation when strict mode feature is
                enabled by user . Accessibility services are used for these
                functions and data is not collected or shared in any way . Once
                accessibility permissions are granted , accessibility
                permissions persists until explicitly revoked .
              </p>
              <p>
                Please note this app does use third party services that may
                collect information used to identify you.
              </p>
              <p>
                Third party Service Providers used in this application - To
                serve advertisement : Google ads
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app
              </p>
              <p>
                {" "}
                <a
                  href="https://support.google.com/admob"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://support.google.com/admob
                </a>{" "}
              </p>
            </>
          )}
          {type == "policy" && (
            <>
              <p>
                I may update these Privacy Policies from time to time. Thus, you
                are advised to review this page periodically for any changes. I
                will notify you of any changes by posting the new or updated
                Privacy Policies on this page. These changes are effective
                immediately, after they are posted on this page.
              </p>
            </>
          )}

          {/* <h2>Information collection and usage </h2>
                    <p>Locks4phone app does not store or process any personal data about you. Data is not collected or shared in any way.</p>
                    <h2>Changes to this Privacy Policy</h2>
                    <p>I may update these Privacy Policies from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new or updated Privacy Policies on this page. These changes are effective immediately, after they are posted on this page.</p>

                    <h2>Consent</h2>
                    <p>By using Locks4phone app, you hereby consent to Privacy Policy and agree to its terms.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or comments about this Privacy Policy, please contact me at <a href="mailto:karnamahabala@gmail.com">Karnamahabala@gmail.com</a> .</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Signup;