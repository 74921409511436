import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

interface PaginationProps {
  totalItems?: number;
  itemsCountPerPage?: number;
  className?: string;
  changePage: any;
  changeCurrentPage?: any;
}

const Paginations = (props: PaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pages, setPages] = useState<Number[]>([]);


  useEffect(() => {
    setCurrentPage(props.changeCurrentPage)
  }, [props.changeCurrentPage]);

  useEffect(() => {
    var totalItems: number = props.totalItems ? props.totalItems : Number(10);
    var itemsCountPerPage: number = props.itemsCountPerPage ? props.itemsCountPerPage : Number(10);
    const totalPage = Math.ceil(totalItems / itemsCountPerPage);
    setLastPage(totalPage);
    var temp = [];
    let startPage, endPage;
    if (totalPage <= 5) {
      startPage = 1;
      endPage = totalPage;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPage) {
        startPage = totalPage - 4;
        endPage = totalPage;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      temp.push(i);
    }
    setPages(temp);
  }, [currentPage, props.totalItems]);

  const changepage = (page: number) => {
    setCurrentPage(page);
    props.changePage(page);
  };

  const nextchangepage = () => {
    setCurrentPage(currentPage == lastPage ? lastPage : currentPage + 1);
    props.changePage(currentPage);
  };

  const previouschangepage = () => {
    setCurrentPage(currentPage == 1 ? currentPage : currentPage - 1);
    props.changePage(currentPage);
  };



  return (
    <>


      <div className=" d-flex justify-content-center mt-auto">
        <Pagination className="mb-0">
          <Pagination.Prev disabled={currentPage == 1 ? true : false} onClick={() => previouschangepage()} />
          {pages.map((page: any, index: number) => {
            return (
              <Pagination.Item key={"pagable_" + index} active={page === currentPage ? true : false} onClick={() => changepage(page)} > {page}</Pagination.Item>
            );
          })}
          <Pagination.Next disabled={currentPage == lastPage ? true : false} onClick={() => nextchangepage()} />
        </Pagination>
      </div>
    </>
  );
};

export default Paginations;
