import { useEffect, useRef, useState } from "react";
import WebService from "../../Services/WebService";
import { Button, Form, Modal } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import AddEventModal from "./AddEvent";
import DeleteIcon from "../../assets/images/delete-icon.svg";
import { HiBell } from "react-icons/hi";
import { RiEdit2Fill } from "react-icons/ri";
import { addEventFormInterface, task_statusInterface } from "../../OpenInterfaces";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
import HelperService from "../../Services/HelperService";
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import CalederIcon from '../../assets/images/calaendar-icon.svg'
import { MdWatchLater } from "react-icons/md";
import './my-calendar.scss';
import CreateTaskModal from "../Planner/CreateTaskModal";
import { useNavigate } from "react-router-dom";
const localizer = momentLocalizer(moment);
const filterSet = [
    { title: "View All", isChecked: true, type: "ALL" },
    { title: "Event", isChecked: true, type: "CALENDAR" },
    { title: "Reminder", isChecked: true, type: "REMINDER" },
    { title: "Planner", isChecked: true, type: "TASK" },
]

const MyCalender = () => {
    const navigate = useNavigate();
    const calenderDate: Date = useSelector<RootState, Date>((state: any) => state.calendarDate);
    const [open, setOpen] = useState<boolean>(false);
    const [openEditTask, setOpenEditTask] = useState<boolean>(false);
    const [showViewEvent, setShowViewEvent] = useState<boolean>(false);
    const [editEventObj, setEditEventObj] = useState<addEventFormInterface>({} as addEventFormInterface);
    const [viewEventObj, setViewEventObj] = useState<addEventFormInterface>({} as addEventFormInterface);
    const [eventList, setEventList] = useState<addEventFormInterface[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteEvent, setDeleteEvent] = useState<addEventFormInterface>({} as addEventFormInterface);
    const [selectedDate, setSelectedDate] = useState<Date>(calenderDate);
    const [filterOptions, setFilterOptions] = useState<any[]>(filterSet);
    const [weekCount, setWeekCount] = useState<number>(0);

    useEffect(() => {
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "Calendar";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        getEventLists(selectedDate, filterOptions);
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
         // eslint-disable-next-line
    }, []);

    const getEventLists = (date: Date, filter: any[]) => {
        const year = HelperService.getFormatedYear(date);
        const month = new Date(date).getMonth() + 1;
        const week = moment(date).week()
        setWeekCount(week)
        let filters: string = "";
        const temp: any[] = [];
        filter.map((tem: any) => {
            if (tem.isChecked) {
                temp.push(tem.type)
            }
        });
        if (temp && temp.length) {
            let isAll: boolean = false;
            temp.forEach((item: any) => {
                if (item == "ALL") {
                    isAll = true;
                }
            });
            if (isAll) {
                filters = "CALENDAR,REMINDER,TASK"
            } else {
                filters = temp.toString();
            }
        }

        WebService.getAPI({ action: `get/calendar/list?year=${year}&month=${month}&type=${filters}` })
            .then((res: any) => {
                if (res.list && res.list.length) {
                    setTimeout(() => {
                        setEventList(res.list);
                    }, 1000)
                } else {
                    setEventList([])
                };
            })
            .catch((e) => { });
    };

    const onCloseEvent = (type: boolean) => {
        setEditEventObj({} as addEventFormInterface)
        setOpen(false);
        setOpenEditTask(false);
        type && getEventLists(selectedDate, filterOptions);
    };

    const onConfirmDeleteEvent = (data: addEventFormInterface) => {
        setDeleteEvent(data)
        setShowDeleteModal(true);
    };

    const onEditEvent = (data: addEventFormInterface) => {
        setEditEventObj(data);
        if (data.type == "TASK") {
            setOpenEditTask(true);
        } else {
            setOpen(true);
        }
    };

    const onDeleteEvent = () => {
        WebService.putAPI({
            action: `delete/event/${deleteEvent.uuid}?type=${deleteEvent.type}`,
            id: "delete_btn",
        })
            .then((res: any) => {
                toast.success(res.message);
                setShowDeleteModal(false);
                setDeleteEvent({} as addEventFormInterface);
                getEventLists(selectedDate, filterOptions);
            })
            .catch(() => { });
    };

    const components = {
        event: (props: any) => {
            const item: addEventFormInterface = props?.event;
            const eventType = item.type || "";
            switch (eventType) {
                case "CALENDAR":
                    return (
                        <div className="callout purple px-2 py-0"  style={{height:'30px'}} >
                            <p className="mb-0 font-10">{HelperService.getFormatedTime(item.start)}</p>
                            <p className="mb-0 font-10 font-bold text-truncate">{item.title ? item.title : "(No title)"}</p>
                        </div>
                    );
                case "FEED":
                    return (
                        <div className="callout purple px-2 py-1 "  >
                            <p className="mb-0 font-12">{HelperService.getFormatedTime(item.start)}</p>
                            <p className="mb-0 font-12 font-bold text-truncate">{item.title ? item.title : "(No title)"}</p>
                        </div>
                    );
                case "REMINDER":
                    return (
                        <div className="callout green px-2 py-1">
                            <div className="d-flex gap-3 justify-content-between align-items-center">
                                <div className="font-12 font-bold">
                                    {item.title ? item.title : "(No title)"}
                                </div>
                                <HiBell size={20} />
                            </div>
                        </div>
                    );
                case "TASK":
                    return (
                        <div className="callout blue px-2 py-1 font-12 font-bold" >
                            {item.title ? item.title : "(No title)"}
                        </div>
                    );
                default:
                    return null;
            }
        },
    };

    const onChangeMonth = (type: string) => {
        if (type == "PRV") {
            let date: any = moment(new Date(selectedDate)).subtract(1, 'month');
            setSelectedDate(new Date(date));
            getEventLists(date, filterOptions);
        } else if (type == "NEXT") {
            let date: any = moment(new Date(selectedDate)).add(1, 'month');
            setSelectedDate(new Date(date));
            getEventLists(date, filterOptions);
        };
    };

    const onChangeFilter = (i: number) => {
        let temp: any[] = [...filterOptions];
        if (i == 0) {
            if (temp[i].isChecked) {
                temp[i].isChecked = !temp[i].isChecked;
            } else {
                temp = temp.map((item: any, j: number) => {
                    item.isChecked = true
                    return { ...item };
                })
            }
        } else {
            if (temp[0].isChecked && temp[i].isChecked) {
                temp[0].isChecked = !temp[0].isChecked;
            }
            temp = temp.map((item: any, j: number) => {
                if (i == j) {
                    item.isChecked = !item.isChecked
                }
                return { ...item };
            })
        }
        let count: number = 0;
        temp.forEach((item: any, i: number) => {
            if (i != 0 && item.isChecked) {
                count = count + 1;
            }
        });

        if (count == 3) {
            temp[0].isChecked = true;
            temp = [...temp]
        }
        setFilterOptions([...temp]);
        getEventLists(selectedDate, temp);
    };

    const onClose = () => {
        setViewEventObj({} as addEventFormInterface);
        setShowViewEvent(false);
    };


    return (
        <>
            <div className="page-my-calender pe-lg-3 page-container">
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex calendar-nave ps-3">
                        <div className="next-prev-date ms-1">
                            <Button className="btn border-end border-light" onClick={() => { onChangeMonth("PRV") }} ><FaAngleLeft size={14} /></Button>
                            <div className="date-picker">{HelperService.getFormatedDateForDatePicker(selectedDate)}</div>
                            <Button className="btn  border-start border-light" onClick={() => { onChangeMonth("NEXT") }}><FaAngleRight size={14} /></Button>
                        </div>
                        <div className="d-flex ms-3 gap-2">
                            {
                                filterOptions && filterOptions.length > 0 && filterOptions.map((item: any, i: number) => {
                                    return (
                                        <Form.Check
                                            type="checkbox"
                                            id={`${i}`}
                                            key={i}
                                            className={`${item.title} check-btn`}
                                            label={item.title}
                                            checked={item.isChecked}
                                            onClick={() => { onChangeFilter(i) }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="text-end">
                        <Button className="btn btn-light bg-white rounded-3" style={{ boxShadow: "-2px 4px 29px 0px rgba(0, 0, 0, 0.05)" }} onClick={() => setOpen(true)}>+ Create</Button>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="prev-dates-col px-1 font-12 ">
                        <div className="date-box">{weekCount}</div>
                        <div className="date-box">{weekCount + 1}</div>
                        <div className="date-box">{weekCount + 2}</div>
                        <div className="date-box">{weekCount + 3}</div>
                        <div className="date-box">{weekCount + 4}</div>
                    </div>
                    <div className="d-block w-100 " >
                        <Calendar onSelectSlot={(slot: any) => { setOpen(true); }} startAccessor='start' endAccessor='end' selectable
                            date={selectedDate} events={eventList} localizer={localizer} components={components} defaultView="month" view={"month"} toolbar={false} onSelectEvent={(e: addEventFormInterface) => { setViewEventObj(e); setShowViewEvent(true); }} showAllEvents={false} popup enableAutoScroll />
                    </div>
                </div>
                <AddEventModal show={open} isClose={(e: boolean) => onCloseEvent(e)} data={editEventObj} />

                <DeleteModal show={showDeleteModal} isClose={() => { setDeleteEvent({} as addEventFormInterface); setShowDeleteModal(false); }} modalTitle={`Are you sure you want to delete this event?`} isDelete={() => { onDeleteEvent() }} subTitle="You won’t be able to undo this action." type="EVENT" />

                {openEditTask && <CreateTaskModal show={openEditTask} isClose={(e: boolean) => { onCloseEvent(e) }} colData={{} as task_statusInterface} isEdit={true} uuid={editEventObj.uuid} />}

                <Modal show={showViewEvent} onHide={() => { onClose(); }} centered className="date-detail-modal">
                    <Modal.Header closeButton>
                        <Modal.Title className="font-20 font-bold">{viewEventObj && viewEventObj.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="form-style-2 pt-0">
                        {viewEventObj &&
                            <>
                                <div className="d-flex align-items-center gap-4 border-bottom mb-3">
                                    <p className="font-14 me-2 font-light text-secondary"> <img src={CalederIcon} width={16} className=" opacity-50 align-bottom" /> {viewEventObj && viewEventObj.start && HelperService.getFormatedDateForCalender(viewEventObj.start)}</p>
                                    <p className="font-14 font-light text-secondary "><MdWatchLater size={16} className="opacity-50" /> {viewEventObj && viewEventObj.type == "TASK" ? HelperService.getFormatedTime(viewEventObj.start) : HelperService.getFormatedTime(viewEventObj.start) + "-" + HelperService.getFormatedTime(viewEventObj.end)}</p>
                                    {viewEventObj.type == "FEED" && <p className="font-14 me-2 font-light text-secondary"> <MdWatchLater size={16} className="opacity-50" /> {viewEventObj && viewEventObj.location}</p>}
                                </div>
                                <p className="text-secondary font-14 font-light event-description"> {viewEventObj.description}</p>
                                {viewEventObj.type == "FEED" ?
                                    <span className="text-brand text-decoration-underline cursor-pointer" onClick={() => navigate(`/event/${viewEventObj.uuid}`)}>View event</span>
                                    : < div className="text-end d-flex justify-content-end align-items-center gap-3">
                                        <a href="javascript:void(0)" className="text-secondary opacity-50 hover-effect"><RiEdit2Fill size={24} onClick={() => { onEditEvent(viewEventObj); onClose(); }} /></a>
                                        <a href="javascript:void(0)" className="text-secondary opacity-50  hover-effect mb-1"><img src={DeleteIcon} width="16" alt="" onClick={() => { onConfirmDeleteEvent(viewEventObj); onClose(); }} /></a>
                                    </div>}
                            </>
                        }
                    </Modal.Body>
                </Modal >


            </div>
        </>
    );
}

export default MyCalender;