import { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Modal, Card } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import WebService from "../../Services/WebService";
import OpenSelect from "../../components/Common/OpenTagSelect/OpenSelect";
import { Label } from "../../components/Common/Label/Label";
import DeleteIcon from "../../assets/images/delete-icon.svg"
import { task_statusInterface } from "../../OpenInterfaces";
import HelperService from "../../Services/HelperService";
import OpenDatePicker from "../../components/Common/OpenDatePicker/OpenDatePicker";
import moment from "moment";
import Pdfile from '../../assets/images/pdf-file.svg'

interface propData {
    show: boolean;
    isClose: any;
    colData: task_statusInterface;
    isEdit: boolean;
    uuid?: string;
}

interface subTaskInterface {
    title: string;
    is_subtask_completed: string;
    deadline: string;
}

interface taskForm {
    uuid: string;
    project_id: string;
    title: string;
    deadline: Date;
    about_task: string;
    priority: string;
    sequence: string;
    folder_id: string;
    task_status: string;
    sub_tasks: subTaskInterface[];
    is_show_calendar: boolean;
    time: string;
    remind_me: string;
    push_notification: string;
};

const subTaskObj: subTaskInterface = {
    title: "",
    is_subtask_completed: "0",
    deadline: ""
}

const remainderOptios = [
    { id: "Day of", value: "Day of" },
    { id: "1 day before", value: "1 day before" },
    { id: "2 days before", value: "2 days before" },
    { id: "3 days before", value: "3 days before" },
    { id: "1 week before", value: "1 week before" },
];

const notifyOptios = [
    { id: "EMAIL", value: "via email" },
    { id: "APP", value: "in app" },
    { id: "EMAIL_APP", value: "email & in app" }
];

const CreateTaskModal = (props: propData) => {
    const { control, handleSubmit, watch, register, reset, formState: { errors, isValid }, setValue, } = useForm<taskForm>();
    const watchAllFields = watch();
    const [formKey, setFormKey] = useState<any>();
    const [subTaskData, setSubTaskData] = useState<subTaskInterface[]>([{ ...subTaskObj }]);
    const [reminderOption, setReminderOption] = useState<any[]>([])
    const [fileData, setFileData] = useState<any[]>([]);


    useEffect(() => {
        if (props.show) {
            setValue('time', '');
            setReminderOption([]);
            if (props.isEdit && props.uuid) {
                getDetail(props.uuid);
                getDocuments(props.uuid);
                getSubtask(props.uuid);
            } else {
                reset({} as taskForm);
                setFormKey(new Date().getTime());
                setFileData([]);
                setSubTaskData([{ ...subTaskObj }]);
                setValue("priority", "MEDIUM")
            }
        }
    }, [props.show]);

    const onSubmit = (data: taskForm) => {
        if (subTaskData && subTaskData.length) {
            let temp: subTaskInterface[] = subTaskData.filter((item: subTaskInterface) => item.deadline || item.title);
            data.sub_tasks = temp;
        };
        if (props.isEdit) {
            WebService.putAPI({ action: `update-task/${props.uuid}`, body: data, id: "save_task_btn" })
                .then((res: any) => {
                    toast.success(res.message);
                    onClose(true);
                })
                .catch(() => { });
        } else {
            if (!props.colData || !props.colData.id || !props.colData.project_id) return;
            data.folder_id = props.colData.uuid;
            data.project_id = props.colData.project_id;
            data.task_status = props.colData.task_status;

            WebService.postAPI({ action: "add-task", body: data, id: "save_task_btn" })
                .then((res: any) => {
                    toast.success(res.message);
                    if (fileData && fileData.length && !props.isEdit) {
                        onSaveFile(0, res.uuid);
                    } else {
                        onClose(true);
                    }
                })
                .catch(() => { });
        }
    };

    const onSaveFile = (index: number, uuid: string) => {
        if (index == fileData.length) { onClose(true); return; };
        WebService.fileUploadAPI({
            action: `upload/task-file`,
            key: "file",
            file: fileData[index].file,
            body: { task_id: uuid, type: fileData[index].type },
            id: "save_task_btn"
        })
            .then((res: any) => {
                toast.success(res.message);
                onSaveFile(index + 1, uuid);
            })
            .catch((error: any) => { onSaveFile(index + 1, uuid); });
    };

    const onSaveFileAtEdit = (file: any, type: string, uuid: string) => {
        WebService.fileUploadAPI({
            action: `upload/task-file`,
            key: "file",
            file: file,
            body: { task_id: uuid, type: type },
            id: "save_task_btn"
        })
            .then((res: any) => {
                toast.success(res.message);
            })
            .catch((error: any) => { });
    };

    const onClose = (type?: boolean) => {
        setFileData([]);
        setSubTaskData([{ ...subTaskObj }]);
        setValue('time', '');
        reset({});
        props.isClose(type);
    };

    const onChangeFile = (event: any) => {
        const { files } = event.target;
        if (!files || !files[0]) { return; };

        const blob = URL.createObjectURL(files[0]);
        var sizeInBytes = event.target.files[0].size;
        var sizeInKb = sizeInBytes / 1024;
        var sizeInMb = sizeInKb / 1024;
        if (sizeInMb > 5) {
            toast.warn("Image must be less then 5 MB!", { theme: "colored" });
            return;
        }

        var isImageExtensions = /(\.(jpg|jpeg|png|pdf))$/i;
        if (!isImageExtensions.test(files[0].name)) {
            toast.warn("The image must be a file of type: jpg, png, jpeg, pdf.", { theme: "colored" });
            return;
        };

        let isPdf: boolean = false;
        var isPDFExtensions = /(\.(pdf))$/i;
        if (isPDFExtensions.test(files[0].name)) { isPdf = true; };

        fileData.push({ url: isPdf ? Pdfile : blob, file: files[0], file_name: files[0].name, type: isPdf ? "PDF" : "IMAGE", })
        setFileData([...fileData]);
        props.isEdit && onSaveFileAtEdit(files[0], isPdf ? "PDF" : "IMAGE", props.uuid || "")
    };

    const getTimeOption = (e: Date) => {
        // if (watchAllFields.time && e) {
        //     let d1 = HelperService.getFormatedDateWithYear(e);
        //     let d2 = HelperService.getFormatedTimeWithSecond(watchAllFields.time);
        //     let d3 = HelperService.getFormatedDateWithoutUTC(`${d1} ${d2}`);
        //     setValue("time", d3);
        // };
        const startTime = moment(new Date(e)).hour(0).minute(0).second(0);
        const endTime = moment(startTime).add(1, "days");
        let opt: any = HelperService.getTimes(startTime, endTime, 30);
        const options = opt.map((item: any) => {
            if (watchAllFields.time && (HelperService.getFormatedTime(watchAllFields.time) == HelperService.getFormatedTime(item))) {
                setValue("time", item);
            };
            return { id: item, value: HelperService.getFormatedTime(item) };
        });
        setReminderOption(options);
    };

    const onDeleteFile = (index: number, data: any) => {
        if (data && data.uuid) {
            WebService.putAPI({ action: `delete/file/${data.uuid}` })
                .then((res: any) => {
                    toast.success(res.message)
                    setFileData(fileData.filter((ie: any, i: number) => i != index))
                })
                .catch((e) => { });
        } else {
            setFileData(fileData.filter((ie: any, i: number) => i != index))
        }
    };

    const getDetail = (uuid: string) => {
        WebService.getAPI({ action: `get/task-detail/${uuid}` })
            .then((res: any) => {
                if (res.list) {
                    reset(res.list);

                    setTimeout(() => { getTimeOption(res.list?.deadline); }, 100)
                }
            })
            .catch((e) => { });
    };

    const getDocuments = (uuid: string) => {
        WebService.getAPI({ action: `get/task/documents/${uuid}` })
            .then((res: any) => {
                res.list && setFileData(res.list.map((item: any) => { return { ...item, ...{ url: item.type == "PDF" ? Pdfile : item.file } } }));
            })
            .catch((e) => { });
    };

    const getSubtask = (uuid: string) => {
        WebService.getAPI({ action: `get/sub-task/list/${uuid}` })
            .then((res: any) => {
                res.list && setSubTaskData(res.list);
            })
            .catch((e) => { });
    };

    const onDeleteSubtask = (j: number, data: any) => {
        if (data && data.uuid) {
            WebService.putAPI({ action: `delete/sub-task/${data.uuid}` })
                .then((res: any) => {
                    subTaskData.splice(j, 1); setSubTaskData([...subTaskData]);
                })
                .catch((e) => { });
        } else {
            subTaskData.splice(j, 1); setSubTaskData([...subTaskData]);
        }
    };


    return (
        <>
            <Modal
                show={props.show}
                onHide={() => { onClose(); }}
                centered className="modal-size-660">
                <Modal.Header closeButton>
                    <Modal.Title>{props.isEdit ? "Edit Task" : "Add New Task"}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="form-style-2">
                    <form onSubmit={handleSubmit(onSubmit)} key={formKey}>
                        <p className="font-medium font-14">Details</p>
                        <Form.Group className="mb-4 overlap-label" controlId="email">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" value={watchAllFields.title} {...register("title", { required: false })} />
                            {errors.title && (
                                <div className="login-error">
                                    <Label title={"Please enter title."} modeError={true} />
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label">
                            <Form.Label>Deadline<span className="text-danger">*</span></Form.Label>
                            <Controller
                                control={control}
                                name="deadline"
                                rules={{ required: true }}
                                render={({ field }: any) => (
                                    <>
                                        <OpenDatePicker
                                            type="date"
                                            selected={watchAllFields.deadline}
                                            minData={new Date()}
                                            onChange={(e: any) => { field.onChange(e); setTimeout(() => { getTimeOption(e) }, 100) }}
                                        />
                                        {errors.deadline && (
                                            <div className="login-error">
                                                <Label title={"Please select deadline."} modeError={true} />
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label" controlId="email">
                            <Form.Label>About the task</Form.Label>
                            <Form.Control as="textarea" className="h-auto" rows={4} value={watchAllFields.about_task} {...register("about_task", { required: false })} />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Check
                                type="switch"
                                label="Add task to the calendar"
                                id="completed"
                                checked={watchAllFields.is_show_calendar}
                                onChange={() => { setValue("is_show_calendar", !watchAllFields.is_show_calendar) }}
                            />
                        </Form.Group>

                        {watchAllFields.is_show_calendar &&
                            <Form.Group className="mb-4 overlap-label">
                                <Form.Label>Pick a time<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name="time"
                                    rules={{ required: true }}
                                    render={({ field }: any) => (
                                        <>
                                            <div className="react-select">
                                                <OpenSelect
                                                    sakey="time"
                                                    placeholder=""
                                                    isSearchable={true}
                                                    options={reminderOption}
                                                    selected={watchAllFields.time}
                                                    onChange={(e: any) => { field.onChange(e.id); }}
                                                    column={3}
                                                />
                                            </div>
                                            {errors.time && (
                                                <div className="login-error">
                                                    <Label title={"Please select time."} modeError={true} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </Form.Group>
                        }

                        {
                            subTaskData && subTaskData.length > 0 && subTaskData.map((item: subTaskInterface, j: number) => {
                                return (
                                    <div key={j}>
                                        <Form.Group className="mb-4 overlap-label" controlId="email">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" value={item.title} onChange={(e: any) => { subTaskData[j].title = e.target.value; setSubTaskData([...subTaskData]) }} />
                                        </Form.Group>

                                        <Form.Group className=" overlap-label">
                                            <Form.Label>Deadline</Form.Label>
                                            <OpenDatePicker
                                                type="date"
                                                selected={item.deadline}
                                                minData={new Date()}
                                                onChange={(e: any) => { subTaskData[j].deadline = e; setSubTaskData([...subTaskData]) }}
                                            />
                                        </Form.Group>
                                        {subTaskData.length > 1 && < div className="text-end mb-3">
                                            <span className="text-secondary text-decoration-underline font-12 font-medium cursor-pointer mb-2 w-auto" onClick={() => { onDeleteSubtask(j, item) }}>Delete subtask</span>
                                        </div>}
                                    </div>

                                )
                            })
                        }
                        <Button className="btn bg-white font-14 w-100 mb-4 create-btn mt-3" onClick={() => { subTaskData.push({ ...subTaskObj }); setSubTaskData([...subTaskData]) }}>+ Add subtask</Button>

                        <p>Please select priority for this task:</p>
                        <div className="btn-group priority-btns mb-4" role="group">
                            <input type="radio" className="btn-check" id="btnLow" value={"LOW"} checked={watchAllFields.priority == "LOW" ? true : false}
                                {...register("priority", { required: false })} />
                            <label className="btn btn-low" htmlFor="btnLow">Low</label>

                            <input type="radio" className="btn-check" id="btnMedium" value={"MEDIUM"} checked={watchAllFields.priority == "MEDIUM" ? true : false} {...register("priority", { required: false })} />
                            <label className="btn btn-medium" htmlFor="btnMedium">Medium</label>

                            <input type="radio" className="btn-check" id="btnHight" value={"HIGH"} checked={watchAllFields.priority == "HIGH" ? true : false} {...register("priority", { required: false })} />
                            <label className="btn btn-high" htmlFor="btnHight">High</label>
                        </div>

                        <p>Add reminders</p>
                        <Form.Group className="mb-4 overlap-label">
                            <Form.Label>Reminder</Form.Label>
                            <Controller
                                control={control}
                                name="remind_me"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                    <OpenSelect
                                        sakey="remind_me"
                                        placeholder=""
                                        isSearchable={true}
                                        options={remainderOptios}
                                        selected={watchAllFields.remind_me}
                                        onChange={(e: any) => { field.onChange(e.id); }}
                                        column={3}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Form.Group className="mb-4 overlap-label">
                            <Form.Label>Notification</Form.Label>
                            <Controller
                                control={control}
                                name="push_notification"
                                rules={{ required: false }}
                                render={({ field }: any) => (
                                    <OpenSelect
                                        sakey="push_notification"
                                        placeholder=""
                                        isSearchable={true}
                                        options={notifyOptios}
                                        selected={watchAllFields.push_notification}
                                        onChange={(e: any) => { field.onChange(e.id); }}
                                    />
                                )}
                            />
                        </Form.Group>

                        <Button className="btn bg-white font-14 w-100 mb-4 create-btn" type="button" onClick={() => { document.getElementById("upload_task_file")?.click() }}>+ Add files</Button>
                        <input type="file" id="upload_task_file" hidden onChange={onChangeFile} key={new Date().getTime()} />


                        {
                            fileData.map((item: any, i: number) => {
                                return (
                                    <Card className="p-2 border mb-3 rounded-3 border-brand" key={item.file_name}>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex align-items-center gap-3">
                                                <img src={item.url} className="rounded-3 object-fit-cover" width={48} height={48} />
                                                <span className="font-medium text-brand">{item.file_name}</span>
                                            </div>
                                            <button className="bg-transparent p-0 border-0 hover-effect opacity-50" type="button" onClick={() => onDeleteFile(i, item)}> <img src={DeleteIcon} width={16} alt="" /> </button>
                                        </div>
                                    </Card>
                                )
                            })
                        }

                        <div className="mt-3">
                            <Button type="submit" disabled={!isValid} className="btn-brand-1 w-100 mb-3" id="save_task_btn">
                                Save
                            </Button>
                            <Button type="button" className="btn-outline-brand w-100 mb-3" onClick={() => { onClose(); }}>
                                Cancel
                            </Button>
                            {/* {watchAllFields.uuid && <div className="text-center">
                                <button className="border-0 text-danger bg-transparent font-medium text-decoration-underline" type="button" >Delete Project</button>
                            </div>} */}
                        </div>


                    </form>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default CreateTaskModal;
