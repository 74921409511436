import { useEffect, useRef, useState } from "react";
import { Row, Col, Card, Form, Dropdown, Button, ProgressBar, InputGroup } from "react-bootstrap"
import { useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { BsStarFill, BsStar } from "react-icons/bs";
import filtericon from "../../assets/images/filter-icon.svg";
import avtart1 from "../../assets/images/profile-user.png";
import "../UniversitySearch/university-search.scss";
import Paginations from "../../components/Common/pagination/Pagination";
import WebService from "../../Services/WebService";
import HelperService from "../../Services/HelperService";
import { Rating } from "react-simple-star-rating";
import ReviewUniversity from "./ReviewUniversity";
import CardLoader from "../../components/Common/CardLoader";
import { userInfoInterface } from "../../OpenInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../config/Store";
import { RiEdit2Fill } from "react-icons/ri";
import DeleteIcon from "../../assets/images/delete-icon.svg";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
import OpenSelect from "../../components/Common/OpenTagSelect/OpenSelect";
import { Options } from "../../components/Common/OpenTagSelect/OpenSelect";
import { CiSearch } from "react-icons/ci";

const sortOption: Options[] = [
    { id: "desc", value: "Most recent" },
    { id: "asc", value: "Oldest" }
];

const UniversityReview = () => {
    const user_info: userInfoInterface = useSelector<RootState, userInfoInterface>((state: any) => state.commonData.user_info);
    const { id } = useParams<{ id: string }>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [userCount, setUserCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reviewList, setReviewList] = useState<any[]>([]);
    const [reviewDetail, setReviewDetail] = useState<any>({});
    const [showReview1, setShowReview1] = useState(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [editReviewIndex, setEditReviewIndex] = useState<number>(-1);
    const [reviewMessage, setReviewMessage] = useState<string>('');
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteReviewId, setDeleteReviewId] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const sortBy = useRef<string>('desc');

    useEffect(() => {
        const element: any = document.getElementById('page_title');
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        getReviewList(0, searchText);
        getReviewDetail();
        return () => {
            element.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    const getReviewList = (offset: number, keyword: string) => {
        setCurrentPage(offset)
        setIsLoading(true);
        WebService.getAPI({ action: `get/university-review/list?id=${id}&offset=${offset}&limit=10&sort_by=created_at&order_by=${sortBy.current}&keyword=${(keyword || keyword == "") ? keyword : searchText}` })
            .then((res: any) => {
                setUserCount(res.count)
                setReviewList([...res.list]);
                setIsLoading(false);
            })
            .catch((e: any) => { setIsLoading(false); });
    };

    const getReviewDetail = () => {
        setIsLoadingDetail(true);
        WebService.getAPI({ action: `get/university-review/detail?id=${id}` })
            .then((res: any) => {
                setUserCount(res.count);
                res.result.list = res.result.list.reverse();
                setReviewDetail({ ...res.result });
                const element = document.getElementById('page_title');
                if (element) {
                    element.textContent = `${res.result?.university?.name} reviews`;
                };
                setIsLoadingDetail(false);
            })
            .catch((e: any) => { setIsLoadingDetail(false); });
    };

    const handleCloseReview1 = () => setShowReview1(false);

    const onDeleteReview = () => {
        WebService.deleteAPI({ action: `delete/university-review?id=${deleteReviewId}`, id: "delete_btn" })
            .then((res: any) => {
                toast.success(res.message);
                setShowDeleteModal(false);
                setReviewList(reviewList.filter((item: any) => item.uuid !== deleteReviewId));
                setDeleteReviewId('');
                getReviewDetail();
            })
            .catch((e: any) => { });
    };

    const onUpdateReview = (id: string) => {
        WebService.putAPI({ action: `update/university-review`, id: "update_review_btn", body: { id: id, about: reviewMessage } })
            .then((res: any) => {
                toast.success(res.message);
                setEditReviewIndex(-1);
                setReviewList(reviewList.map((item: any) => item.uuid === id ? { ...item, about: reviewMessage } : item));
                setReviewMessage("");
            })
            .catch((e: any) => { setEditReviewIndex(-1); });
    };


    return (
        <>
            <div className="page-container page-university-search">
                <Card className="card-shadow mo-no-card-style">
                    <div className="px-lg-5">
                        <Card.Body className="px-lg-5">
                            <div className="d-flex justify-content-between mb-4 pb-3">
                                <div className="col-lg-4 col-7 d-flex align-items-center gap-3">
                                    <div className="border rounded-2 text-secondary d-flex gap-2 align-items-center px-2 graph-dd ">
                                        <span className="text-nowrap">Sort by</span>
                                        <OpenSelect isSearchable options={sortOption} selected={sortBy.current} placeholder="" onChange={(e: any) => {
                                            if (sortBy.current != e.id) {
                                                sortBy.current = e.id;
                                                getReviewList(0, searchText);
                                            }
                                        }} />
                                    </div>
                                </div>
                                <div className="text-end gap-lg-3 gap-2 col-lg-8 col-5 flex-wrap">
                                    <Dropdown className="filter-dd" align="end" autoClose="outside">
                                        <Dropdown.Toggle className="btn-outline-light"> All Review <img src={filtericon} width={16} className="ms-1" /> </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Form.Check type="checkbox" id="Notprestigious" label="Alum" />
                                            <Form.Check type="checkbox" id="Prestigious" label="Current students" />
                                            <Form.Check type="checkbox" id="Veryprestigious" label="Faculty" />
                                            <Form.Check type="checkbox" id="Ultraprestigious" label="Prospective students" />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <Row className="mb-5">
                                {
                                    isLoadingDetail ? <Col lg={12} xs={12}><CardLoader /></Col> :
                                        <>
                                            <Col lg={2} xs={6} className="text-center border-end">
                                                <p className="font-18 mb-2 font-medium">Total reviews</p>
                                                <h2 className="text-brand font-36 font-bold">{reviewDetail?.total_ratings}</h2>
                                            </Col>
                                            <Col lg={2} xs={6} className="text-center border-end border-xs-0">
                                                <p className="font-18 mb-2 font-medium">Average rating</p>
                                                <h2 className="text-brand font-36 font-bold">{reviewDetail?.avg_rating}</h2>
                                            </Col>
                                            <Col lg={7} className="ps-lg-5 mt-lg-0 mt-4">
                                                {
                                                    !isLoadingDetail && reviewDetail && reviewDetail?.list && reviewDetail?.list.length > 0 && reviewDetail?.list.map((data: any) => {
                                                        return (
                                                            <div className="d-flex gap-3 align-items-center mb-3 justify-content-between">
                                                                <span className="text-brand text-nowrap">
                                                                    <BsStarFill size={18} className=" align-text-bottom" />&nbsp;
                                                                    {data.rating > 1 ? <BsStarFill size={18} className=" align-text-bottom" /> : <BsStar size={18} className=" align-text-bottom" />}&nbsp;
                                                                    {data.rating > 2 ? <BsStarFill size={18} className=" align-text-bottom" /> : <BsStar size={18} className=" align-text-bottom" />}&nbsp;
                                                                    {data.rating > 3 ? <BsStarFill size={18} className=" align-text-bottom" /> : <BsStar size={18} className=" align-text-bottom" />}&nbsp;
                                                                    {data.rating > 4 ? <BsStarFill size={18} className=" align-text-bottom" /> : <BsStar size={18} className=" align-text-bottom" />}&nbsp;
                                                                </span>
                                                                <div className="d-flex gap-2 align-items-center progress progress-sm w-100">
                                                                    <ProgressBar now={data.count} max={reviewDetail?.total_ratings ? reviewDetail?.total_ratings : 100} visuallyHidden className="w-100 " />
                                                                </div>
                                                                <span className="text-secondary me-3">{data.count}</span>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </Col>
                                        </>
                                }
                            </Row>

                            {reviewDetail && !reviewDetail.is_reviewed && < Button className="create-btn w-100 mb-4 bg-light font-12" onClick={() => setShowReview1(true)} style={{ padding: "12px 10px" }}><FiPlus size={18} className="me-2 text-dark" /> Leave a review</Button>}

                            <InputGroup className="mb-4 search-bar">
                                <InputGroup.Text id="inputGroup-sizing-sm"><CiSearch size={16} /></InputGroup.Text>
                                <Form.Control placeholder="Search reviews..." onChange={(e: any) => {
                                    setSearchText(e.target.value); getReviewList(0, e.target.value);
                                }} value={searchText} />
                            </InputGroup>

                            {
                                !isLoading && reviewList && reviewList.length > 0 && reviewList.map((data) => {
                                    return (
                                        <Card className="rounded-4 card-shadow p-4 mb-3" key={data.uuid}>
                                            <Row className="mb-3">
                                                <Col lg={8}>
                                                    <div className="d-flex gap-2">
                                                        <div>
                                                            <img src={data.user?.avtar ? data.user?.avtar : avtart1} width={38} height={38} className=" rounded-circle object-fit-cover" alt="" />
                                                        </div>
                                                        <div>
                                                            <p className="mb-0 font-medium">{data.user?.firstName} {data.user?.lastName}</p>
                                                            <p className="mb-0 text-secondary">Student at {data.user?.university_name}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="text-md-end mt-lg-0 mt-3 ps-5 ps-lg-3 ms-2 ms-lg-0">
                                                    <span className="text-secondary font-12 me-3 d-none d-lg-inline">{data.created_at && HelperService.getTimeago(data.created_at)}</span>
                                                    <span className="text-brand">
                                                        <Rating size={21} readonly allowFraction SVGstrokeColor="#5d61a2" SVGstorkeWidth={1} emptyColor="#fff" fillColor="#5d61a2" onClick={(rate: number) => { }} initialValue={data?.rating ? data.rating : 0} />

                                                    </span>
                                                </Col>
                                            </Row>
                                            {
                                                editReviewIndex === data.id ?
                                                    <div className="mb-3">
                                                        <div className="mt-3">
                                                            <textarea className="form-control border-brand mb-3" style={{ height: "200px" }} placeholder="Write your review here" value={reviewMessage} onChange={(e: any) => { setReviewMessage(e.target.value) }} ></textarea>
                                                        </div>
                                                        < div className="d-flex justify-content-between align-items-center gap-3">
                                                            <Button className="btn-outline-brand w-40" onClick={() => { setReviewMessage(""); setEditReviewIndex(-1); }}>Cancel</Button>
                                                            <Button id="update_review_btn" className="btn-brand-1 w-40" onClick={() => { onUpdateReview(data.uuid); }}>Save changes</Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="text-secondary font-light">
                                                            {data.about}
                                                        </div>
                                                        <div className="text-end">
                                                            {user_info && user_info.id === data.user_id &&
                                                                <>
                                                                    < div className="text-end d-flex justify-content-end align-items-center gap-3">
                                                                        <a href="javascript:void(0)" className="text-secondary opacity-50  hover-effect mb-1"><img src={DeleteIcon} width="18" alt="" onClick={() => { setDeleteReviewId(data.uuid); setShowDeleteModal(true); }} /></a>
                                                                        <a href="javascript:void(0)" className="text-secondary opacity-50 hover-effect"><RiEdit2Fill size={24} onClick={() => { setReviewMessage(data?.about); setEditReviewIndex(data.id); }} /></a>

                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                            }
                                        </Card>
                                    )
                                })
                            }
                            {isLoading && <CardLoader />}

                            <div className=" d-flex justify-content-center">
                                {reviewList && reviewList.length > 0 && <div className=" d-flex justify-content-center">
                                    <Paginations changePage={(page: number) => { getReviewList(page - 1, searchText); }} totalItems={userCount} itemsCountPerPage={10} changeCurrentPage={currentPage + 1} />
                                </div>}
                            </div>
                        </Card.Body>
                    </div>
                </Card >
            </div >

            <DeleteModal show={showDeleteModal} isClose={() => { setDeleteReviewId(""); setShowDeleteModal(false); }} modalTitle={`Are you sure you want to delete this review?`} isDelete={() => { onDeleteReview() }} subTitle="You won’t be able to undo this action." type="EVENT" />

            <ReviewUniversity show={showReview1} isClose={(e: any) => { handleCloseReview1(); e && getReviewDetail(); e && getReviewList(0, searchText) }} name={reviewDetail?.university?.name} id={reviewDetail?.university?.id} />
        </>
    )
}
export default UniversityReview;