import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import WebService from "../Services/WebService";
import loader from "../assets/images/open_new_logo.gif";

const EmailRedirect = () => {
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const token: any = params.get("token");
    const type: any = params.get("type");
    if (!token || !type) {
      window.location.href = "/";
    }
    getRedirectPath(token, type);
    // eslint-disable-next-line
  }, []);

  const getRedirectPath = (token: string, type: string) => {
    let url: string = "";
    const web_base_url: string = WebService.getBaseUrl("frontend");
    const app_base_url: string = "openapp://";
    if (type === "VERIFY_EMAIL" || type === "RESEND_MAIL") {
      url = isMobile
        ? `${app_base_url}confirm/${token}`
        : `${web_base_url}login/${token}`;
    } else if (type === "RESET_PASSWORD" || type === "RESEND_FORGOT_EMAIL") {
      url = isMobile
        ? `${app_base_url}forget_password/${token}`
        : `${web_base_url}set-password/${token}`;
    } else if (type === "REQUEST_UPDATE_EMAIL") {
      url = isMobile
        ? `${app_base_url}change_email/${token}`
        : `${web_base_url}change/email/${token}`;
    } else if (type === "EMAIL_CHANGED") {
      url = isMobile ? `${app_base_url}login` : `${web_base_url}login`;
    } else if (type === "REQ_SCHOOL_REPORT") {
      url = isMobile
        ? `${app_base_url}school_report_request/${token}`
        : `${web_base_url}school-report-request/${token}`;
    } else if (type === "REQ_LOR") {
      url = isMobile
        ? `${app_base_url}recommendation_letter_request/${token}`
        : `${web_base_url}recommendation-letter-request/${token}`;
    } else if (type === "VERIFICATION_REQUEST") {
      url = isMobile
        ? `${app_base_url}verify_activity/${token}`
        : `${web_base_url}verify-activity/${token}`;
    } else {
      url = isMobile
        ? `${app_base_url}login/${token}`
        : `${web_base_url}login/${token}`;
    }
    window.location.href = url;
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <div style={{ width: "100%", textAlign: "center" }}>
        <img src={loader} alt="No loader found" />
      </div>
    </div>
  );
};
export default EmailRedirect;
