import { Card, Row, Col, Tab, Dropdown } from "react-bootstrap"
import menuIcon from "../../../assets/images/menu-icon.svg"
import NavTabs from "./NavTabs";
import { useEffect, useState } from "react";
import WebService from "../../../Services/WebService";
import { useLocation, useNavigate } from "react-router-dom";
import RecentSchool from "../../../components/my-details/RecentSchool";
import OtherSchool from "../../../components/my-details/OtherSchool";
import OtherCollege from "../../../components/my-details/OtherCollege";
import GPAInfo from "../../../components/my-details/GPAInfo";
import GradesAndCourse from "../../../components/my-details/GradesAndCourse";
import Honours from "../../../components/my-details/Honours";
import ComunityBasedOrg from "../../../components/my-details/ComunityBasedOrg";
import FuturePlans from "../../../components/my-details/FuturePlans";

const hashData = ["career", "community", "honors", "gpa", "grades", "courses", "additional", "recent"];

const MyDetailEducation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<string>('recent');
    const [pageStatus, setPageStatus] = useState<any>({
        "isCompleteCurrentSchool": false,
        "isCompleteAdditionalSchool": false,
        "isCompleteCourseCollegeUniversities": false,
        "isCompleteGpa": false,
        "isCompleteHonorsRecognition": false,
        "isCompleteCommunityOrganizations": false,
        "isCompleteCourseGrade": false,
        "isCompleteFuturePlan": false
    });
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const [confirmTabChange, setConfirmTabChange] = useState<string>('');

    useEffect(() => {
        getPageStatus();
        if (location.hash) {
            hashData.map((item: any) => {
                if (item == location.hash.replaceAll("#", "")) {
                    setActiveTab(item);
                }
            })
        };
        var PageHeading: any = document.getElementById("page_title");
        PageHeading.textContent = "My Education Details";
        var BackArrow: any = document.getElementById("back_nav");
        BackArrow.classList.add("d-block");
        BackArrow.onclick = (() => { window.history.back() });
        return () => {
            PageHeading.textContent = "";
            BackArrow.classList.remove("d-block");
            BackArrow.onclick = (() => { window.history.back() });
        }
        // eslint-disable-next-line
    }, []);

    const getPageStatus = () => {
        WebService.getAPI({ action: "get/personal-detail?type=EDUCATION" })
            .then((res: any) => {
                if (res.result) {
                    setPageStatus(res.result);
                }
            })
            .catch((e: any) => { });
    };

    const onChangeTab = (tab: string) => {
        if (isFormDirty) {
            setConfirmTabChange(tab);
        } else {
            setActiveTab(tab);
            navigate(`#${tab}`, { replace: true });
        }
    };

    const updateFormStatus = (e: boolean, tab: string) => {
        if (tab == activeTab) {
            setIsFormDirty(e);
        }
    };

    return (
        <>
            <div className="page-my-details page-container">
                <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                    <Row className="gx-05">
                        <Col>
                            <Card className="card-shadow form-style-2 rounded-end-0 card-padding h-100 about-card-min-h mo-no-card-style">
                                <Tab.Content>
                                    <Tab.Pane eventKey="recent">
                                        {activeTab == "recent" && <RecentSchool isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="additional">
                                        {activeTab == "additional" && <OtherSchool type={"SCHOOL"} isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="courses">
                                        {activeTab == "courses" && <OtherCollege type={"COLLEGE"} isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="gpa">
                                        {activeTab == "gpa" && <GPAInfo isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="grades">
                                        {activeTab == "grades" && <GradesAndCourse isUpdate={() => { getPageStatus() }} tab={activeTab} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="honors">
                                        {activeTab == "honors" && <Honours isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="community">
                                        {activeTab == "community" && <ComunityBasedOrg isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="career">
                                        {activeTab == "career" && <FuturePlans isUpdate={() => { getPageStatus() }} tab={activeTab} isFormDirty={(e: boolean, tab: string) => updateFormStatus(e, tab)} confirmTabChange={confirmTabChange} updateActiveTab={(tab: string) => { setIsFormDirty(false); setConfirmTabChange(""); setActiveTab(tab); }} />}
                                    </Tab.Pane>
                                </Tab.Content>

                            </Card>
                        </Col>
                        <Col lg={4} className="about-tab-col">
                            <div className="d-lg-block d-none d-flex h-100">
                                <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                            </div>
                            <Dropdown className="d-lg-none d-block mob-tab-menu">
                                <Dropdown.Toggle className="btn btn-tabs-nav"><img src={menuIcon} width={18} alt="" /></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <NavTabs activeTab={activeTab} changeTab={(tab: string) => { onChangeTab(tab); }} status={pageStatus} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    )
}
export default MyDetailEducation;
