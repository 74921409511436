import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import WebService from "../../Services/WebService";
import { BiTrash } from "react-icons/bi";
import UploadFile from "../Common/UploadFile/UploadFile";
import docIcon from "../../assets/images/doc-icon.svg";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import CardLoader from "../Common/CardLoader";

interface PropData {
  show: boolean;
  isClose: Function;
  grade: number;
  isEdit: boolean;
}

const gradingOption = [
  { id: "A-F", value: "A-F" },
  { id: "1-100", value: "1-100" },
  { id: "0.0-4.0", value: "0.0-4.0" },
  { id: "0.0-5.0", value: "0.0-5.0" },
  { id: "0.0-6.0", value: "0.0-6.0" },
  { id: "1-7", value: "1-7" },
  { id: "1-10", value: "1-10" },
  { id: "1-11", value: "1-11" },
  { id: "1-20", value: "1-20" },
  { id: "Other", value: "Other" },
];

const scheduleOption = [
  { id: "SEMESTER", value: "Semester" },
  { id: "TRIMESTER", value: "Trimester" },
  { id: "QUARTER", value: "Quarter" },
  { id: "YEARLY", value: "Yearly" },
  { id: "OTHER", value: "Other" },
];

const gradeCouseLevel = [
  { id: "Standard/Regular", value: "Standard/Regular" },
  { id: "Accelerated", value: "Accelerated" },
  { id: "Advanced", value: "Advanced" },
  { id: "AdvancePlacement(AP)", value: "Advance Placement (AP)" },
  {
    id: "AS/A-Level/International/A-Level/Cambridge AICE",
    value: "AS/A-Level/International/A-Level/Cambridge AICE",
  },
  { id: "College Prep", value: "College Prep" },
  { id: "Dual Enrollment", value: "Dual Enrollment" },
  { id: "Enriched", value: "Enriched" },
  { id: "GCSE, IGCSE", value: "GCSE, IGCSE" },
  { id: "Gifted", value: "Gifted" },
  { id: "Honors", value: "Honors" },
  { id: "High Honors", value: "High Honors" },
  { id: "Intensive", value: "Intensive" },
  {
    id: "International Baccalaureate (IB)",
    value: "International Baccalaureate (IB)",
  },
  { id: "Pre IB", value: "Pre IB" },
  { id: "Regents", value: "Regents" },
  { id: "N/A", value: "N/A" },
];

const AFGradeOptions = [
  { id: "A +", value: "A +" },
  { id: "A", value: "A" },
  { id: "A -", value: "A -" },
  { id: "B +", value: "B +" },
  { id: "B", value: "B" },
  { id: "B -", value: "B -" },
  { id: "C +", value: "C +" },
  { id: "C", value: "C" },
  { id: "C -", value: "C -" },
  { id: "D +", value: "D +" },
  { id: "D", value: "D" },
  { id: "D -", value: "D -" },
  { id: "E", value: "E" },
  { id: "F", value: "F" },
  { id: "Incomplete", value: "Incomplete" },
  { id: "No Grade", value: "No Grade" },
  { id: "Fail - (Pass / Fail)", value: "Fail - (Pass / Fail)" },
  { id: "Pass - (Pass / Fail)", value: "Pass - (Pass / Fail)" },
  { id: "S - Satisfactory", value: "S - Satisfactory" },
  { id: "U - Unsatisfactory", value: "U - Unsatisfactory" },
  { id: "Withdrawn", value: "Withdrawn" },
  { id: "Withdrawn - Failed", value: "Withdrawn - Failed" },
  { id: "Medical Reason", value: "Medical Reason" },
];

const commomOptions = [
  { id: "Incomplete", value: "Incomplete" },
  { id: "No Grade", value: "No Grade" },
  { id: "Fail - (Pass / Fail)", value: "Fail - (Pass / Fail)" },
  { id: "Pass - (Pass / Fail)", value: "Pass - (Pass / Fail)" },
  { id: "S - Satisfactory", value: "S - Satisfactory" },
  { id: "U - Unsatisfactory", value: "U - Unsatisfactory" },
  { id: "Withdrawn", value: "Withdrawn" },
  { id: "Withdrawn - Failed", value: "Withdrawn - Failed" },
  { id: "Medical Reason", value: "Medical Reason" },
];

const docSemOption = [
  {
    type: "SEMESTER",
    option: [
      { id: "S1", value: "Semester 1" },
      { id: "S2", value: "Semester 2" },
      { id: "Final", value: "Final" },
    ],
  },
  {
    type: "TRIMESTER",
    option: [
      { id: "T1", value: "Trimester 1" },
      { id: "T2", value: "Trimester 2" },
      { id: "T3", value: "Trimester 3" },
      { id: "Final", value: "Final" },
    ],
  },
  {
    type: "QUARTER",
    option: [
      { id: "Q1", value: "Quarter 1" },
      { id: "Q2", value: "Quarter 2" },
      { id: "Q3", value: "Quarter 3" },
      { id: "Q4", value: "Quarter 4" },
      { id: "Final", value: "Final" },
    ],
  },
  {
    type: "YEARLY",
    option: [
      // { id: "Yearly", value: "Yearly" },
      { id: "Final", value: "Final" },
    ],
  },
];

const AddGradeModal = (props: PropData) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
    control,
    reset,
  } = useForm<any>({});
  const {
    handleSubmit: handleSubmit1,
    formState: { isValid: isValid1 },
    watch: watch1,
    setValue: setValue1,
    control: control1,
    reset: reset1,
  } = useForm<any>({});
  const watchAllFields = watch();
  const watchAllFields1 = watch1();
  const yearOption: any = HelperService.getPairedYearList(
    35,
    new Date().getFullYear()
  );
  const OneToHunradeOptions1: any[] = HelperService.getNumberList(1, 105, 1);
  const OneToHunradeOptions: any[] = [
    ...OneToHunradeOptions1.reverse(),
    ...commomOptions,
  ];
  const zeroToFive: any[] = [
    ...HelperService.getNumberList(0.0, 5.0, 0.1).reverse(),
    ...commomOptions,
  ];
  const zeroToFour: any[] = [
    ...HelperService.getNumberList(0.0, 4.0, 0.1).reverse(),
    ...commomOptions,
  ];
  const zeroToSix: any[] = [
    ...HelperService.getNumberList(0.0, 6.0, 0.1).reverse(),
    ...commomOptions,
  ];
  const oneToSeven: any[] = [
    ...HelperService.getNumberList(1, 7, 1).reverse(),
    ...commomOptions,
  ];
  const oneToTen: any[] = [
    ...HelperService.getNumberList(1, 10, 1).reverse(),
    ...commomOptions,
  ];
  const oneToEleven: any[] = [
    ...HelperService.getNumberList(1, 11, 1).reverse(),
    ...commomOptions,
  ];
  const oneToTwenty: any[] = [
    ...HelperService.getNumberList(1, 20, 1).reverse(),
    ...commomOptions,
  ];
  const [userData, setUserData] = useState<any[]>([
    {
      random_id: HelperService.generateOTP(),
      courses: [HelperService.generateOTP()],
    },
  ]);
  const [userSchool, setUserSchool] = useState<any[]>([]);
  const [showDocModal, setShowDocModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const [fileSize, setFileSize] = useState<string>("");
  const [userTranscript, setUserTranscript] = useState<any[]>([]);
  const [subjectList, setSubjectList] = useState<any[]>([]);

  useEffect(() => {
    if (props.show) {
      reset({});
      setUserData([
        {
          random_id: HelperService.generateOTP(),
          courses: [HelperService.generateOTP()],
        },
      ]);
      subjectList.length == 0 && getSubjectList();
      getCourseDetail();
      getUserTranscript();
      getUserSchool();
    }
  }, [props.show]);

  const getSubjectList = () => {
    WebService.getAPI({
      action: "subject/list?type=SUBJECT",
    }).then((res: any) => {
      if (res.list && res.list.length > 0) {
        setSubjectList(
          res.list.map((item: any) => {
            return {
              ...item,
              ...{ ...{ value: item.name } },
            };
          })
        );
      }
    });
  };

  const getCourseDetail = () => {
    setShowLoader(true);
    WebService.getAPI({
      action: `get/user/course-grade/details?type=${props.grade}th`,
    })
      .then((res: any) => {
        if (res.result && res.result.length) {
          let temp: any[] = [];
          res.result.map((item: any) => {
            let random_id = HelperService.generateOTP();
            let obj: any = { random_id: random_id, courses: [] };
            setValue(`school_id${random_id}`, item.school_id);
            setValue(`uuid${random_id}`, item.uuid);
            setValue(`school_year${random_id}`, item.school_year);
            setValue(`grading_scale${random_id}`, item.grading_scale);
            setValue(`schedule${random_id}`, item.schedule);
            setValue(`other_describe${random_id}`, item.other_describe);
            if (item.courses && item.courses.length) {
              item.courses.map((ite: any) => {
                let sub_random_id = HelperService.generateOTP();
                obj.courses.push(sub_random_id);
                /////////// SET VALUE /////////////////////
                setValue(`uuid_${random_id}_${sub_random_id}`, ite.uuid);
                setValue(`subject_${random_id}_${sub_random_id}`, ite.subject);
                setValue(
                  `course_name_${random_id}_${sub_random_id}`,
                  ite.course_name
                );
                setValue(
                  `course_level_${random_id}_${sub_random_id}`,
                  ite.course_level
                );
                setValue(`g_1_${random_id}_${sub_random_id}`, ite.g_1);
                setValue(`g_2_${random_id}_${sub_random_id}`, ite.g_2);
                setValue(`g_3_${random_id}_${sub_random_id}`, ite.g_3);
                setValue(`g_4_${random_id}_${sub_random_id}`, ite.g_4);
                setValue(`g_final_${random_id}_${sub_random_id}`, ite.g_final);
                setValue(
                  `is_credit_${random_id}_${sub_random_id}`,
                  ite.is_credit
                );
                setValue(`c_1_${random_id}_${sub_random_id}`, ite.c_1);
                setValue(`c_2_${random_id}_${sub_random_id}`, ite.c_2);
                setValue(`c_3_${random_id}_${sub_random_id}`, ite.c_3);
                setValue(`c_4_${random_id}_${sub_random_id}`, ite.c_4);
                setValue(`c_final_${random_id}_${sub_random_id}`, ite.c_final);
                ////////////////////////////////////////////
              });
            } else {
              let sub_random_id = HelperService.generateOTP();
              obj.courses.push(sub_random_id);
            }
            temp.push(obj);
          });
          setUserData(temp);
        }
        setShowLoader(false);
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const closeClick = (type?: boolean) => {
    reset({});
    setUserData([
      {
        random_id: HelperService.generateOTP(),
        courses: [HelperService.generateOTP()],
      },
    ]);
    closeDocModal();
    props.isClose(type);
  };

  const onSave = (data: any) => {
    let temp: any[] = [];
    userData.map((item: any) => {
      let obj: any = {
        uuid: data[`uuid${item.random_id}`],
        school_id: data[`school_id${item.random_id}`],
        school_year: data[`school_year${item.random_id}`],
        grading_scale: data[`grading_scale${item.random_id}`],
        type:
          props.grade == 9
            ? "9th"
            : props.grade == 10
            ? "10th"
            : props.grade == 11
            ? "11th"
            : props.grade == 12
            ? "12th"
            : "",
        schedule: data[`schedule${item.random_id}`],
        other_describe: data[`other_describe${item.random_id}`],
        courses: [],
      };
      item.courses &&
        item.courses.length &&
        item.courses.map((res: any) => {
          let obj1 = {
            uuid: data[`uuid_${item.random_id}_${res}`],
            subject: data[`subject_${item.random_id}_${res}`],
            course_name: data[`course_name_${item.random_id}_${res}`],
            course_level: data[`course_level_${item.random_id}_${res}`],
            g_1: data[`g_1_${item.random_id}_${res}`],
            g_2: data[`g_2_${item.random_id}_${res}`],
            g_3: data[`g_3_${item.random_id}_${res}`],
            g_4: data[`g_4_${item.random_id}_${res}`],
            g_final: data[`g_final_${item.random_id}_${res}`],
            is_credit: data[`is_credit_${item.random_id}_${res}`] ? 1 : 0,
            c_1: !data[`is_credit_${item.random_id}_${res}`]
              ? data[`c_1_${item.random_id}_${res}`]
              : null,
            c_2: !data[`is_credit_${item.random_id}_${res}`]
              ? data[`c_2_${item.random_id}_${res}`]
              : null,
            c_3: !data[`is_credit_${item.random_id}_${res}`]
              ? data[`c_3_${item.random_id}_${res}`]
              : null,
            c_4: !data[`is_credit_${item.random_id}_${res}`]
              ? data[`c_4_${item.random_id}_${res}`]
              : null,
            c_final: !data[`is_credit_${item.random_id}_${res}`]
              ? data[`c_final_${item.random_id}_${res}`]
              : null,
          };
          obj.courses.push(obj1);
        });
      temp.push(obj);
    });

    WebService.postAPI({
      action: props.isEdit
        ? `update/student-course?type=${props.grade}th`
        : "save/student-course",
      body: { grade_courses: temp },
      id: "Save_grades_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        closeClick(true);
      })
      .catch((e: any) => {});
  };

  const getUserSchool = () => {
    WebService.getAPI({ action: `get/user-school/list` })
      .then((res: any) => {
        if (res.list && res.list.length) {
          setUserSchool(
            res.list.map((item: any) => {
              item.id = item.high_school_id;
              item.value = item.name;
              item.disable = false;
              return { ...item };
            })
          );
        }
      })
      .catch(() => {});
  };

  const onAddCourse = (id: string) => {
    setUserData(
      userData.map((item: any) => {
        if (item.random_id == id) {
          if (item.courses && item.courses.length > 0) {
            item.courses.push(HelperService.generateOTP());
          } else {
            item.courses = [HelperService.generateOTP()];
          }
          return { ...item };
        } else {
          return { ...item };
        }
      })
    );
  };

  const onDeleteCourse = (id1: string, id2: string) => {
    setUserData(
      userData.map((item: any) => {
        if (item.random_id == id1) {
          if (item.courses && item.courses.length > 0) {
            item.courses = item.courses.filter((ite: any) => {
              return ite != id2;
            });
          }
          return { ...item };
        } else {
          return { ...item };
        }
      })
    );
    resetCourse(id1, id2);
  };

  const resetCourse = (id1: string, id2: string) => {
    setValue(`subject_${id1}_${id2}`, "");
    setValue(`course_name_${id1}_${id2}`, "");
    setValue(`course_level_${id1}_${id2}`, "");
    setValue(`g_1_${id1}_${id2}`, "");
    setValue(`g_2_${id1}_${id2}`, "");
    setValue(`g_3_${id1}_${id2}`, "");
    setValue(`g_4_${id1}_${id2}`, "");
    setValue(`g_final_${id1}_${id2}`, "");
    setValue(`is_credit_${id1}_${id2}`, "");
    setValue(`c_1_${id1}_${id2}`, "");
    setValue(`c_2_${id1}_${id2}`, "");
    setValue(`c_3_${id1}_${id2}`, "");
    setValue(`c_4_${id1}_${id2}`, "");
    setValue(`c_final_${id1}_${id2}`, "");
  };

  const getOptions = (id: string) => {
    const temp: any[] = [{ id: "N/A", value: "N/A" }];
    if (watchAllFields[`grading_scale${id}`] == "A-F") {
      return [...AFGradeOptions, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "1-100") {
      return [...OneToHunradeOptions, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "0.0-4.0") {
      return [...zeroToFour, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "0.0-5.0") {
      return [...zeroToFive, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "0.0-6.0") {
      return [...zeroToSix, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "1-7") {
      return [...oneToSeven, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "1-10") {
      return [...oneToTen, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "1-11") {
      return [...oneToEleven, ...temp];
    } else if (watchAllFields[`grading_scale${id}`] == "1-20") {
      return [...oneToTwenty, ...temp];
    } else {
      return [];
    }
  };

  const AddOrRemoveNewForm = async (index?: number) => {
    if (index || index == 0) {
      userData.splice(index, 1);
    } else {
      userData.push({
        random_id: HelperService.generateOTP(),
        courses: [HelperService.generateOTP()],
      });
    }
    setUserData([...userData]);
    closeDocModal();
  };

  const closeDocModal = () => {
    setFile("");
    setFileName("");
    setFileSize("");
    reset1({});
    setShowError(false);
    setShowDocModal(false);
  };

  const onUploadTranscript = (data: any, name: string, size: string) => {
    if (handleSchoolandSchedule()) {
      return;
    }
    setFile(data);
    setFileName(name);
    setFileSize(size);
    setShowDocModal(true);
  };

  const handleSchoolandSchedule = () => {
    let isError: boolean = false;
    userData.map((item: any) => {
      if (
        !watchAllFields[`schedule${item.random_id}`] ||
        !watchAllFields[`school_id${item.random_id}`]
      ) {
        isError = true;
      }
    });
    if (isError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    return isError;
  };

  const getScheduleUnitOptions = (type: string) => {
    let temp: any[] = [];
    docSemOption.filter((res: any) => {
      if (type == res.type) {
        temp = res.option;
      }
    });
    return temp;
  };

  const getUserSchoolOption = (id: string) => {
    let temp: any[] = [...userSchool];
    temp = temp.map((item: any) => {
      let isDisable: boolean = false;
      userData.map((ite: any) => {
        if (
          ite.random_id != id &&
          watchAllFields[`school_id${ite.random_id}`] == item.id
        ) {
          isDisable = true;
        }
      });
      item.disable = isDisable;
      return { ...item };
    });
    return temp;
  };

  const onSaveTranscript = (data: any) => {
    data.class = `${props.grade}th`;
    WebService.fileUploadAPI({
      action: `upload/document`,
      key: "file",
      file: file,
      body: data,
      id: "Save_btn_transcript",
    })
      .then((res: any) => {
        toast.success(res.message);
        closeDocModal();
        getUserTranscript();
      })
      .catch((error: any) => {});
  };

  const getUserTranscript = () => {
    WebService.getAPI({
      action: `get/user-document/detail?grade=${props.grade}th`,
    })
      .then((res: any) => {
        if (res.result && res.result.length) {
          setUserTranscript(res.result);
        } else {
          setUserTranscript([]);
        }
      })
      .catch(() => {});
  };

  const deleteTranscript = (id: string) => {
    WebService.postAPI({ action: `delete-document?uuid=${id}` })
      .then((res: any) => {
        toast.success(res.message);
        setUserTranscript(
          userTranscript.filter((item: any) => {
            return item.uuid != id;
          })
        );
      })
      .catch(() => {});
  };

  const handleDisableSave = () => {
    let isDisalbe: boolean = true;
    if (watchAllFields) {
      Object.entries(watchAllFields).map((item: any) => {
        if (item[1]) {
          isDisalbe = false;
        }
      });
    }
    return isDisalbe;
  };

  const getFileUploadSchoolOption = () => {
    let temp: any[] = [];
    if (userSchool && userSchool.length > 0) {
      temp = userSchool.filter((item: any) => {
        let isSelcted: boolean = false;
        userData.map((ite: any) => {
          if (watchAllFields[`school_id${ite.random_id}`] == item.id) {
            isSelcted = true;
          }
        });
        if (isSelcted) {
          return item;
        }
      });
      temp = temp.map((item: any) => {
        item.disable = false;
        return { ...item };
      });
    }
    return temp;
  };

  return (
    <>
      <Modal
        className="modal-size-660"
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton onClick={() => closeClick()}>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.grade}th grade
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showLoader ? (
            <CardLoader />
          ) : (
            <Row>
              <form onSubmit={handleSubmit(onSave)}>
                {userData.map((item: any, i: number) => {
                  return (
                    <Row key={item.random_id}>
                      <Col lg={12} md={12}>
                        {userData.length > 1 && (
                          <div className="text-end">
                            {i > 0 && <hr />}
                            <BiTrash
                              size={18}
                              className="text-danger cursor-pointer"
                              onClick={() => AddOrRemoveNewForm(i)}
                            />
                          </div>
                        )}
                        <Form.Group className="mb-4">
                          <Form.Label>
                            {" "}
                            School name<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name={`school_id${item.random_id}`}
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <div className="react-select1">
                                <OpenSelect
                                  sakey={`school_id${item.random_id}`}
                                  isSearchable={true}
                                  options={getUserSchoolOption(item.random_id)}
                                  selected={
                                    watchAllFields[`school_id${item.random_id}`]
                                  }
                                  onChange={(e: any) => {
                                    field.onChange(e.id);
                                    showError && handleSchoolandSchedule();
                                  }}
                                />
                              </div>
                            )}
                          />
                          {errors[`school_id${item.random_id}`] && (
                            <div className="login-error">
                              <Label
                                title={"Please Select School Name"}
                                modeError={true}
                              />
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>
                            {" "}
                            School year<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name={`school_year${item.random_id}`}
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <>
                                <div className="react-select1">
                                  <OpenSelect
                                    sakey={`school_year${item.random_id}`}
                                    isSearchable={true}
                                    options={yearOption}
                                    selected={
                                      watchAllFields[
                                        `school_year${item.random_id}`
                                      ]
                                    }
                                    onChange={(e: any) => {
                                      field.onChange(e.id);
                                    }}
                                  />
                                </div>
                                {errors[`school_year${item.random_id}`] && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Select School Year."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>
                            {" "}
                            Grading scale<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name={`grading_scale${item.random_id}`}
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <>
                                <div className="react-select1">
                                  <OpenSelect
                                    // sakey={`grading_scale${item.random_id}`}
                                    isSearchable={true}
                                    options={gradingOption}
                                    selected={
                                      watchAllFields[
                                        `grading_scale${item.random_id}`
                                      ]
                                    }
                                    onChange={(e: any) => {
                                      field.onChange(e.id);
                                    }}
                                  />
                                </div>
                                {errors[`grading_scale${item.random_id}`] && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Select Grading Scale."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4} md={12}>
                        <Form.Group className="mb-4">
                          <Form.Label>
                            {" "}
                            Schedule<span className="text-danger">*</span>
                          </Form.Label>
                          <Controller
                            control={control}
                            name={`schedule${item.random_id}`}
                            rules={{ required: false }}
                            render={({ field }: any) => (
                              <>
                                <div className="react-select1">
                                  <OpenSelect
                                    sakey={`schedule${item.random_id}`}
                                    isSearchable={true}
                                    options={scheduleOption}
                                    selected={
                                      watchAllFields[
                                        `schedule${item.random_id}`
                                      ]
                                    }
                                    onChange={(e: any) => {
                                      field.onChange(e.id);
                                      showError && handleSchoolandSchedule();
                                    }}
                                  />
                                </div>
                                {errors[`schedule${item.random_id}`] && (
                                  <div className="login-error">
                                    <Label
                                      title={"Please Select Schedule."}
                                      modeError={true}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      {watchAllFields[`schedule${item.random_id}`] ==
                        "OTHER" && (
                        <Col lg={12} md={12}>
                          <Form.Group className="mb-4">
                            <Form.Label>
                              Please describe
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className={`${
                                watchAllFields[
                                  `other_describe${item.random_id}`
                                ]
                                  ? "border-brand"
                                  : ""
                              }`}
                              value={
                                watchAllFields[
                                  `other_describe${item.random_id}`
                                ]
                              }
                              {...register(`other_describe${item.random_id}`, {
                                required: false,
                              })}
                            />
                            {errors[`other_describe${item.random_id}`] && (
                              <div className="login-error">
                                <Label
                                  title={"Please Enter Grade."}
                                  modeError={true}
                                />
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                      )}

                      {item.courses &&
                        item.courses.length > 0 &&
                        item.courses.map((res: any, j: number) => {
                          return (
                            <Row className="p-0 m-0" key={i + "_" + j}>
                              <Col lg={12} md={12}>
                                <Form.Group className="mb-4">
                                  <Form.Label>
                                    {" "}
                                    Subject
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Controller
                                    control={control}
                                    name={`subject_${item.random_id}_${res}`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                      <>
                                        <div className="react-select1">
                                          <OpenSelect
                                            sakey={`subject_${item.random_id}_${res}`}
                                            isSearchable={true}
                                            options={subjectList}
                                            selected={
                                              watchAllFields[
                                                `subject_${item.random_id}_${res}`
                                              ]
                                            }
                                            onChange={(e: any) => {
                                              field.onChange(e.id);
                                            }}
                                          />
                                        </div>
                                        {errors[
                                          `subject_${item.random_id}_${res}`
                                        ] && (
                                          <div className="login-error">
                                            <Label
                                              title={"Please Select Subject."}
                                              modeError={true}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={6} md={12}>
                                <Form.Group className="mb-4">
                                  <Form.Label>
                                    {" "}
                                    Course name
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    className={`${
                                      watchAllFields[
                                        `course_name_${item.random_id}_${res}`
                                      ]
                                        ? "border-brand"
                                        : ""
                                    }`}
                                    value={
                                      watchAllFields[
                                        `course_name_${item.random_id}_${res}`
                                      ]
                                    }
                                    {...register(
                                      `course_name_${item.random_id}_${res}`,
                                      { required: false }
                                    )}
                                  />
                                  {errors[
                                    `course_name_${item.random_id}_${res}`
                                  ] && (
                                    <div className="login-error">
                                      <Label
                                        title={"Please Enter Course Name."}
                                        modeError={true}
                                      />
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={6} md={12}>
                                <Form.Group className="mb-4">
                                  <Form.Label>
                                    {" "}
                                    Course level
                                    <span className="text-danger">*</span>
                                  </Form.Label>
                                  <Controller
                                    control={control}
                                    name={`course_level_${item.random_id}_${res}`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                      <>
                                        <div className="react-select1">
                                          <OpenSelect
                                            sakey={`course_level_${item.random_id}_${res}`}
                                            isSearchable={true}
                                            options={gradeCouseLevel}
                                            selected={
                                              watchAllFields[
                                                `course_level_${item.random_id}_${res}`
                                              ]
                                            }
                                            onChange={(e: any) => {
                                              field.onChange(e.id);
                                            }}
                                          />
                                        </div>
                                        {errors[
                                          `course_level_${item.random_id}_${res}`
                                        ] && (
                                          <div className="login-error">
                                            <Label
                                              title={
                                                "Please Select Course Level."
                                              }
                                              modeError={true}
                                            />
                                          </div>
                                        )}
                                      </>
                                    )}
                                  />
                                </Form.Group>
                              </Col>

                              {watchAllFields &&
                                watchAllFields[`schedule${item.random_id}`] && (
                                  <>
                                    <Col
                                      lg={
                                        watchAllFields[
                                          `schedule${item.random_id}`
                                        ] == "OTHER" ||
                                        watchAllFields[
                                          `schedule${item.random_id}`
                                        ] == "YEARLY"
                                          ? 6
                                          : 12
                                      }
                                      md={12}
                                    >
                                      <Row>
                                        <Form.Label className="text-secondary font-14">
                                          {" "}
                                          SEMESTER GRADES
                                        </Form.Label>

                                        {watchAllFields[
                                          `schedule${item.random_id}`
                                        ] != "YEARLY" &&
                                          watchAllFields[
                                            `schedule${item.random_id}`
                                          ] != "OTHER" && (
                                            <>
                                              <Col
                                                lg={
                                                  watchAllFields[
                                                    `schedule${item.random_id}`
                                                  ] == "TRIMESTER"
                                                    ? 3
                                                    : watchAllFields[
                                                        `schedule${item.random_id}`
                                                      ] == "SEMESTER" && 4
                                                }
                                              >
                                                <Form.Group className="mb-4">
                                                  <Form.Label>
                                                    {" "}
                                                    {watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "TRIMESTER"
                                                      ? "T1"
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "SEMESTER"
                                                      ? "S1"
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "QUARTER"
                                                      ? "Q1"
                                                      : "Y1"}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </Form.Label>
                                                  <Controller
                                                    control={control}
                                                    name={`g_1_${item.random_id}_${res}`}
                                                    rules={{ required: false }}
                                                    render={({
                                                      field,
                                                    }: any) => (
                                                      <>
                                                        {watchAllFields[
                                                          `grading_scale${item.random_id}`
                                                        ] == "Other" ? (
                                                          <Form.Control
                                                            type="text"
                                                            className={`${
                                                              watchAllFields[
                                                                `g_1_${item.random_id}_${res}`
                                                              ]
                                                                ? "border-brand"
                                                                : ""
                                                            }`}
                                                            value={
                                                              watchAllFields[
                                                                `g_1_${item.random_id}_${res}`
                                                              ]
                                                            }
                                                            onChange={(
                                                              e: any
                                                            ) => {
                                                              field.onChange(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        ) : (
                                                          <div className="react-select1">
                                                            <OpenSelect
                                                              sakey={`g_1_${item.random_id}_${res}`}
                                                              isSearchable={
                                                                true
                                                              }
                                                              placeholder="-"
                                                              options={getOptions(
                                                                item.random_id
                                                              )}
                                                              selected={
                                                                watchAllFields[
                                                                  `g_1_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.id
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {errors[
                                                          `g_1_${item.random_id}_${res}`
                                                        ] && (
                                                          <div className="login-error">
                                                            <Label
                                                              title={
                                                                "Please Select Grade."
                                                              }
                                                              modeError={true}
                                                            />
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  />
                                                </Form.Group>
                                              </Col>
                                              <Col
                                                lg={
                                                  watchAllFields[
                                                    `schedule${item.random_id}`
                                                  ] == "TRIMESTER"
                                                    ? 3
                                                    : watchAllFields[
                                                        `schedule${item.random_id}`
                                                      ] == "SEMESTER" && 4
                                                }
                                              >
                                                <Form.Group className="mb-4">
                                                  <Form.Label>
                                                    {watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "TRIMESTER"
                                                      ? "T2"
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "SEMESTER"
                                                      ? "S2"
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "QUARTER"
                                                      ? "Q2"
                                                      : ""}
                                                    <span className="text-danger">
                                                      *
                                                    </span>
                                                  </Form.Label>
                                                  <Controller
                                                    control={control}
                                                    name={`g_2_${item.random_id}_${res}`}
                                                    rules={{ required: false }}
                                                    render={({
                                                      field,
                                                    }: any) => (
                                                      <>
                                                        {watchAllFields[
                                                          `grading_scale${item.random_id}`
                                                        ] == "Other" ? (
                                                          <Form.Control
                                                            type="text"
                                                            className={`${ watchAllFields[
                                                              `g_2_${item.random_id}_${res}`
                                                            ] ? "border-brand" : ""}`}
                                                            value={
                                                              watchAllFields[
                                                                `g_2_${item.random_id}_${res}`
                                                              ]
                                                            }
                                                            onChange={(
                                                              e: any
                                                            ) => {
                                                              field.onChange(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                        ) : (
                                                          <div className="react-select1">
                                                            <OpenSelect
                                                              isSearchable={
                                                                true
                                                              }
                                                              sakey={`g_2_${item.random_id}_${res}`}
                                                              placeholder="-"
                                                              options={getOptions(
                                                                item.random_id
                                                              )}
                                                              selected={
                                                                watchAllFields[
                                                                  `g_2_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.id
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                        {errors[
                                                          `g_2_${item.random_id}_${res}`
                                                        ] && (
                                                          <div className="login-error">
                                                            <Label
                                                              title={
                                                                "Please Select Grade."
                                                              }
                                                              modeError={true}
                                                            />
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  />
                                                </Form.Group>
                                              </Col>
                                              {(watchAllFields[
                                                `schedule${item.random_id}`
                                              ] == "QUARTER" ||
                                                watchAllFields[
                                                  `schedule${item.random_id}`
                                                ] == "TRIMESTER") && (
                                                <Col
                                                  lg={
                                                    watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "TRIMESTER"
                                                      ? 3
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "SEMESTER" && 4
                                                  }
                                                >
                                                  <Form.Group className="mb-4">
                                                    <Form.Label>
                                                      {" "}
                                                      {watchAllFields[
                                                        `schedule${item.random_id}`
                                                      ] == "TRIMESTER"
                                                        ? "T3"
                                                        : watchAllFields[
                                                            `schedule${item.random_id}`
                                                          ] == "SEMESTER"
                                                        ? "S3"
                                                        : watchAllFields[
                                                            `schedule${item.random_id}`
                                                          ] == "QUARTER"
                                                        ? "Q3"
                                                        : ""}
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </Form.Label>
                                                    <Controller
                                                      control={control}
                                                      name={`g_3_${item.random_id}_${res}`}
                                                      rules={{
                                                        required: false,
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <>
                                                          {watchAllFields[
                                                            `grading_scale${item.random_id}`
                                                          ] == "Other" ? (
                                                            <Form.Control
                                                              type="text"
                                                              className={`${watchAllFields[
                                                                `g_3_${item.random_id}_${res}`
                                                              ] ? "border-brand" :""}`}
                                                              value={
                                                                watchAllFields[
                                                                  `g_3_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                          ) : (
                                                            <div className="react-select1">
                                                              <OpenSelect
                                                                sakey={`g_3_${item.random_id}_${res}`}
                                                                isSearchable={
                                                                  true
                                                                }
                                                                placeholder="-"
                                                                options={getOptions(
                                                                  item.random_id
                                                                )}
                                                                selected={
                                                                  watchAllFields[
                                                                    `g_3_${item.random_id}_${res}`
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  field.onChange(
                                                                    e.id
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                          {errors[
                                                            `g_3_${item.random_id}_${res}`
                                                          ] && (
                                                            <div className="login-error">
                                                              <Label
                                                                title={
                                                                  "Please Select Grade."
                                                                }
                                                                modeError={true}
                                                              />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    />
                                                  </Form.Group>
                                                </Col>
                                              )}
                                              {watchAllFields[
                                                `schedule${item.random_id}`
                                              ] == "QUARTER" && (
                                                <Col
                                                  lg={
                                                    watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "TRIMESTER"
                                                      ? 3
                                                      : watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "SEMESTER" && 4
                                                  }
                                                >
                                                  <Form.Group className="mb-4">
                                                    <Form.Label>
                                                      {" "}
                                                      Q4
                                                      <span className="text-danger">
                                                        *
                                                      </span>
                                                    </Form.Label>

                                                    <Controller
                                                      control={control}
                                                      name={`g_4_${item.random_id}_${res}`}
                                                      rules={{
                                                        required: false,
                                                      }}
                                                      render={({
                                                        field,
                                                      }: any) => (
                                                        <>
                                                          {watchAllFields[
                                                            `grading_scale${item.random_id}`
                                                          ] == "Other" ? (
                                                            <Form.Control
                                                              type="text"
                                                              className={`${ watchAllFields[
                                                                `g_4_${item.random_id}_${res}`
                                                              ] ? "border-brand" :""}`}
                                                              value={
                                                                watchAllFields[
                                                                  `g_4_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.target.value
                                                                );
                                                              }}
                                                            />
                                                          ) : (
                                                            <div className="react-select1">
                                                              <OpenSelect
                                                                sakey={`g_4_${item.random_id}_${res}`}
                                                                isSearchable={
                                                                  true
                                                                }
                                                                placeholder="-"
                                                                options={getOptions(
                                                                  item.random_id
                                                                )}
                                                                selected={
                                                                  watchAllFields[
                                                                    `g_4_${item.random_id}_${res}`
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  field.onChange(
                                                                    e.id
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          )}
                                                          {errors[
                                                            `g_4_${item.random_id}_${res}`
                                                          ] && (
                                                            <div className="login-error">
                                                              <Label
                                                                title={
                                                                  "Please Select Grade."
                                                                }
                                                                modeError={true}
                                                              />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    />
                                                  </Form.Group>
                                                </Col>
                                              )}
                                            </>
                                          )}

                                        <Col
                                          lg={
                                            watchAllFields[
                                              `schedule${item.random_id}`
                                            ] == "TRIMESTER"
                                              ? 3
                                              : watchAllFields[
                                                  `schedule${item.random_id}`
                                                ] == "SEMESTER" && 4
                                          }
                                        >
                                          <Form.Group className="mb-4">
                                            <Form.Label>
                                              {" "}
                                              Final
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Form.Label>
                                            <Controller
                                              control={control}
                                              name={`g_final_${item.random_id}_${res}`}
                                              rules={{ required: false }}
                                              render={({ field }: any) => (
                                                <>
                                                  {watchAllFields[
                                                    `grading_scale${item.random_id}`
                                                  ] == "Other" ? (
                                                    <Form.Control
                                                      type="text"
                                                      className={`${watchAllFields[
                                                        `g_final_${item.random_id}_${res}`
                                                      ] ? "border-brand" :""}`}
                                                      value={
                                                        watchAllFields[
                                                          `g_final_${item.random_id}_${res}`
                                                        ]
                                                      }
                                                      onChange={(e: any) => {
                                                        field.onChange(
                                                          e.target.value
                                                        );
                                                      }}
                                                    />
                                                  ) : (
                                                    <div className="react-select1">
                                                      <OpenSelect
                                                        sakey={`g_final_${item.random_id}_${res}`}
                                                        isSearchable={true}
                                                        placeholder="-"
                                                        options={getOptions(
                                                          item.random_id
                                                        )}
                                                        selected={
                                                          watchAllFields[
                                                            `g_final_${item.random_id}_${res}`
                                                          ]
                                                        }
                                                        onChange={(e: any) => {
                                                          field.onChange(e.id);
                                                        }}
                                                      />
                                                    </div>
                                                  )}

                                                  {errors[
                                                    `g_final_${item.random_id}_${res}`
                                                  ] && (
                                                    <div className="login-error">
                                                      <Label
                                                        title={
                                                          "Please Select Grade."
                                                        }
                                                        modeError={true}
                                                      />
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Col>

                                    <Col
                                      lg={
                                        watchAllFields[
                                          `schedule${item.random_id}`
                                        ] == "OTHER" ||
                                        watchAllFields[
                                          `schedule${item.random_id}`
                                        ] == "YEARLY"
                                          ? 6
                                          : 12
                                      }
                                      md={12}
                                    >
                                      <Row>
                                        <div
                                          className="d-flex justify-content-between"
                                          style={{ height: "30px" }}
                                        >
                                          <div>
                                            <Form.Label className="text-secondary font-14">
                                              {" "}
                                              SEMESTER CREDITS
                                            </Form.Label>
                                          </div>
                                          <div>
                                            <Form.Group className="mb-3">
                                              <Form.Check
                                                type="checkbox"
                                                id="Exact"
                                                label="N/A"
                                                {...register(
                                                  `is_credit_${item.random_id}_${res}`,
                                                  { required: false }
                                                )}
                                              />
                                            </Form.Group>
                                          </div>
                                        </div>

                                        {!watchAllFields[
                                          `is_credit_${item.random_id}_${res}`
                                        ] && (
                                          <>
                                            {watchAllFields[
                                              `schedule${item.random_id}`
                                            ] != "YEARLY" &&
                                              watchAllFields[
                                                `schedule${item.random_id}`
                                              ] != "OTHER" && (
                                                <>
                                                  <Col
                                                    lg={
                                                      watchAllFields[
                                                        `schedule${item.random_id}`
                                                      ] == "TRIMESTER"
                                                        ? 3
                                                        : watchAllFields[
                                                            `schedule${item.random_id}`
                                                          ] == "SEMESTER" && 4
                                                    }
                                                  >
                                                    <Form.Group className="mb-4">
                                                      <Form.Label>
                                                        {watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "TRIMESTER"
                                                          ? "T1"
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "SEMESTER"
                                                          ? "S1"
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "QUARTER"
                                                          ? "Q1"
                                                          : ""}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </Form.Label>
                                                      <Controller
                                                        control={control}
                                                        name={`c_1_${item.random_id}_${res}`}
                                                        rules={{
                                                          required: false,
                                                        }}
                                                        render={({
                                                          field,
                                                        }: any) => (
                                                          <>
                                                            <Form.Control
                                                              type="text"
                                                              className={`${watchAllFields[
                                                                `c_1_${item.random_id}_${res}`
                                                              ] ? "border-brand" :""}`}
                                                              value={
                                                                watchAllFields[
                                                                  `c_1_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.target.value
                                                                );
                                                                // let total: number = Number(e.target.value)
                                                                // total += watchAllFields[`c_2_${item.random_id}_${res}`] ? Number(watchAllFields[`c_2_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_3_${item.random_id}_${res}`] ? Number(watchAllFields[`c_3_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_4_${item.random_id}_${res}`] ? Number(watchAllFields[`c_4_${item.random_id}_${res}`]) : 0;
                                                                // setValue(`c_final_${item.random_id}_${res}`, total)
                                                              }}
                                                              onKeyPress={(e) =>
                                                                HelperService.allowNewDecimalValue(
                                                                  e
                                                                )
                                                              }
                                                              onKeyUp={(e) =>
                                                                HelperService.formateUptoTwoDecimal(
                                                                  e
                                                                )
                                                              }
                                                            />
                                                            {errors[
                                                              `c_1_${item.random_id}_${res}`
                                                            ] && (
                                                              <div className="login-error">
                                                                <Label
                                                                  title={
                                                                    "Please Select Grade."
                                                                  }
                                                                  modeError={
                                                                    true
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          </>
                                                        )}
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                  <Col
                                                    lg={
                                                      watchAllFields[
                                                        `schedule${item.random_id}`
                                                      ] == "TRIMESTER"
                                                        ? 3
                                                        : watchAllFields[
                                                            `schedule${item.random_id}`
                                                          ] == "SEMESTER" && 4
                                                    }
                                                  >
                                                    <Form.Group className="mb-4">
                                                      <Form.Label>
                                                        {watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "TRIMESTER"
                                                          ? "T2"
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "SEMESTER"
                                                          ? "S2"
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "QUARTER"
                                                          ? "Q2"
                                                          : ""}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </Form.Label>
                                                      <Controller
                                                        control={control}
                                                        name={`c_2_${item.random_id}_${res}`}
                                                        rules={{
                                                          required: false,
                                                        }}
                                                        render={({
                                                          field,
                                                        }: any) => (
                                                          <>
                                                            <Form.Control
                                                              type="text"
                                                              className={`${watchAllFields[
                                                                `c_2_${item.random_id}_${res}`
                                                              ] ? "border-brand" :""}`}
                                                              value={
                                                                watchAllFields[
                                                                  `c_2_${item.random_id}_${res}`
                                                                ]
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) => {
                                                                field.onChange(
                                                                  e.target.value
                                                                );
                                                                // let total: number = Number(e.target.value)
                                                                // total += watchAllFields[`c_1_${item.random_id}_${res}`] ? Number(watchAllFields[`c_1_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_3_${item.random_id}_${res}`] ? Number(watchAllFields[`c_3_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_4_${item.random_id}_${res}`] ? Number(watchAllFields[`c_4_${item.random_id}_${res}`]) : 0;
                                                                // setValue(`c_final_${item.random_id}_${res}`, total)
                                                              }}
                                                              onKeyPress={(e) =>
                                                                HelperService.allowNewDecimalValue(
                                                                  e
                                                                )
                                                              }
                                                              onKeyUp={(e) =>
                                                                HelperService.formateUptoTwoDecimal(
                                                                  e
                                                                )
                                                              }
                                                            />
                                                            {errors[
                                                              `c_2_${item.random_id}_${res}`
                                                            ] && (
                                                              <div className="login-error">
                                                                <Label
                                                                  title={
                                                                    "Please Select Grade."
                                                                  }
                                                                  modeError={
                                                                    true
                                                                  }
                                                                />
                                                              </div>
                                                            )}
                                                          </>
                                                        )}
                                                      />
                                                    </Form.Group>
                                                  </Col>
                                                  {(watchAllFields[
                                                    `schedule${item.random_id}`
                                                  ] == "QUARTER" ||
                                                    watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "TRIMESTER") && (
                                                    <Col
                                                      lg={
                                                        watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "TRIMESTER"
                                                          ? 3
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "SEMESTER" && 4
                                                      }
                                                    >
                                                      <Form.Group className="mb-4">
                                                        <Form.Label>
                                                          {watchAllFields[
                                                            `schedule${item.random_id}`
                                                          ] == "TRIMESTER"
                                                            ? "T3"
                                                            : watchAllFields[
                                                                `schedule${item.random_id}`
                                                              ] == "SEMESTER"
                                                            ? "S3"
                                                            : watchAllFields[
                                                                `schedule${item.random_id}`
                                                              ] == "QUARTER"
                                                            ? "Q3"
                                                            : ""}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Form.Label>
                                                        <Controller
                                                          control={control}
                                                          name={`c_3_${item.random_id}_${res}`}
                                                          rules={{
                                                            required: false,
                                                          }}
                                                          render={({
                                                            field,
                                                          }: any) => (
                                                            <>
                                                              <Form.Control
                                                                type="text"
                                                                className={`${watchAllFields[`c_3_${item.random_id}_${res}`] ? "border-brand" : ""}`}
                                                                value={
                                                                  watchAllFields[
                                                                    `c_3_${item.random_id}_${res}`
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  field.onChange(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                  // let total: number = Number(e.target.value)
                                                                  // total += watchAllFields[`c_1_${item.random_id}_${res}`] ? Number(watchAllFields[`c_1_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_2_${item.random_id}_${res}`] ? Number(watchAllFields[`c_2_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_4_${item.random_id}_${res}`] ? Number(watchAllFields[`c_4_${item.random_id}_${res}`]) : 0;
                                                                  // setValue(`c_final_${item.random_id}_${res}`, total)
                                                                }}
                                                                onKeyPress={(
                                                                  e
                                                                ) =>
                                                                  HelperService.allowNewDecimalValue(
                                                                    e
                                                                  )
                                                                }
                                                                onKeyUp={(e) =>
                                                                  HelperService.formateUptoTwoDecimal(
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                              {errors[
                                                                `c_3_${item.random_id}_${res}`
                                                              ] && (
                                                                <div className="login-error">
                                                                  <Label
                                                                    title={
                                                                      "Please Select Grade."
                                                                    }
                                                                    modeError={
                                                                      true
                                                                    }
                                                                  />
                                                                </div>
                                                              )}
                                                            </>
                                                          )}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                  {watchAllFields[
                                                    `schedule${item.random_id}`
                                                  ] == "QUARTER" && (
                                                    <Col
                                                      lg={
                                                        watchAllFields[
                                                          `schedule${item.random_id}`
                                                        ] == "TRIMESTER"
                                                          ? 3
                                                          : watchAllFields[
                                                              `schedule${item.random_id}`
                                                            ] == "SEMESTER" && 4
                                                      }
                                                    >
                                                      <Form.Group className="mb-4">
                                                        <Form.Label>
                                                          Q4
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Form.Label>
                                                        <Controller
                                                          control={control}
                                                          name={`c_4_${item.random_id}_${res}`}
                                                          rules={{
                                                            required: false,
                                                          }}
                                                          render={({
                                                            field,
                                                          }: any) => (
                                                            <>
                                                              <Form.Control
                                                                type="text"
                                                                className={`${watchAllFields[
                                                                  `c_4_${item.random_id}_${res}`
                                                                ] ? "border-brand" : ""}`}
                                                                value={
                                                                  watchAllFields[
                                                                    `c_4_${item.random_id}_${res}`
                                                                  ]
                                                                }
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  field.onChange(
                                                                    e.target
                                                                      .value
                                                                  );
                                                                  // let total: number = Number(e.target.value)
                                                                  // total += watchAllFields[`c_1_${item.random_id}_${res}`] ? Number(watchAllFields[`c_1_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_3_${item.random_id}_${res}`] ? Number(watchAllFields[`c_3_${item.random_id}_${res}`]) : 0; total += watchAllFields[`c_2_${item.random_id}_${res}`] ? Number(watchAllFields[`c_2_${item.random_id}_${res}`]) : 0;
                                                                  // setValue(`c_final_${item.random_id}_${res}`, total)
                                                                }}
                                                                onKeyPress={(
                                                                  e
                                                                ) =>
                                                                  HelperService.allowNewDecimalValue(
                                                                    e
                                                                  )
                                                                }
                                                                onKeyUp={(e) =>
                                                                  HelperService.formateUptoTwoDecimal(
                                                                    e
                                                                  )
                                                                }
                                                              />
                                                              {errors[
                                                                `c_4_${item.random_id}_${res}`
                                                              ] && (
                                                                <div className="login-error">
                                                                  <Label
                                                                    title={
                                                                      "Please Select Grade."
                                                                    }
                                                                    modeError={
                                                                      true
                                                                    }
                                                                  />
                                                                </div>
                                                              )}
                                                            </>
                                                          )}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  )}
                                                </>
                                              )}

                                            <Col
                                              lg={
                                                watchAllFields[
                                                  `schedule${item.random_id}`
                                                ] == "TRIMESTER"
                                                  ? 3
                                                  : watchAllFields[
                                                      `schedule${item.random_id}`
                                                    ] == "SEMESTER" && 4
                                              }
                                            >
                                              <Form.Group className="mb-4">
                                                <Form.Label>
                                                  Final
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={`${watchAllFields[
                                                    `c_final_${item.random_id}_${res}`
                                                  ] ? "border-brand" : ""}`}
                                                  value={
                                                    watchAllFields[
                                                      `c_final_${item.random_id}_${res}`
                                                    ]
                                                  }
                                                  {...register(
                                                    `c_final_${item.random_id}_${res}`,
                                                    { required: false }
                                                  )}
                                                  // disabled={(watchAllFields[`schedule${item.random_id}`] == "YEARLY" || watchAllFields[`schedule${item.random_id}`] == "OTHER") ? false : true}
                                                  onKeyPress={(e) =>
                                                    HelperService.allowNewDecimalValue(
                                                      e
                                                    )
                                                  }
                                                  onKeyUp={(e) =>
                                                    HelperService.formateUptoTwoDecimal(
                                                      e
                                                    )
                                                  }
                                                />
                                                {errors[
                                                  `c_final_${item.random_id}_${res}`
                                                ] && (
                                                  <div className="login-error">
                                                    <Label
                                                      title={
                                                        "Please Enter Grade."
                                                      }
                                                      modeError={true}
                                                    />
                                                  </div>
                                                )}
                                              </Form.Group>
                                            </Col>
                                          </>
                                        )}
                                      </Row>
                                    </Col>
                                  </>
                                )}
                              {item.courses.length > 1 && (
                                <span
                                  className="text-secondary text-decoration-underline font-14 font-medium cursor-pointer mb-2 w-auto"
                                  onClick={() =>
                                    onDeleteCourse(item.random_id, res)
                                  }
                                >
                                  Delete course
                                </span>
                              )}
                            </Row>
                          );
                        })}
                      <span
                        className="text-decoration-underline font-14 font-medium cursor-pointer my-3 text-brand w-auto"
                        onClick={() => onAddCourse(item.random_id)}
                      >
                        + Add another course
                      </span>
                    </Row>
                  );
                })}
                <input type="submit" id="submit_grades" hidden />
              </form>
              <span
                className="text-brand text-decoration-underline font-14 font-medium cursor-pointer my-3 w-auto"
                onClick={() => AddOrRemoveNewForm()}
              >
                + Add courses from another school
              </span>
              {showDocModal ? (
                <form onSubmit={handleSubmit1(onSaveTranscript)}>
                  <Card className="bg-brand-light p-4 rounded-4 mb-4">
                    <Form.Group className="mb-4">
                      <Form.Label>
                        {" "}
                        High school<span className="text-danger">*</span>
                      </Form.Label>
                      <Controller
                        control={control1}
                        name={`high_school_id`}
                        rules={{ required: true }}
                        render={({ field }: any) => (
                          <>
                            <div className="react-select1">
                              <OpenSelect
                                sakey="high_school_id"
                                placeholder="school name"
                                isSearchable={true}
                                options={getFileUploadSchoolOption()}
                                selected={watchAllFields1[`high_school_id`]}
                                onChange={(e: any) => {
                                  field.onChange(e.id);
                                }}
                              />
                            </div>
                            {errors[`high_school_id`] && (
                              <div className="login-error">
                                <Label
                                  title={"Please Select Schedule."}
                                  modeError={true}
                                />
                              </div>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>

                    {watchAllFields1[`high_school_id`] &&
                      userData.map((item: any) => {
                        return (
                          <>
                            {watchAllFields[`school_id${item.random_id}`] ==
                              watchAllFields1[`high_school_id`] && (
                              <Form.Group className="mb-4">
                                <Form.Label>
                                  {" "}
                                  {watchAllFields[
                                    `schedule${item.random_id}`
                                  ] &&
                                    HelperService.capitalizeFirstLetter(
                                      watchAllFields[
                                        `schedule${item.random_id}`
                                      ]
                                    )}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Controller
                                  control={control1}
                                  name={`semester`}
                                  rules={{ required: true }}
                                  render={({ field }: any) => (
                                    <>
                                      {watchAllFields[
                                        `schedule${item.random_id}`
                                      ] == "OTHER" ? (
                                        <Form.Control
                                          type="text"
                                          value={watchAllFields1[`semester`]}
                                          onChange={(e: any) => {
                                            field.onChange(e.target.value);
                                          }}
                                        />
                                      ) : (
                                        <div className="react-select1">
                                          <OpenSelect
                                            sakey={`schedule${item.random_id}`}
                                            placeholder=""
                                            isSearchable={true}
                                            options={getScheduleUnitOptions(
                                              watchAllFields[
                                                `schedule${item.random_id}`
                                              ]
                                            )}
                                            selected={
                                              watchAllFields1[`semester`]
                                            }
                                            onChange={(e: any) => {
                                              field.onChange(e.id);
                                            }}
                                          />
                                        </div>
                                      )}
                                      {errors[`semester`] && (
                                        <div className="login-error">
                                          <Label
                                            title={"Please Select Schedule."}
                                            modeError={true}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                />
                              </Form.Group>
                            )}
                          </>
                        );
                      })}

                    <Card className="card-style p-3 bg-light border w-fit-contain">
                      <div className="d-flex gap-2 align-items-center">
                        <div className="d-flex gap-3">
                          <div>
                            <img src={docIcon} alt="" width={40} height={40} />
                          </div>
                          <div style={{ overflowWrap: "anywhere" }}>
                            <p className="font-16 font-medium mb-1">
                              {fileName}
                            </p>
                            <span className="font-14 text-secondary">
                              {fileSize}KB
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card>

                    <div className="text-center d-flex gap-2 mt-3">
                      <Button
                        className="btn btn-brand-1 w-100 mb-3"
                        type="submit"
                        disabled={!isValid1}
                        id="Save_btn_transcript"
                      >
                        Save
                      </Button>
                      <Button
                        className="btn btn-outline-brand w-100"
                        type="button"
                        onClick={() => closeDocModal()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card>
                </form>
              ) : (
                <div className="mt-3 mb-3">
                  <p className="font-16 mt-2 mb-1">Upload your transcript</p>
                  {showError && (
                    <Label
                      title={
                        "School name and schedule needed to upload a transcript"
                      }
                      modeError={true}
                    />
                  )}
                  <div className="mt-3">
                    <UploadFile
                      onChange={(data: any, name: string, size: string) => {
                        onUploadTranscript(data, name, size);
                      }}
                      acceptFileType={["jpg", "jpeg", "pdf", "png"]}
                      message="Upload or Drag and Drop your transcript"
                      acceptedSize={5}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex gap-3 flex-wrap">
                {userTranscript &&
                  userTranscript.length > 0 &&
                  userTranscript.map((item: any) => {
                    return (
                      <Card className="card-style p-3 bg-light border w-auto">
                        <div className="d-flex gap-2 align-items-center">
                          <div>
                            <MdClose
                              size={22}
                              className="text-secondary cursor-pointer"
                              onClick={() => deleteTranscript(item.uuid)}
                            />
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            <div>
                              {" "}
                              <img
                                src={docIcon}
                                alt=""
                                width={40}
                                height={40}
                              />{" "}
                            </div>
                            <div>
                              <p className="font-16 font-medium mb-1">
                                {item.updated_file}
                              </p>
                              <span className="font-14 text-secondary">
                                {item.file_size}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
              </div>

              <div className="text-center mt-3">
                <Button
                  className="btn btn-brand-1 w-100 mb-3"
                  type="button"
                  id="Save_grades_btn"
                  disabled={handleDisableSave()}
                  onClick={() =>
                    document.getElementById("submit_grades")?.click()
                  }
                >
                  Save
                </Button>
                <Button
                  className="btn btn-outline-brand w-100"
                  type="button"
                  onClick={() => closeClick()}
                >
                  Cancel
                </Button>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddGradeModal;
