import { Row, Col, Button, Form } from "react-bootstrap"
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import OpenDatePicker from "../../../components/Common/OpenDatePicker/OpenDatePicker";
import HelperService from "../../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
    activeTab: any;
    isUpdate: Function;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

interface formData {
    sat_math: string;
    sat_math_score_date: Date;
    sat_reading_writing: string;
    sat_reading_writing_score_date: Date;
    sat_taken: string;
    future_sat_expected: string;
}

const SATTest = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<formData>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const scoreOption: any[] = HelperService.getNumberList(200, 800, 10).reverse();
    const numberOption: any[] = HelperService.getNumberList(1, 5, 1);
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.activeTab == "SATTests" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.activeTab == "SATTests" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'SATTests');
        } else {
            props.isFormDirty(false, 'SATTests');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.activeTab == "SATTests" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange]);

    useEffect(() => {
        if (props.activeTab == "SATTests") {
            reset({});
            getDatail();
        }
    }, [props.activeTab]);

    const getDatail = () => {
        WebService.getAPI({
            action: `get/standarized/test/detail?type=SAT_TEST`,
        })
            .then((res: any) => {
                if (res.result) {
                    reset(res.result);
                    setValue("future_sat_expected", res.result.future_sat_expected.toString())
                }
            })
            .catch((e) => { });
    };

    const onSave = async (data: any) => {
        await WebService.postAPI({
            action: "save/tests?type=SAT_TEST",
            body: data,
            id: "SAT_test_btn",
        })
            .then((res: any) => {
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };


    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />
            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col style={{ maxWidth: "624px" }}>
                        <h4 className="font-medium font-20 mb-3 pb-1">SAT Tests</h4>
                        <Form className="form-style-2">
                            <Form.Group className="mb-4">
                                <Form.Label> Number of past SAT scores you wish to report<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name={`sat_taken`}
                                    rules={{ required: false }}
                                    render={({ field }: any) => (
                                        <div className="react-select">
                                            <OpenSelect
                                                sakey="sat_taken"
                                                options={numberOption}
                                                selected={watchAllFields.sat_taken}
                                                onChange={(data: any) => { field.onChange(data.id); }}
                                                isSearchable={true}
                                                placeholder="Choose an option"
                                            />
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>How many more times do you plan to take the SAT?<span className="text-danger">*</span></Form.Label>
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="0"
                                    value={"0"}
                                    {...register("future_sat_expected", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="1"
                                    value={"1"}
                                    {...register("future_sat_expected", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="2"
                                    value={"2"}
                                    {...register("future_sat_expected", { required: false })}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Exact"
                                    label="3"
                                    value={"3"}
                                    {...register("future_sat_expected", { required: false })}
                                />
                            </Form.Group>

                            <Row>
                                {/* Reading and writing */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Highest reading and writing score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`sat_reading_writing`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="sat_reading_writing"
                                                        options={scoreOption}
                                                        selected={watchAllFields.sat_reading_writing}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Reading and writing score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="sat_reading_writing_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.sat_reading_writing_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>
                                {/* Math */}
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>What is your highest math score<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name={`sat_math`}
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <div className="react-select">
                                                    <OpenSelect
                                                        sakey="sat_math"
                                                        options={scoreOption}
                                                        selected={watchAllFields.sat_math}
                                                        onChange={(data: any) => { field.onChange(data.id); }}
                                                        isSearchable={true}
                                                        placeholder="Choose an option"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Math score date<span className="text-danger">*</span></Form.Label>
                                        <Controller
                                            control={control}
                                            name="sat_math_score_date"
                                            rules={{ required: false }}
                                            render={({ field }: any) => (
                                                <OpenDatePicker
                                                    type="date"
                                                    selected={watchAllFields.sat_math_score_date}
                                                    maxData={new Date()}
                                                    onChange={(e: any) => field.onChange(e)}
                                                />
                                            )}
                                        />
                                        <p className="font-12 text-secondary mb-2">Date uses "month, day, year" format (e.g. August 1, 2002)</p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <div className="text-center">
                            <Button type="submit" id="SAT_test_btn" className="btn btn-brand-1 w-100">
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    )
}
export default SATTest;
