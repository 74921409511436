import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import WebService from "../../Services/WebService";
import OpenSelect from "../Common/OpenTagSelect/OpenSelect";
import { Label } from "../Common/Label/Label";
import HelperService from "../../Services/HelperService";

interface propsData {
  show: boolean;
  isClose: Function;
  schoolName: string;
  data: any;
}

const AddSchoolAddress = ({ show, isClose, schoolName, data }: propsData) => {
  const { handleSubmit, formState: { errors, isValid }, control, watch, reset, setValue, register, } = useForm<any>({});
  const watchAllFields = watch();
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);

  useEffect(() => {
    if (show) {
      getCountryOption();
      if (data?.school_country_id || data?.school_state_id || data?.school_city || data?.school_street || data?.school_zip) {
        data?.school_country_id && getStateOption(data?.school_country_id);
        setValue("country_id", data?.school_country_id);
        setValue("state_id", data?.school_state_id);
        setValue("city", data?.school_city);
        setValue("street", data?.school_street);
        setValue("zipcode", data?.school_zip);
        setValue("school_state_code", data.state_code);
        setValue("address_1", data?.school_address_1);
        setValue("address_2", data?.school_address_2);
        setValue("address_3", data?.school_address_3);
      } else {
        setValue("country_id", "");
        setValue("state_id", "");
        setValue("city", "");
        setValue("street", "");
        setValue("zipcode", "");
        setValue("school_state_code", "");
        setValue("address_1", "");
        setValue("address_2", "");
        setValue("address_3", "");
      }
    }
  }, [show]);

  const onSave = (data: any) => {
    isClose(true, data);
  };

  const getCountryOption = () => {
    WebService.getAPI({ action: "countries" })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          const usIndex = res.list.findIndex(
            (item: any) => item.name === "United States"
          );

          if (usIndex !== -1) {
            const updatedList = [
              res.list[usIndex],
              ...res.list.slice(0, usIndex),
              ...res.list.slice(usIndex + 1),
            ];
            setCountryOptions(
              updatedList.map((item: any) => {
                return { ...item, ...{ value: item.name } };
                //label: item.name,
              })
            );
          } else {
            setCountryOptions(
              res.list.map((item: any) => {
                return { ...item, ...{ value: item.name } };
              })
            );
          }
        }
      })
      .catch((e: any) => { });
  };

  const getStateOption = (id: string) => {
    WebService.postAPI({ action: `states`, body: { country_id: id } })
      .then((res: any) => {
        if (res.list && res.list.length > 0) {
          setStateOptions(
            res.list.map((item: any) => {
              return { ...item, ...{ value: item.name } };
            })
          );
        }
      })
      .catch((e: any) => { });
  };

  const getZipCodeData = (zip: string) => {
    // WebService.getAPI({ action: `zipcode?zipcode=${zip}` })
    //   .then((res: any) => {
    //     if (res.result) {
    //       setValue("state_id", res.result.city.state_id);
    //     }
    //   })
    //   .catch((e: any) => { });
  };

  const resetAddress = () => {
    setValue("state_id", "");
    setValue("city", "");
    setValue("zipcode", "");
    setValue("street", "");
    setValue("address_1", "");
    setValue("address_2", "");
    setValue("address_3", "");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          reset();
          isClose();
        }}
        centered
        className="modal-size-660"
      >
        <Modal.Header closeButton className="pt-3">
          <Modal.Title>Add an address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-style-2 mt-3">
            <p className="font-16 font-medium">
              Add an address of <span className="text-brand">{schoolName}</span>
            </p>
            <form onSubmit={handleSubmit(onSave)}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Country<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="country_id"
                      rules={{ required: true }}
                      render={({ field }: any) => (
                        <div className="form-style">
                          <OpenSelect
                            sakey="country_id"
                            isSearchable={true}
                            selected={watchAllFields.country_id}
                            options={countryOptions}
                            onChange={(e: any) => {
                              field.onChange(e.id);
                              if (e.id == 239) {
                                getStateOption(e.id);
                              }
                              resetAddress();
                            }}
                          />
                        </div>
                      )}
                    />
                    {errors.country_id && (
                      <div className="login-error">
                        <Label
                          title={"Please Select Home Country."}
                          modeError={true}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {watchAllFields.country_id &&
                  watchAllFields.country_id != 239 ? (
                  <>
                    <Form.Group className="mb-4 col-lg-12">
                      <Form.Control
                        type="text"
                        className={`${watchAllFields.address_1 ? "border-brand" : ""
                          }`}
                        value={watchAllFields.address_1}
                        {...register("address_1", {
                          required: true,
                        })}
                        placeholder="Address line 1*"
                      />
                      {errors.address_1 && (
                        <div className="login-error">
                          <Label
                            title={"Please enter address."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4 col-lg-12">
                      <Form.Control
                        type="text"
                        className={`${watchAllFields.address_2 ? "border-brand" : ""
                          }`}
                        value={watchAllFields.address_2}
                        {...register("address_2", {
                          required: false,
                        })}
                        placeholder="Address line 2"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 col-lg-12">
                      <Form.Control
                        type="text"
                        className={`${watchAllFields.address_3 ? "border-brand" : ""
                          }`}
                        value={watchAllFields.address_3}
                        {...register("address_3", {
                          required: false,
                        })}
                        placeholder="Address line 3 (if applicable)"
                      />
                    </Form.Group>
                    <Form.Group className="mb-4 col-lg-12">
                      <Form.Control
                        type="text"
                        className={`${watchAllFields.city ? "border-brand" : ""
                          }`}
                        value={watchAllFields.city}
                        {...register("city", { required: true })}
                        placeholder="City/Town/Settlement*"
                      />
                      {errors.city && (
                        <div className="login-error">
                          <Label
                            title={"Please enter city/town/settlement."}
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4 col-lg-12">
                      <Form.Control
                        type="text"
                        className={`${watchAllFields.zipcode ? "border-brand" : ""
                          }`}
                        onKeyPress={(e) =>
                          HelperService.allowOnlyNumericValueUpto8(e)
                        }
                        {...register("zipcode", { required: true, min: 4 })}
                        placeholder="Postal code*"
                      />
                      {errors.zipcode && (
                        <div className="login-error">
                          <Label
                            title={
                              watchAllFields.zipcode
                                ? "Please enter valid zipcode."
                                : "Please enter zipcode."
                            }
                            modeError={true}
                          />
                        </div>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <>
                    <Col md={12}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Street<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields.street ? "border-brand" : ""
                            }`}
                          value={watchAllFields[`street`]}
                          {...register(`street`, { required: true })}
                        />
                        {errors.street && (
                          <div className="login-error">
                            <Label
                              title={"Please enter street."}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          City<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields[`city`] ? "border-brand" : ""
                            }`}
                          value={watchAllFields[`city`]}
                          {...register(`city`, { required: true })}
                          onKeyPress={(e) =>
                            HelperService.allowOnlyAlphabetsValues(e)
                          }
                        />
                        {errors[`city`] && (
                          <div className="login-error">
                            <Label
                              title={"Please enter city."}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-4 w-100">
                        <Form.Label>
                          State<span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                          control={control}
                          name="state_id"
                          rules={{ required: true }}
                          render={({ field }: any) => (
                            <OpenSelect
                              sakey="state_id"
                              isSearchable={true}
                              options={stateOptions}
                              selected={watchAllFields.state_id}
                              onChange={(e: any) => {
                                field.onChange(e.id);
                                setValue("state_code", e.code);
                              }}
                              placeholder={"Select State"}
                            />
                          )}
                        />
                        {errors.state_id && (
                          <div className="login-error">
                            <Label
                              title={"Please Select State."}
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-4 w-100">
                        <Form.Label>
                          Zip code<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={`${watchAllFields.zipcode ? "border-brand" : ""}`}
                          onKeyPress={(e) =>
                            HelperService.allowOnlyNumericValueUpto8(e)
                          }
                          {...register("zipcode", { required: true, min: 4 })}
                          onBlur={(e: any) => {
                            e.target.value.length > 4 &&
                              getZipCodeData(e.target.value);
                          }}
                        />
                        {errors.zipcode && (
                          <div className="login-error">
                            <Label
                              title={
                                watchAllFields.zipcode
                                  ? "Please enter valid zipcode."
                                  : "Please enter zipcode."
                              }
                              modeError={true}
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              <Button
                className="btn-brand-1 w-100"
                type="submit"
                disabled={!isValid}
              >
                {" "}
                Save
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddSchoolAddress;
