import { Row, Col, Button, Form } from "react-bootstrap";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OpenSelect from "../../../components/Common/OpenTagSelect/OpenSelect";
import WebService from "../../../Services/WebService";
import OpenDatePicker from "../../../components/Common/OpenDatePicker/OpenDatePicker";
import HelperService from "../../../Services/HelperService";
import { useBlocker, useNavigate } from "react-router-dom";
import ExitConfirmModal from "../../../components/my-details/ExitConfirmModal";

interface propData {
  activeTab: any;
  isUpdate: Function;
  isFormDirty: Function;
  confirmTabChange: string;
  updateActiveTab: Function;
}

interface formData {
  act_taken: string;
  is_report_act_writing_score: string;
  future_act_expected: string;
  act_highest_composite_score: string;
  act_highest_composite_score_date: Date;
  act_english: string;
  act_english_score_date: Date;
  act_math: string;
  act_math_score_date: Date;
  act_reading: string;
  act_reading_score_date: Date;
  act_science: string;
  act_science_score_date: Date;
  act_writing: string;
  act_writing_score_date: Date;
}

const ACTTest = (props: propData) => {
  const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<formData>({ mode: "onSubmit", reValidateMode: "onChange" });
  const navigate = useNavigate();
  const { dirtyFields } = useFormState({ control });
  const watchAllFields = watch();
  const scoreOption: any[] = HelperService.getNumberList(0, 36, 1).reverse();
  const numberOption: any[] = HelperService.getNumberList(1, 5, 1);
  const writingOption: any[] = HelperService.getNumberList(2, 12, 1).reverse();
  const [confirmExit, setConfirmExit] = useState(false);
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return props.activeTab == "ACTTests" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (props.activeTab == "ACTTests" && !HelperService.isEmptyObject(dirtyFields)) {
      props.isFormDirty(true, 'ACTTests');
    } else {
      props.isFormDirty(false, 'ACTTests');
    }
  }, [dirtyFields, watchAllFields]);

  useEffect(() => {
    if (props.activeTab == "ACTTests" && props.confirmTabChange) {
      setConfirmExit(true);
    }
  }, [props.confirmTabChange]);

  useEffect(() => {
    if (props.activeTab == "ACTTests") {
      reset({});
      getDatail();
    }
  }, [props.activeTab]);

  const getDatail = () => {
    WebService.getAPI({
      action: `get/standarized/test/detail?type=ACT_TEST`,
    })
      .then((res: any) => {
        if (res.result) {
          reset(res.result);
          setValue(
            "future_act_expected",
            res.result.future_act_expected.toString()
          );
        }
      })
      .catch((e) => { });
  };

  const onSave = async (data: any) => {
    await WebService.postAPI({
      action: "save/tests?type=ACT_TEST",
      body: data,
      id: "ACT_test_btn",
    })
      .then((res: any) => {
        toast.success(res.message);
        props.isUpdate();
        let tempData = { ...watchAllFields };
        reset({});
        reset(tempData);
      })
      .catch((e: any) => { });
  };

  return (
    <>
      <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
        if (e) {
          WebService.addLoader('Save_and_exit_btn');
          await onSave(watch());
          WebService.removeLoader('Save_and_exit_btn');
        }
        reset({});
        blocker.state === "blocked" && blocker.proceed();
        if (confirmExit) {
          navigate(`#${props.confirmTabChange}`, { replace: true });
          props.updateActiveTab(props.confirmTabChange);
          setConfirmExit(false);
        }
      }} />
      <form onSubmit={handleSubmit(onSave)}>
        <Row>
          <Col style={{ maxWidth: "624px" }}>
            <h4 className="font-medium font-20 mb-3 pb-1">ACT Tests</h4>
            <Form className="form-style-2">
              <Form.Group className="mb-4">
                <Form.Label>
                  {" "}
                  Number of past ACT scores you wish to report
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name={`act_taken`}
                  rules={{ required: false }}
                  render={({ field }: any) => (
                    <div className="react-select1">
                      <OpenSelect
                        sakey="act_taken"
                        options={numberOption}
                        selected={watchAllFields.act_taken}
                        onChange={(data: any) => {
                          field.onChange(data.id);
                        }}
                        isSearchable={true}
                        placeholder="Choose an option"
                      />
                    </div>
                  )}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {" "}
                  Would you like to report an ACT Plus Writing score?
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="Yes"
                  value={"YES"}
                  {...register("is_report_act_writing_score", {
                    required: false,
                  })}
                />
                <Form.Check
                  type="radio"
                  id="Decile"
                  label="No"
                  value={"NO"}
                  {...register("is_report_act_writing_score", {
                    required: false,
                  })}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  {" "}
                  Number of future ACT sittings you expect
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="0"
                  value={"0"}
                  {...register("future_act_expected", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="1"
                  value={"1"}
                  {...register("future_act_expected", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="2"
                  value={"2"}
                  {...register("future_act_expected", { required: false })}
                />
                <Form.Check
                  type="radio"
                  id="Exact"
                  label="3"
                  value={"3"}
                  {...register("future_act_expected", { required: false })}
                />
              </Form.Group>

              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      What is your highest composite score
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`act_highest_composite_score`}
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="act_highest_composite_score"
                            options={scoreOption}
                            selected={
                              watchAllFields.act_highest_composite_score
                            }
                            onChange={(data: any) => {
                              field.onChange(data.id);
                            }}
                            isSearchable={true}
                            placeholder="Choose an option"
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Composite score date<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="act_highest_composite_score_date"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <OpenDatePicker
                          type="date"
                          selected={
                            watchAllFields.act_highest_composite_score_date
                          }
                          maxData={new Date()}
                          onChange={(e: any) => field.onChange(e)}
                        />
                      )}
                    />
                    <p className="font-12 text-secondary mb-2">
                      Date uses "month, day, year" format (e.g. August 1, 2002)
                    </p>
                  </Form.Group>
                </Col>
                {/* English score */}
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      What is your highest English score
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`act_english`}
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="act_english"
                            options={scoreOption}
                            selected={watchAllFields.act_english}
                            onChange={(data: any) => {
                              field.onChange(data.id);
                            }}
                            isSearchable={true}
                            placeholder="Choose an option"
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      English score date<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="act_english_score_date"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <OpenDatePicker
                          type="date"
                          selected={watchAllFields.act_english_score_date}
                          maxData={new Date()}
                          onChange={(e: any) => field.onChange(e)}
                        />
                      )}
                    />
                    <p className="font-12 text-secondary mb-2">
                      Date uses "month, day, year" format (e.g. August 1, 2002)
                    </p>
                  </Form.Group>
                </Col>
                {/* Math */}
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      What is your highest math score
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`act_math`}
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="act_math"
                            options={scoreOption}
                            selected={watchAllFields.act_math}
                            onChange={(data: any) => {
                              field.onChange(data.id);
                            }}
                            isSearchable={true}
                            placeholder="Choose an option"
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Math score date<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="act_math_score_date"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <OpenDatePicker
                          type="date"
                          selected={watchAllFields.act_math_score_date}
                          maxData={new Date()}
                          onChange={(e: any) => field.onChange(e)}
                        />
                      )}
                    />
                    <p className="font-12 text-secondary mb-2">
                      Date uses "month, day, year" format (e.g. August 1, 2002)
                    </p>
                  </Form.Group>
                </Col>
                {/* Reading */}
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      What is your highest reading score
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`act_reading`}
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="act_reading"
                            options={scoreOption}
                            selected={watchAllFields.act_reading}
                            onChange={(data: any) => {
                              field.onChange(data.id);
                            }}
                            isSearchable={true}
                            placeholder="Choose an option"
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Reading score date<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="act_reading_score_date"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <OpenDatePicker
                          type="date"
                          selected={watchAllFields.act_reading_score_date}
                          maxData={new Date()}
                          onChange={(e: any) => field.onChange(e)}
                        />
                      )}
                    />
                    <p className="font-12 text-secondary mb-2">
                      Date uses "month, day, year" format (e.g. August 1, 2002)
                    </p>
                  </Form.Group>
                </Col>
                {/* Science */}
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      What is your highest science score
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name={`act_science`}
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <div className="react-select1">
                          <OpenSelect
                            sakey="act_science"
                            options={scoreOption}
                            selected={watchAllFields.act_science}
                            onChange={(data: any) => {
                              field.onChange(data.id);
                            }}
                            isSearchable={true}
                            placeholder="Choose an option"
                          />
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>
                      Science score date<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      control={control}
                      name="act_science_score_date"
                      rules={{ required: false }}
                      render={({ field }: any) => (
                        <OpenDatePicker
                          type="date"
                          selected={watchAllFields.act_science_score_date}
                          maxData={new Date()}
                          onChange={(e: any) => field.onChange(e)}
                        />
                      )}
                    />
                    <p className="font-12 text-secondary mb-2">
                      Date uses "month, day, year" format (e.g. August 1, 2002)
                    </p>
                  </Form.Group>
                </Col>
                {watchAllFields.is_report_act_writing_score == "YES" && (
                  <>
                    <Col lg={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          What is your highest writing score
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                          control={control}
                          name={`act_writing`}
                          rules={{ required: false }}
                          render={({ field }: any) => (
                            <div className="react-select1">
                              <OpenSelect
                                sakey="act_writing"
                                options={writingOption}
                                selected={watchAllFields.act_writing}
                                onChange={(data: any) => {
                                  field.onChange(data.id);
                                }}
                                isSearchable={true}
                                placeholder="Choose an option"
                              />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-4">
                        <Form.Label>
                          Writing score date
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Controller
                          control={control}
                          name="act_writing_score_date"
                          rules={{ required: false }}
                          render={({ field }: any) => (
                            <OpenDatePicker
                              type="date"
                              selected={watchAllFields.act_writing_score_date}
                              maxData={new Date()}
                              onChange={(e: any) => field.onChange(e)}
                            />
                          )}
                        />
                        <p className="font-12 text-secondary mb-2">
                          Date uses "month, day, year" format (e.g. August 1,
                          2002)
                        </p>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
            <div className="text-center">
              <Button
                type="submit"
                id="ACT_test_btn"
                className="btn btn-brand-1 w-100"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default ACTTest;
