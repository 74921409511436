import { Form, Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/login-logo.svg'
import Logo2 from '../../assets/images/open-logo-light.svg'
import "./login.scss";
import { Controller, useForm } from "react-hook-form";
import { SignupAPI, forgetPasswordInterface, signupFormInterface } from "../../OpenInterfaces";
import { Label } from "../Common/Label/Label";
import WebService from "../../Services/WebService";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { SET_SIGNUP_DATA, setDataInRedux } from "../../action/CommonAction";
import { toast } from "react-toastify";


const ForgotPassword = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const { handleSubmit, formState: { errors, isValid }, control, watch } = useForm<forgetPasswordInterface>({});
    const watchAllFields = watch();
    const emailRegex = /^[\w-\.+_-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const navigate = useNavigate();

    const onSubmit = (data: forgetPasswordInterface) => {
        WebService.postAPI({ action: "forgot-password", body: data, id: "forget_password_btn" })
            .then((res: any) => {
                const emailData = { email: data.email } as signupFormInterface
                dispatch(setDataInRedux({ type: SET_SIGNUP_DATA, value: emailData }));
                toast.success(res.message)
                navigate("/check-email")
            })
            .catch((e) => { });
    };

    return (
        <>
            <div className="login-page">
                <div className="row g-0 justify-content-center align-items-center min-vh-100">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className=" w-100">
                            <div className="px-lg-5 px-3 ">
                                 
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="card p-4 form-style-2 rounded-4 shadow border-0 login-card">
                                        <div className="p-2">
                                        <div className="text-center">
                                            <h2 className="mb-4 font-bold font-36">Forgot your password?</h2>
                                            <p className=" text-secondary font-14 mb-4 text-start font-light">Enter the email you used to create your account so we can send you instructions on how to reset your password.</p>
                                        </div>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{
                                                required: "Please Enter Email",
                                                pattern: {
                                                    value: emailRegex,
                                                    message: "Enter valid email address",
                                                }
                                            }}
                                            render={({
                                                field: { onChange, onBlur },
                                                fieldState: { isTouched }
                                            }: any) => (
                                                <div className='mb-4'>
                                                    <Form.Group className="overlap-label" controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="text" className={`${watchAllFields.email ? 'border-brand' : ''}`} onChange={onChange} onBlur={onBlur} />
                                                    </Form.Group>
                                                    {
                                                        (errors["email"]?.message || Boolean(errors["email"]?.message) || (isTouched && !watchAllFields.email) || (watchAllFields.email && !emailRegex.test(watchAllFields.email))) &&
                                                        <div className="login-error">
                                                            <Label
                                                                title={(errors.email?.message || watchAllFields.email
                                                                    ? "Enter valid email address"
                                                                    : "Please Enter Email.")}
                                                                modeError={true}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        />
                                        <Button disabled={!isValid} type="submit" className="btn btn-brand-1 w-100 mb-3" id="forget_password_btn">Send</Button>
                                        <Link to="/login" className="btn btn-outline-brand w-100">Back to Log in</Link>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;