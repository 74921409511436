import Logo from '../../assets/images/login-logo.svg'
import { Form, Button, InputGroup } from "react-bootstrap"
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./login.scss";
import { Controller, useForm } from 'react-hook-form';
import { setPasswordInterface } from '../../OpenInterfaces';
import { useEffect, useState } from 'react';
import { Label } from '../Common/Label/Label';
import WebService from '../../Services/WebService';
import { toast } from 'react-toastify';
import SuccesIcon from '../../assets/images/icon-success.svg'

const ResetPassword = () => {
    const { id } = useParams();
    const { handleSubmit, formState: { errors, isValid }, control, watch, getValues } = useForm<setPasswordInterface>({});
    const watchAllFields = watch();
    const navigate = useNavigate();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?])[A-Za-z\d~`!@#$%^&*()\-_=+{}[\]\\|;:"<>,./?]{8,20}$/;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [validateForm, setValidateForm] = useState<string>("");

    useEffect(() => {
        if (id) {
            validateToken()
        } else {
            navigate("/login")
        }
        // eslint-disable-next-line
    }, []);

    const validateToken = () => {
        WebService.postAPI({ action: "verified-token", body: { token: id } })
            .then((res: any) => {
                if (!res?.is_valid) {
                    navigate("/login")
                }
                setValidateForm("form")
            })
            .catch((e) => { });
    };

    const onSetPassword = (data: any) => {
        data.token = id;
        WebService.postAPI({ action: "set-password", body: data, id: "Reset-password-btn" })
            .then((res: any) => {
                toast.success(res?.message);
                setValidateForm("success")
            })
            .catch((e) => { });
    };

    return (
        <>
            <div className="login-page">
                <div className="row g-0 justify-content-center align-items-center min-vh-100">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                        <div className="w-100">
                            <div className="px-lg-5">
                               
                                {
                                    validateForm == 'form' &&
                                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                        <div className="text-center">
                                            <h2 className="mb-4 font-bold font-36">Reset password</h2>
                                            {/* <p className=" text-secondary font-14 mb-4 font-light pb-3">Choose a new password for your account</p> */}
                                        </div>
                                        <p className=" text-secondary font-14 mb-4">Choose a new password for your account</p>
                                        <form onSubmit={handleSubmit(onSetPassword)}>
                                            {/* Password */}
                                            <Controller
                                                control={control}
                                                name="password"
                                                rules={{
                                                    required: "Please Enter Password",
                                                    pattern: {
                                                        value: passwordRegex,
                                                        message: "Between 8 to 20 characters and at least one upper case, lower case, number and special character."
                                                    }
                                                }}
                                                render={({
                                                    field: { onChange, onBlur },
                                                    fieldState: { isTouched }
                                                }: any) => (
                                                    <div className="mb-3">
                                                        <InputGroup className="overlap-label">
                                                            <Form.Label>New Password</Form.Label>
                                                            <Form.Control type={showPassword ? "text" : "password"} onChange={onChange} onBlur={onBlur} />
                                                            <InputGroup.Text id="basic-addon2" className='cursor-pointer' onClick={() => setShowPassword(!showPassword)}> {showPassword ? <BsEyeSlash size={16} /> : <BsEye size={16} />}</InputGroup.Text>
                                                        </InputGroup>
                                                        {
                                                            (errors["password"]?.message || Boolean(errors["password"]?.message) || (isTouched && !watchAllFields.password) || (watchAllFields.password && !passwordRegex.test(watchAllFields.password))) &&
                                                            <div className="login-error">
                                                                <Label
                                                                    title={(errors.password?.message || watchAllFields.password
                                                                        ? "Between 8 to 20 characters and at least one upper case, lower case, number and special character." : "Please Enter Password.")}
                                                                    modeError={true}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                            />

                                            {/* Confirm Password */}
                                            <Controller
                                                control={control}
                                                name="comfirmpassword"
                                                rules={{
                                                    required: "Please Enter Password",
                                                    validate: (value: any) => {
                                                        const { password } = getValues();
                                                        return password === value || "Passwords must match";
                                                    }
                                                }}
                                                render={({
                                                    field: { onChange, onBlur },
                                                    fieldState: { isTouched }
                                                }: any) => (
                                                    <div className="mb-3">
                                                        <InputGroup className="overlap-label">
                                                            <Form.Label>Confirm New Password</Form.Label>
                                                            <Form.Control type={showPassword1 ? "text" : "password"} onChange={onChange} onBlur={onBlur} />
                                                            <InputGroup.Text id="basic-addon2" className='cursor-pointer' onClick={() => setShowPassword1(!showPassword1)}> {showPassword1 ? <BsEyeSlash size={16} /> : <BsEye size={16} />}</InputGroup.Text>
                                                        </InputGroup>
                                                        {
                                                            (errors["comfirmpassword"]?.message || Boolean(errors["comfirmpassword"]?.message) || (isTouched && !watchAllFields.comfirmpassword) || (watchAllFields.comfirmpassword && (watchAllFields.password != watchAllFields.comfirmpassword))) &&
                                                            <div className="login-error">
                                                                <Label
                                                                    title={(errors.comfirmpassword?.message || watchAllFields.comfirmpassword
                                                                        ? "Passwords Must Match" : "Please Enter Confirm Password.")}
                                                                    modeError={true}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                )}
                                            />

                                            <div className="text-center mb-3">
                                                <Button className="btn btn-brand-1 w-100" type='submit' disabled={!isValid} id='Reset-password-btn'>Reset password</Button>
                                            </div>
                                        </form>
                                        <Link to="/login" type="submit" className="btn btn-outline-brand w-100">Back to Log in</Link>
                                    </div>
                                }
                                {
                                    validateForm == 'success' &&
                                    <div className="card p-4 form-style-2 border-0 rounded-4 shadow login-card">
                                        <div className="text-center">
                                            <img src={SuccesIcon} width={100} className="mb-4" />
                                            <h3 className="mb-4 font-bold">Password reset successfully</h3>
                                        </div>
                                        <Link to="/login" className="btn btn-brand-1 w-100">Log in</Link>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default ResetPassword;