import { Form, Button, Row, Col } from "react-bootstrap"
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Label } from '../Common/Label/Label';
import { useEffect, useState } from 'react';
import WebService from '../../Services/WebService';
import { toast } from 'react-toastify';
import PhoneNumberInput from "../Common/PhoneNumberInput/PhoneNumberInput";
import { useBlocker, useNavigate } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import ExitConfirmModal from "./ExitConfirmModal";

interface profileForm {
    id: number;
    phone_code: any;
    phone: string;
    is_alternate_phone: string;
    alternate_phone_code: any;
    alternate_phone: string;
    country_code: any;
    alternate_country_code: any;
}

interface propData {
    isUpdate: Function;
    tab?: string;
    isFormDirty: Function;
    confirmTabChange: string;
    updateActiveTab: Function;
}

const PhoneInfo = (props: propData) => {
    const { handleSubmit, register, watch, setValue, control, reset, formState: { errors } } = useForm<profileForm>({ mode: "onSubmit", reValidateMode: "onChange" });
    const navigate = useNavigate();
    const { dirtyFields } = useFormState({ control });
    const watchAllFields = watch();
    const mobileRegex = /^[0-9]{8,10}$/;
    const [confirmExit, setConfirmExit] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return props.tab == "contact" && !HelperService.isEmptyObject(dirtyFields) && currentLocation.pathname !== nextLocation.pathname;
    });

    useEffect(() => {
        if (props.tab == "contact" && !HelperService.isEmptyObject(dirtyFields)) {
            props.isFormDirty(true, 'contact');
        } else {
            props.isFormDirty(false, 'contact');
        }
    }, [dirtyFields, watchAllFields]);

    useEffect(() => {
        if (props.tab == "contact" && props.confirmTabChange) {
            setConfirmExit(true);
        }
    }, [props.confirmTabChange])


    useEffect(() => {
        if (props.tab == "contact") {
            reset({});
            getUserContactDetail()
        }
    }, [props.tab]);

    const getUserContactDetail = () => {
        WebService.getAPI({ action: "personal/contact-information/detail" })
            .then((res: any) => {
                reset(res.result);
            }).catch((e: any) => { });
    };

    const onSave = async (data: profileForm) => {
        await WebService.postAPI({ action: "save/personal/contact-details", body: data, id: "contact_save_btn", })
            .then((res: any) => {
                toast.success(res.message);
                props.isUpdate();
                let tempData = { ...watchAllFields };
                reset({});
                reset(tempData);
            })
            .catch((e: any) => { });
    };



    return (
        <>
            <ExitConfirmModal show={confirmExit || blocker.state === "blocked"} isClose={async (e: boolean) => {
                if (e) {
                    WebService.addLoader('Save_and_exit_btn');
                    await onSave(watch());
                    WebService.removeLoader('Save_and_exit_btn');
                }
                reset({});
                blocker.state === "blocked" && blocker.proceed();
                if (confirmExit) {
                    navigate(`#${props.confirmTabChange}`, { replace: true });
                    props.updateActiveTab(props.confirmTabChange);
                    setConfirmExit(false);
                }
            }} />

            <form onSubmit={handleSubmit(onSave)}>
                <Row>
                    <Col style={{ maxWidth: '624px' }}>
                        <Form className="form-style-2">
                            <h4 className="font-medium font-20 mb-3 pb-1">Contact info</h4>
                            <Form.Group className="mb-4">
                                <Form.Label>Mobile phone<span className="text-danger">*</span></Form.Label>
                                <Controller
                                    control={control}
                                    name="phone"
                                    rules={{ required: false, pattern: mobileRegex }}
                                    render={({ field }: any) => (
                                        <div>
                                            <PhoneNumberInput selected={watchAllFields.phone_code} value={watchAllFields.phone} onChange={(e: any) => { field.onChange(e); }} onChangeCode={(e: any) => setValue("phone_code", e)} />
                                            {errors.phone && (
                                                <div className="login-error">
                                                    <Label
                                                        title={watchAllFields.phone ? "Please Enter Valid Mobile Number." : "Please Enter Mobile Number."}
                                                        modeError={true}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <p className="font-16">Alternate phone</p>
                                <Form.Check
                                    type="radio"
                                    id="Noothertelephone"
                                    label="No other telephone"
                                    value={"NO"}
                                    onClick={() => { setValue("alternate_phone", ""); setValue("alternate_phone_code", "") }}
                                    {...register("is_alternate_phone")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Home2"
                                    label="Home"
                                    value={"HOME"}
                                    onClick={() => { setValue("alternate_phone", ""); setValue("alternate_phone_code", 239) }}
                                    {...register("is_alternate_phone")}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Mobile2"
                                    label="Mobile"
                                    value={"MOBILE"}
                                    onClick={() => { setValue("alternate_phone", ""); setValue("alternate_phone_code", 239) }}
                                    {...register("is_alternate_phone")}
                                />
                            </Form.Group>

                            {
                                watchAllFields && watchAllFields.is_alternate_phone && watchAllFields.is_alternate_phone != "NO" &&
                                <Form.Group className="mb-4">
                                    <Controller
                                        control={control}
                                        name="alternate_phone"
                                        rules={{ required: false }}
                                        render={({ field }: any) => (
                                            <div>
                                                <PhoneNumberInput selected={watchAllFields.alternate_phone_code} value={watchAllFields.alternate_phone} onChange={(e: any) => { setValue("alternate_phone", e) }} onChangeCode={(e: any) => setValue("alternate_phone_code", e)} />
                                                {errors.alternate_phone && (
                                                    <div className="login-error">
                                                        <Label
                                                            title={watchAllFields.alternate_phone ? "Please Enter Valid Mobile Number." : "Please Enter Mobile Number."}
                                                            modeError={true}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    />
                                </Form.Group>
                            }

                        </Form>

                        <div className="text-center">
                            <Button type='submit' id='contact_save_btn' className="btn btn-brand-1 w-100">Save</Button>
                        </div>
                    </Col>
                </Row>
            </form >
        </>
    )
}
export default PhoneInfo;
